<template>
  <div id="app">
    <header :class="{loginRegister: isLoginPage}">
      <nav v-if="showHomeButton" tag="ul" id="navbar" class="navbar">
        <section class="secNavTexts">
          <router-link tag="li" to="/home" id="navHome" exact>
            <img src="../public/eSano_white.png">
          </router-link>
          <section v-if="isEditor">
            <router-link tag="li" :to="{ name: 'myStudies'}" id="navStudies" exact>
              {{ 'myStudiesTranslation.myStudies' | translate }}
            </router-link>

            <router-link tag="li" :to="{ name: 'myInterventions'}" id="navInterventions" exact>
              {{ 'myStudiesTranslation.myInterventions' | translate }}
            </router-link>
            <section>
              <button class="dropbtn" id="navMore" @click="showDropdownContent">
                {{ 'myStudiesTranslation.more' | translate }}
                <i class="fa fa-caret-down"></i>
              </button>
              <div class="dropdown-content" :id="dropdownId">
                <router-link tag="li" :to="{ name: 'moreStudies'}" id="navMoreStudies" exact>
                  {{ 'myStudiesTranslation.moreStudies' | translate }}
                </router-link>
                <router-link tag="li" :to="{ name: 'moreInterventions'}" id="navMoreInterventions" exact>
                  {{ 'myStudiesTranslation.moreInterventions' | translate }}
                </router-link>
              </div>
            </section>
          </section>
        </section>

        <div class="secNavButtons">
          <section
            v-if="isDevelopVersion"
            class="devMarker"
            :class="{
              devMarkerInt: $route.name === 'interventionEditor',
              devMarkerLesson: $route.name === 'lessonEditor' || $route.name === 'diaryEditor',
              devMarkerSkill: $route.name === 'skillEditor'
            }"
          >
            Develop
          </section>
          <i
            v-if="getAnnouncements.length > 0"
            id="navShowHideAnnouncements"
            class="fas fa-bell fa-lg annButton"
            :class="{annActive: showAnnouncements}"
            v-tooltip="$t('generalTranslation.showHideAnnouncements')"
            @click="showHideAnnouncements"
          ></i>
          <router-link
            v-if="isAdmin"
            tag="li"
            :to="{ name: 'adminUsers'}"
            id="navUsers"
            exact
            v-tooltip="$t('adminUsersTranslation.adminFunctions')"
          >
            <i class="fas fa-user-cog fa-lg"></i>
          </router-link>
          <router-link
            v-if="isAdmin"
            tag="li"
            :to="{ name: 'adminAnnouncements'}"
            id="navAnnouncements"
            exact
            v-tooltip="$t('adminAnnouncementsTranslation.adminAnnouncements')"
          >
            <i class="fas fa-bullhorn fa-lg"></i>
          </router-link>
          <router-link
            tag="li"
            to="/preferences"
            id="navPreferences"
            exact
            v-tooltip="$t('preferencesTranslation.preferences')"
            :class="{'spaceNotAdmin': !isAdmin}"
          >
            <i class="fas fa-cog fa-lg"></i>
          </router-link>
          <router-link
            tag="li"
            to="/logout"
            id="navLogout"
            v-tooltip="$t('logoutTranslation.logout')"
          >
            <i class="fa fa-sign-out-alt fa-lg"></i>
          </router-link>
        </div>
      </nav>

      <div v-if="!isLoginPage && $route.name != 'logout' && showAnnouncements && getAnnouncements.length > 0" id="announcement" class="wrapper announcement"
          :class="{textImportant: getAnnouncements[selectedAnnouncement].attributes.type == 'error',
          textTip: getAnnouncements[selectedAnnouncement].attributes.type == 'warning',
          textInfo: getAnnouncements[selectedAnnouncement].attributes.type == 'info',
          textSuccess: getAnnouncements[selectedAnnouncement].attributes.type == 'success'}">
        <p class="wrapper">
          <label class="alignCenter col secAnnLeft">
            {{getAnnouncements[selectedAnnouncement].attributes.title}}
          </label>
          <section class="announcementButtons closeAnnButton col">
            <button id="closeAnnouncement" @click="hideAnnouncements">
              <i class="fa fa-times fa-sm"></i>
            </button>
          </section>
        </p>
        <p>
          {{getAnnouncements[selectedAnnouncement].attributes.message}}
        </p>
        <div class="alignCenter announcementButtons">
          <button v-if="selectedAnnouncement > 0" @click="previousAnnouncement">
            <i class="fa fa-chevron-left fa-sm" id="announcementsBack"></i>
          </button>
          {{selectedAnnouncement + 1}} {{ 'generalTranslation.of' | translate }} {{getAnnouncements.length}}
          <button v-if="selectedAnnouncement < getAnnouncements.length - 1" @click="nextAnnouncement">
            <i class="fa fa-chevron-right fa-sm" id="announcementsNext"></i>
          </button>
        </div>
      </div>
      <router-view/>
    </header>

    <footer class="footer">
      <i
        :id="topButtonId"
        v-tooltip="$t('appTranslation.topButton')"
        class="fa fa-arrow-circle-up fa-2x"
        @click="topFunction()"
      ></i>
    </footer>
  </div>
</template>

<script>
import "floating-vue/dist/style.css";
import Vue from "vue";
import { mapGetters } from "vuex";
import { Quill } from "vue2-editor";
import { FONTS } from "./utils/fonts";

// Set available fonts in Vue2-Editor
const quillFonts = Quill.import("formats/font");
quillFonts.whitelist = FONTS.map(font => font.value);
Quill.register(quillFonts, true);


Vue.prototype.$urlBase = process.env.VUE_APP_API_URL;
Vue.prototype.$urlAuth = process.env.VUE_APP_AUTH_URL;
Vue.prototype.$url = Vue.prototype.$urlBase + "/api/v1";
Vue.prototype.$urlUploads = Vue.prototype.$urlBase + "/uploads";
Vue.prototype.$urlUploadsOld = ["https://aas2api.klips-ulm.de/uploads", "https://api.aas2.klips.ifp.uni-ulm.de/uploads", "https://intervention.api.aas2.klips.ifp.uni-ulm.de/uploads",
  "https://api.esano-trainings.de/uploads", "https://buddy.api.aas2.klips.ifp.uni-ulm.de/uploads", "https://api.esano-research.klips-ulm.de/uploads"]
Vue.prototype.$perPage = 15;
Vue.prototype.$defaultLessonPageColor = "#fefcf0";
Vue.prototype.$tokenTime = 60;
Vue.prototype.$enabledFeatures = ["deletion-requests"];
//research: ["buddy", "jitai", "progress", "skills"]
//all features: ["buddy", "jitai", "progress", "skills", "deletion-requests"]
Vue.prototype.$supportMail = Vue.prototype.$url.includes("research") ? "support.esano-research@klips-ulm.de" : "support@esano-trainings.de";

require('./app.css');

export default {
  name: 'app',

  data: function(){
    return {
      selectedAnnouncement: 0,
      showAnnouncements: false,
      topButtonId: "topButton",
      dropdownId: "dropdownContent"
    }
  },

  computed: {
    ...mapGetters([
      'getRoles',
      'getAnnouncements',
    ]),

    isEditor: function(){
      return this.getRoles.includes("editor")
    },

    isAdmin: function(){
      return this.getRoles.includes("admin")
    },

    isLoginPage: function(){
      return (this.$route.name === 'login' || this.$route.name === 'resetPassword')
    },

    showHomeButton: function(){
      return (this.$route.name != 'login' && this.$route.name != 'resetPassword' && this.$route.name != 'logout' && this.$route.name != 'privacy' && this.$route.name != 'imprint')
    },

    isDevelopVersion: function(){
      return !this.$url.includes("esano")
    }
  },

  created(){
    var self = this;
    window.onscroll = function(){
      if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById(self.topButtonId).style.display = "block";
      }else{
        document.getElementById(self.topButtonId).style.display = "none";
      }
    };
    window.onclick = function(e){
      if(!e.target.matches('.dropbtn')){
      var dropdownMenu = document.getElementById(self.dropdownId);
        if(dropdownMenu != null && dropdownMenu.classList.contains('showDropdown')){
          dropdownMenu.classList.remove('showDropdown');
        }
      }
    }
    //refresh on other pages than login, resetPassword, home, adminAnnouncements
    if(this.$route.name != "login" && this.$route.name != "resetPassword" && this.$route.name != "home" &&
        this.$route.name != "adminAnnouncements" && localStorage.getItem('announcements') != null){
      this.showAnnouncements = JSON.parse(localStorage.getItem('announcements')).show;
      this.selectedAnnouncement = JSON.parse(localStorage.getItem('announcements')).number;
      localStorage.removeItem('announcements');
    }
  },

  mounted(){
    window.addEventListener('unload', this.unloadFunction);
  },

  beforeDestroy(){
    window.removeEventListener('unload', this.unloadFunction);
  },

  watch:{
    $route(newVal, oldVal){
      if(newVal.name === "login" || newVal.name === "resetPassword" || newVal.name === "logout"){
        this.selectedAnnouncement = 0;
      }
      if(newVal.name === "home" && oldVal.name === "login" && this.getAnnouncements.length > 0){
        this.showAnnouncements = true;
      }
    },

    getAnnouncements(newVal, oldVal){
      if(localStorage.getItem('announcements') != null){
        this.showAnnouncements = JSON.parse(localStorage.getItem('announcements')).show;
        this.selectedAnnouncement = JSON.parse(localStorage.getItem('announcements')).number;
        localStorage.removeItem('announcements');
      }else if(newVal.length > 0 && oldVal.length < newVal.length){
        this.showAnnouncements = true;
        this.selectedAnnouncement = newVal.length - 1;
      }else if(this.selectedAnnouncement > newVal.length - 1){
        this.selectedAnnouncement = newVal.length - 1;
      }
    }
  },

  methods:{
    topFunction: function(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    showDropdownContent: function(){
      document.getElementById(this.dropdownId).classList.toggle("showDropdown");
    },

    previousAnnouncement: function(){
      this.selectedAnnouncement--;
    },

    nextAnnouncement: function(){
      this.selectedAnnouncement++;
    },

    hideAnnouncements: function(){
      this.showAnnouncements = false;
    },

    showHideAnnouncements: function(){
      this.showAnnouncements = !this.showAnnouncements;
    },

    unloadFunction: function(){
      localStorage.setItem('announcements', JSON.stringify({ show: this.showAnnouncements, number: this.selectedAnnouncement }));
    },
  }
}
</script>
