<template>
  <div id="interventionList" class="interventionList">
    <section class="smallerComp">
      <section class="wrapper" :class="{createImportButtons: usedInRoute === 'studyEditable' && getIsEditable}">
        <section v-if="showHeadline" class="headlineClass">
          <h1>
            {{headlineMyMore}}
            <i
              v-if="usedInRoute === 'moreInterventions'"
              class="far fa-question-circle fa-xs"
              v-tooltip="{ content: $t('myStudiesTranslation.moreInterventionsExplanation'), theme: 'info-tooltip' }"
            ></i>
          </h1>
        </section>
        <!--
        <button type="button" v-if="!loading && usedInRoute === 'studyEditable' && getIsEditable" id="buttonImportIntervention"
            class="createButton" @click="$emit('show-modal', {type: 'InterventionImport'})">
          {{ 'interventionTranslation.importIntervention' | translate }}
        </button>
        -->
        <button type="button" v-if="!loading && (usedInRoute === 'myInterventions' || (usedInRoute === 'studyEditable' && getIsEditable))"
            id="buttonCreateIntervention" class="createButton buttonForm" @click="$emit('show-modal', {type: 'InterventionCreate'})">
          {{ 'studyTranslation.newInterv' | translate }}
        </button>
      </section>

      <BaseSearch
        v-show="!loading && (interventions.length > 0 || noSearchResults)"
        id="searchInterventions"
        type="int-list"
        @get-new-list="getNewList"
        @reset-page="$emit('reset-page')"
      ></BaseSearch>

      <section v-if="!loading">
        <BasePagination
          id="paginationInterventionsTop"
          :numberOfPages="interventionListData.allPages"
          :currentPage="interventionListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <ul :class="{selectFromList: isUsedInCopyLesson && !disableWhileCopy}">
          <li v-for="(intervention, index) in interventions" :id="'entryIntervention' + intervention.id" :key="intervention.id" class="interventionElement"
              :class="{selectedForCopy: isUsedInCopyLesson && selectedIntervention.id === intervention.id}" @click="setInterventionForCopy(intervention, interventionRoles[index])">
            <section class="wrapper">
              <section class="col homeImageSection">
                <img v-if="intervention.attributes.picture && !isMoreInterventions" :src="getPictureLink(intervention.attributes.picture, intervention.attributes.study_id)">
                <i class="far fa-image fa-2x" v-else></i>
              </section>
              <section class="col homeDescriptionSection">
                <button type="button" :id="'buttonShowIntervention' + intervention.id" :disabled="!isStudyEditableOrMyInterventions" class="linkButton"
                    @click="getDetailsOfIntervention(intervention.id, interventionRoles[index], 1)">
                  <template v-if="!isUsedInStudy">
                    {{intervention.attributes.study_name}}
                    <i class="fas fa-angle-right"></i>
                  </template>
                  {{intervention.attributes.name}}
                </button>

                <p>
                  {{getInterventionTitle(intervention)}}
                </p>
                <p>
                  {{getInterventionDescription(intervention)}}
                </p>

                <!-- permissions -->
                <section v-if="interventionRoles[index] != 'Owner' && !isUsedInStudy" :id="'permissionsIntervention' + intervention.id" class="permissionSec">
                  <i class="far fas fa-pen" v-if="interventionRoles[index] === 'Collaborator'" :id="'collabPermissionIntervention' + intervention.id"></i>
                  <i class="far fa-copy" v-else-if="interventionRoles[index] === 'Access'" :id="'accessPermissionIntervention' + intervention.id"></i>
                  {{getPermissionText(index)}}
                </section>
              </section>

              <section v-if="isStudyEditableOrMyInterventions" class="col homeButtonSectionLarge">
                <i
                  class="fa fa-info-circle fa-2x homeButtons"
                  :id="'buttonShowInterventionDetails' + intervention.id"
                  v-tooltip="$t('myStudiesTranslation.details')"
                  @click="getDetailsOfIntervention(intervention.id, interventionRoles[index], 0)"
                ></i>

                <i
                  class="fas fa-search fa-2x homeButtons"
                  :id="'buttonShowInterventionPreview' + intervention.id"
                  v-tooltip="$t('interventionTranslation.preview')"
                  @click="showPreview(intervention.id)"
                ></i>

                <i
                  class="fa fa-copy fa-2x homeButtons"
                  :id="'buttonCopyIntervention' + intervention.id"
                  v-tooltip="$t('generalTranslation.copy')"
                  @click="copyInt(intervention)"
                ></i>

                <i
                  class="fa fa-upload fa-2x homeButtons uploadColor"
                  v-if="intervention.attributes.is_active"
                  v-tooltip="$t('myStudiesTranslation.interventionActive')"
                  disabled
                ></i>

                <i
                  class="fa fa-upload fa-2x homeButtons"
                  v-if="!intervention.attributes.is_active && isGlobalEcoach"
                  :id="'buttonPublishIntervention' + intervention.id"
                  v-tooltip="$t('myStudiesTranslation.activate')"
                  @click="showActivateModal(intervention)"
                ></i>

                <i
                  class="fa fa-trash fa-2x homeButtons"
                  v-if="!intervention.attributes.is_active && interventionRoles[index] === 'Owner'"
                  :id="'buttonDeleteIntervention' + intervention.id"
                  v-tooltip="$t('generalTranslation.delete')"
                  @click="showDeleteModal(intervention)"
                ></i>
              </section>
            </section>
          </li>
        </ul>
        <p v-if="interventions.length < 1" id="noInterventions" class="noAvailableElements">
          {{noInterventions}}
          <i
            class="far fa-question-circle"
            v-if="isStudyEditableOrMyInterventions && !noSearchResults"
            v-tooltip="{ content: createInterventionInstruction, theme: 'info-tooltip' }"
          ></i>
        </p>

        <BasePagination
          id="paginationInterventionsBottom"
          :numberOfPages="interventionListData.allPages"
          :currentPage="interventionListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
    </section>

    <BaseModal
      v-if="deleteInterventionVisible"
      id="deleteInterventionModal"
      :headerText="$t('interventionTranslation.deleteWarning', { name: selectedIntervention.attributes.name })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      @close-modal="closeModalDelete"
    ></BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import permissionHelperMixin from '../mixins/permissionHelper';
import BasePagination from './BasePagination.vue';
import BaseModal from './BaseModal.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'InterventionList',

  components: {
    BaseModal,
    BasePagination,
    BaseSearch
  },

  mixins: [httpHelper, permissionHelperMixin],

  props: {
    usedInRoute: {
      required: true,
      type: String,
    },

    //do not react in watch functions while copy intervention functionality is used
    disableWhileCopy: {
      required: true,
      type: Boolean,
    },

    interventionListData: { //with list, currentPage, allPages
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      interventions: [],
      deleteInterventionVisible: false,
      interventionRoles: [],
      incompleteLanguages: [],
      loading: true,
      filterTerm: "",
      selectedIntervention: "",
    }
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
      'getNotificationText',
      'getRoles',
      'getIsEditable'
    ]),

    studyId: function(){
      return this.isUsedInStudy ? Number(this.$route.params.studyId) : -1
    },

    isGlobalEcoach: function(){
      return this.getRoles.includes("ecoach")
    },

    isUsedInStudy: function(){
      return (this.usedInRoute === "studyEditable" || this.usedInRoute === 'studyNotEditable')
    },

    isUsedInCopyLesson: function(){
      return (this.usedInRoute === "copyLesson")
    },

    isStudyEditableOrMyInterventions: function(){
      return (this.usedInRoute === "studyEditable" || this.usedInRoute === 'myInterventions')
    },

    isCollaboratingInt: function(){
      return (this.usedInRoute === "myInterventions" || this.usedInRoute === "copyLesson")
    },

    isMoreInterventions: function(){
      return (this.usedInRoute === "moreInterventions" || this.getMyRoleForStudy === "None")
    },

    createInterventionInstruction: function(){
      if(this.usedInRoute === "myInterventions"){
        return Vue.i18n.translate('generalTranslation.noInterventionsInstruction')
      }else if(this.usedInRoute === "studyEditable"){
        return Vue.i18n.translate('generalTranslation.noInterventionsInstructionStudy')
      }else{
        return Vue.i18n.translate('generalTranslation.noInterventionsInstructionModal')
      }
    },

    showHeadline: function(){
      return this.usedInRoute === 'myInterventions' || this.usedInRoute === 'moreInterventions'
    },

    headlineMyMore: function(){
      if(this.usedInRoute === "myInterventions"){
        return Vue.i18n.translate('myStudiesTranslation.myInterventions')
      }else if(this.usedInRoute === "moreInterventions"){
        return Vue.i18n.translate('myStudiesTranslation.moreInterventions')
      }else{
        return ""
      }
    },

    noSearchResults: function(){
      return (this.interventions.length === 0 && this.filterTerm != '')
    },

    noInterventions: function(){
      return this.filterTerm === "" ? Vue.i18n.translate('generalTranslation.noInterventions') : Vue.i18n.translate('generalTranslation.noResults')
    },
  },

  watch:{
    'interventionListData.list'(newVal){
      this.interventions = newVal;
      this.loading = false;
      this.getMyStudyRoles();
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error" && !this.disableWhileCopy){
        this.loading = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_MYROLEFORSTUDY',
    ]),

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getNewInterventions(1);
    },

    getNewInterventions: function(page){
      this.loading = true;
      this.$emit('get-new-list', { page: page, filterTerm: this.filterTerm })
    },

    getDetailsOfIntervention: function(interventionId, role, view){
      this.closeNotification();

      if(this.isCollaboratingInt){
        this.SET_MYROLEFORSTUDY(role);
      }
      this.$emit('route-to', { name: 'interventionEditor', params: { interventionId: interventionId, view: view }});
    },

    showPreview: function(interventionId){
      this.closeNotification();
      this.$emit('route-to', { name: 'interventionPreview', params: { interventionId: interventionId }});
    },

    copyInt: function(intervention){
      if(intervention.id != ""){
        this.$emit('show-modal', {type: 'InterventionCopy', id: intervention.id, name: intervention.attributes.name, studyId: Number(intervention.attributes.study_id) });
      }
    },

    showDeleteModal: function(intervention){
      this.closeNotification();
      this.selectedIntervention = intervention;
      this.deleteInterventionVisible = true;
      this.$emit('show-modal', { type: 'InterventionDelete', value: true });
    },

    showActivateModal: function(intervention){
      this.closeNotification();
      //todo check translation status -> request intervention details
      /*
      var incomplete = "";
      if(!(typeof translationStatus === "undefined")){
        if(translationStatus != null){
          var languages = Object.keys(translationStatus);
          for(var language in languages){
            if(translationStatus[languages[language]] < 1){
              if(incomplete != ""){
                incomplete = incomplete + ", ";
              }
              incomplete = incomplete + languages[language];
            }
          }
          this.incompleteLanguages = incomplete;
          if(this.incompleteLanguages != ""){
            this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('myStudiesTranslation.warningLanguages') + "" + this.incompleteLanguages});
          }
        }else{
          this.incompleteLanguages = true;
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('myStudiesTranslation.warningInterventionDetails')});
        }
      }else{
        this.incompleteLanguages = "";
      }
      */

      this.$emit('show-modal', { type: 'InterventionPublish', value: true, selectedIntervention: intervention });
    },

    closeModalDelete: function(done){
      this.deleteInterventionVisible = false;
      this.$emit('show-modal', { type: 'InterventionDelete', value: false });

      if(done){
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.deleteInterventionLoad')});
        var requestPage = this.interventionListData.currentPage;
        if(this.interventions.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteInterventionRequest(this.selectedIntervention.id)
        .then(function (){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.deleteInterventionSuccess')});
          self.getNewInterventions(requestPage);
          self.selectedIntervention = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModalDelete(done) }, "");
        });
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    //my roles for interventions (access/collaborator/owner)
    getMyStudyRoles: function(){
      if(this.isCollaboratingInt){
        this.interventionRoles = this.getMyRoles(this.interventions, false, false);
      }else{
        this.interventionRoles = new Array(this.interventions.length);
        if(this.usedInRoute === "moreInterventions" || this.usedInRoute === "studyNotEditable"){
          this.interventionRoles.fill("None");
        }else{ //studyEditable
          this.interventionRoles.fill(this.getMyRoleForStudy);//is set because in StudyEditor
        }
      }
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.closeNotification();
        this.getNewInterventions(selectedNumber);
      }
    },

    setInterventionForCopy: function(intervention, myRole){
      if(this.isUsedInCopyLesson){
        this.selectedIntervention = intervention;
        this.$emit('item-selected', { interventionId: Number(intervention.id), studyId: Number(intervention.attributes.study_id),
          role: myRole, config: intervention.attributes.default_configuration });
      }
    },

    getPermissionText: function(index){
      if(this.interventionRoles[index] === "Collaborator"){
        return Vue.i18n.translate('permissionsTranslation.editPermission')
      }else if(this.interventionRoles[index] === "Access"){
        return Vue.i18n.translate('permissionsTranslation.copyPermission')
      }else{
        return ""
      }
    },

    getInterventionTitle: function(intervention){
      return intervention.attributes.title ? intervention.attributes.title :  Vue.i18n.translate('generalTranslation.noTitle')
    },

    getInterventionDescription: function(intervention){
      return intervention.attributes.description ? intervention.attributes.description : Vue.i18n.translate('generalTranslation.noDescription')
    },

    getPictureLink: function(interventionPicture, studyId){
      return this.$urlUploads + '/studies/' + studyId + '/' + interventionPicture
    },
  }
}
</script>
