<template>
  <div class="interventionPreview">
    <section v-if="!loading">
      <BaseMenuBar
        :nameList="[studyDetails.name, interventionDetails.name]"
        :idList="[studyId]"
      ></BaseMenuBar>

      <section>
        <section class="wrapper interventionPrev">
          <section class="col sec41">
            <section v-if="currentPage === 0" class="previewLanguage">
              <select :value="getSelectedLanguage" class="buttonForm" id="inputSelectedLanguage" @change="changeLanguage1($event.target.value)">
                <option value="" id="inputSelectedLanguageNone" disabled>
                  {{ $tlang(getSelectedLanguage, 'generalTranslation.chooseLanguage')}}
                </option>
                <option v-for="language in interventionDetails.locales" :id="'inputSelectedLanguage' + language" :key="language">
                  {{language}}
                </option>
              </select>
            </section>
          </section>
          <section class="col secArrow">
            <i v-if="currentPage > 0" class="fa fa-arrow-left fa-lg" id="buttonPreviewLastLesson" @click="getNewLesson(false)"></i>
          </section>
          <section class="col sec12">
            <select v-model="currentPage" id="inputPreviewPage" @change="getNewLessonSelect">
              <option value="" id="inputPreviewPageNone" disabled>
                {{ $tlang(getSelectedLanguage, 'interventionPreviewTranslation.selectLesson')}}
              </option>
              <option v-for="lesson in pages + 1" :id="'inputPreviewPage' + lesson" :value="Number(lesson - 1)" :key="lesson">
                {{navigationText(lesson - 1)}}
              </option>
            </select>

          </section>
          <section class="col secArrow">
            <i v-if="showNextPageArrow" class="fa fa-arrow-right fa-lg" id="buttonPreviewNextLesson" @click="getNewLesson(true)"></i>
          </section>
          <section class="col sec41">
          </section>
        </section>
      </section>

      <section v-if="currentPage === 0">
        <section class="wrapper intPreview">
          <section class="previewStart">
            <img v-if="pictureLink != ''" :src="pictureLink">
            <i class="far fa-image fa-2x" v-else></i>
            <h4>
              {{titleText}}
            </h4>
          </section>

          <section class="navButtons">
            <section class="navigationButtonsFirst">
              <ion-button slot="start" id="buttonStartIntervention" class="navigationButton" @click="getNewLesson(true)">
                {{ $tlang(getSelectedLanguage, 'interventionPreviewTranslation.startIntervention')}}
              </ion-button>
            </section>
          </section>
        </section>
      </section>

      <section v-if="0 < currentPage && currentPage < pages + 1">
        <section v-if="complete" class="col sectionRight88">
          <LessonPreview
            :isShownFromStart="true"
            :isDiary="false"
            :isSkill="false"
            :lessonDetails="lessonDetails"
            :interventionDetails="interventionDetails"
            @new-page="getNewLesson(true)"
          ></LessonPreview>
        </section>
        <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-else></i>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import questionConditionHelper from '../mixins/questionConditionHelper';
import BaseMenuBar from './BaseMenuBar.vue';
import BaseNotification from './BaseNotification.vue';
import LessonPreview from './LessonPreview.vue'

export default {
  name: 'InterventionPreview',

  components: {
    BaseMenuBar,
    BaseNotification,
    LessonPreview,
  },

  mixins: [httpHelper, elementJSONToPropsHelper, questionConditionHelper],

  data: function(){
    return {
      elements: [],
      pages: 0,
      currentPage: 0,
      complete: false,
      pictureLink: '',
      title: '',
      lessonDetails: [],
      interventionDetails: [],
      allLessons: [],
      loading: true,
      studyDetails: [],
      isRefreshed: false,
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getLessonElementsArr',
      'getUserId',
      'getMyRoleForStudy',
      'getIsEditable',
    ]),

    interventionId: function(){
      return Number(this.$route.params.interventionId)
    },

    studyId: function(){
      return Number(this.interventionDetails.study_id)
    },

    showNextPageArrow: function(){
      return (this.currentPage < this.pages)
    },

    titleText: function(){
      if(this.title != null && this.title != ""){
        return this.title
      }else{
        return Vue.i18n.translate('generalTranslation.noTitle')
      }
    },
  },

  watch:{
    getLessonElementsArr(newVal){
      this.SET_QUESTIONLABELLIST(this.getLabelList(newVal).list);
    },
  },

  mounted(){
    this.closeNotification();
    document.body.style.background = "#B1AFAD";
    this.getInitialData();
  },

  beforeRouteLeave(to, from, next){
    this.SET_QUESTIONLABELLIST([]);
    document.body.style.background = "white";
    next();
  },

  methods: {
    ...mapMutations([
      'SET_SELECTEDLANGUAGE',
      'SET_QUESTIONLABELLIST',
      'SET_LESSONELEMENTSARR',
    ]),

    getInitialData: function(){
      var self = this;
      this.requestInterventionDetails(this.interventionId)
      .then(function (response){
        self.interventionDetails = response;
        self.init();
        return self.requestCollaboratorsIncludingPermissions("page=0&id=" + self.getUserId, self.studyId, false, -1, -1)
      })
      .then(function (){
        return self.requestStudyDetails(self.studyId)
      })
      .then( function(response){
        self.studyDetails = response;
        return self.requestLessonsOfIntervention(self.interventionId, 0, self.getIsEditable, false, -1, -1, false)
      })
      .then( function(response){
        self.allLessons = response.allLessons;
        self.pages = response.allLessons.length;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getInitialData() }, "studyDetails");
      });
    },

    navigationText: function(page){
      if(page === 0){
        return Vue.i18n.translateIn(this.getSelectedLanguage, 'interventionPreviewTranslation.start')
      }else if(0 < page && page < this.pages + 1){
        return Vue.i18n.translateIn(this.getSelectedLanguage, 'generalTranslation.lesson') + " " + page + ": " +
          this.allLessons[page - 1].attributes.name;
      }else{
        return ""
      }
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    getNewLesson: function(nextLesson){
      this.complete = false;
      if(nextLesson){
        if(this.currentPage < this.pages){
          this.currentPage++;
        }else{
          this.currentPage = 0;
        }
      }else{
        this.currentPage--;
      }
      if(this.currentPage > 0 && this.currentPage < this.pages + 1){
        this.getLessonDetailsOfCurrentPage(this.allLessons[this.currentPage - 1].id);
      }
    },

    getNewLessonSelect: function(){
      this.complete = false;
      if(this.currentPage > 0 && this.currentPage < this.pages + 1){
        this.getLessonDetailsOfCurrentPage(this.allLessons[this.currentPage - 1].id);
      }
    },

    getLessonDetailsOfCurrentPage: function(lessonId){
      var self = this;
      this.requestLessonDetails(lessonId)
      .then(function (response){
        self.lessonDetails = response;
        return self.requestLessonElements(lessonId, false, false);
      })
      .then(function (response){
        self.SET_LESSONELEMENTSARR(response);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        self.complete = true;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getLessonDetailsOfCurrentPage(lessonId) }, "");
      });
    },

    changeLanguage1: function(language){
      this.closeNotification();
      this.SET_SELECTEDLANGUAGE(language);
      this.init();
    },

    init: function(){
      this.pictureLink = this.interventionDetails.picture ? this.$urlUploads  + "/studies/" + this.studyId + "/" + this.interventionDetails.picture : "";

      if(this.interventionDetails.locales == null){
        this.interventionDetails.locales = ["de", "en"];
      }
      if(!this.interventionDetails.locales.includes(this.getSelectedLanguage)){
        var language = (this.interventionDetails.locales.includes("de")) ? "de" : this.interventionDetails.locales[0];
        this.SET_SELECTEDLANGUAGE(language);
      }

      //translated attributes
      var index = this.interventionDetails.translations.findIndex(translation => translation.locale === this.getSelectedLanguage);
      this.title = (index != -1) ? this.interventionDetails.translations[index].title : "";
    },
  }
}
</script>
