import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getRoleIds',
    ]),
  },

  methods: {
    //get my roles for list (study, intervention, diary)
    //access/collaborator/owner
    getMyRoles: function(list, usedForStudy, activateBool){
      var roles = [];
      for(var item in list){
        if(list[item].relationships){
          var currentRoles = list[item].relationships.roles.data;
          var allRoles = [];
          var highestEditorRole;
          for(var role in currentRoles){
            allRoles.push(Number(currentRoles[role].id));
          }
          if(allRoles.includes(this.getRoleIds.owner)){
            highestEditorRole = "Owner";
          }else if(allRoles.includes(this.getRoleIds.collaborator)){
            highestEditorRole = "Collaborator";
          }else if(allRoles.includes(this.getRoleIds.access)){
            highestEditorRole = "Access";
          }else if(usedForStudy && allRoles.includes(this.getRoleIds.ecoachmanager)){
            if(!activateBool){
              highestEditorRole = "Access"; //ecoachmanager can only see content, not edit (-> is like access)
            }else{
              highestEditorRole = "Ecoachmanager";
            }
          }else{
            highestEditorRole = "None";
          }
          roles[item] = highestEditorRole;
        }else{
          roles[item] = "None";
        }
      }
      return roles
    },

    //returns list with ids of roles for owner, collaborator, access
    getRolesList: function(includedRolesList){
      var rolesList = {
        "owner": 3,
        "collaborator": 4,
        "access": 8,
        "ecoachmanager": 5
      }

      //change roles if ids not like this anymore
      for(var includedRole in includedRolesList){
        if(includedRolesList[includedRole].type === "roles"){
          if(includedRolesList[includedRole].attributes.slug === "study.owner"){
            rolesList.owner = Number(includedRolesList[includedRole].id);
          }else if(includedRolesList[includedRole].attributes.slug === "study.collaborator"){
            rolesList.collaborator = Number(includedRolesList[includedRole].id);
          }else if(includedRolesList[includedRole].attributes.slug === "study.access"){
            rolesList.access = Number(includedRolesList[includedRole].id);
          }else if(includedRolesList[includedRole].attributes.slug === "study.ecoachmanager"){
            rolesList.access = Number(includedRolesList[includedRole].id);
          }
        }
      }

      return rolesList
    }
  }
};
