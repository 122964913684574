<template>
  <div class="elementsTextPreview">
    <section class="ulPreview">
      <section
        class="wrapper"
        :class="{
          textImportant: propsObj.type == 'important',
          textTip: propsObj.type == 'tip',
          textInfo: propsObj.type == 'info',
          textSuccess: propsObj.type == 'success',
          textHighlight: propsObj.type == 'highlight',
          secIcon: propsObj.type != 'none',
        }"
      >
        <section v-if="typeWithIcon" class="secIconLeft col">
          <ion-icon :name="nameForIcon" size="large"></ion-icon>
        </section>
        <section :class="{ secIconRight: typeWithIcon, col: typeWithIcon }">
          <section class="question-text text ql-editor">
            <p>
              <span v-html="sanitizeHTML(replaceReferences(translation))"></span>
            </p>
          </section>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import questionConditionHelper from "../mixins/questionConditionHelper";
import utils from "../mixins/utils";

export default {
  name: "ElementsTextPreview",

  mixins: [questionConditionHelper, utils],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },
  },

  computed: {
    typeWithIcon: function() {
      return this.propsObj.type != "" && this.propsObj.type != "none" && this.propsObj.type != "highlight";
    },

    nameForIcon: function() {
      switch (this.propsObj.type) {
        case "tip":
          return "bulb";
        case "important":
          return "alert";
        case "info":
          return "information-circle-outline";
        case "success":
          return "checkmark";
        default:
          return "";
      }
    },
  },
}
</script>
