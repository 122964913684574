var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('header',{class:{loginRegister: _vm.isLoginPage}},[(_vm.showHomeButton)?_c('nav',{staticClass:"navbar",attrs:{"tag":"ul","id":"navbar"}},[_c('section',{staticClass:"secNavTexts"},[_c('router-link',{attrs:{"tag":"li","to":"/home","id":"navHome","exact":""}},[_c('img',{attrs:{"src":require("../public/eSano_white.png")}})]),(_vm.isEditor)?_c('section',[_c('router-link',{attrs:{"tag":"li","to":{ name: 'myStudies'},"id":"navStudies","exact":""}},[_vm._v(" "+_vm._s(_vm._f("translate")('myStudiesTranslation.myStudies'))+" ")]),_c('router-link',{attrs:{"tag":"li","to":{ name: 'myInterventions'},"id":"navInterventions","exact":""}},[_vm._v(" "+_vm._s(_vm._f("translate")('myStudiesTranslation.myInterventions'))+" ")]),_c('section',[_c('button',{staticClass:"dropbtn",attrs:{"id":"navMore"},on:{"click":_vm.showDropdownContent}},[_vm._v(" "+_vm._s(_vm._f("translate")('myStudiesTranslation.more'))+" "),_c('i',{staticClass:"fa fa-caret-down"})]),_c('div',{staticClass:"dropdown-content",attrs:{"id":_vm.dropdownId}},[_c('router-link',{attrs:{"tag":"li","to":{ name: 'moreStudies'},"id":"navMoreStudies","exact":""}},[_vm._v(" "+_vm._s(_vm._f("translate")('myStudiesTranslation.moreStudies'))+" ")]),_c('router-link',{attrs:{"tag":"li","to":{ name: 'moreInterventions'},"id":"navMoreInterventions","exact":""}},[_vm._v(" "+_vm._s(_vm._f("translate")('myStudiesTranslation.moreInterventions'))+" ")])],1)])],1):_vm._e()],1),_c('div',{staticClass:"secNavButtons"},[(_vm.isDevelopVersion)?_c('section',{staticClass:"devMarker",class:{
            devMarkerInt: _vm.$route.name === 'interventionEditor',
            devMarkerLesson: _vm.$route.name === 'lessonEditor' || _vm.$route.name === 'diaryEditor',
            devMarkerSkill: _vm.$route.name === 'skillEditor'
          }},[_vm._v(" Develop ")]):_vm._e(),(_vm.getAnnouncements.length > 0)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('generalTranslation.showHideAnnouncements')),expression:"$t('generalTranslation.showHideAnnouncements')"}],staticClass:"fas fa-bell fa-lg annButton",class:{annActive: _vm.showAnnouncements},attrs:{"id":"navShowHideAnnouncements"},on:{"click":_vm.showHideAnnouncements}}):_vm._e(),(_vm.isAdmin)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('adminUsersTranslation.adminFunctions')),expression:"$t('adminUsersTranslation.adminFunctions')"}],attrs:{"tag":"li","to":{ name: 'adminUsers'},"id":"navUsers","exact":""}},[_c('i',{staticClass:"fas fa-user-cog fa-lg"})]):_vm._e(),(_vm.isAdmin)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('adminAnnouncementsTranslation.adminAnnouncements')),expression:"$t('adminAnnouncementsTranslation.adminAnnouncements')"}],attrs:{"tag":"li","to":{ name: 'adminAnnouncements'},"id":"navAnnouncements","exact":""}},[_c('i',{staticClass:"fas fa-bullhorn fa-lg"})]):_vm._e(),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('preferencesTranslation.preferences')),expression:"$t('preferencesTranslation.preferences')"}],class:{'spaceNotAdmin': !_vm.isAdmin},attrs:{"tag":"li","to":"/preferences","id":"navPreferences","exact":""}},[_c('i',{staticClass:"fas fa-cog fa-lg"})]),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('logoutTranslation.logout')),expression:"$t('logoutTranslation.logout')"}],attrs:{"tag":"li","to":"/logout","id":"navLogout"}},[_c('i',{staticClass:"fa fa-sign-out-alt fa-lg"})])],1)]):_vm._e(),(!_vm.isLoginPage && _vm.$route.name != 'logout' && _vm.showAnnouncements && _vm.getAnnouncements.length > 0)?_c('div',{staticClass:"wrapper announcement",class:{textImportant: _vm.getAnnouncements[_vm.selectedAnnouncement].attributes.type == 'error',
        textTip: _vm.getAnnouncements[_vm.selectedAnnouncement].attributes.type == 'warning',
        textInfo: _vm.getAnnouncements[_vm.selectedAnnouncement].attributes.type == 'info',
        textSuccess: _vm.getAnnouncements[_vm.selectedAnnouncement].attributes.type == 'success'},attrs:{"id":"announcement"}},[_c('p',{staticClass:"wrapper"},[_c('label',{staticClass:"alignCenter col secAnnLeft"},[_vm._v(" "+_vm._s(_vm.getAnnouncements[_vm.selectedAnnouncement].attributes.title)+" ")]),_c('section',{staticClass:"announcementButtons closeAnnButton col"},[_c('button',{attrs:{"id":"closeAnnouncement"},on:{"click":_vm.hideAnnouncements}},[_c('i',{staticClass:"fa fa-times fa-sm"})])])]),_c('p',[_vm._v(" "+_vm._s(_vm.getAnnouncements[_vm.selectedAnnouncement].attributes.message)+" ")]),_c('div',{staticClass:"alignCenter announcementButtons"},[(_vm.selectedAnnouncement > 0)?_c('button',{on:{"click":_vm.previousAnnouncement}},[_c('i',{staticClass:"fa fa-chevron-left fa-sm",attrs:{"id":"announcementsBack"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.selectedAnnouncement + 1)+" "+_vm._s(_vm._f("translate")('generalTranslation.of'))+" "+_vm._s(_vm.getAnnouncements.length)+" "),(_vm.selectedAnnouncement < _vm.getAnnouncements.length - 1)?_c('button',{on:{"click":_vm.nextAnnouncement}},[_c('i',{staticClass:"fa fa-chevron-right fa-sm",attrs:{"id":"announcementsNext"}})]):_vm._e()])]):_vm._e(),_c('router-view')],1),_c('footer',{staticClass:"footer"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('appTranslation.topButton')),expression:"$t('appTranslation.topButton')"}],staticClass:"fa fa-arrow-circle-up fa-2x",attrs:{"id":_vm.topButtonId},on:{"click":function($event){return _vm.topFunction()}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }