<template>
  <div class="elementsQuestionPreview">
    <section v-if="isYesNoQuestion" class="question">
      <p :class="{requiredLabel: showRequired, notRequiredLabel: !showRequired}">
        <ion-label text-wrap>
          {{(translation && translation.question) ? replaceReferences(translation.question) : ""}}
        </ion-label>
      </p>
      <ion-button :id="'buttonPreviewQuestion' + propsObj.id + 'Yes'" class="notSelectedButton button button-md button-solid button-solid-md hydrated ionYesButton"
          :class="{selectedButton: selectedValYesNo}" @click="selectedValYesNo = true">
        {{ $tlang(getSelectedLanguage, 'generalTranslation.yes')}}
      </ion-button>
      <ion-button :id="'buttonPreviewQuestion' + propsObj.id + 'No'" class="button button-md button-solid button-solid-md hydrated"
          :class="{selectedButton: buttonIsSelected, notSelectedButton: !buttonIsSelected}" @click="selectedValYesNo = false">
        {{ $tlang(getSelectedLanguage, 'generalTranslation.no')}}
      </ion-button>
    </section>

    <!--
    for the following question types notice difference between value (for ionChange) & val (for value)
    (i.e. selectedValueSingleMultiple & selectedValQuestion) -> see comments below on watch function & init props
    -->

    <!-- v-show to init answer in mounted -> do not change to v-if -->
    <section v-show="isSingleMultipleChoiceQuestion" :class="{required: showRequired}" class="ionQuestionSingleMulitpleDate">
      <ion-item :key="singleMultipleChoiceKey" lines="none" align-items-center text-wrap>
        <ion-label text-wrap>
          {{(translation && translation.question) ? replaceReferences(translation.question) : ""}}
        </ion-label>
        <ion-select :multiple="isMultipleChoiceQuestion" :id="singleMultipleId" text-wrap :cancel-text="$tlang(getSelectedLanguage, 'generalTranslation.abort')"
            @ionChange="selectedValueSingleMultiple = $event.target.value">
          <ion-select-option v-for="(answer, index) in answersArray" :value="propsObj.values[index]" :key="index" text-wrap>
            <ion-label>
              {{replaceReferences(answer)}}
            </ion-label>
          </ion-select-option>
        </ion-select>
      </ion-item>
    </section>

    <section v-if="isSliderQuestion || isDateOrTimeQuestion || isTextQuestion" :class="{requiredLabel: showRequired && isSliderQuestion, notRequiredLabel: !showRequired && isSliderQuestion,
      required: showRequired && !isSliderQuestion, ionQuestionText: isTextQuestion}" key="previewQuestionLabel">
      <ion-label text-wrap>
        {{(translation && translation.question) ? replaceReferences(translation.question) : ""}}
      </ion-label>
    </section>

    <div v-if="isSliderQuestion" class="sliderDiv" fxLayout="column" key="previewSlider">
      <div class="range-slider-labels">
        <ion-label class="labelLeft" slot="start">
          {{(translation && translation.answers) ? translation.answers[0].label : ""}}
        </ion-label>
        <h4>
          {{(selectedValueSlider === "") ? (selectedValQuestion === "" ? "" : selectedValQuestion) : selectedValueSlider}}
        </h4>
        <ion-label class="labelRight" slot="end">
          {{(translation && translation.answers) ? translation.answers[1].label : ""}}
        </ion-label>
      </div>
      <div :class="{notAnswered: sliderNotAnswered}">
        <ion-item lines="none" class="ionNoPadding questionSlider" text-wrap>
          <ion-range :value="(selectedValQuestion != '') ? selectedValQuestion : propsObj.values.start" pin="true" snaps="true" :min="propsObj.values.min" :max="propsObj.values.max"
            :step="propsObj.values.step" debounce="50" :id="'inputPreviewQuestionSlider' + propsObj.id" :class="{sliderAnswered: selectedValQuestion != ''}"
            @ionFocus="selectedValueSlider = $event.target.value" @ionChange="selectedValueSlider = $event.target.value">
          </ion-range>
        </ion-item>
      </div>
    </div>

    <section v-if="isDateOrTimeQuestion" key="previewDate" :class="{ required: showRequired }" class="ionQuestionDate">
      <flatPickr
        v-model="selectedValDateISOFormat"
        :config="config"
        :id="'inputPreviewQuestionDate' + propsObj.id"
        :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
        @on-change="newValueDate"
      />
    </section>

    <ion-item v-else-if="isTextQuestion" key="previewTextStringArea" :class="{required: showRequired}" class="ionQuestionText" lines="none" text-wrap :id="ionSelectIdText">
      <ion-input type="text" v-if="isShortTextQuestion" :value="selectedValQuestion" :id="'inputPreviewQuestionTextShort' + propsObj.id" key="previewTextString" @ionChange="textAnswer = $event.target.value"></ion-input>

      <ion-textarea v-else :value="selectedValQuestion" :id="'inputPreviewQuestionTextLong' + propsObj.id" key="previewTextArea" :class="{diaryTextArea: isDiary}"
        @ionChange="textAnswer = $event.target.value"></ion-textarea>
    </ion-item>

    <section v-else-if="isQuestionTable" key="previewQuestionTable" :class="{required: showRequired, wideTable: numberOfValues > 5}" class="questionTable">
      <!--
      <div class="wrapper">
        <div class="col sec25">
        </div>
        <div class="col secQuestionTable">
          <div v-for="n in (propsObj.values.max - propsObj.values.min + 1)" :key="n" class="col textMiddle" text-wrap>
            {{replaceReferences(translation.answers[propsObj.values.min + n - 1])}}
          </div>
        </div>
      </div>
      -->
      <section v-for="(question, index) in translation.question" :key="index" class="wrapper">
        <ion-label text-wrap class="ionLabelText">
          {{replaceReferences(question)}}
        </ion-label>
        <ion-radio-group :value="selectedValQuestion[index]" :id="'inputPreviewQuestionTable' + propsObj.id + '-' + index"
            @ionChange="selectedValueTable = Number($event.target.value); selectedValueTableIndex = index" class="col secQuestionTable">
          <ion-item v-for="m in numberOfValues" :key="m" lines="none" class="col" text-wrap>
            <ion-label>
              {{(translation && translation.answers) ? replaceReferences(translation.answers[propsObj.values.min + m - 1]) : ""}}
            </ion-label>
            <ion-radio slot="start" :value="propsObj.values.min + m - 1" :id="'inputPreviewQuestionTable' + propsObj.id + '-' + index + '-' + (propsObj.values.min + m - 1)"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </section>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/de.js';
import questionConditionHelper from '../mixins/questionConditionHelper';

export default {
  name: 'ElementsQuestionPreview',

  components: {
    flatPickr
  },

  mixins: [questionConditionHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    repetitionIndex: {
      required: true,
      type: Number,
    },

    isDiary: {
      required: true,
      type: Boolean
    },

    showRequiredQuestions: {
      required: false,
      type: Boolean
    },
  },

  data: function(){
    return {
      initBool: false,
      singleMultipleId: '',
      ionSelectIdText: '',
      selectedValQuestion: "",
      selectedValYesNo: '',
      selectedValuePreview: '',
      selectedValDateISOFormat: null,
      selectedValueSlider: "",
      selectedValueSingleMultiple: "",
      selectedValueTable: -1,
      selectedValueTableIndex: -1,
      textAnswer: "",
      initDate: false,
      sliderNotAnswered: true,
      singleMultipleChoiceKey: 0,
    }
  },

  computed: {
    ...mapGetters([
      'getPreviewAnswers',
      'getSelectedLanguage',
    ]),

    config: function() {
      return {
        altFormat: this.displayFormat[this.propsObj.selectedQuestionProp],
        altInput: true,
        dateFormat: 'U',
        enableTime: this.isTimeQuestion,
        time_24hr: this.show24hrs,
        noCalendar: !this.isDateQuestion,
        defaultHour: 0,
        defaultMinute: 0,
        locale: this.getSelectedLanguage,
        position: "auto center",
      };
    },

    show24hrs: function(){
      return Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.ampm') === "false"
    },

    buttonIsSelected: function(){
      return (typeof this.selectedValYesNo === 'boolean' && !this.selectedValYesNo)
    },

    displayFormat: function(){
      return {
        "TextDate": Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.dateFormat'),
        "TextDateTime": Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.dateFormat') + " " +
          Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.timeFormat'),
        "TextTime": Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.timeFormat')
      }
    },

    showRequired: function(){
      return (this.propsObj.questionReq && this.showRequiredQuestions && (!this.getPreviewAnswers[this.propsObj.id] || this.getPreviewAnswers[this.propsObj.id].length === 0 ||
        this.getPreviewAnswers[this.propsObj.id][this.repetitionIndex] === ""))
    },

    isYesNoQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'YesNoSwitch'
    },

    isSingleMultipleChoiceQuestion: function(){
      return (this.propsObj.selectedQuestionProp === 'SingleChoice' || this.isMultipleChoiceQuestion)
    },

    isMultipleChoiceQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'MultipleChoice'
    },

    isSliderQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'Slider'
    },

    isDateQuestion: function(){
      return (this.propsObj.selectedQuestionProp === 'TextDate' || this.propsObj.selectedQuestionProp === 'TextDateTime')
    },

    isTimeQuestion: function(){
      return (this.propsObj.selectedQuestionProp === 'TextTime' || this.propsObj.selectedQuestionProp === 'TextDateTime')
    },

    isDateOrTimeQuestion: function(){
      return (this.isDateQuestion || this.isTimeQuestion)
    },

    isTextQuestion: function(){
      return (this.isShortTextQuestion || this.propsObj.selectedQuestionProp === 'TextArea')
    },

    isShortTextQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'TextString'
    },

    isQuestionTable: function(){
      return this.propsObj.selectedQuestionProp === 'QuestionTable'
    },

    numberOfValues: function(){
      return (this.propsObj.values.max - this.propsObj.values.min + 1)
    },

    evaluatedReferences: function(){
      var referenceString = "";
      if(this.isSingleMultipleChoiceQuestion && this.translation && this.translation.answers){
        for(var answ in this.translation.answers){
          referenceString += this.replaceReferences(this.translation.answers[answ]);
        }
      }
      return referenceString
    },

    answersArray: function(){
      return (this.translation && this.translation.answers) ? this.translation.answers : []
    }
  },

  watch:{
    //preview
    //on change of questions these variables are set -> set other values & then this.selectedValuePreview = newVal; -> see selectedValuePreview watch function, which sets the new preview values
    selectedValYesNo: function(newVal){
      if(!this.initBool){
        this.selectedValuePreview = newVal;
      }else{
        this.initBool = false;
      }
    },

    selectedValueSingleMultiple: function(newVal) {
      if (newVal && this.isSingleMultipleChoiceQuestion) {
        this.selectedValuePreview = newVal;
      }
    },

    selectedValueSlider: function(newVal){
      if(!(typeof newVal === "undefined") && newVal != null){
        this.selectedValuePreview = String(newVal);
        this.sliderNotAnswered = false;
      }
    },

    selectedValueTable: function(newVal){
      if(this.selectedValueTableIndex != -1){
        var oldAnswers = new Array(this.translation.question.length);
        oldAnswers.fill(null)
        if(this.getPreviewAnswers[this.propsObj.id] && this.getPreviewAnswers[this.propsObj.id][this.repetitionIndex]){
          oldAnswers = this.getPreviewAnswers[this.propsObj.id][this.repetitionIndex];
        }
        oldAnswers[this.selectedValueTableIndex] = newVal;
        this.selectedValueTableIndex = -1;
        this.selectedValueTable = -1;

        //json stringify parse needed because only one value of array changes -> else not detected by selectedValuePreview change function
        this.selectedValuePreview = JSON.parse(JSON.stringify(oldAnswers));
      }
    },

    textAnswer: function(newVal){
      this.selectedValuePreview = newVal;
    },

    selectedValuePreview: function(newVal) {
      if (newVal != null) {
        const newAnswers = this.getPreviewAnswers;
        if (!newAnswers[this.propsObj.id]) {
          newAnswers[this.propsObj.id] = {};
        }
        newAnswers[this.propsObj.id][this.repetitionIndex] = newVal;
        this.SET_PREVIEWANSWERS(JSON.parse(JSON.stringify(newAnswers)));
      }
    },

    evaluatedReferences: function(){
      //reload ion-select on change of answers (references could have changed; if those are in selected answer, the text is not updated automatically)
      if(this.isSingleMultipleChoiceQuestion && this.getPreviewAnswers[this.propsObj.id] && this.getPreviewAnswers[this.propsObj.id][this.repetitionIndex]){
        this.singleMultipleChoiceKey++;
        setTimeout(() => {
          //value attribute is not used -> has to be set
          document.getElementById(this.singleMultipleId).value = this.getPreviewAnswers[this.propsObj.id][this.repetitionIndex];
        }, 10);
      }
    }
  },

  created(){
    this.singleMultipleId = "inputPreviewQuestionSingleMultiple" + this.propsObj.id;
    this.ionSelectIdText = "inputPreviewQuestionText" + this.propsObj.id;
  },

  mounted(){
    if(this.isTextQuestion){
      const style = document.createElement('style');
      style.textContent = `div.item-inner { padding-right: 0px !important; } div.item-native { padding-left: 0px !important; }`;
      document.getElementById(this.ionSelectIdText).shadowRoot.appendChild(style);

    }else if(this.isSingleMultipleChoiceQuestion){
      const style = document.createElement('style');
      style.textContent = `div.select-text { white-space: normal; text-overflow: unset; word-break: break-word; line-height: normal; }
        div.select-icon { align-self: center }`;
      document.getElementById(this.singleMultipleId).shadowRoot.appendChild(style);
    }

    var arr = this.getPreviewAnswers[this.propsObj.id];
    if(Object.keys(this.getPreviewAnswers).length > 0){
      //set selectedValQuestion etc. which are used to display the old values
      //-> selectedValuePreview watcher is not triggered on init
      if(!this.isDateOrTimeQuestion){
        if(arr != null){
          if(Object.keys(arr).length != 0){
            switch(this.propsObj.selectedQuestionProp){
              case "YesNoSwitch":
                this.initBool = true;
                this.selectedValYesNo = arr[this.repetitionIndex];
                break;

              case "SingleChoice":
              case "MultipleChoice":
                if(arr[this.repetitionIndex] != ""){
                  document.getElementById(this.singleMultipleId).value = arr[this.repetitionIndex];
                }
                break;

              default:
                this.selectedValQuestion = arr[this.repetitionIndex];
                if(this.propsObj.selectedQuestionProp === "Slider" && this.selectedValQuestion != ""){
                  this.sliderNotAnswered = false;
                }
                break;
            }
          // }else if(this.propsObj.selectedQuestionProp === "Slider"){
          //   this.selectedValQuestion = this.propsObj.values.start;
          }
        }
      }else if(arr != undefined && Object.keys(arr).length > 0){
        if(arr != null){
          this.initDate = true;
          //date is in UTC -> convert to timezone of user
          var dateVal = new Date(arr[this.repetitionIndex]);
          dateVal.setFullYear(dateVal.getUTCFullYear());
          dateVal.setMonth(dateVal.getUTCMonth());
          dateVal.setDate(dateVal.getUTCDate());
          dateVal.setHours(dateVal.getUTCHours());
          dateVal.setMinutes(dateVal.getUTCMinutes());
          this.selectedValDateISOFormat = dateVal;
        }
      }
    // }else if(this.propsObj.selectedQuestionProp === "Slider"){
    //   this.selectedValQuestion = this.propsObj.values.start;
    }
  },

  methods:{
    ...mapMutations([
      'SET_PREVIEWANSWERS',
    ]),

    newValueDate: function(selectedDates) {
      if (!this.initDate) {
        if (selectedDates.length > 0) {
          const dateVal = new Date(selectedDates[0]);
          //newval is date in timezone of user -> has to be in utc
          this.selectedValuePreview = new Date(
            Date.UTC(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes()),
          );
        }
      } else {
        this.initDate = false;
      }
    },
  }
}
</script>
