<template>
  <div class="diaryEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name, diaryDetails.name]"
        :idList="[studyId]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <section v-show="selectedView === 0">
        <button type="button" id="buttonExportDiary" @click="exportDiary">
          {{ 'interventionTranslation.export' | translate }}
        </button>
      </section>

      <BaseSelectLanguage
        v-if="selectedView != 2 && diaryDetails.locales"
        :localesArray="diaryDetails.locales"
        :selectedLanguage2="selectedLanguage2"
        :isTranslationMode="isTranslationMode"
        @close-notification="closeNotification"
        @change-language2="changeLanguage2"
        @change-translationmode="changeTranslationMode"
      ></BaseSelectLanguage>

      <section v-show="selectedView === 0" :id="diaryDetailsFormId" class="wrapper">
        <section v-show="!loadingLanguage">
          <!-- normal language (left) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <InterventionEditorDetails
              :interventionDetails="diaryDetails"
              :translation="currentTranslation"
              :isTranslation="false"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguage2"
              :isDiary="true"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @show-modal="showModal"
            ></InterventionEditorDetails>
          </section>

          <!-- translation language (right) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <section v-if="isTranslationMode && getIsEditable">
              <InterventionEditorDetails
                :interventionDetails="diaryDetails"
                :translation="currentTranslation2"
                :isTranslation="true"
                :isTranslationMode="isTranslationMode"
                :selectedLanguageForTranslation="selectedLanguage2"
                :isDiary="true"
                :studyId="studyId"
                @close-notification="closeNotification"
                @change-attribute="changeAttributeForDetails"
                @show-modal="showModal"
              ></InterventionEditorDetails>
            </section>
          </section>

          <section class="wrapper">
            <button type="submit" id="buttonSaveDiaryDetails" :disabled="isSaved" @click="saveDiaryDetails">
              {{ 'generalTranslation.save' | translate }}
            </button>
          </section>
        </section>

        <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
      </section>

      <section v-if="!loadingElements" id="lessonElements">
        <LessonEditorElements
          v-show="selectedView === 1"
          :editorModalsVisible="warningChangesVisible"
          :lessonDetails="diaryDetailsComplete"
          :interventionDetails="{'study_id': studyId}"
          :formChanged="formChanged"
          :isTranslationMode="isTranslationMode"
          :selectedLanguageForTranslation="selectedLanguage2"
          :isDiary="true"
          :isSkill="false"
          :studyId="studyId"
          :currentPageLessonElements="currentPageLessonElements"
          @close-notification="closeNotification"
          @show-modal="showModal"
          @route-to="routeTo"
        ></LessonEditorElements>
      </section>

      <section v-show="selectedView === 2" id="translationStatus" class="wrapper">
        <div v-if="diaryDetails.translation_status != null" key="progressDiv">
          <section v-for="language in Object.keys(diaryDetails.translation_status)" :key="language">
            <section class="wrapper">
              <section class="col sec3">
                {{language}}
              </section>
              <section class="col sec25">
                <progress :value="diaryDetails.translation_status[language]" max="1"></progress>
              </section>
            </section>
          </section>
        </div>
        <div v-else key="noProgressDiv">
          {{ 'interventionTranslation.noProgress' | translate }}
        </div>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="warningText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="resolvePromise"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import router from '../router';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import BaseMenuBar from './BaseMenuBar.vue';
import BaseTabs from './BaseTabs.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BaseSelectLanguage from './BaseSelectLanguage.vue';
import InterventionEditorDetails from './InterventionEditorDetails.vue';
import LessonEditorElements from './LessonEditorElements.vue';

export default {
  name: 'DiaryEditor',

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BaseSelectLanguage,
    InterventionEditorDetails,
    LessonEditorElements
  },

  mixins: [httpHelper, elementJSONToPropsHelper],

  props: ['view'],

  data: function(){
    return {
      diaryDetails: [],
      diaryDetailsComplete: [],
      selectedLanguage2: '',
      warningChangesVisible: false,
      nextRouteName: '',
      isSaved: false,
      selectedView: 1,
      loadingLanguage: true,
      formChanged: false, //true if diary details changed by user & not yet saved
      isTranslationMode: false,
      currentTranslation: "",
      currentTranslation2: "",
      otherModalVisible: false,
      studyDetails: [],
      selectMediaVisible: false,
      loadingPage: true,
      loadingElements: true,
      resolvePromise: null,
      rejectPromise: null,
      currentPageLessonElements: 1,
      copyElementsBool: false,
      diaryDetailsFormId: "diaryDetailsForm",
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getNotificationText',
      'getPageRefreshBool',
      'getPageRefreshDetails',
      'getMyRoleForStudy',
      'getFormElementsChanged',
      'getUserId',
      'getConditionEditor',
      'getIsEditable',
    ]),

    studyId: function(){
      return Number(this.diaryDetails.study_id)
    },

    diaryId: function(){
      return Number(this.$route.params.diaryId)
    },

    warningText: function(){
      var string = Vue.i18n.translate('generalTranslation.unsavedChanges') + ": ";
      if(this.formChanged){
        string += Vue.i18n.translate('diaryTranslation.diaryDetails');
      }
      if(this.getFormElementsChanged && this.nextRouteName != 'conditionEditor'){
        string += (this.formChanged ? ", " : "") + Vue.i18n.translate('diaryTranslation.diaryElements');
      }
      return string
    },

    tabDetails: function(){
      return [
        {text: Vue.i18n.translate('diaryTranslation.diaryDetails'), show: true, id: "details"},
        {text: Vue.i18n.translate('diaryTranslation.diaryElements'), show: true, id: "elements"},
        {text: Vue.i18n.translate('interventionTranslation.translationProgress'), show: true, id: "progress"},
      ]
    }
  },

  watch:{
    '$route.params.diaryId': function(){
      if(this.copyElementsBool){
        //get new data after copy elements in different lesson
        this.copyElementsBool = false;
        this.loadingPage = true;
        this.loadingLanguage = true;
        this.loadingElements = false;
        this.SET_FORMELEMENTSCHANGED(true);
        this.initDiary(true, false);
      }else{
        //get new data when going back from lesson where elements were copied to to lesson where elements where copied from
        this.loadingPage = true;
        this.loadingLanguage = true;
        this.loadingElements = true;
        this.initDiary(true, true);
      }
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isSaved = false;
        this.loadingLanguage = false;
      }else if(newVal != "" && newVal.type === "success"){
        if(newVal.text === Vue.i18n.translate('diaryTranslation.updateDiaryDetailsSuccess')){
          this.formChanged = false;
          this.loadingLanguage = true;
          this.initDiary(false, true);
        }
        this.isSaved = false;
      }
    },

    getSelectedLanguage(){
      this.setCurrentTranslations(false);
    },

    selectedLanguage2(newVal){
      if(newVal != ""){
        this.setCurrentTranslations(true);
      }else if(this.currentTranslation2 != ""){
        this.diaryDetails.translations.push(this.currentTranslation2);
        this.currentTranslation2 = "";
      }
    },
  },

  mounted(){
    if(this.getNotificationText.text != Vue.i18n.translate('diaryTranslation.createDiarySuccess')){
      this.closeNotification();
    }
    this.SET_PREVIEWANSWERS({});
    if((this.getPageRefreshBool || this.getConditionEditor) && this.getPageRefreshDetails.id === this.diaryId){
      this.SET_CONDITIONEDITOR(false);
      this.diaryDetails = this.getPageRefreshDetails.diaryDetails;
      this.diaryDetailsComplete = JSON.parse(JSON.stringify(this.diaryDetails));
      this.studyDetails = this.getPageRefreshDetails.studyDetails;
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.loadingElements = false;
      this.init();
      this.loadingPage = false;
      this.selectedView = this.getPageRefreshDetails.view;
      this.currentPageLessonElements = this.getPageRefreshDetails.currentPage
    }else{
      if(this.view != undefined){
        this.selectedView = this.view;
      }
      this.initDiary(true, true);
    }
    this.SET_CONDITIONEDITOR(false);
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    if(this.getIsEditable && document.getElementById(this.diaryDetailsFormId) != null){
      document.getElementById(this.diaryDetailsFormId).addEventListener("input", this.changeFormHasChanged);
    }
    window.addEventListener('beforeunload', this.beforeunloadFunction);
    window.addEventListener('unload', this.unloadFunction);
  },

  beforeRouteUpdate (to, from, next) {
    this.nextRouteName = to.name;
    if(to.name === from.name && !this.copyElementsBool && (this.formChanged || this.getFormElementsChanged)){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          self.formChanged = false;
          if(to.name != "conditionEditor"){
            self.SET_FORMELEMENTSCHANGED(false);
          }
          next();
        }else{
          next(false);
        }
      });
    }else{
      next();
    }
  },

  beforeRouteLeave(to, from, next){
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);
    if(this.otherModalVisible || this.selectMediaVisible){
      next(false);
    }else if(this.formChanged || (this.getFormElementsChanged && to.name != "conditionEditor")){
      var self = this;
      this.nextRouteName = to.name;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          if(to.params.oldDiaryId === undefined && to.name === "studyEditor"){
            to.params.oldDiaryId = self.diaryId;
          }
          if(to.name != "conditionEditor"){
            self.SET_QUESTIONLABELLIST([]);
            self.SET_FORMELEMENTSCHANGED(false);
          }else{
            var data = self.setRefreshData();
            self.SET_PAGEREFRESHDETAILS(data);
          }
          next();
        }else{
          next(false);
        }
      });
    }else{
      if(to.params.oldDiaryId === undefined && to.name === "studyEditor"){
        to.params.oldDiaryId = this.diaryId;
      }

      this.formChanged = false;
      if(to.name != "conditionEditor"){
        this.SET_QUESTIONLABELLIST([]);
        this.SET_FORMELEMENTSCHANGED(false);
      }else{
        var data = this.setRefreshData();
        this.SET_PAGEREFRESHDETAILS(data);
      }
      next();
    }
  },

  beforeDestroy(){
    if(this.getIsEditable && document.getElementById(this.diaryDetailsFormId) != null){
      document.getElementById(this.diaryDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener('beforeunload', this.beforeunloadFunction);
    window.removeEventListener('unload', this.unloadFunction);
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_SELECTEDLANGUAGE',
      'SET_PAGEREFRESHBOOL',
      'SET_PAGEREFRESHDETAILS',
      'SET_QUESTIONLABELLIST',
      'SET_FORMELEMENTSCHANGED',
      'SET_PREVIEWANSWERS',
      'SET_CONDITIONEDITOR',
    ]),

    showWarningModal: function(){
      return new Promise((resolve, reject) => {
        this.warningChangesVisible = true;
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },

    initDiary: function(created, withElements){
      var self = this;
      this.requestDiaryAndLesson(this.diaryId, withElements)
      .then(function (response){
        self.loadingElements = false;
        self.diaryDetails = response;
        self.diaryDetailsComplete = JSON.parse(JSON.stringify(response));
        if(created){
          self.init();
          self.getInitialData();
        }else{
          self.setCurrentTranslations(false);
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.initDiary(created, withElements) }, "diaryDetails");
      });
    },

    getInitialData: function(){
      var self = this;
      this.requestCollaboratorsIncludingPermissions("page=0&id=" + this.getUserId, this.studyId, false, -1, -1)
      .then(function (){
        return self.requestStudyDetails(self.studyId)
      })
      .then( function(response){
        self.studyDetails = response;
        self.loadingPage = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getInitialData() }, "");
      });
    },

    init: function(){
      this.setCurrentTranslations(false);

      if(this.diaryDetails.locales == null || this.diaryDetails.locales.length === 0){
        this.diaryDetails.locales = ["de", "en"];
      }

      if(!this.diaryDetails.locales.includes(this.getSelectedLanguage)){
        var language = (this.diaryDetails.locales.includes("de")) ? "de" : this.diaryDetails.locales[0];
        this.SET_SELECTEDLANGUAGE(language);
      }

      if(this.$enabledFeatures.includes("jitai")){
        if(!this.diaryDetails.schedule){
          this.diaryDetails.schedule = {
            every: []
          };
        }else if(!this.diaryDetails.schedule.every){
          this.diaryDetails.schedule.every = [];
        }
      }
    },

    showModal: function(obj){
      switch (obj.type) {
        case "StudyMedia":
          this.selectMediaVisible = obj.value;
        break;

        case "LanguageError":
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.deleteSelectedLanguageWarning')});
        break;

        case "LessonPreview":
          this.setUpdatedDiaryDetailsForPreview();
        break;

        case "any":
          this.otherModalVisible = obj.value;
        break;

        default:
        break;
      }
    },

    changeFormHasChanged: function(){
      this.formChanged = true;
    },

    closeModal: function(){
      this.warningChangesVisible = false;
    },

    saveDiaryDetails: function(){
      if(this.diaryDetails.name === "" || this.currentTranslation.title === "" || this.currentTranslation.description === "" ||
      this.currentTranslation2 != "" && (this.currentTranslation2.title === "" || this.currentTranslation2.description === "")){

        var errorText = "";
        if(this.diaryDetails.name === ""){
          errorText += Vue.i18n.translate('generalTranslation.name');
        }

        if(this.currentTranslation.title === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.title');
        }
        if(this.currentTranslation.description === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.description');
        }

        if(this.currentTranslation2 != "" && this.currentTranslation2.title === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.titleTranslation');
        }

        if(this.currentTranslation2 != "" && this.currentTranslation2.description === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.descriptionTranslation');
        }

        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminUsersTranslation.errorFillIn') + errorText});
      }else{
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('diaryTranslation.updateDiaryDetailsLoad')});

        var details = JSON.parse(JSON.stringify(this.diaryDetails));

        if(!details.page_color){
          details.page_color = this.$defaultLessonPageColor;
        }

        //all translations in translations array
        details.translations.push(this.currentTranslation);
        if(this.currentTranslation2 != ""){
          details.translations.push(this.currentTranslation2);
        }

        var json = {
          "data" : {
            "type" : "diary",
            "attributes" : details
          }
        };

        var self = this;
        this.updateDiaryDetailsRequest(json, this.diaryId)
        .then( function(){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.updateDiaryDetailsSuccess')});
        })
        .catch( function(error){
          self.handleErrors(error, function(){ self.saveDiaryDetails() }, "");
        });
      }
    },

    changeLanguage2: function(language){
      this.selectedLanguage2 = language;
    },

    changeTranslationMode: function(isTranslationMode){
      this.isTranslationMode = isTranslationMode;
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    unloadFunction: function(){
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();
      this.isTranslationMode = false;
      this.currentPageLessonElements = 1;
      var data = this.setRefreshData();
      this.SET_PAGEREFRESHDETAILS(data);
    },

    setRefreshData: function(){
      //all translations in translations array
      var diaryDetailsArr = JSON.parse(JSON.stringify(this.diaryDetails));
      if(this.currentTranslation){
        diaryDetailsArr.translations.push(this.currentTranslation);
        this.currentTranslation = "";
      }

      if(this.currentTranslation2){
        diaryDetailsArr.translations.push(this.currentTranslation2);
        this.currentTranslation2 = "";
      }

      var data = {
        id: this.diaryId,
        diaryDetails: diaryDetailsArr,
        studyDetails: this.studyDetails,
        view: this.selectedView,
        formChanged: this.formChanged,
        currentPage: this.currentPageLessonElements
      };
      return data
    },

    setUpdatedDiaryDetailsForPreview: function(){
      this.closeNotification();
      var diaryDetailsArr = JSON.parse(JSON.stringify(this.diaryDetails));
      if(this.currentTranslation){
        diaryDetailsArr.translations.push(JSON.parse(JSON.stringify(this.currentTranslation)));
      }

      if(this.currentTranslation2){
        diaryDetailsArr.translations.push(JSON.parse(JSON.stringify(this.currentTranslation2)));
        this.currentTranslation2 = "";
      }
      this.diaryDetailsComplete = diaryDetailsArr;
    },

    showView: function(view){
      this.selectedView = view;
    },

    routeTo: function(obj){
      if(obj.name === 'conditionEditor'){
        this.currentPageLessonElements = obj.params.page;
        delete obj.params['page'];
        obj.params.lessonId = this.diaryDetails.questionnaire_id;
      }else if(obj.name === 'diaryEditor'){
        //route changes -> get new diary details, studyDetails
        //permissions and elements already set
        //in route.params.diaryId watch function
        this.copyElementsBool = true;
      }

      router.push(obj);
    },

    exportDiary: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('diaryTranslation.exportDiaryLoad')});
      var self = this;
      this.exportDiaryRequest(this.diaryId)
      .then( function(response){
        let dataStr = JSON.stringify(response, null, "\t");
        let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        let exportFileDefaultName = 'data.json';
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.exportDiarySuccess')});
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.exportDiary() }, "");
      });
    },

    beginCreateCopy: function(){
      this.formChanged = false;
    },

    changeAttributeForDetails: function(obj){
      var isUpdated = true;
      this.closeNotification();
      if(obj.name === "locales"){
        obj.value.sort();
      }else if(obj.name === "is_private"){
        if(obj.value === 1){
          this.diaryDetails.accesstype = "invite";
        }else{
          this.diaryDetails.accesstype = "";
        }
      }
      if(obj.name === "schedule"){
        if(this.diaryDetails.schedule.every.length === obj.value){
          isUpdated = false;
        }else{
          var schedule = this.diaryDetails.schedule.every;
          var newSchedule = [];
          for(var entry = 0; entry < obj.value; entry++){
            if(schedule[entry] != undefined){
              newSchedule.push(schedule[entry]);
            }else{
              newSchedule.push({
                weekday: 1,
                hour: 12,
                minute: 0
              });
            }
          }
          this.diaryDetails.schedule.every = newSchedule;
        }
      }else if(obj.name === "schedule-day"){
        this.diaryDetails.schedule.every[obj.index].weekday = Number(obj.value);
      }else if(obj.name === "schedule-time"){
        var hour = obj.value.substring(0,2);
        var minute = obj.value.substring(3,5);
        this.diaryDetails.schedule.every[obj.index].hour = hour;
        this.diaryDetails.schedule.every[obj.index].minute = minute;
      }else if(obj.name != "title" && obj.name != "description"){
        this.diaryDetails[obj.name] = obj.value;
      }else if(!obj.isTranslation){
        this.currentTranslation[obj.name] = obj.value;
      }else{
        this.currentTranslation2[obj.name] = obj.value;
      }
      if(isUpdated){
        this.formChanged = true;
      }
    },

    setCurrentTranslations: function(isTranslation){
      this.loadingLanguage = true;
      var language;
      if(!isTranslation){
        language = this.getSelectedLanguage;
      }else{
        language = this.selectedLanguage2;
      }

      if(this.selectedLanguage2 === "" && this.currentTranslation2 != ''){
        this.diaryDetails.translations.push(this.currentTranslation2);
        this.currentTranslation2 = '';
      }

      var translation = '';
      var index = this.diaryDetails.translations.findIndex(elem => elem.locale === language);
      if(index != -1){
        translation = this.diaryDetails.translations[index];
        this.diaryDetails.translations.splice(index, 1);
      }else if(language != ""){
        translation = {
          "locale": language,
          "title": "",
          "description": "",
        };
      }

      if(!isTranslation){
        if(this.currentTranslation != ""){
          this.diaryDetails.translations.push(this.currentTranslation);
        }
        if(translation != ""){
          this.currentTranslation = translation;
        }
      }else{
        if(this.currentTranslation2 != ''){
          this.diaryDetails.translations.push(this.currentTranslation2);
        }
        if(translation != "" && this.selectedLanguage2 != ""){
          this.currentTranslation2 = translation;
        }
      }
      this.loadingLanguage = false;
    },

    beforeunloadFunction: function(e){
      if(this.formChanged || this.getFormElementsChanged){
        e.preventDefault();
        e.returnValue = '';
        return;
      }
      delete e['returnValue'];
    },
  }
}
</script>
