<template>
  <div class="lessonPreviewElement">
    <!-- language included in key since getEmptyTranslation has to be called again for new language but is not computed --> 
    <div
      v-if="element.type != 'ElementsLine' && element.type != 'ElementsSpace'"
      :is="element.type + 'Preview'"
      :id="'previewElement' + element.propsObj.id + '-' + repetitionIndex"
      :key="element.propsObj.id + getSelectedLanguage"
      :propsObj="element.propsObj"
      :translation="element.translations ? element.translations[getSelectedLanguage] : getEmptyTranslation(element)"
      :isDiary="isDiary"
      :repetitionIndex="repetitionIndex"
      :showRequiredQuestions="showRequiredQuestions"
      :showAllConditions="showAllConditions"
    ></div>
    <div v-else-if="element.type === 'ElementsLine'" :id="'previewElement' + element.propsObj.id" class="elementsLine otherElementsPreview">
      <hr>
    </div>
    <div v-else :id="'previewElement' + element.propsObj.id" :class="'spaceSize' + element.propsObj.size" class="otherElementsPreview">
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ElementsBlockPreview from './ElementsBlockPreview.vue';
import ElementsHeadlinePreview from './ElementsHeadlinePreview.vue';
import ElementsMediaPreview from './ElementsMediaPreview.vue';
import ElementsQuestionPreview from './ElementsQuestionPreview.vue';
import ElementsTablePreview from './ElementsTablePreview.vue';
import ElementsTextPreview from './ElementsTextPreview.vue';

export default {
  name: 'LessonPreviewElement',

  components: {
    ElementsBlockPreview,
    ElementsHeadlinePreview,
    ElementsMediaPreview,
    ElementsQuestionPreview,
    ElementsTablePreview,
    ElementsTextPreview,
  },

  props: {
    element: {
      required: true,
      type: Object,
    },

    repetitionIndex: {
      required: true,
      type: Number,
    },

    isDiary: {
      required: true,
      type: Boolean,
    },

    showRequiredQuestions: {
      required: true,
      type: Boolean,
    },

    showAllConditions: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters([
      'getPreviewAnswers',
      'getSelectedLanguage'
    ]),
  },

  methods:{
    getEmptyTranslation: function(element){
      if(element.type === "ElementsMedia"){
        return { uri: "", filename: "", subtitle: "", description: "" }
      }else if(element.type === "ElementsQuestion"){
        var answersArr = [];
        switch (element.propsObj.selectedQuestionProp){
          case "YesNoSwitch":
            answersArr = (this.getSelectedLanguage === "de") ?  ["JA","NEIN"] : ["YES","NO"];
          break;

          case "SingleChoice":
          case "MultipleChoice":
            answersArr = new Array(element.propsObj.values.length).fill("");
          break;

          case "Slider":
            answersArr = [
              { value: element.propsObj.values.min, label: "" },
              { value: element.propsObj.values.max, label: "" }
            ];
          break;

          default:
          break;
        }
        return { question: "", answers: answersArr }
      }else{
        return ""
      }
    }
  }
}
</script>
