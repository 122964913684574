<template>
  <div class="elementsQuestion">
    <section v-if="getIsEditable" :style="{visibility: showIfNotTranslation}" class="wrapper">
      <section v-if="showError" class="errorMessage" :id="'errorTextElement' + propsObj.id">
        <section v-if="errorTextsForElements[propsObj.id] != ''">
          {{errorTextsForElements[propsObj.id]}}
        </section>
        <p v-if="errorDescription != ''">
          {{errorDescription}}
        </p>
        <p v-if="errorDescription2 != ''">
          {{errorDescription2}}
        </p>
      </section>
    </section>

    <section v-if="getIsEditable" :style="{visibility: showIfNotTranslation}" class="wrapper">
      <label>
        {{ 'elementsQuestionTranslation.questionType' | translate }} *
      </label>

      <select :value="propsObj.selectedQuestionProp" :id="'inputQuestionType' + propsObj.id" @input="$emit('change-element', {'type': 'attribute',
          'name': 'selectedQuestionProp', 'isTranslation': isTranslation, 'value': $event.target.value})">
        <option value="" disabled :id="'inputQuestionType' + propsObj.id + 'None'">
          {{ 'elementsQuestionTranslation.selectQuestionType' | translate }}
        </option>
        <option v-for="data in questionTypeList" :value="data.value" :id="'inputQuestionType' + propsObj.id + '-' + data.value" :key="data.value">
          {{data.text}}
        </option>
      </select>
    </section>

    <section v-if="!getIsEditable" class="wrapper">
      <section v-for="data in questionTypeList" :key="data.value">
        <p v-if="propsObj.selectedQuestionProp === data.value">
          <label :id="'questionType' + propsObj.id">
            {{ 'elementsQuestionTranslation.questionType' | translate }}
          </label>
          {{data.text}}
        </p>
      </section>
    </section>

    <section :style="{visibility: showIfNotTranslation}" class="wrapper">
      <label>
        {{ 'elementsQuestionTranslation.questionLabel' | translate }} *
      </label>

      <div v-if="getIsEditable">
        ({{ 'elementsQuestionTranslation.reference' | translate }})
      </div>

      <section v-if="getIsEditable" class="secLabel">
        <input type="text" :value="propsObj.labelProp" :id="'inputQuestionLabel' + propsObj.id" placeholder="Fragelabel" class="questionLabelInput"
          @input="$emit('change-element', {'type': 'attribute', 'name': 'labelProp', 'value': $event.target.value})">
        <button type="button" :id="'buttonGenerateLabel' + propsObj.id" @click="generateQuestionLabel">
          {{ 'elementsQuestionTranslation.generateQuestionLabel' | translate }}
        </button>
      </section>

      <p v-else :id="'questionLabel' + propsObj.id">
        {{propsObj.labelProp}}
      </p>
    </section>

    <section v-if="propsObj.selectedQuestionProp != 'QuestionTable'" class="wrapper">
      <label>
        {{questionTextLabelInLanguage}} *
      </label>
      <ElementsReference
        :id="propsObj.id + isTranslation"
        :text="translation.question"
        :classToSet="propsObj.selectedQuestionProp"
        :placeholderData="questionTextLabelInLanguage"
        :isSkill="false"
        @change-text="changeQuestionText"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </section>

    <ElementsQuestionSingleMultiple
      v-if="isSingleMultipleQuestion"
      key="singleMultipleSec"
      :propsObj="propsObj"
      :translation="translation"
      :isTranslation="isTranslation"
      :isTranslationMode="isTranslationMode"
      @change-element="$emit('change-element', $event)"
    ></ElementsQuestionSingleMultiple>

    <ElementsQuestionSlider
      v-else-if="propsObj.selectedQuestionProp === 'Slider'"
      key="sliderSec"
      :propsObj="propsObj"
      :translation="translation"
      :isTranslation="isTranslation"
      @change-element="$emit('change-element', $event)"
    ></ElementsQuestionSlider>

    <ElementsQuestionTable
      v-if="propsObj.selectedQuestionProp === 'QuestionTable'"
      key="questionTableSec"
      :propsObj="propsObj"
      :translation="translation"
      :isTranslation="isTranslation"
      @change-element="$emit('change-element', $event)"
    ></ElementsQuestionTable>

    <!-- example of answer field -->
    <section v-if="!isTranslation" readonly class="wrapper willBeAnswer" :class="{willBeAnswerQuestionTable: propsObj.selectedQuestionProp === 'QuestionTable'}">
      <label>
        {{ 'elementsQuestionTranslation.answerExample' | translate }}
      </label>

      <section v-if="propsObj.selectedQuestionProp === 'YesNoSwitch'" key="yesNoSec" class="radioSection">
        <br>
        <label v-for="n in 2" :key="n" class="container">
          {{n === 1 ? $t('generalTranslation.yes') : $t('generalTranslation.no')}}
          <input type="radio" :checked="n === 1" disabled>
          <span class="checkmark"></span>
        </label>
      </section>

      <section v-else-if="isSingleMultipleQuestion" key="singleMultipleSec"
          :class="{radioSection: propsObj.selectedQuestionProp === 'SingleChoice', checkboxSection: propsObj.selectedQuestionProp === 'MultipleChoice'}">
        <br>
        <label v-for="n in 2" :key="n" class="container">
          {{ 'elementsQuestionTranslation.answer' | translate }} {{n}}
          <input :type="propsObj.selectedQuestionProp === 'SingleChoice' ? 'radio': 'checkbox'" :checked="n === 1 || propsObj.selectedQuestionProp === 'MultipleChoice'" disabled>
          <span class="checkmark"></span>
        </label>
      </section>

       <div v-else-if="propsObj.selectedQuestionProp === 'Slider'" key="sliderSec">
         <div class="range-slider-labels">
           <ion-label class="labelLeft" slot="start">
             min
           </ion-label>
           <ion-label class="labelRight" slot="end">
             max
           </ion-label>
         </div>
         <input type="range" value="25" min="1" max="100" disabled>
       </div>

      <section v-else-if="isDateQuestion" key="dateSec" class="dateQuestion">
        <input type="text" :placeholder="placeholderDateTime" disabled>
      </section>

      <input type="text" v-else-if="propsObj.selectedQuestionProp === 'TextString'" key="textInput" :placeholder="$t('elementsQuestionTranslation.answerfield')" disabled>

      <textarea v-else-if="propsObj.selectedQuestionProp === 'TextArea'" :placeholder="$t('elementsQuestionTranslation.answerfield')" disabled/>

      <section v-else key="questionTableSec" class="questionTable">
        <section v-for="n in 2" :key="n" class="wrapper">
          <ion-label class="col sec25" text-wrap>
            {{ 'elementsQuestionTranslation.question' | translate }} {{n}}
          </ion-label>
          <ion-radio-group class="col secQuestionTable" disabled>
            <div class="wrapper">
              <ion-item v-for="m in 3" :key="m" class="col" text-wrap>
                <ion-label>
                  {{ 'elementsQuestionTranslation.answer' | translate }} {{m}}
                </ion-label>
                <ion-radio slot="start" :checked="(n === 1 && m === 1 || n === 2 && m === 3)" disabled></ion-radio>
              </ion-item>
            </div>
          </ion-radio-group>
        </section>
      </section>
    </section>

    <section v-if="!isTranslation" class="wrapper">
      <label>
        {{ 'elementsQuestionTranslation.questionRequired' | translate }} *
      </label>
      <select :value="propsObj.questionReq" :disabled="!getIsEditable" :id="'inputQuestionRequired' + propsObj.id" @input="$emit('change-element', {'type': 'attribute', 'name': 'questionReq',
          'isTranslation': isTranslation, 'value': Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0]})">
        <option value="" disabled :id="'inputQuestionRequired' + propsObj.id + 'None'">
          {{ 'generalTranslation.select' | translate }}
        </option>
        <option :value="true" :id="'inputQuestionRequired' + propsObj.id + 'True'" :key="'requiredTrue' + propsObj.id">
          {{ 'generalTranslation.yes' | translate }}
        </option>
        <option :value="false" :id="'inputQuestionRequired' + propsObj.id + 'False'" :key="'requiredFalse' + propsObj.id">
          {{ 'generalTranslation.no' | translate }}
        </option>
      </select>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import ElementsQuestionSingleMultiple from './ElementsQuestionSingleMultiple.vue'
import ElementsQuestionSlider from './ElementsQuestionSlider.vue'
import ElementsQuestionTable from './ElementsQuestionTable.vue'
import ElementsReference from './ElementsReference.vue';
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'ElementsQuestion',

  components: {
    ElementsQuestionSingleMultiple,
    ElementsQuestionSlider,
    ElementsQuestionTable,
    ElementsReference,
  },

  mixins: [elementsHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    sliderRepetitionErrorLabels: {
      required: false,
      type: Array
    },
  },

  data: function(){
    return {
      errorDescription: '',
      errorDescription2: '',
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
      'getQuestionLabelList',
      'getSelectedLanguage',
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    showError: function(){
      return (this.errorDescription != '' || this.errorDescription2 != '' || this.errorTextsForElements[this.propsObj.id])
    },

    questionTextLabelInLanguage: function(){
      return Vue.i18n.translate('elementsQuestionTranslation.question' + (this.isTranslation ? 'Translation' : ''))
    },

    placeholderDateTime: function(){
      var string = "";
      if(this.propsObj.selectedQuestionProp.includes("Date")){
        string += Vue.i18n.translate('elementsBlockTranslation.placeholderDate')
      }
      if(this.propsObj.selectedQuestionProp.includes("Time")){
        string += (string != "" ? " " : "") + Vue.i18n.translate('elementsBlockTranslation.placeholderTime')
      }
      return string
    },

    isSingleMultipleQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'SingleChoice' || this.propsObj.selectedQuestionProp === 'MultipleChoice'
    },

    isDateQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'TextDate' || this.propsObj.selectedQuestionProp === 'TextTime' || this.propsObj.selectedQuestionProp === 'TextDateTime'
    },

    isTextQuestion: function(){
      return this.propsObj.selectedQuestionProp === 'TextString' || this.propsObj.selectedQuestionProp === 'TextArea'
    },
  },

  watch:{
    'propsObj.labelProp': function(newVal){
      this.checkIfLabelAlreadyExisting(newVal);
    },

    //needs deep:true & handler because array in array changes -> 'normal' watch function does not detect changes in nested array
    getQuestionLabelList: {
      handler: function(){
        if(!this.isTranslation){
          this.checkIfLabelAlreadyExisting(this.propsObj.labelProp);
        }
      },
      deep: true
    },

    sliderRepetitionErrorLabels: function(newVal){
      if(this.propsObj.selectedQuestionProp === "Slider" && newVal.length > 0){
        if(newVal.includes(this.propsObj.labelProp)){
          this.errorDescription2 = Vue.i18n.translate('elementsQuestionTranslation.errorRepetition') + ".";
        }else{
          this.errorDescription2 = "";
        }
      }
    },
  },

  created(){
    if(this.propsObj.labelProp != ""){
      this.checkIfLabelAlreadyExisting(this.propsObj.labelProp);
    }
  },

  methods:{
    changeQuestionText: function(text){
      this.$emit('change-element', {'type': 'translation-attribute', 'name': 'question', 'isTranslation': this.isTranslation, 'value': text });
    },

    checkIfLabelAlreadyExisting: function(label){
      var labelList = JSON.parse(JSON.stringify(this.getQuestionLabelList));
      //set this pos to 0 -> do not want to get this index
      labelList[this.propsObj.id] = 0;
      var idOfElement = labelList.findIndex(elem => elem && elem.label === label);
      //only show error for one element -> the one with higher id
      if(idOfElement != - 1 && this.propsObj.id > idOfElement){
        this.errorDescription = Vue.i18n.translate('elementsQuestionTranslation.errorDuplicateLabel');
      }else{
        this.errorDescription = "";
      }
    },

    generateQuestionLabel: function(){
      var text;
      if(this.propsObj.selectedQuestionProp != "QuestionTable"){
        text = this.translation.question.toLowerCase().replace(/ä/g,"ae").replace(/ö/g,"oe").replace(/ü/g,"ue").replace(/ß/g,"ss");
        text = text.replace(/[\W_]+/g,"").substring(0,20);
      }else{
        text = Vue.i18n.translate('elementsQuestionTranslation.questionTable') + this.propsObj.id
      }
      this.$emit('change-element', {'type': 'attribute', 'name': 'labelProp', 'value': text});
    },
  }
}
</script>
