import Vue from "vue";
import vuexI18n from "vuex-i18n";
import Ionic from "@ionic/vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import FloatingVue from "floating-vue";

Vue.config.silent = true
Vue.config.productionTip = false
Vue.use(FloatingVue, {
  themes: {
    tooltip: {
      popperTriggers: ["hover", "focus", "touch"], // Make tooltip-text interactable
      delay: { show: 150, hide: 0 },
    },
    "info-tooltip": {
      $extend: "tooltip",
      delay: { show: 20, hide: 0 },
    },
  },
});
Vue.use(Ionic);
Vue.use(vuexI18n.plugin, store);
Vue.i18n.set('de');
Vue.i18n.fallback('en');
Vue.i18n.add('en', require('./languages/en.js').default);
Vue.i18n.add('de', require('./languages/de.js').default);

//routes with requiresAuth require token and admin or editor role
router.beforeEach(function(to, _from, next){
  let token, roles;
  if(localStorage.getItem('store') === null){
    token = "";
    roles = [];
  }else{
    token = JSON.parse(localStorage.getItem('store')).token;
    roles = JSON.parse(localStorage.getItem('store')).roles;
  }
  if(to.matched.some(record => record.meta.requiresAuth)){
    if(token === "" || (!roles.includes("editor") && !roles.includes("admin"))){
      //self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('myStudiesTranslation.loginWarning')});
      localStorage.clear();
      next({ path: '/', query: { redirect: to.fullPath }})
    }else if((to.matched.some(record => record.meta.requiresEditorPermission) && !roles.includes("editor"))
      || (to.matched.some(record => record.meta.requiresAdminPermission) && !roles.includes("admin"))){
      next({ path: '/home', query: { redirect: to.fullPath }});
    }else if(to.matched.some(record => record.meta.requiresSkillsEnabled) && !Vue.prototype.$enabledFeatures.includes('skills')){
      next({ path: '/home', query: { redirect: to.fullPath }});
    }else{
      next();
    }
  }else if(token != "" && roles.includes("editor")){
    next({ path: '/home', query: { redirect: to.fullPath }});
  }else if(token != "" && roles.includes("admin")){
    next({ path: '/admin', query: { redirect: to.fullPath }});
  }else{
    localStorage.clear();
    next();
  }
});

new Vue({
  router,
  store,
  vuexI18n,
  beforeCreate(){
		this.$store.commit('initialiseStore');
	},
  render: h => h(App)
}).$mount('#app')

if(module.hot){
  module.hot.accept(['./languages/de.js', './languages/en.js'], function () {
    Vue.i18n.replace('en', require('./languages/en.js').default);
    Vue.i18n.replace('de', require('./languages/de.js').default);
  })
}

store.subscribe((_mutation, state) => {
	// Store the state object as a JSON string
  const s = JSON.parse(JSON.stringify(state));
  delete s.i18n;
	localStorage.setItem('store', JSON.stringify(s));
});
