<template>
  <div class="theErrorPage">

  </div>
</template>

<script>
import router from '../router';

export default {
  name: 'TheErrorPage',

  created(){
    router.push({ name: 'home'});
  },
}
</script>
