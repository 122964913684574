<template>
  <div class="adminUsers">
    <section class="wrapper">
      <section class="headlineClass">
        <h1>
          {{ 'adminUsersTranslation.manageUsers' | translate }}
        </h1>
      </section>
      <button type="button" v-if="!loading && selectedView === 0" id="buttonCreateAccount" class="createButton" @click="showCreateAccountModal">
        {{ 'adminUsersTranslation.createAccount' | translate }}
      </button>
    </section>

    <BaseTabs
      v-if="$enabledFeatures.includes('deletion-requests')"
      :tabDetails="tabDetails"
      :selectedView="selectedView"
      @show-view="showView"
    ></BaseTabs>

    <section class="smallerComp">
      <section v-show="!loading && selectedView === 0" id="sectionUsers">
        <BaseSearch
          id="searchUsers"
          type="admin-users"
          @get-new-list="getNewList"
          @reset-page="resetPage"
        ></BaseSearch>

        <BasePagination
          id="paginationUsersTop"
          :numberOfPages="userListData.allPages"
          :currentPage="userListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <table v-if="userListData.list && userListData.list.length > 0" id="userList" class="permissionsTable">
          <tr v-for="(user, userIndex) in userListData.list" :id="'entryUser' + user.id" :key="user.id" class="wrapper interventionElement">
            <div class="tableFirstColumn">
              <label>
                {{user.firstname}} {{user.lastname}} ({{user.name}})
              </label>
              {{user.email}}
            </div>
            <template v-if="user.verified">
              <div v-for="role in rolesArray" :key="role" class="tableCell notBold">
                <label :class="{notSelectedRole: !user.role.includes(role)}">
                  {{ 'adminUsersTranslation.' + role | translate }}
                  <i class="fas" :class="{'fa-check': user.role.includes(role), 'fa-times': !user.role.includes(role)}" :id="'role' + role + user.id"></i>
                </label>
              </div>
            </template>
            <div v-else class="resendButtonSec">
              <button type="button" :id="'buttonResendVerification' + user.id" :disabled="isUpdated" @click="resendVerification(user.email)">
                {{ 'adminUsersTranslation.resendVerification' | translate }}
              </button>
            </div>
            <div class="adminButtons">
              <i
                class="fas fa-pen fa-lg"
                :id="'buttonEditUser' + user.id"
                v-tooltip="$t('adminUsersTranslation.edit')"
                :disabled="isUpdated"
                :style="{ visibility: showIfVerified(user.verified) }"
                @click="changeRolesOfUser(userIndex, user)"
              ></i>

              <i
                class="fa fa-trash fa-lg"
                :id="'buttonDeleteUser' + user.id"
                v-tooltip="$t('generalTranslation.delete')"
                :disabled="isUpdated"
                :style="{ visibility: showIfOtherUser(user.id) }"
                @click="showDeleteUser(user)"
              ></i>
            </div>
          </tr>
        </table>

        <section v-else id="noUsers">
          {{ 'permissionsTranslation.noUsers' | translate }}
        </section>

        <BasePagination
          id="paginationUsersBottom"
          :numberOfPages="userListData.allPages"
          :currentPage="userListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <section v-show="!loading && selectedView === 1" id="sectionDeletion">
        <!--
        <BaseSearch
          id="searchDeletions"
          type="admin-users-deletion"
          @get-new-list="getNewListDeletion"
          @reset-page="resetPageDeletion"
        ></BaseSearch> -->

        <BasePagination
          id="paginationDeletionsTop"
          :numberOfPages="requestListData.allPages"
          :currentPage="requestListData.currentPage"
          @new-page="getNextPageDeletion"
        ></BasePagination>

        <table v-if="requestListData.list && requestListData.list.length > 0" id="deletionList" class="deleteReqTable">
          <tr>
            <th>
              {{ 'adminUsersTranslation.userDetails' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.studiesOfUser' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.eCoachManager' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.data' | translate }}
            </th>
            <th>
              {{ 'adminUsersTranslation.acceptDecline' | translate }}
            </th>
          </tr>
          <tr v-for="request in requestListData.list" :id="'entryDeletion' + request.id" :key="request.id" class="interventionElement">
            <td>
              <div v-if="request.relationships.users && request.relationships.users.data">
                <label>
                  {{request.relationships.users.data.attributes.email}}
                </label>
                {{getUserName(request.relationships.users.data.attributes)}}
              </div>
            </td>
            <td>
              <ul v-if="request.relationships.studies && request.relationships.studies.data && request.relationships.studies.data.length > 0">
                <li v-for="study in request.relationships.studies.data" :key="study.id">
                  {{getStudyName(request, study)}}
                </li>
              </ul>
              <div v-else>
                {{ 'adminUsersTranslation.userHasNoStudies' | translate }}
              </div>
            </td>
            <td>
              <ul v-if="request.attributes.studies && request.attributes.studies.length > 0">
                <li v-for="(study, index) in request.attributes.studies" :key="index"> <!-- todo key -->
                  {{getECoachManagers(study.ecoach_managers)}}
                </li>
              </ul>
              <div v-else>
                {{ 'generalTranslation.none' | translate }}
              </div>
            </td>
            <td>
              {{getDataText(request.attributes.pseudonymized)}}
            </td>
            <td>
              <div class="adminButtons">
                <i
                  class="fas fa-check fa-lg"
                  :id="'buttonAcceptDeletion' + request.id"
                  v-tooltip="$t('adminUsersTranslation.acceptDeletion')"
                  :disabled="isUpdated"
                  @click="showAccountDeletion(request, true)"
                ></i>
                <i
                  class="fa fa-times fa-lg"
                  :id="'buttonDeclineDeletion' + request.id"
                  v-tooltip="$t('adminUsersTranslation.declineDeletion')"
                  :disabled="isUpdated"
                  @click="showAccountDeletion(request, false)"
                ></i>
              </div>
            </td>
          </tr>
        </table>

        <section v-else id="noDeletions">
          {{ 'adminUsersTranslation.noDeletionRequests' | translate }}
        </section>

        <BasePagination
          id="paginationDeletionsBottom"
          :numberOfPages="requestListData.allPages"
          :currentPage="requestListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPageDeletion"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loading"></i>
    </section>

    <AdminUserCreate
      v-if="createAccountVisible"
      @close-modal="closeModalCreate"
    ></AdminUserCreate>

    <BaseModal
      v-if="updateRolesVisible"
      id="editUserModal"
      :headerText="$t('adminUsersTranslation.changeRoles', { email: selectedUser.email })"
      :leftButtonText="$t('generalTranslation.save')"
      @close-modal="closeModalRoles"
    >
      <template v-slot:body>
        <h4>
          {{nameOfUser}}
        </h4>

        <section v-for="role in rolesArray" :key="role" class="checkboxSection notBold">
          <label class="container" :class="{notSelectedRole: role === 'admin' && Number(idOfUpdateUser) === Number(userId)}">
            <i class="fas fa-check" v-if="role === 'admin' && Number(idOfUpdateUser) === Number(userId)"></i>
            {{ 'adminUsersTranslation.' + role | translate }}
            <input type="checkbox" v-if="role != 'admin' || Number(idOfUpdateUser) != Number(userId)" v-model="selectedUser.roles"
              :value="role" :id="'inputEditUserRole' + role">
            <span class="checkmark" v-if="role != 'admin' || Number(idOfUpdateUser) != Number(userId)"></span>
          </label>
        </section>
      </template>
    </BaseModal>

    <BaseModal
      v-if="deleteUserVisible"
      id="deleteUserModal"
      :headerText="$t('adminUsersTranslation.deleteWarning', { email: selectedUser.email })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      :disableButtons="isUpdated"
      @close-modal="closeModalDeleteUser"
    ></BaseModal>

    <BaseModal
      v-if="accountDeletionVisible"
      id="accountDeletionModal"
      :headerText="$t('adminUsersTranslation.' + (selectedRequest.accept ? 'deleteWarning' : 'declineWarning'), { email: selectedRequest.email })"
      :bodyText="accountDeletionBodyText"
      :leftButtonText="accountDeletionButtonText"
      :disableButtons="isUpdated"
      @close-modal="closeModalDeleteAccount"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import AdminUserCreate from './AdminUserCreate.vue';
import BaseTabs from './BaseTabs.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BasePagination from './BasePagination.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'AdminUsers',

  components: {
    AdminUserCreate,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BasePagination,
    BaseSearch,
  },

  mixins: [httpHelper],

  data: function(){
    return{
      updateRolesVisible: false,
      loading: true,
      isUpdated: false,
      userListData: "",
      requestListData: "",
      filterTerm: "",
      deleteUserVisible: false,
      selectedRequest: "",
      selectedUser: "",
      createAccountVisible: false,
      selectedView: 0,
      accountDeletionVisible: false,
      rolesArray: ["admin", "ecoach", "editor"]
    }
  },

  computed: {
    ...mapGetters([
      'getUserId',
      'getNotificationText',
    ]),

    userId: function(){
      return this.getUserId
    },

    idOfUpdateUser: function(){
      return this.selectedUser ? this.userListData.list[this.selectedUser.index].id : ""
    },

    nameOfUser: function(){
      if(this.selectedUser){
        var name = "";
        if(this.userListData.list[this.selectedUser.index].firstname){
          name += this.userListData.list[this.selectedUser.index].firstname;
        }
        if(this.userListData.list[this.selectedUser.index].lastname){
          name += " " + this.userListData.list[this.selectedUser.index].lastname;
        }
        if(this.userListData.list[this.selectedUser.index].name){
          name += " (" + this.userListData.list[this.selectedUser.index].name + ")";
        }
        return name
      }else{
        return ""
      }
    },

    accountDeletionBodyText: function(){
      if(this.selectedRequest.accept){
        var text = ' ' + Vue.i18n.translate('myStudiesTranslation.warning');
        if(this.selectedRequest.pseudonymize){
          return Vue.i18n.translate('adminUsersTranslation.dataPseudonymized') + text
        }else{
          return Vue.i18n.translate('adminUsersTranslation.dataDeleted') + text
        }
      }else{
        return Vue.i18n.translate('adminUsersTranslation.declineText')
      }
    },

    accountDeletionButtonText: function(){
      return this.selectedRequest.accept ? Vue.i18n.translate('generalTranslation.delete') : Vue.i18n.translate('adminUsersTranslation.decline')
    },

    tabDetails: function(){
      return [
        {text: Vue.i18n.translate('adminUsersTranslation.allUsers'), show: true, id: "users"},
        {text: Vue.i18n.translate('adminUsersTranslation.deletionRequests'), show: true, id: "deletion"},
      ]
    }
  },

  mounted(){
    this.closeNotification();
    this.getAllUsersIncludingPermissions(1);
  },

  beforeRouteLeave(to, from, next){
    if(this.createAccountVisible || this.updateRolesVisible){
      next(false);
    }else{
      next();
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.loading = false;
        this.isUpdated = false;
      }else if(newVal != "" && newVal.type === "success"){
        this.isUpdated = false;
      }
    },
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    changeRolesOfUser: function(index, user){
      this.selectedUser = {
        email: user.email,
        index: index,
        roles: this.userListData.list[index].role
      };
      this.updateRolesVisible = true;
    },

    closeModalRoles: async function(done){
      if(done){
        var oldRoles = this.userListData.list[this.selectedUser.index].role
        if(this.selectedUser.roles != oldRoles){
          this.isUpdated = true;
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.saveRolesLoad')});
          var rolesToRemove = [];
          var rolesToAdd = [];

          for(var role in this.rolesArray){
            if(oldRoles.includes(this.rolesArray[role]) && !this.selectedUser.roles.includes(this.rolesArray[role])){
              rolesToRemove.push(this.rolesArray[role]);
            }
            if(!oldRoles.includes(this.rolesArray[role]) && this.selectedUser.roles.includes(this.rolesArray[role])){
              rolesToAdd.push(this.rolesArray[role]);
            }
          }

          try{
            if(rolesToRemove.length != 0){
              await this.removeRolesFromUserRequest(this.idOfUpdateUser, rolesToRemove)
            }
            if(rolesToAdd.length != 0){
              await this.addRolesToUserRequest(this.idOfUpdateUser, rolesToAdd)
            }
            this.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.saveRolesSuccess')});
            this.getAllUsersIncludingPermissions(this.userListData.currentPage);
            this.updateRolesVisible = false;
          }catch(error){
            this.handleErrors(error, function(){ this.closeModalRoles(done) }, "");
          }
        }

      }else if(!this.isUpdated){
        var rolesChanged = (JSON.stringify(this.selectedUser.roles.sort()) != JSON.stringify(this.userListData.list[this.selectedUser.index].role.sort()));
        if(rolesChanged && (this.getNotificationText === "" || this.getNotificationText.text != Vue.i18n.translate('adminUsersTranslation.unsavedChangesRoles'))){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminUsersTranslation.unsavedChangesRoles')});
        }else{
          this.closeNotification();
          this.updateRolesVisible = false;
        }
      }
    },

    resendVerification: function(userMail) {
      this.isUpdated = true;
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.resendVerificationLoad')});
      this.resendVerificationForUserRequest(userMail);
    },

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getAllUsersIncludingPermissions(1);
    },

    getNewListDeletion: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getDeletionRequests(1);
    },

    resetPage: function(){
      this.userListData.currentPage = 1;
    },

    resetPageDeletion: function(){
      this.requestListData.currentPage = 1;
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.getAllUsersIncludingPermissions(selectedNumber);
      }
    },

    getNextPageDeletion(selectedNumber){
      if(selectedNumber != ""){
        this.getDeletionRequests(selectedNumber);
      }
    },

    showView: function(view){
      this.loading = true;
      this.selectedView = view;
      this.filterTerm = "";
      if(view === 0){
        this.getAllUsersIncludingPermissions(1);
      }else{
        this.getDeletionRequests(1);
      }
    },

    getAllUsersIncludingPermissions: function(page){
      this.loading = true;
      var self = this;
      this.requestAllUsersIncludingPermissions(page, this.filterTerm)
      .then( function(response){
        self.userListData = response;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getAllUsersIncludingPermissions(page) }, "");
      });
    },

    getDeletionRequests: function(page){
      this.loading = true;
      var self = this;
      this.requestAccountDeletionRequests(page, this.filterTerm)
      .then( function(response){
        self.requestListData = response;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getDeletionRequests(page) }, "");
      });
    },

    closeModalDeleteUser: function(done){
      if(done){
        this.isUpdated = true;
        this.deleteUserVisible = false;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.deleteUserLoad')});
        var requestPage = this.userListData.currentPage;
        if(this.userListData.list.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteUserRequest(this.selectedUser.id)
        .then(function (){
          self.getAllUsersIncludingPermissions(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.deleteUserSuccess')});
          self.selectedUser = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModalDeleteUser(done) }, "");
        });
      }else{
        this.deleteUserVisible = false;
      }
    },

    closeModalDeleteAccount: function(done){
      this.accountDeletionVisible = false;

      if(done){
        this.isUpdated = true;
        if(this.selectedRequest.accept){
          this.acceptAccountDeletion();
        }else{
          this.declineAccountDeletion();
        }
      }
    },

    acceptAccountDeletion: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.acceptAccountDeletionLoad')});
      var requestPage = this.requestListData.currentPage;
      if(this.requestListData.list.length === 1){
        //delete last element of page -> request page before that
        requestPage--;
      }

      var self = this;
      if(this.selectedRequest.pseudonymize){
        this.pseudonymizeUserRequest(this.selectedRequest.userId)
        .then(function (){
          self.getDeletionRequests(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.acceptAccountDeletionSuccess')});
          self.selectedRequest = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.acceptAccountDeletion() }, "");
        });
      }else{
        this.deleteUserCompletelyRequest(this.selectedRequest.userId)
        .then(function (){
          self.getDeletionRequests(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.acceptAccountDeletionSuccess')});
          self.selectedRequest = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.acceptAccountDeletion() }, "");
        });
      }
    },

    declineAccountDeletion: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.declineAccountDeletionLoad')});
      var requestPage = this.requestListData.currentPage;
      if(this.requestListData.list.length === 1){
        //delete last element of page -> request page before that
        requestPage--;
      }

      var self = this;
      this.declineAccountDeletionRequest(this.selectedRequest.id)
      .then(function (){
        self.getDeletionRequests(requestPage);
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.declineAccountDeletionSuccess')});
        self.electedRequest = "";
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.declineAccountDeletion() }, "");
      });
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    showCreateAccountModal: function(){
      this.closeNotification();
      this.createAccountVisible = true;
    },

    closeModalCreate: function(created){
      this.createAccountVisible = false;

      if(created){
        //get last page
        var reqPage = Math.ceil((this.userListData.total + 1) / this.$perPage);
        this.getAllUsersIncludingPermissions(reqPage);
      }
    },

    showDeleteUser: function(user){
      this.closeNotification();
      this.selectedUser = user;
      this.deleteUserVisible = true;
    },

    showAccountDeletion: function(request, accept){
      this.closeNotification();
      this.selectedRequest = {
        accept: accept,
        id: request.id,
        userId: request.attributes.user_id,
        email: request.relationships.users && request.relationships.users.data ? request.relationships.users.data.attributes.email : "",
        pseudonymize: request.attributes.pseudonymized
      };
      this.accountDeletionVisible = true;
    },

    showIfVerified: function(verified){
      return verified ? 'visible' : 'hidden'
    },

    showIfOtherUser: function(idOfUser){
      return (Number(idOfUser) === Number(this.userId)) ? 'hidden' : 'visible'
    },

    getDataText: function(pseudonymized){
      return Vue.i18n.translate('adminUsersTranslation.' + (pseudonymized ? 'pseudonymize' : 'deleteAll'))
    },

    getStudyName: function(request, study){
      var index = request.relationships.studies.data.findIndex(s => s.id === study.id);
      return (index != -1) ? request.relationships.studies.data[index].attributes.name : ""
    },

    getUserName: function(attributes){
      var name = (attributes.firstname) ? (attributes.firstname) : "";
      if(attributes.lastname){
        name += ((name != "") ? " " : "") + attributes.lastname;
      }
      if(attributes.name){
        name += ((name != "") ? " " : "") + "(" + attributes.name + ")"
      }

      return name
    },

    getECoachManagers: function(arr){
      if(arr.length > 0){
        var string = "";
        for(var entry in arr){
          string += ((entry > 0) ? ", " : "") + arr[entry];
        }
        return string
      }else{
        return Vue.i18n.translate('generalTranslation.none')
      }
    }
  }
}
</script>
