<template>
  <div class="lessonEditorConditionTreeItem" :class="{conditionTrue: parentTestBool && conditionTestMode,
      conditionFalse: !parentTestBool && conditionTestMode}" :id="_uid">
    <draggable
      v-if="parentType != 'leaf'"
      tag="ul"
      :id="'draggable' + _uid"
      :list="item"
      :group="{ name: 'g1', put: false}"
      :sort="false"
      :class="{dragArea: parentType != 'root', dragAreaRoot: parentType === 'root'}"
      class="list-group"
    >
      <section v-for="(element, index) in item" :key="index" class="sec"> <!-- todo key -->
        <!-- only one item & item was dropped in bottom field-->
        <section v-if="(index === 0 && item.length < 2) && droppedInBottomField &&
            parentType != 'root' && parentType != 'not' && parentType != ''">
          <draggable
            tag="ul"
            :id="'draggableTop' + _uid"
            :list="item"
            :group="{ name: 'g1', put: putFunction}"
            :sort="false"
            class="dragItems list-group"
          ></draggable>
          <section class="nodeType">
            {{partentTypeAndOr}}
          </section>
        </section>

        <section v-if="index === 0 && parentType === 'not'" class="nodeType">
          {{ 'conditionEditorTranslation.not' | translate }}
        </section>

        <li class="list-group-item" :class="{conditionTrue: element.testBool && conditionTestMode,
            conditionFalse: !element.testBool && conditionTestMode, listItem: element.nodeType != 'leaf',
            listItemLeaf: element.nodeType === 'leaf'}">
          <section class="wrapper">
            <button type="button" :id="'buttonRemoveNode' + _uid" class="deleteNodeButton" @click="deleteIndex(item, index)" >
              x
            </button>
            <i
              class="fas fa-info-circle"
              v-tooltip="{ content: conditionToText, theme: 'info-tooltip' }"
              @mouseover="conditionToString(item, index)"
            ></i>
          </section>
          <section>

            <!-- children of and/or/not -->
            <ul>
              <section class="childElementsContainer">
                <!--
                  @change-node-type="$emit('change-node-type', $event)"
                -->
                <LessonEditorConditionTreeItem
                  :item="element.children"
                  :parentType="element.nodeType"
                  :parentTestBool="element.testBool"
                  :conditionTestMode="conditionTestMode"
                  @change-condition="$emit('change-condition', $event)"
                >
                <!--@change-item-name="$emit('change-item-name', $event)"-->
                </LessonEditorConditionTreeItem>
              </section>
            </ul>

            <!-- leaf -->
            <BaseCondition
              v-if="element.leafType != ''"
              :id="'condition' + _uid"
              :condition="element"
              :valuesForLabel="element.valuesForLabel"
              @change-condition="$emit('change-condition', {'name': $event.name, 'item': element, 'value': $event.value})"
            ></BaseCondition>
          </section>
        </li>

        <section v-if="(index < item.length - 1) && parentType != 'root' && parentType != 'not'" class="nodeType">
          {{partentTypeAndOr}}
        </section>
      </section>

      <!-- no items -->
      <section v-if="item.length === 0 && parentType != 'root' && parentType != ''">
        <section v-if="parentType != 'not'">
          <draggable
            tag="ul"
            :id="'draggableTop' + _uid"
            :list="item"
            :group="{ name: 'g1', put: putFunctionTop}"
            :sort="false"
            class="dragItems list-group"
          ></draggable>
        </section>

        <section class="nodeType">
          {{partentTypeAndOrNot}}
        </section>

        <draggable
          tag="ul"
          :id="'draggableBottom' + _uid"
          :list="item"
          :group="{ name: 'g1', put: putFunctionBottom}"
          :sort="false"
          class="dragItems list-group"
          ></draggable>
      </section>

      <!-- only one item & item was dropped in top field-->
      <section v-if="(item.length === 1 && !droppedInBottomField) && parentType != 'root' && parentType != 'not' && parentType != ''">
        <section class="nodeType">
          {{partentTypeAndOr}}
        </section>
        <draggable
          tag="ul"
          :id="'draggableBottom' + _uid"
          :list="item"
          :group="{ name: 'g1', put: putFunction}"
          :sort="false"
          class="dragItems list-group"
        ></draggable>
      </section>

      <!-- more than one item-->
      <section v-if="(item.length > 1) && parentType != 'root' && parentType != 'not' && parentType != ''">
        <section v-if="showAddField" key="fieldShown">
          <section class="nodeType">
            {{partentTypeAndOr}}
          </section>
          <draggable
            tag="ul"
            :id="'draggableAdd' + _uid"
            :list="item"
            :group="{ name: 'g1', put: putFunctionTop}"
            :sort="false"
            class="dragItems list-group"
          ></draggable>
          <button type="button" :id="'buttonHideField' + _uid" @click="hideNextField">
            <i class="fas fa-minus"></i> {{ 'conditionEditorTranslation.nextField' | translate }}
          </button>
        </section>
        <section v-else key="fieldHidden">
          <section class="nodeType">
            <button type="button" :id="'buttonShowField' + _uid" @click="showNextField">
              <i class="fas fa-plus"></i> {{ 'conditionEditorTranslation.nextField' | translate }}
            </button>
          </section>
        </section>
      </section>
    </draggable>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import draggable from "vuedraggable";
import BaseCondition from './BaseCondition.vue';
import questionConditionHelper from '../mixins/questionConditionHelper';

export default {
  name: 'LessonEditorConditionTreeItem',

  components: {
    draggable,
    BaseCondition
  },

  mixins: [questionConditionHelper],

  props: {
    item: {
      required: true,
      type: Array,
    },

    parentType: {
      required: true,
      type: String,
    },

    parentTestBool: {
      required: true,
      type: Boolean,
    },

    conditionTestMode: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      droppedInBottomField: false,
      showAddField: false,
      conditionToText: "",
    }
  },

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getSelectedLanguage',
    ]),

    partentTypeAndOr: function(){
      if(this.parentType === 'and'){
        return Vue.i18n.translate('conditionEditorTranslation.and')
      }else{
        return Vue.i18n.translate('conditionEditorTranslation.or')
      }
    },

    partentTypeAndOrNot: function(){
      if(this.parentType === 'and'){
        return Vue.i18n.translate('conditionEditorTranslation.and')
      }else if(this.parentType === 'or'){
        return Vue.i18n.translate('conditionEditorTranslation.or')
      }else{
        return Vue.i18n.translate('conditionEditorTranslation.not')
      }
    },
  },

  watch: {
    'item.length': function(newVal){
      if(newVal > 1 && !this.droppedInBottomField && this.parentType != "root"){
        this.$emit('change-condition', { 'name': 'change-order', 'item': this.item });
      }
    },
  },

  methods: {
    putFunction: function(){
      if(this.parentType != ""){
        if(this.parentType === 'leaf' || this.parentType === 'root'){
          return false
        }else if(this.parentType === 'not' && this.item){
          return this.item.length === 0
        }else{
          return true
        }
      }else{
        return true
      }
    },

    putFunctionBottom: function(){
      this.droppedInBottomField = true;
      var bool = this.putFunction();
      return bool
    },

    putFunctionTop: function(){
      this.droppedInBottomField = false;
      var bool = this.putFunction();
      return bool
    },

    deleteIndex: function(item, index){
      if(index === 0 && item.length === 2){
        this.droppedInBottomField = true;
      }else if(index === 1 && item.length === 2){
        this.droppedInBottomField = false;
      }else{
        this.droppedInBottomField = true;
      }
      this.$emit('change-condition', { 'name': 'delete-item', 'item': item, 'index': index });
    },

    showNextField: function(){
      this.showAddField = true;
    },

    hideNextField: function(){
      this.showAddField = false;
    },

    conditionToString: function(item, index){
      this.conditionToText = "";
      //Die Bedingung ist erfüllt, wenn:
      this.conditionToText = Vue.i18n.translate('conditionEditorTranslation.conditionString') +
        this.treeToString(item[index], this.getQuestionLabelList, false);
    },
  }
}
</script>
