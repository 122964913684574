<template>
  <div class="elementsSpace">
    <section v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''" :id="'errorTextElement' + propsObj.id" :style="{visibility: showIfNotTranslation}" class="wrapper errorMessage">
      {{errorTextsForElements[propsObj.id]}}
    </section>

    <section v-if="!isTranslation" class="sec99">
      <label>
        {{ 'elementsSpaceTranslation.size' | translate }} *
      </label>
      <select :value="propsObj.size" :id="'inputSpaceSize' + propsObj.id" :disabled="!getIsEditable"
          @input="$emit('change-element', {'type': 'attribute', 'name': 'size', 'value': $event.target.value})">
        <option value="" :id="'inputSpaceSize' + propsObj.id + 'None'" disabled>
          {{ 'elementsSpaceTranslation.selectSize' | translate }}
        </option>
        <option v-for="n in 5" :value="n" :id="'inputSpaceSize' + propsObj.id + '-' + n" :key="'size' + n">
          {{n}}
        </option>
      </select>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ElementsSpace',

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },
  },
}
</script>
