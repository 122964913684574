<template>
  <div class="homePage">
    <!--
    <section v-if="!(getRoles.includes('admin') && !getRoles.includes('editor'))" class="menuButton">
      <button type="button" @click="show('myStudies')">
        {{ 'myStudiesTranslation.myStudies' | translate }}
      </button>
      <button type="button" @click="show('myInterventions')">
        {{ 'myStudiesTranslation.myInterventions' | translate }}
      </button>
    </section>
    -->
    <i class="fa fa-spinner fa-spin fa-2x loadData"></i>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import router from '../router';
import BaseNotification from './BaseNotification.vue';

export default {
  name: 'HomePage',

  components: {
    BaseNotification,
  },

  computed: {
    ...mapGetters([
      'getRoles',
      'getAppLanguage',
    ]),
  },

  created(){
    if(this.getAppLanguage === ""){
      this.SET_APPLANGUAGE("de");
      Vue.i18n.set("de");
    }
  },

  mounted(){
    if(this.getRoles.includes("admin") && !this.getRoles.includes("editor")){
      this.closeNotification();
      router.push({ name: 'adminUsers'});
    }else{
      this.show('myStudies');
    }
  },

  methods:{
    ...mapMutations([
      'SET_APPLANGUAGE',
    ]),

    show: function(name){
      this.closeNotification();
      router.push({ name: name});
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },
  }
}
</script>
