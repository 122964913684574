<template>
  <BaseModal
    id="copyInterventionModal"
    class="interventionCopy pageModal"
    :headerText="$t('interventionTranslation.copyIntervention', { name: sourceData.name })"
    :leftButtonText="$t('generalTranslation.copy')"
    :disableButtons="isCopied"
    :disableLeftButton="selectedStudyData === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <h3 class="hCopy">
        {{ 'interventionTranslation.copyInstruction' | translate }}
      </h3>
      <p>
        {{ 'interventionTranslation.shownGroups' | translate }}
      </p>
      <StudyList
        :studyListData="studyListData"
        :copyBool="true"
        :activateBool="false"
        :disableWhileCopy="isCopied"
        @get-new-list="getNewStudies"
        @item-selected="setSelectedStudy"
        @close-notification="closeNotification"
        @reset-page="resetPage"
      ></StudyList>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import StudyList from './StudyList.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: 'InterventionCopy',

  components: {
    StudyList,
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    sourceData: { //data of the intervention which should be copied or activated (name, id, studyId)
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      isCopied: false,
      studyListData: "",
      selectedStudyData: "",
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  created(){
    document.body.style.overflow = 'hidden';
    this.getNewStudies({"page": 1, "filterTerm": ""});
  },

  beforeDestroy(){
    document.body.style.overflow = 'visible';
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCopied = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_MYROLEFORSTUDY',
    ]),

    setSelectedStudy: function(obj){
      this.selectedStudyData = obj;
    },

    closeModal: function(done) {
      if(done){
        if(this.selectedStudyData === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorSelectWorkgroup')});
        }else{
          this.isCopied = true;
          this.$emit('set-changed', { name: 'all', value: false });
          this.SET_MYROLEFORSTUDY(this.selectedStudyData.role);
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.copyInterventionLoad')});
          this.copyInterventionInWorkgroup();
        }
      }else if(!this.isCopied){
        this.$emit('close-modal', { done: false });
      }
    },

    copyInterventionInWorkgroup: function(){
      var self = this;
      this.copyInterventionInWorkgroupRequest(this.sourceData.id, this.selectedStudyData.studyId, (this.sourceData.studyId === this.selectedStudyData.studyId))
      .then(function (){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.copyInterventionSuccess')});
        self.$emit('close-modal', { done: true, studyId: self.selectedStudyData.studyId });
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.copyInterventionInWorkgroup() }, "");
      });
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    getNewStudies: function(obj){
      //copy needs owner or edit permissions in target workgroup
      var filterTerm = obj.filterTerm + "&role=study.owner,study.collaborator";
      var self = this;
      this.requestCollaboratingOrNonCollaboratingStudies(obj.page, filterTerm, "workgroup", true, false, -1)
      .then( function(response){
        self.studyListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewStudies(obj) }, "");
      });
    },

    resetPage: function(){
      this.studyListData.currentPage = 1;
    },
  }
}
</script>
