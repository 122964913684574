<template>
  <div class="elementsTablePreview">
    <section class="ulPreview">
      <section class="wrapper">
        <section class="question-text texttable" :class="{ wideTable: numberOfColumns > 3 }">
          <p>
            <span text-left v-html="sanitizeHTML(translation)" />
          </p>
        </section>
      </section>
    </section>
  </div>
</template>
<script>
import utils from "../mixins/utils";

export default {
  name: "ElementsTablePreview",

  mixins: [utils],

  props: {
    translation: {
      required: true,
      type: String,
    },
  },

  computed: {
    numberOfColumns: function() {
      return this.translation.split("<tbody>")[1].split("<tr>")[1].split("<td>").length - 1;
    },
  },
}
</script>
