<template>
  <div class="basePagination wrapper" :id="id">
    <ul v-if="numberOfPages > 1" class="secPagination">
      <li v-if="currentPage != 1" :id="'buttonPaginationBack-' + id" class="fa fa-angle-double-left fa-lg paginationArrow"
        @click="$emit('new-page', 1)"></li>
      <li v-for="number in numberOfPages" :key="number" class="paginationButton">
        <button type="button" v-if="getPageCondition(number)" :id="'buttonPagination-' + id + '-Page' + number"
            :disabled="number === currentPage" @click="$emit('new-page', number)">
          {{number}}
        </button>
      </li>
      <li v-if="currentPage != numberOfPages && numberOfPages != 0" :id="'buttonPaginationNext-' + id" class="fa fa-angle-double-right fa-lg paginationArrow"
        @click="$emit('new-page', numberOfPages)"></li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'BasePagination',

  props: {
    id: {
      required: true,
      type: String,
    },

    numberOfPages: {
      required: true,
      type: Number,
    },

    currentPage: {
      required: true,
      type: Number,
    },
  },

  methods: {
    getPageCondition: function(number){
      if(this.currentPage === 1){ //first page
        return (this.numberOfPages > 1 && number < this.currentPage + 4)
      }else if(this.currentPage === this.numberOfPages){ //last page
        return (number > this.currentPage - 4)
      }else{
        return (this.currentPage - 2 < number && number < this.currentPage + 3)
      }
    },
  },
}
</script>
