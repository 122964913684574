var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elementsTextPreview"},[_c('section',{staticClass:"ulPreview"},[_c('section',{staticClass:"wrapper",class:{
        textImportant: _vm.propsObj.type == 'important',
        textTip: _vm.propsObj.type == 'tip',
        textInfo: _vm.propsObj.type == 'info',
        textSuccess: _vm.propsObj.type == 'success',
        textHighlight: _vm.propsObj.type == 'highlight',
        secIcon: _vm.propsObj.type != 'none',
      }},[(_vm.typeWithIcon)?_c('section',{staticClass:"secIconLeft col"},[_c('ion-icon',{attrs:{"name":_vm.nameForIcon,"size":"large"}})],1):_vm._e(),_c('section',{class:{ secIconRight: _vm.typeWithIcon, col: _vm.typeWithIcon }},[_c('section',{staticClass:"question-text text ql-editor"},[_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.sanitizeHTML(_vm.replaceReferences(_vm.translation)))}})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }