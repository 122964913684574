import Vue from 'vue'
import Router from 'vue-router'
import TheLogin from './components/TheLogin.vue'
import TheLogout from './components/TheLogout.vue'
import TheResetPassword from './components/TheResetPassword.vue'
import ThePrivacy from './components/ThePrivacy.vue'
import TheImprint from './components/TheImprint.vue'
import TheErrorPage from './components/TheErrorPage.vue'
import HomePage from './components/HomePage.vue'
import MyMoreStudies from './components/MyMoreStudies.vue'
import StudyEditor from './components/StudyEditor.vue'
import MyMoreInterventions from './components/MyMoreInterventions.vue'
import InterventionEditor from './components/InterventionEditor.vue'
import InterventionPreview from './components/InterventionPreview.vue'
import DiaryEditor from './components/DiaryEditor.vue'
import LessonEditor from './components/LessonEditor.vue'
import LessonEditorConditionEditor from './components/LessonEditorConditionEditor.vue'
import LessonEditorConditionCode from './components/LessonEditorConditionCode.vue'
import SkillEditor from './components/SkillEditor.vue'
import Preferences from './components/Preferences.vue'
import AdminUsers from './components/AdminUsers.vue'
import AdminAnnouncements from './components/AdminAnnouncements.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: TheLogin,
      meta: { }
    },
    {
      path: '/logout',
      name: 'logout',
      component: TheLogout,
      meta: { requiresAuth: true }
    },
    {
      path: '/resetpassword',
      name: 'resetPassword',
      component: TheResetPassword,
      meta: { }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: ThePrivacy,
      meta: { }
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: TheImprint,
      meta: { }
    },
    {
      path: '*',
      name: 'errorPage',
      component: TheErrorPage,
      meta: { requiresAuth: true }
    },
    {
      path: '/home',
      name: 'home',
      component: HomePage,
      meta: { requiresAuth: true }
    },
    {
      path: '/preferences',
      name: 'preferences',
      component: Preferences,
      meta: { requiresAuth: true }
    },
    {
      path: '/privacy-cms',
      name: 'privacy-cms',
      component: ThePrivacy,
      meta: { requiresAuth: true }
    },
    {
      path: '/imprint-cms',
      name: 'imprint-cms',
      component: TheImprint,
      meta: { requiresAuth: true }
    },
    {
      path: '/users',
      name: 'adminUsers',
      component: AdminUsers,
      meta: { requiresAuth: true, requiresAdminPermission: true }
    },
    {
      path: '/announcements',
      name: 'adminAnnouncements',
      component: AdminAnnouncements,
      meta: { requiresAuth: true, requiresAdminPermission: true }
    },
    {
      path: '/studies/more',
      name: 'moreStudies',
      component: MyMoreStudies,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/studies/my',
      name: 'myStudies',
      component: MyMoreStudies,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/interventions/more',
      name: 'moreInterventions',
      component: MyMoreInterventions,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/interventions/my',
      name: 'myInterventions',
      component: MyMoreInterventions,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/intervention/:interventionId',
      name: 'interventionEditor',
      component: InterventionEditor,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/diary/:diaryId',
      name: 'diaryEditor',
      component: DiaryEditor,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/study/:studyId',
      name: 'studyEditor',
      component: StudyEditor,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/intervention/:interventionId/preview',
      name: 'interventionPreview',
      component: InterventionPreview,
      meta: { requiresAuth: true, requiresEditorPermission: true }
    },
    {
      path: '/lesson/:lessonId',
      name: 'lessonEditor',
      component: LessonEditor,
      meta: { requiresAuth: true, requiresEditorPermission: true },
      props: true,
    },
    {
      path: '/lesson/:lessonId/condition',
      name: 'conditionEditor',
      component: LessonEditorConditionEditor,
      meta: { requiresAuth: true, requiresEditorPermission: true }
    },
    {
      path: '/lesson/:lessonId/conditioncode',
      name: 'conditionCodeEditor',
      component: LessonEditorConditionCode,
      meta: { requiresAuth: true, requiresEditorPermission: true }
    },
    {
      path: '/skill/:skillId',
      name: 'skillEditor',
      component: SkillEditor,
      meta: { requiresAuth: true, requiresEditorPermission: true, requiresSkillsEnabled: true },
      props: true,
    },
  ]
})
