<template>
  <BaseModal
    id="importDiaryModal"
    class="diaryImport"
    :headerText="$t('diaryTranslation.importDiaryHeader', { name: targetData.name })"
    :leftButtonText="$t('generalTranslation.import')"
    :disableButtons="isImported"
    :disableLeftButton="selectedFile === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <h3>
        {{ 'diaryTranslation.selectImport' | translate }} *
      </h3>
      <form>
        <input type="file" id="inputDiaryFile" ref="fileRef" accept="application/JSON"
          :disabled="isImported" @change="handleFileSelection"/>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'DiaryImport',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    targetData: { //study in which diary should be imported (name, id)
      required: true,
      type: Object
    },
  },

  data: function(){
    return {
      isImported: false,
      selectedFile: "",
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isImported = false;
      }else if(newVal != "" && newVal.type === "success" && newVal.text === Vue.i18n.translate('diaryTranslation.importDiarySuccess')){
        this.isImported = false;
        this.$emit('close-modal', { done: true });
      }
    },
  },

  created(){
    document.body.style.overflow = 'hidden';
  },

  beforeDestroy(){
    document.body.style.overflow = 'visible';
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    handleFileSelection: function(){
      this.selectedFile = this.$refs.fileRef.files[0];
      this.SET_NOTIFICATIONTEXT("");
    },

    closeModal: function(done) {
      if(done){
        if(this.selectedFile === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorSelectFile')});
        }else{
          this.isImported = true;
          this.$emit('set-changed', { name: 'all', value: false });
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('diaryTranslation.importDiaryLoad')});
          var reader = new FileReader();
          var self = this;
          reader.onload = function(evt){
            var jsonObj = JSON.parse(evt.target.result);
            self.importDiaryRequest(jsonObj, self.targetData.id);
          };
          reader.readAsText(this.selectedFile);
        }
      }else if(!this.isImported){
        this.$emit('close-modal', { done: false });
      }
    },

  }
}
</script>
