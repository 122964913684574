<template>
  <div class="myMoreStudies">
    <StudyList
      :key="$route.fullPath"
      :studyListData="studyListData"
      :copyBool="false"
      :activateBool="false"
      :disableWhileCopy="false"
      @get-new-list="getNewStudies"
      @route-to="routeTo($event, false)"
      @close-notification="closeNotification"
      @reset-page="resetPage"
      @show-modal="showModal"
    ></StudyList>

    <StudyCreate
      v-if="createStudyVisible"
      @close-modal="closeModal"
      @route-to="routeTo($event, true)"
    ></StudyCreate>

    <StudyCopy
      v-if="copyStudyVisible"
      :sourceData="sourceData"
      @close-modal="closeModalCopy"
    ></StudyCopy>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import router from '../router';
import BaseNotification from './BaseNotification.vue';
import StudyList from './StudyList.vue';
import StudyCopy from './StudyCopy.vue';
import StudyCreate from './StudyCreate.vue';

export default {
  name: 'MyMoreStudies',

  components: {
    BaseNotification,
    StudyList,
    StudyCopy,
    StudyCreate,
  },

  mixins: [httpHelper],

  props: ['oldStudyId'],

  data: function(){
    return{
      copyStudyVisible: false,
      createStudyVisible: false,
      sourceData: "",
      studyListData: "",
      deleteStudyVisible: false,
      getSpecificPage: false,
    }
  },

  computed: {
    ...mapGetters([
      'getMyRoleForStudy',
    ]),
  },

  watch:{
    '$route': function(){
      this.getNewStudies({"page": 1, "filterTerm": ""});
    },
  },

  mounted(){
    this.closeNotification();
    if(this.oldStudyId != undefined){
      // if old study id is set, user navigated from study editor etc. to this page -> the page with this study should be shown
      // -> request all studies, find study by id, calculate page & set studies array accordingly
      this.getSpecificPage = true;
    }
    this.getAnnouncements();
  },

  beforeRouteLeave(to, from, next){
    if(this.copyStudyVisible || this.createStudyVisible || this.deleteStudyVisible){
      next(false);
    }else{
      next();
    }
  },

  methods: {
    ...mapMutations([
      'SET_ANNOUNCEMENTS',
    ]),

    showModal: function(obj){
      switch (obj.type) {
        case "StudyDelete":
          this.deleteStudyVisible = obj.value;
        break;

        case "StudyCopy":
          this.closeNotification();
          this.sourceData = obj;
          this.copyStudyVisible = true;
        break;

        case "StudyCreate":
          this.closeNotification();
          this.createStudyVisible = true;
        break;

        default:
        break;
      }
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    routeTo: function(obj, closeModal){
      if(closeModal){ //todo check if closeModal should be for all
        this.closeModal();
      }
      router.push(obj);
    },

    closeModalCopy: function(obj){
      if(obj.done){
        this.copyStudyVisible = false;

        var page = this.studyListData.allPages;
        if(this.studyListData.total > 0 && this.studyListData.total % this.$perPage === 0){
          //last page has 15 -> copy is on next
          page++;
        }

        this.getNewStudies({ "page": page, "filterTerm": "" });
      }else{
        this.copyStudyVisible = false;
      }
    },

    getNewStudies: function(obj){
      var isCollab = (this.$route.name === "myStudies");
      var self = this;
      this.requestCollaboratingOrNonCollaboratingStudies(obj.page, obj.filterTerm, "workgroup", isCollab, this.getSpecificPage, this.oldStudyId)
      .then( function(response){
        self.studyListData = response;
        self.getSpecificPage = false;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewStudies(obj) }, "");
      });
    },

    resetPage: function(){
      this.studyListData.currentPage = 1;
    },

    closeModal: function(){
      this.createStudyVisible = false;
    },

    getAnnouncements: function(){
      var self = this;
      this.requestAnnouncements(1)
      .then( function(response){
        self.SET_ANNOUNCEMENTS(response);
        self.getNewStudies({"page": self.studyListData.currentPage, "filterTerm": ""});
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getAnnouncements() }, "");
      });
    },
  }
}
</script>
