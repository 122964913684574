<template>
  <BaseModal
    id="setSkillsModal"
    class="skillsForLesson pageModal"
    :headerText="$t('interventionTranslation.updateSkills')"
    :leftButtonText="$t('generalTranslation.save')"
    :disableButtons="disableSkillsBool"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <ul>
        <li v-for="(lesson, lessonIndex) in lessonPositionsArray" :id="'entryLessonSkill' + lesson.id" :key="lesson.id" class="interventionElement lessonsDragDrop">
          <section class="wrapper">
            <section class="col interventionDescriptionSection">
              <section class="wrapper">
                <section class="col sec50">
                  <b>
                    {{lessonIndex + 1}} {{lesson.attributes.name}}
                  </b>
                  <p>
                    {{getLessonTitle(lesson)}}
                  </p>
                  <p>
                    {{getLessonDescription(lesson)}}
                  </p>
                </section>
                <section class="col sec50">
                  <label>
                    {{ 'interventionTranslation.skillsForLesson' | translate }}
                  </label>
                  <br>
                  <ul>
                    <li v-for="(skill, skillIndex) in lesson.attributes.skills" :id="'entryLessonSkill' + lesson.id + '-' + skill.id" :key="skill.id">
                      {{getSkillName(skill)}}
                      <i
                        class="fa fa-trash"
                        v-if="getIsEditable"
                        :id="'buttonDeleteSkill' + lesson.id + '-' + skill.id"
                        v-tooltip="{ content: $t('lessonTranslation.removeElement'), placement: 'right' }"
                        @click="deleteSkillOfLesson(lessonIndex, skillIndex)"
                      ></i>
                    </li>
                  </ul>
                  <section v-if="lesson.attributes.skills.length === 0" :id="'entryLessonSkill' + lesson.id + 'None'">
                    {{ 'interventionTranslation.noSkillsSelected' | translate }}
                  </section>
                  <br>
                  <label>
                    {{ 'interventionTranslation.addSkills' | translate }}
                  </label>
                  <select v-if="getSelectableSkills(lessonIndex).length > 0" v-model="selectedSkills[lessonIndex]" :id="'inputAddSkill' + lesson.id"
                        class="addLanguageSelect buttonForm" :disabled="!getIsEditable">
                    <option value="" :id="'inputAddSkill' + lesson.id + 'None'" disabled selected>
                      {{ 'interventionTranslation.selectSkill' | translate }}
                    </option>
                    <option v-for="selectableSkill in getSelectableSkills(lessonIndex)" :value="Number(selectableSkill.id)" :key="selectableSkill.id" :id="'inputAddSkill' + lesson.id + '-' + selectableSkill.id">
                      {{selectableSkill.attributes.name}}
                    </option>
                  </select>
                  <p v-else :id="'addLessonSkill' + lesson.id + 'None'">
                    {{ 'interventionTranslation.noMoreSkills' | translate }}
                  </p>
                  <button type="button" v-if="getSelectableSkills(lessonIndex).length > 0" :id="'buttonAddSkill' + lesson.id" :disabled="!getIsEditable || selectedSkills[lessonIndex] === ''"
                      class="buttonMiddle2" @click="addSkillForLesson(lessonIndex)">
                    {{ 'interventionTranslation.add' | translate }}
                  </button>
                </section>
              </section>
            </section>
          </section>
        </li>
      </ul>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'SkillsForLesson',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    allLessonsArray: {
      required: true,
      type: Array,
    },

    skillListData: {
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      lessonPositionsArray: [],
      selectedSkills: [],
      disableSkillsBool: false,
      skillsChanged: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
      'getIsEditable'
    ]),
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.disableSkillsBool = false;
      }
    },
  },

  created(){
    this.lessonPositionsArray = JSON.parse(JSON.stringify(this.allLessonsArray));
    for(var lesson in this.lessonPositionsArray){
      if(!this.lessonPositionsArray[lesson].attributes.skills){
        this.lessonPositionsArray[lesson].attributes.skills = [];
      }
    }
    this.selectedSkills = new Array(this.lessonPositionsArray.length);
    this.selectedSkills.fill("");
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT'
    ]),

    addSkillForLesson: function(lessonIndex){
      var lesson = this.lessonPositionsArray[lessonIndex];
      lesson.attributes.skills.push(Number(this.selectedSkills[lessonIndex]));
      this.lessonPositionsArray.splice(lessonIndex, 1, lesson); //todo check if needed
      this.selectedSkills.splice(lessonIndex, 1, "");
      this.skillsChanged = true;
    },

    deleteSkillOfLesson: function(lessonIndex, skillIndex){
      var lesson = this.lessonPositionsArray[lessonIndex];
      lesson.attributes.skills.splice(skillIndex, 1);
      this.lessonPositionsArray.splice(lessonIndex, 1, lesson); //todo check if needed
      this.skillsChanged = true;
    },

    getLessonTitle: function(lesson){
      return lesson.attributes.title ? lesson.attributes.title : Vue.i18n.translate('generalTranslation.noTitle')
    },

    getLessonDescription: function(lesson){
      return lesson.attributes.description ? lesson.attributes.description : Vue.i18n.translate('generalTranslation.noDescription')
    },

    getSkillName: function(skill){
      var index = this.skillListData.list.findIndex(elem => Number(elem.id) === Number(skill));
      return (index != -1) ? this.skillListData.list[index].attributes.name : ""
    },

    getSelectableSkills: function(lesson){
      return this.skillListData.list.filter(skill => !this.lessonPositionsArray[lesson].attributes.skills.includes(Number(skill.id))).filter(skill => !this.lessonPositionsArray[lesson].attributes.skills.includes(skill.id))
    },

    closeModal: function(done){
      if(done){
        this.closeNotification();
        this.disableSkillsBool = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updateSkillsLessonsLoad')});
        var arr = [];
        for(var lesson in this.lessonPositionsArray){
          arr.push(
            {
              questionnaire_id: this.lessonPositionsArray[lesson].id,
              skills: this.lessonPositionsArray[lesson].attributes.skills
            }
          );
        }

        var self = this;
        this.updateLessonSkillsInInterventionRequest(arr)
        .then(function (){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updateSkillsLessonsSuccess')});
          self.$emit('close-modal', { done: true });
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModal(done) }, "");
        });

      }else if(!this.disableSkillsBool){
        if(this.skillsChanged && (this.getNotificationText === "" ||
            (this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.unsavedChangesSkillsLeave') &&
            this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.unsavedChangesSkillsLeaveInterventionEditor')))){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.unsavedChangesSkillsLeave')});
        }else{
          this.closeNotification();
          this.$emit('close-modal', { done: false });
        }
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },
  }
}
</script>
