<template>
  <BaseModal
    id="createStudyModal"
    class="studyCreate"
    :headerText="$t('generalTranslation.createNewStudy')"
    :leftButtonText="$t('generalTranslation.create')"
    :disableButtons="isCreated"
    :disableLeftButton="name === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <section class="wrapper">
        <form @submit.prevent="closeModal(true)">
          <label>
            {{ 'generalTranslation.name' | translate }} *
          </label>
          <input type="text" v-model="name" id="inputNewName" :placeholder="$t('generalTranslation.name')" required><br>
        </form>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'StudyCreate',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  data: function(){
    return{
      name: '',
      isCreated: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_MYROLEFORSTUDY',
    ]),

    closeModal: function(done) {
      if(done){
        if(this.name === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorNameEmpty')});
        }else{
          this.isCreated = true;
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('studyTranslation.createStudyLoad')});

          var self = this;
          this.createStudyRequest(this.name)
          .then(function (newStudyId){
            self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('studyTranslation.createStudySuccess')});
            self.SET_MYROLEFORSTUDY("Owner");
            self.$emit('route-to', { name: 'studyEditor', params: { studyId: newStudyId, view: 0, owners: [] }});
          })
          .catch(function (error){
            self.handleErrors(error, function(){ self.closeModal(done) }, "");
          });
        }
      }else if(!this.isCreated){
        this.$emit('close-modal');
      }
    },
  }
}
</script>
