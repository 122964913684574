<template>
  <div class="skillEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name, interventionDetails.name, skillDetails.name]"
        :idList="[studyId, interventionId]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <BaseSelectLanguage
        v-if="selectedView != 2 && skillDetails.locales"
        :localesArray="skillDetails.locales"
        :selectedLanguage2="selectedLanguageForTranslation"
        :isTranslationMode="isTranslationMode"
        @close-notification="closeNotification"
        @change-language2="changeLanguage2"
        @change-translationmode="changeTranslationMode"
      ></BaseSelectLanguage>

      <section v-show="selectedView === 0" :id="skillDetailsFormId" class="wrapper">
        <button type="submit" disabled style="display: none"></button>
        <section v-show="!loadingLanguage">
          <!-- normal language (left) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <SkillEditorDetails
              :skillDetails="skillDetails"
              :translation="currentTranslation"
              :isTranslation="false"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguageForTranslation"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @show-modal="showModal"
            ></SkillEditorDetails>
          </section>

          <!-- translation language (right) -->
          <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
            <SkillEditorDetails
              v-if="isTranslationMode && getIsEditable"
              :skillDetails="skillDetails"
              :translation="currentTranslation2"
              :isTranslation="true"
              :isTranslationMode="isTranslationMode"
              :selectedLanguageForTranslation="selectedLanguageForTranslation"
              :studyId="studyId"
              @close-notification="closeNotification"
              @change-attribute="changeAttributeForDetails"
              @show-modal="showModal"
            ></SkillEditorDetails>
          </section>

          <section v-if="getIsEditable" class="wrapper">
            <button type="submit" id="buttonSaveSkillDetails" :disabled="isSaved" @click="saveSkillDetails">
              {{ 'generalTranslation.save' | translate }}
            </button>
          </section>
        </section>

        <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
      </section>

      <section v-if="!loadingElements" id="lessonElements">
        <LessonEditorElements
          v-show="selectedView === 1"
          :editorModalsVisible="warningChangesVisible"
          :lessonDetails="skillDetailsComplete"
          :interventionDetails="interventionDetails"
          :formChanged="formChanged"
          :isTranslationMode="isTranslationMode"
          :selectedLanguageForTranslation="selectedLanguageForTranslation"
          :isDiary="false"
          :isSkill="true"
          :studyId="studyId"
          :currentPageLessonElements="currentPageSkillElements"
          @close-notification="closeNotification"
          @show-modal="showModal"
          @route-to="routeTo"
        ></LessonEditorElements>
      </section>

      <section v-show="selectedView === 2" id="translationStatus" class="wrapper"> <!-- todo skill translation status -->
        <div v-if="skillDetails.translation_status != null" key="progressDiv">
          <section v-for="language in Object.keys(skillDetails.translation_status)" :key="language">
            <section class="wrapper">
              <section class="col sec3">
                {{language}}
              </section>
              <section class="col sec25">
                <progress :value="skillDetails.translation_status[language]" max="1"></progress>
              </section>
            </section>
          </section>
        </div>
        <div v-else key="noProgressDiv">
          {{ 'interventionTranslation.noProgress' | translate }}
        </div>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="warningText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="resolvePromise"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import router from '../router';
import BaseMenuBar from './BaseMenuBar.vue';
import BaseTabs from './BaseTabs.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BaseSelectLanguage from './BaseSelectLanguage.vue';
import SkillEditorDetails from './SkillEditorDetails.vue';
import LessonEditorElements from './LessonEditorElements.vue';
import httpHelper from '../mixins/httpHelper';

export default {
  name: 'SkillEditor',

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BaseSelectLanguage,
    SkillEditorDetails,
    LessonEditorElements
  },

  mixins: [httpHelper],

  props: ['view'],

  data: function(){
    return {
      selectedView: 1,
      warningChangesVisible: false,
      skillDetails: [],
      currentTranslation: "",
      currentTranslation2: "",
      isSaved: false,
      nextRouteName: '',
      loadingLanguage: true,
      otherModalVisible: false,
      selectedLanguageForTranslation: "",
      formChanged: false, //true if skill details changed by user & not yet saved
      isTranslationMode: false,
      skillDetailsComplete: [],
      interventionDetails: [],
      selectMediaVisible: false,
      studyDetails: [],
      loadingPage: true,
      loadingElements: true,
      copyElementsBool: false,
      resolvePromise: null,
      rejectPromise: null,
      currentPageSkillElements: 1,
      skillDetailsFormId: "skillDetailsForm",
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getMyRoleForStudy',
      'getPageRefreshBool',
      'getNotificationText',
      'getFormElementsChanged',
      'getConditionEditor',
      'getPageRefreshDetails',
      'getUserId',
      'getIsEditable'
    ]),

    studyId: function(){
      return Number(this.interventionDetails.study_id)
    },

    interventionId: function(){
      return Number(this.skillDetails.intervention_id)
    },

    skillId: function(){
      return Number(this.$route.params.skillId)
    },

    warningText: function(){
      var string = Vue.i18n.translate('generalTranslation.unsavedChanges') + ": ";
      string += this.formChanged ? Vue.i18n.translate('interventionTranslation.skillDetails') : "";

      if(this.getFormElementsChanged){
        string += (this.formChanged ? ", " : "") + Vue.i18n.translate('interventionTranslation.skillDescription');
      }
      return string
    },

    tabDetails: function(){
      return [
        {text: Vue.i18n.translate('interventionTranslation.skillDetails'), show: true, id: "details"},
        {text: Vue.i18n.translate('interventionTranslation.skillDescription'), show: true, id: "elements"},
        {text: Vue.i18n.translate('interventionTranslation.translationProgress'), show: true, id: "progress"},
      ]
    }
  },

  watch: {
    //todo copy skill elements in other skill
    // '$route.params.lessonId': function(){
    //   if(this.copyElementsBool){
    //     //get new data after copy elements in different lesson
    //     this.copyElementsBool = false;
    //     this.loadingPage = true;
    //     this.loadingLanguage = true;
    //     this.loadingElements = false;
    //     this.SET_FORMELEMENTSCHANGED(true);
    //     this.getDetailsOfLesson(true);
    //   }else{
    //     //get new data when going back from lesson where elements were copied to to lesson where elements where copied from
    //     this.loadingPage = true;
    //     this.loadingLanguage = true;
    //     this.loadingElements = true;
    //     this.getElementsAndDetails();
    //   }
    // },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isSaved = false;
        this.loadingLanguage = false;
      }else if(newVal != "" && newVal.type === "success"){
        this.isSaved = false;
        if(newVal.text === Vue.i18n.translate('interventionTranslation.updateSkillDetailsSuccess')){
          this.formChanged = false;
          this.getDetailsOfSkill(false);
        }
      }
    },

    getSelectedLanguage(){
      this.setCurrentTranslations(false);
    },

    selectedLanguageForTranslation(newVal){
      if(newVal != ""){
        this.setCurrentTranslations(true);
      }else if(this.currentTranslation2 != ""){
        this.skillDetails.translations.push(this.currentTranslation2);
        this.currentTranslation2 = "";
      }
    },
  },

  mounted(){
    if(this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.createSkillSuccess')){
      this.closeNotification();
    }

    this.SET_PREVIEWANSWERS([]);

    if(this.getPageRefreshBool && this.getPageRefreshDetails.id === this.skillId){
      this.SET_CONDITIONEDITOR(false);
      this.skillDetails = this.getPageRefreshDetails.skillDetails;
      this.skillDetailsComplete = JSON.parse(JSON.stringify(this.skillDetails));
      this.interventionDetails = this.getPageRefreshDetails.interventionDetails;
      this.studyDetails = this.getPageRefreshDetails.studyDetails;
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.loadingElements = false;
      this.init();
      this.loadingPage = false;
      this.selectedView = this.getPageRefreshDetails.view;
      this.currentPageSkillElements = this.getPageRefreshDetails.currentPage
    }else{
      if(this.view != undefined){
        this.selectedView = this.view;
      }
      this.getElementsAndDetails();
    }
    this.SET_CONDITIONEDITOR(false);
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    window.addEventListener('beforeunload', this.beforeunloadFunction);
    window.addEventListener('unload', this.unloadFunction);

    if(document.getElementById(this.skillDetailsFormId) != null){
      document.getElementById(this.skillDetailsFormId).addEventListener("input", this.changeFormHasChanged);
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.nextRouteName = to.name;
    if(to.name === from.name && !this.copyElementsBool && (this.formChanged || this.getFormElementsChanged)){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          self.formChanged = false;
          self.SET_FORMELEMENTSCHANGED(false);
          next();
        }else{
          next(false);
        }
      });
    }else{
      next();
    }
  },

  beforeRouteLeave(to, from, next){
    this.nextRouteName = to.name;
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);
    if(this.otherModalVisible || this.selectMediaVisible || to.name === "conditionEditor"){
      next(false);
    }else if(this.formChanged || this.getFormElementsChanged){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          if(to.params.oldSkillId === undefined && to.name === "interventionEditor"){
            to.params.oldSkillId = self.skillId;
          }
          self.SET_QUESTIONLABELLIST([]);
          self.SET_FORMELEMENTSCHANGED(false);
          next();
        }else{
          next(false);
        }
      });
    }else{
      if(to.params.oldSkillId === undefined && to.name === "interventionEditor"){
        to.params.oldSkillId = this.skillId;
      }
      this.formChanged = false;
      this.SET_QUESTIONLABELLIST([]);
      this.SET_FORMELEMENTSCHANGED(false);
      next();
    }
  },

  beforeDestroy(){
    if(document.getElementById(this.skillDetailsFormId)){
      document.getElementById(this.skillDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener('beforeunload', this.beforeunloadFunction);
    window.removeEventListener('unload', this.unloadFunction);
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_SELECTEDLANGUAGE',
      'SET_PREVIEWANSWERS',
      'SET_PAGEREFRESHBOOL',
      'SET_FORMELEMENTSCHANGED',
      'SET_QUESTIONLABELLIST',
      'SET_PAGEREFRESHDETAILS',
      'SET_LESSONELEMENTSARR',
      'SET_CONDITIONEDITOR',
    ]),

    showWarningModal: function(){
      return new Promise((resolve, reject) => {
        this.warningChangesVisible = true;
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },

    getElementsAndDetails: function(){
      var self = this;
      this.requestSkillElements(this.skillId, false)
      .then(function (response){
        self.SET_LESSONELEMENTSARR(response);
        self.loadingElements = false;
        self.getDetailsOfSkill(true);
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getElementsAndDetails() }, "studyDetails");
      });
    },

    getDetailsOfSkill: function(initBool){
      var self = this;
      this.requestSkillDetails(this.skillId)
      .then(function (response){
        if(initBool){
          self.skillDetails = response;
          self.skillDetailsComplete = JSON.parse(JSON.stringify(self.skillDetails));
          self.init();
          self.getInterventionAndStudy();
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getDetailsOfSkill(initBool) }, "studyDetails");
      });
    },

    getInterventionAndStudy: function(){
      var self = this;
      this.requestInterventionDetails(this.interventionId)
      .then(function (response){
        self.interventionDetails = response;
        return self.requestCollaboratorsIncludingPermissions("page=0&id=" + self.getUserId, self.studyId, false, -1, -1)
      })
      .then(function (){
        return self.requestStudyDetails(self.studyId)
      })
      .then( function(response){
        self.studyDetails = response;
        self.loadingPage = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getInterventionAndStudy() }, "");
      });
    },

    init: function(){
      if(!this.skillDetails.locales || this.skillDetails.locales.length === 0){
        this.skillDetails.locales = ["de", "en"];
      }

      if(!this.skillDetails.locales.includes(this.getSelectedLanguage)){
        var language = (this.skillDetails.locales.includes("de")) ? "de" : this.skillDetails.locales[0];
        this.SET_SELECTEDLANGUAGE(language);
      }

      this.selectedLanguageForTranslation = "";
      this.isTranslationMode = false;

      this.setCurrentTranslations(false);
    },

    showModal: function(obj){
      switch (obj.type) {
        case "StudyMedia":
          this.selectMediaVisible = obj.value;
        break;

        case "LanguageError":
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.deleteSelectedLanguageWarning')});
        break;

        case "LessonPreview":
          this.setUpdatedSkillDetailsForPreview();
        break;

        case "any":
          this.otherModalVisible = obj.value;
        break;

        default:
        break;
      }
    },

    changeLanguage2: function(language){
      this.selectedLanguageForTranslation = language;
    },

    changeTranslationMode: function(isTranslationMode){
      this.isTranslationMode = isTranslationMode;
    },

    showView: function(view){
      this.closeNotification();
      this.selectedView = view;
    },

    changeAttributeForDetails: function(obj){
      this.closeNotification();
      if(obj.name === "locales"){
        obj.value.sort();
      }
      if(obj.name != "title"){
        this.skillDetails[obj.name] = obj.value;
        this.skillDetailsComplete[obj.name] = obj.value;
      }else if(!obj.isTranslation){
        this.currentTranslation[obj.name] = obj.value;
      }else{
        this.currentTranslation2[obj.name] = obj.value;
      }

      this.formChanged = true;
    },

    changeFormHasChanged: function(){
      this.closeNotification();
      this.formChanged = true;
    },

    routeTo: function(obj){
      //todo copy skill elements in other skill
      if(obj.name === 'skillEditor'){
        //route changes -> get new skillDetails, interventionDetails, studyDetails
        //permissions and elements already set
        //in route.params.skillId watch function
        this.copyElementsBool = true;
      }

      router.push(obj);
    },

    saveSkillDetails: function(){
      if(this.skillDetails.name === "" || this.currentTranslation.title === "" || (this.currentTranslation2 != "" && this.currentTranslation2.title === "")){

        var errorText = "";
        if(this.skillDetails.name === ""){
          errorText += Vue.i18n.translate('generalTranslation.name');
        }

        if(this.currentTranslation.title === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.title');
        }

        if(this.currentTranslation2 != "" && this.currentTranslation2.title === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.titleTranslation');
        }

        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminUsersTranslation.errorFillIn') + errorText});
      }else{
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updateSkillDetailsLoad')});

        var details = JSON.parse(JSON.stringify(this.skillDetails));

        //all translations in translations array
        details.translations.push(this.currentTranslation);
        if(this.currentTranslation2 != ""){
          details.translations.push(this.currentTranslation2);
        }

        if(!details.color){
          details.color = "#15417e";
        }

        this.updateDetails(details);
      }
    },

    updateDetails: function(details){
      var self = this;
      this.updateSkillDetailsRequest(details, this.skillId)
      .then(function (){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updateSkillDetailsSuccess')});
        self.getDetailsOfSkill(false);
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.updateDetails(details) }, "");
      });
    },

    setCurrentTranslations: function(isTranslation){
      var language = !isTranslation ? this.getSelectedLanguage : this.selectedLanguageForTranslation;

      if(!this.skillDetails.translations){
        this.skillDetails.translations = [];
      }

      if(this.selectedLanguageForTranslation === "" && this.currentTranslation2 != ''){
        this.skillDetails.translations.push(this.currentTranslation2);
        this.currentTranslation2 = '';
      }

      var translation = '';
      var index = this.skillDetails.translations.findIndex(elem => elem.locale === language);
      if(index != -1){
        translation = this.skillDetails.translations[index];
        this.skillDetails.translations.splice(index, 1);
      }else if(language != ""){
        translation = { "locale": language, "title": "" };
      }

      if(!isTranslation){
        if(this.currentTranslation != ""){
          this.skillDetails.translations.push(this.currentTranslation);
        }
        if(translation != ""){
          this.currentTranslation = translation;
        }
      }else{
        if(this.currentTranslation2 != ''){
          this.skillDetails.translations.push(this.currentTranslation2);
        }
        if(translation != "" && this.selectedLanguageForTranslation != ""){
          this.currentTranslation2 = translation;
        }
      }
      this.loadingLanguage = false;
    },

    unloadFunction: function(){
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();
      this.isTranslationMode = false;
      this.currentPageSkillElements = 1;
      var data = this.setRefreshData();
      this.SET_PAGEREFRESHDETAILS(data);
    },

    setRefreshData: function(){
      //all translations in translations array
      var skillDetailsArr = JSON.parse(JSON.stringify(this.skillDetails));
      if(this.currentTranslation){
        skillDetailsArr.translations.push(this.currentTranslation);
        this.currentTranslation = "";
      }

      if(this.currentTranslation2){
        skillDetailsArr.translations.push(this.currentTranslation2);
        this.currentTranslation2 = "";
      }

      var data = {
        id: this.skillId,
        skillDetails: skillDetailsArr,
        interventionDetails: this.interventionDetails,
        studyDetails: this.studyDetails,
        view: this.selectedView,
        formChanged: this.formChanged,
        currentPage: this.currentPageSkillElements
      };
      return data
    },

    beforeunloadFunction: function(e){
      if(this.formChanged || this.getFormElementsChanged){
        e.preventDefault();
        e.returnValue = '';
        return;
      }
      delete e['returnValue'];
    },

    setUpdatedSkillDetailsForPreview: function(){
      this.closeNotification();
      var skillDetailsArr = JSON.parse(JSON.stringify(this.skillDetails));
      if(this.currentTranslation){
        skillDetailsArr.translations.push(JSON.parse(JSON.stringify(this.currentTranslation)));
      }

      if(this.currentTranslation2){
        skillDetailsArr.translations.push(JSON.parse(JSON.stringify(this.currentTranslation2)));
        this.currentTranslation2 = "";
      }
      this.skillDetailsComplete = skillDetailsArr;
    },

    closeModal: function(){
      this.warningChangesVisible = false;
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },
  }
}
</script>
