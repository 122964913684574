<template>
  <div id="notificationPopup" class="baseNotification">
    <div :id="'notification' + type" class="popupNotification" :class="{popupNotificationSuccess: type === 'success',
        popupNotificationError: type === 'error', popupNotificationLoad: type === 'load'}" :key="type" @click="closeNotification">
      <span class="popuptextNotification" :class="{show: isShown}">
        <section class="wrapper">
          <section class="col sec15">
            <i class="fa fa-lg" :class="{'fa-check': type === 'success', 'fa-times': type === 'error',
              'fa-spinner': type === 'load', 'fa-spin': type === 'load'}"></i>
          </section>
          <section class="col sec85">
            {{notificationText}}
          </section>
        </section>
      </span>
    </div>
  </div>
</template>
<script>
import {mapMutations, mapGetters} from 'vuex';

export default {
  name: 'BaseNotification',

  data: function(){
    return {
      notificationText: "",
      type: "",
      isShown: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  watch: {
    getNotificationText(newVal){
      if(newVal != ""){
        this.notificationText = this.getNotificationText.text;
        this.type = this.getNotificationText.type;
        this.isShown = true;
      }else{
        this.closeNotification();
      }
    },
  },

  mounted(){
    if(this.getNotificationText != ""){
      this.notificationText = this.getNotificationText.text;
      this.type = this.getNotificationText.type;
      this.isShown = true;
    }
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    closeNotification: function(){
      this.notificationText = "";
      this.SET_NOTIFICATIONTEXT("");
      this.isShown = false;
    },
  }
}
</script>
