<template>
  <div class="adminAnnouncements">
    <section class="smallerComp">
      <section class="wrapper">
        <section class="headlineClass">
          <h1>
            {{ 'adminAnnouncementsTranslation.adminAnnouncements' | translate }}
          </h1>
        </section>
        <button type="button" v-if="!loading" id="buttonCreateAnnouncement" class="createButton" @click="showCreateAnnouncementModal">
          {{ 'adminAnnouncementsTranslation.createAnnouncement' | translate }}
        </button>
      </section>

      <section v-show="!loading">
        <BaseSearch
          id="searchAnnouncements"
          type="announcements"
          @get-new-list="getNewList"
          @reset-page="resetPage"
        ></BaseSearch>

        <BasePagination
          id="paginationAnnouncementsTop"
          :numberOfPages="announcementListData.allPages"
          :currentPage="announcementListData.currentPage"
          @new-page="getNextPage"
        ></BasePagination>

        <table v-if="announcementListData.list && announcementListData.list.length > 0" id="announcementList">
          <tr>
            <th>
              {{ 'adminAnnouncementsTranslation.content' | translate }}
            </th>
            <th>
              {{ 'adminAnnouncementsTranslation.creator' | translate }}
            </th>
            <th>
              {{ 'adminAnnouncementsTranslation.type' | translate }}
            </th>
            <th>
              {{ 'adminAnnouncementsTranslation.clients' | translate }}
            </th>
            <th>
              {{ 'adminAnnouncementsTranslation.date' | translate }}
            </th>
            <th>
            </th>
          </tr>
          <tr v-for="announcement in announcementListData.list" :id="'entryAnnouncement' + announcement.id" :key="announcement.id" class="interventionElement">
            <td>
              <section v-for="(translation, index) in announcement.attributes.translations" :key="translation.id">
                <template v-if="index > 0">
                  <br>
                </template>
                <label>
                  {{translation.title}}
                </label>
                {{translation.message}}
              </section>
            </td>
            <td>
              {{getUserInfo(announcement.relationships)}}
            </td>
            <td>
              {{formatType(announcement.attributes.type)}}
            </td>
            <td>
              {{formatClients(announcement.attributes.client)}}
            </td>
            <td>
              {{formatDate(announcement.attributes.start)}} - {{formatDate(announcement.attributes.end)}}
            </td>
            <td>
              <div class="adminButtons">
                <i
                  class="fas fa-pen fa-lg"
                  :id="'buttonEditAnnouncement' + announcement.id"
                  v-tooltip="$t('adminUsersTranslation.edit')"
                  :disabled="isUpdated"
                  @click="updateAnnouncement(announcement)"
                ></i>
                <i
                  class="fa fa-trash fa-lg"
                  :id="'buttonDeleteAnnouncement' + announcement.id"
                  v-tooltip="$t('generalTranslation.delete')"
                  :disabled="isUpdated"
                  @click="showDeleteAnnouncement(announcement.id, announcement.attributes.title)"
                ></i>
              </div>
            </td>
          </tr>
        </table>

        <section v-else id="noAnnouncements">
          {{ 'adminAnnouncementsTranslation.noAnnouncements' | translate }}
        </section>

        <BasePagination
          id="paginationAnnouncementsBottom"
          :numberOfPages="announcementListData.allPages"
          :currentPage="announcementListData.currentPage"
          class="paginationBottom"
          @new-page="getNextPage"
        ></BasePagination>
      </section>

      <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loading"></i>
    </section>

    <AdminAnnouncementCreate
      v-if="createAnnouncementVisible"
      :announcementData="announcementData"
      @close-modal="closeModalCreate"
    ></AdminAnnouncementCreate>

    <BaseModal
      v-if="deleteAnnouncementVisible"
      id="deleteAnnouncementModal"
      :headerText="$t('adminAnnouncementsTranslation.deleteWarning', { name: titleForDeleting })"
      :bodyText="$t('myStudiesTranslation.warning')"
      :leftButtonText="$t('generalTranslation.delete')"
      :disableButtons="isUpdated"
      @close-modal="closeModalDeleteAnn"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import AdminAnnouncementCreate from './AdminAnnouncementCreate.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BasePagination from './BasePagination.vue';
import BaseSearch from './BaseSearch.vue';

export default {
  name: 'AdminAnnouncements',

  components: {
    AdminAnnouncementCreate,
    BaseNotification,
    BasePagination,
    BaseSearch,
    BaseModal,
  },

  mixins: [httpHelper],

  data: function(){
    return{
      announcementListData: {},
      loading: true,
      filterTerm: "",
      dateOptions: { year: 'numeric', month: 'numeric', day: 'numeric'},
      timeOptions: { hour: 'numeric', minute: 'numeric'},
      createAnnouncementVisible: false,
      isUpdated: false,
      deleteAnnouncementVisible: false,
      titleForDeleting: "",
      announcementIdForDeleting: "",
      announcementData: {},
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
      'getAppLanguage',
    ]),

    timeZone: function(){
      return this.getAppLanguage === "de" ? "de-DE" : "en-US"
    },
  },

  mounted(){
    this.closeNotification();
    this.getAnnouncementsCMS(1);
  },

  beforeRouteLeave(to, from, next){
    if(this.createAnnouncementVisible){
      next(false);
    }else{
      next();
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.loading = false;
        this.isUpdated = false;
      }else if(newVal != "" && newVal.type === "success"){
        this.isUpdated = false;
      }
    },
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_ANNOUNCEMENTS',
    ]),

    getAnnouncements: function(page){
      this.loading = true;
      var self = this;
      this.requestAdminAnnouncements(page, this.filterTerm)
      .then( function(response){
        self.announcementListData = response;
        self.loading = false;
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getAnnouncements(page) }, "");
      });
    },

    getNextPage(selectedNumber){
      if(selectedNumber != ""){
        this.getAnnouncements(selectedNumber);
      }
    },

    resetPage: function(){
      this.announcementListData.currentPage = 1;
    },

    getNewList: function(filterTerm){
      this.filterTerm = filterTerm;
      this.getAnnouncements(1);
    },

    formatDate(dateUnformatted){
      var date = new Date(dateUnformatted * 1000);
      var datestring = date.toLocaleDateString(this.timeZone, this.dateOptions) + ", " +
        date.toLocaleTimeString(this.timeZone, this.timeOptions) + " " +
        Vue.i18n.translateIn(this.getAppLanguage, 'elementsQuestionTranslation.time');
      return datestring
    },

    formatClients(clients){
      var clientString = "";
      if(clients.includes("cms")){
        clientString += Vue.i18n.translateIn(this.getAppLanguage, 'adminAnnouncementsTranslation.cms');
      }
      if(clients.includes("ecoach")){
        clientString += ((clientString != "") ? ", " : "") + Vue.i18n.translateIn(this.getAppLanguage, 'adminAnnouncementsTranslation.ecoach');
      }
      if(clients.includes("app")){
        clientString += ((clientString != "") ? ", " : "") + Vue.i18n.translateIn(this.getAppLanguage, 'adminAnnouncementsTranslation.app');
      }
      return clientString
    },

    formatType(type){
      return Vue.i18n.translateIn(this.getAppLanguage, 'adminAnnouncementsTranslation.' + type);
    },

    showCreateAnnouncementModal: function(){
      this.closeNotification();
      this.announcementData = {};
      this.createAnnouncementVisible = true;
    },

    updateAnnouncement: function(announcement){
      this.closeNotification();

      this.announcementData = {
        "title" : "",
        "titleEN" : "",
        "message" : "",
        "messageEN" : "",
        "type" : announcement.attributes.type,
        "client" : announcement.attributes.client,
        "start": announcement.attributes.start,
        "end": announcement.attributes.end,
        "id": announcement.id
      };

      var translations = announcement.attributes.translations;
      for(var translation in translations){
        if(translations[translation].locale == "de"){
          this.announcementData.title = translations[translation].title;
          this.announcementData.message = translations[translation].message;
        }else if(translations[translation].locale == "en"){
          this.announcementData.titleEN = translations[translation].title;
          this.announcementData.messageEN = translations[translation].message;
        }
      }

      this.createAnnouncementVisible = true;
    },

    closeModalCreate: function(obj){
      this.closeNotification();
      this.createAnnouncementVisible = false;

      if(obj.done){
        var reqPage = this.announcementListData.currentPage;
        if(Object.keys(this.announcementData).length === 0){//if create
          //get last page
          reqPage = Math.ceil((this.announcementListData.total + 1) / this.$perPage);
        }

        this.getAnnouncementsCMS(reqPage);
      }
    },

    showDeleteAnnouncement: function(announcementId, title){
      this.closeNotification();
      this.announcementIdForDeleting = announcementId;
      this.titleForDeleting = title;
      this.deleteAnnouncementVisible = true;
    },

    closeModalDeleteAnn: function(done){
      this.closeModalDelete();

      if(done){
        this.isUpdated = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminAnnouncementsTranslation.deleteAnnouncementLoad')});
        var requestPage = this.announcementListData.currentPage;
        if(this.announcementListData.list.length === 1){
          //delete last element of page -> request page before that
          requestPage--;
        }

        var self = this;
        this.deleteAnnouncementRequest(this.announcementIdForDeleting)
        .then(function (){
          self.getAnnouncementsCMS(requestPage);
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminAnnouncementsTranslation.deleteAnnouncementSuccess')});
          self.announcementIdForDeleting = "";
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.closeModalDeleteAnn(done) }, "");
        });
      }
    },

    closeModalDelete: function(){
      this.deleteAnnouncementVisible = false;
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    getAnnouncementsCMS: function(pageForAnnouncementList){
      var self = this;
      this.requestAnnouncements(1)
      .then( function(response){
        self.SET_ANNOUNCEMENTS(response);
        self.getAnnouncements(pageForAnnouncementList);
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getAnnouncementsCMS(pageForAnnouncementList) }, "");
      });
    },

    getUserInfo: function(relationships){
      if(relationships && relationships.user && relationships.user.data && relationships.user.data.attributes){
        if(relationships.user.data.attributes.firstname && relationships.user.data.attributes.lastname){
          return relationships.user.data.attributes.firstname + " " + relationships.user.data.attributes.lastname;
        }else{
          return relationships.user.data.attributes.email;
        }
      }else{
        return ""
      }
    }
  }
}
</script>
