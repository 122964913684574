<template>
  <BaseModal
    id="copyStudyModal"
    class="studyCopy"
    :headerText="$t('studyTranslation.copyStudy', { name: sourceData.name })"
    :leftButtonText="$t('generalTranslation.copy')"
    :disableButtons="isCopied"
    :disableLeftButton="name === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <form @submit.prevent="closeModal(true)">
        <label>
          {{ 'studyTranslation.newName' | translate }} *
        </label>
        <input type="text" v-model="name" id="inputNewName" :placeholder="$t('studyTranslation.newName')"><br>
      </form>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'StudyCopy',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    sourceData: { //study which should be copied (name, id)
      required: true,
      type: Object
    },
  },

  data: function(){
    return{
      name: '',
      isCopied: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCopied = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    closeModal: function(done) {
      if(done){
        if(this.name === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorNameEmpty')});
        }else{
          this.isCopied = true;
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('studyTranslation.copyStudyLoad')});

          var self = this;
          this.copyStudyRequest(this.sourceData.id, this.name)
          .then(function (){
            self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('studyTranslation.copyStudySuccess')});
            self.$emit('close-modal', { done: true });
          })
          .catch(function (error){
            self.handleErrors(error, function(){ self.closeModal(done) }, "");
          });
        }
      }else if(!this.isCopied){
        this.$emit('close-modal', { done: false });
      }
    },
  }
}
</script>
