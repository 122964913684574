<template>
  <div class="elementsMediaPreview">
    <div class="question">
      <h3 :id="'mediaPreview' + propsObj.id + 'Title'">
        {{replaceReferences(translation.subtitle)}}
      </h3>

      <div class="preview" :class="{mediaDiv: propsObj.banner}">
        <div v-if="propsObj.linkType.startsWith('image')" :class="{isBanner: propsObj.banner}">
          <img :src="uriString" :width="pictureWidth" :height="pictureHeight" :id="'mediaPreview' + propsObj.id + 'Image'">
        </div>

        <video v-else-if="propsObj.linkType.startsWith('video') && !propsObj.isAudio" controls :src="uriString"
          :width="propsObj.width + '%'" :id="'mediaPreview' + propsObj.id + 'Video'"></video>

        <audio v-else-if="(propsObj.linkType.startsWith('video') && propsObj.isAudio) || propsObj.linkType.startsWith('audio')"
          width="450" controls :src="uriString" :id="'mediaPreview' + propsObj.id + 'Audio'"></audio>

        <span v-else-if="hasTypeFile(propsObj.linkType)">
          <a class="fas fa-4x" :class="fileClass" :href="uriString" target="_blank" rel="noopener noreferrer"
            :download="translation.filename" :id="'mediaPreview' + propsObj.id + 'File' + fileClass"></a>
          <br>
          <a :href="uriString" target="_blank" rel="noopener noreferrer" :download="translation.filename" :id="'mediaPreview' + propsObj.id + 'FileLink'">
            {{translation.filename}}
          </a>
        </span>
      </div>

      <p text-center :id="'mediaPreview' + propsObj.id + 'Description'">
        {{replaceReferences(translation.description)}}
      </p>
    </div>
  </div>
</template>

<script>
import questionConditionHelper from '../mixins/questionConditionHelper';
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'ElementsMediaPreview',

  mixins: [questionConditionHelper, elementsHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },
  },

  computed: {
    pictureWidth: function(){
      return this.propsObj.banner ? "100%" : this.propsObj.width + '%'
    },

    pictureHeight: function(){
      return this.propsObj.banner ? this.propsObj.height + '%' : "auto"
    },

    uriString: function(){
      return this.translation.uri != null ? this.translation.uri : ""
    },

    fileClass: function(){
      if(this.hasTypeWord(this.propsObj.linkType)){
        return 'fa-file-word'
      }else if(this.hasTypePPT(this.propsObj.linkType)){
        return 'fa-file-powerpoint'
      }else{
        return 'fa-file-pdf'
      }
    }
  },
  //document.getElementById().pause()
  //document.getElementById().currentTime = 0
}
</script>
