import Vue from 'vue';
import {mapGetters} from 'vuex';
import '@aas2/aas2-evaluation-service';

export default {
  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getSelectedLanguage',
      'getPreviewAnswers',
    ]),
  },

  methods: {
    getLabelList: function(elementsList){
      var result = this.getLabelListEntries(elementsList, 0);

      for(var q = 0; q < result.list.length; q++){
        if(result.list[q] === undefined){
          result.list[q] = 0;
        }
      }

      return result
    },

    checkDuplicateQuestionLabels: function(labellist){
      var questionLabels = [];
      for(var entry in labellist){
        if(labellist[entry] && labellist[entry].label){
          if(!questionLabels.includes(labellist[entry].label)){
            questionLabels.push(labellist[entry].label);
          }else{
            return false
          }
        }
      }
      return true
    },

    getLabelListEntries: function(elementsList, highestId){
      var list = [0];

      for(var element in elementsList){
        if(elementsList[element].propsObj.id > highestId){
          highestId = elementsList[element].propsObj.id;
        }
        if(elementsList[element].type === "ElementsQuestion"){
          //if addQuestion then selectedQuestionProp is not yet defined
          var entry = {
            label: elementsList[element].propsObj.labelProp,
            value: [true, false],
            type: "question",
            questionType: elementsList[element].propsObj.selectedQuestionProp
          };

          if(elementsList[element].propsObj.selectedQuestionProp != undefined){
            var values = JSON.parse(JSON.stringify(elementsList[element].propsObj.values));
            switch(elementsList[element].propsObj.selectedQuestionProp) {
              case "SingleChoice":
              case "MultipleChoice":
                entry.value = values;
                entry.values = JSON.parse(JSON.stringify(entry.value));
                entry.translations = {};

                for(var translation in elementsList[element].translations){
                  entry.translations[translation] = elementsList[element].translations[translation].answers
                }
              break;

              case "Slider":
                entry.value = { min: Number(values.min), max: Number(values.max),
                  step: Number(values.step), start: Number(values.start) };
                entry.type = "slider";
              break;

              case "QuestionTable":
                entry.value = { min: Number(values.min), max: Number(values.max), questions: Number(values.questions) };
                entry.type = "table";
              break;

              case "TextDate":
              case "TextDateTime":
              case "TextTime":
                entry.value = [elementsList[element].propsObj.selectedQuestionProp];
                entry.type = "date";
              break;

              case "TextString":
              case "TextArea":
                entry.value = [];
                entry.type = "text";
              break;

              default:
              break;
            }
          }

          list[elementsList[element].propsObj.id] = entry;
        }else if(elementsList[element].type === "ElementsBlock"){
          var result = this.getLabelListEntries(elementsList[element].propsObj.elements, highestId);
          highestId = result.counter;
          list = Object.assign(list, result.list);
        }
      }
      return {
        list: list,
        counter: highestId
      }
    },

    updateValuesInComplexCondition: function(condition, newVal, oldVal){
      if(condition.nodeType === "leaf"){
        var valueList = [];
        var oldValueList = [];
        var answer;

        if(condition.questionLabel != "" && condition.questionLabel != 0){
          if(newVal[condition.questionLabel] === 0){
            condition.leafType = "question";
            condition.questionLabel = "";
            condition.questionLabel = "";
            condition.operation = "";
            condition.value = "";
            condition.secondValue = "";
          }else{
            if(newVal[condition.questionLabel].type != "text"){
              valueList = newVal[condition.questionLabel].value;
              oldValueList = oldVal[condition.questionLabel].value;
            }

            //question changed type
            if(newVal[condition.questionLabel].type != condition.leafType){
              //leaftype & label
              condition.leafType = newVal[condition.questionLabel].type;

              //operation
              if(newVal[condition.questionLabel].type === "question"){
                condition.operation = "";
                condition.secondValue = "";
              }
              if(valueList[0] === "TextTime" || newVal[condition.questionLabel].type === "text"){
                condition.operation = "answered";
              }
            }

            //value & secondValue
            if(valueList.length > 0){
              if(newVal[condition.questionLabel].type === oldVal[condition.questionLabel].type){
                if(valueList.length === oldValueList.length){
                  for(answer in valueList){
                    if(valueList[answer] != oldValueList[answer]){
                      if(condition.value === oldValueList[answer]){
                        condition.value = String(valueList[answer]);
                      }
                      break;
                    }
                  }
                }else{
                  for(answer in valueList){
                    if(valueList[answer] != oldValueList[answer] && condition.value === oldValueList[answer]){
                      condition.value = "";
                      break;
                    }
                  }
                }
              }else{
                if(!valueList.includes(condition.value)){
                  condition.value = "";
                }
                if(!valueList.includes(condition.secondValue)){
                  condition.secondValue = "";
                }
              }
            }else if(typeof valueList.min != "undefined"){
              if(condition.leafType != "slider" && condition.leafType != "table"){
                condition.value = "";
                condition.secondValue = "";
              }else{
                if(typeof Number(condition.value) != "number" || (condition.leafType === "slider" && Number(condition.value) < valueList.min)){
                  condition.value = "";
                }
                if(typeof  Number(condition.secondValue) != "number" || (condition.leafType === "slider" && Number(condition.secondValue) < valueList.min)){
                  condition.secondValue = "";
                }
              }
            }else if(condition.leafType === "date"){
              if(!this.isValidDate(String(condition.value))){
                condition.value = "";
              }
              if(!this.isValidDate(String(condition.secondValue))){
                condition.secondValue = "";
              }
            }else{
              condition.value = "";
              condition.secondValue = "";
            }
          }
        }
      }else{
        var children = [];
        for(var child in condition.children){
          children.push(this.updateValuesInComplexCondition(JSON.parse(JSON.stringify(condition.children[child])), newVal, oldVal));
        }
        condition.children = children;
      }
      return condition
    },

    isValidDate: function(dateString){
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      return (dateString.match(regEx) != null);
    },

    //usePosition true for ElementsBlock; false for TreeItem
    //true: questionlabel -1
    //in TreeItem string with ... in AND/OR/NOT -> in ElementsBlock not possible because condition usually complete and if not: only gaps in leafs
    treeToString: function(tree, labellist, usePosition){
      var str;
      var child;
      if(tree.nodeType === "leaf"){
        //die Frage <label> -- the question
        str = Vue.i18n.translate('conditionEditorTranslation.questionString') + " ";

        //<label>
        if(usePosition){
          if(tree.questionLabel > 0 && tree.questionLabel != "" && labellist[tree.questionLabel] != 0){
            //questionLabel is pos in lesson
            str += labellist[tree.questionLabel].label;
          }else{
            str += "...";
          }
        }else{
          if(tree.questionLabel != "" && (Number(tree.questionLabel) === 0 || Number(tree.questionLabel) > 0) && labellist[tree.questionLabel]){
            str += labellist[tree.questionLabel].label;
          }else{
            str += "...";
          }
        }

        if(tree.operation === "answered"){
          str += " " + Vue.i18n.translate('conditionEditorTranslation.withAnyString');
        }else{
          if(tree.leafType === "question"){
            //mit dem Wert <value> -- was answered with <value>
            str += " " + Vue.i18n.translate('conditionEditorTranslation.withString') + " ";
            if(typeof tree.value === "boolean"){
              if(tree.value){
                str += Vue.i18n.translate('generalTranslation.yes');
              }else{
                str += Vue.i18n.translate('generalTranslation.no');
              }
            }else{
              str += (tree.value != "") ? tree.value : "...";
            }
          }else{
            // mit einem Wert -- was answered with a value
            str += " " + Vue.i18n.translate('conditionEditorTranslation.operationString');

            //<operation>
            switch(tree.operation) {
              case "less":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.less');
              break;

              case "lessEqual":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.lessEqual');
              break;

              case "greater":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.greater');
              break;

              case "greaterEqual":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.greaterEqual');
              break;

              case "equals":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.equals');
              break;

              case "notEquals":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.notEquals');
              break;

              case "between":
                str += " " + Vue.i18n.translate('conditionEditorTranslation.between');
              break;

              default:
                str += " " + "...";
              break;
            }

            //<value>
            str += (tree.value != "") ? " " + tree.value : " " + "...";

            // und <value>
            if(tree.operation === "between"){
              str += " " + Vue.i18n.translate('conditionEditorTranslation.betweenAnd') + " " + (tree.secondValue != "") ? " " + tree.secondValue : " " + "...";
            }
          }
          //beantwortet wurde -- " "
          str += " " + Vue.i18n.translate('conditionEditorTranslation.answeredString');
        }
      }else if(tree.nodeType === "and" || tree.nodeType === "or"){
        str = "(";
        for(child in tree.children){
          str += this.treeToString(tree.children[child], labellist, usePosition);
          if(child < tree.children.length - 1){
            if(tree.nodeType === "and"){
              str += " " + Vue.i18n.translate('conditionEditorTranslation.and') + " ";
            }else{
              str += " " + Vue.i18n.translate('conditionEditorTranslation.or') + " ";
            }
          }
        }
        if(tree.children.length === 0){
          str += " " + "...";
        }
        if(tree.children.length <= 1){
          if(tree.nodeType === "and"){
            str += " " + Vue.i18n.translate('conditionEditorTranslation.and') + " " + "...";
          }else{
            str += " " + Vue.i18n.translate('conditionEditorTranslation.or') + " " + "...";
          }
        }
        str += ")";
      }else if(tree.nodeType === "not"){
        str = Vue.i18n.translate('conditionEditorTranslation.not') + " (";
        for(child in tree.children){
          str += this.treeToString(tree.children[child], labellist, usePosition);
        }
        if(tree.children.length === 0){
          str += "...";
        }
        str += ")";
      }
      return str
    },

    getValueText: function(value){
      if(typeof value === "boolean"){
        if(value){
          return Vue.i18n.translate('generalTranslation.yes')
        }else{
          return Vue.i18n.translate('generalTranslation.no')
        }
      }else{
        return value
      }
    },

    replaceReferences: function(textInput){
      this.evaluationService = require('@aas2/aas2-evaluation-service');
      return this.evaluationService.evaluateReferences(textInput, this.getPreviewAnswers, this.getQuestionLabelList, this.getSelectedLanguage)
    },
  }
};
