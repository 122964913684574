<template>
  <div class="elementsTextToolbar toolbar ql-toolbar ql-snow" :class="{wrapper: !isTranslationMode}">
    <div :class="{wrapper: isTranslationMode}">
      <div>
        <span v-tooltip="selectTooltip($t('elementsTextTranslation.font'))">
          <select class="ql-font">
            <option v-for="font in FONTS" :value="font.value" :key="font.value">
              {{ font.label }}
            </option>
          </select>
        </span>
      </div>

      <div>
        <span v-tooltip="selectTooltip($t('elementsTextTranslation.header'))">
          <select class="ql-header" name="top">
            <option selected></option>
            <option v-for="n in 6" :value="n" :key="n"></option>
          </select>
        </span>
      </div>

      <button
        type="button"
        v-for="toolbarButton in toolbarButtonsTop"
        :key="toolbarButton.name"
        :class="toolbarButton.name"
        v-tooltip="toolbarButton.title"
      ></button>

      <button
        type="button"
        v-for="toolbarButton in toolbarButtonsAlign"
        :value="toolbarButton.value"
        :key="toolbarButton.name + toolbarButton.value"
        :class="toolbarButton.name"
        v-tooltip="toolbarButton.title"
      ></button>
    </div>

    <div :class="{wrapper: isTranslationMode}">
      <button
        type="button"
        v-for="toolbarButton in toolbarButtonsBottom"
        :value="toolbarButton.value"
        :key="toolbarButton.name + toolbarButton.value"
        :class="toolbarButton.name"
        v-tooltip="toolbarButton.title"
      ></button>

      <div>
        <span
          v-tooltip="{
            content: $t('elementsTextTranslation.color'),
            skidding: -15,
            arrowPadding: 47,
            arrowOverflow: false,
          }"
        >
          <select class="ql-color"></select>
        </span>
      </div>

      <div>
        <span
          v-tooltip="{
            content: $t('elementsTextTranslation.background'),
            skidding: -15,
            arrowPadding: 73,
            arrowOverflow: false,
          }"
        >
          <select class="ql-background"></select>
        </span>
      </div>

      <button type="button" class="ql-blockquote" v-tooltip="$t('elementsTextTranslation.blockquote')"></button>

      <button type="button" class="ql-link" v-tooltip="$t('elementsTextTranslation.link')"></button>

      <button
        type="button"
        class="fas fa-image"
        :id="'buttonInsertImage' + id + (isTranslationMode ? 'Translation' : '')"
        v-tooltip="$t('elementsTextTranslation.image')"
        @click="$emit('show-modal', {type: 'Insert', isRef: false})"
      ></button>

      <button type="button" class="ql-clean" v-tooltip="$t('elementsTextTranslation.clean')"></button>

      <button
        type="button"
        v-if="!isSkill"
        :id="'buttonInsertReference' + id + (isTranslationMode ? 'Translation' : '')"
        v-tooltip="$t('elementsTextTranslation.reference')"
        class="textEditorButton"
        @click="$emit('show-modal', {type: 'Insert', isRef: true})"
      >
        {{buttonText}}
      </button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { FONTS } from "../utils/fonts";

export default {
  name: 'ElementsTextToolbar',

  props: {
    id: {
      required: true,
      type: Number,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    toolbarButtonsTop: function(){
      return [
        {
          name: "ql-bold",
          title: Vue.i18n.translate('elementsTextTranslation.bold')
        },
        {
          name: "ql-italic",
          title: Vue.i18n.translate('elementsTextTranslation.italic')
        },
        {
          name: "ql-underline",
          title: Vue.i18n.translate('elementsTextTranslation.underline')
        },
        {
          name: "ql-strike",
          title: Vue.i18n.translate('elementsTextTranslation.strike')
        },
      ]
    },

    toolbarButtonsAlign: function(){
      return [
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.align'),
          value: ""
        },
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.alignCenter'),
          value: "center"
        },
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.alignRight'),
          value: "right"
        },
        {
          name: "ql-align",
          title: Vue.i18n.translate('elementsTextTranslation.alignJustify'),
          value: "justify"
        },
      ]
    },

    toolbarButtonsBottom: function(){
      return [
        {
          name: "ql-list",
          title: Vue.i18n.translate('elementsTextTranslation.listOrdered'),
          value: "ordered"
        },
        {
          name: "ql-list",
          title: Vue.i18n.translate('elementsTextTranslation.listBullet'),
          value: "bullet"
        },
        {
          name: "ql-script",
          title: Vue.i18n.translate('elementsTextTranslation.scriptSub'),
          value: "sub"
        },
        {
          name: "ql-script",
          title: Vue.i18n.translate('elementsTextTranslation.scriptSuper'),
          value: "super"
        },
        {
          name: "ql-indent",
          title: Vue.i18n.translate('elementsTextTranslation.indentMinus'),
          value: "-1"
        },
        {
          name: "ql-indent",
          title: Vue.i18n.translate('elementsTextTranslation.indentPlus'),
          value: "+1"
        },
      ]
    },
  },

  data: function(){
    return {
      buttonText: '{{}}',
      FONTS,
    }
  },

  methods: {
    selectTooltip(message) {
      return {
        content: message,
        skidding: -40,
        arrowPadding: 80,
        arrowOverflow: false,
      };
    },
  },
}
</script>
