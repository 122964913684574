<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="baseModal" role="dialog">
        <header class="modal-header">
          <slot name="header"></slot>
          {{headerText}}
          <section v-if="isLeaveSiteWarning">
            {{ 'generalTranslation.leaveSiteWarning' | translate }}
          </section>
          <i class="fa fa-window-close fa-lg closeButton" id="buttonCloseModal" :disabled="disableButtons" @click="$emit('close-modal', false)"></i>
        </header>
        <section class="modal-body">
          <slot name="body"></slot>
          {{bodyText}}
          <p v-if="isLeaveSiteWarning" id="warningTextModal">
            {{ 'generalTranslation.warning' | translate }}
          </p>
        </section>
        <footer class="modal-footer">
          <section class="wrapper">
            <button type="button" v-if="hasSecondPage && !isFirstPage" id="buttonBackModal" :disabled="disableButtons"
                class="buttonLeft" @click="$emit('modal-page-changed', false)">
              <i class="fa fa-chevron-left"></i>
              {{backButtonText}}
            </button>
            <section v-if="!hasSecondPage || (hasSecondPage && !isFirstPage)" class="buttonRight">
              <button type="button" id="buttonConfirmModal" :disabled="disableButtons || disableLeftButton" @click="$emit('close-modal', true)">
                {{leftButtonText}}
              </button>
              <button type="button" id="buttonCancelModal" :disabled="disableButtons" @click="$emit('close-modal', false)">
                {{ 'generalTranslation.abort' | translate }}
              </button>
            </section>
            <section v-else class="buttonRight">
              <button type="button" id="buttonNextModal" :disabled="disableButtons || disableNextButton" @click="$emit('modal-page-changed', true)">
                {{ 'generalTranslation.next' | translate }}
                <i class="fa fa-chevron-right"></i>
              </button>
            </section>
          </section>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    headerText: {
      type: String,
      required: false,
    },

    bodyText: {
      type: String,
      required: false,
    },

    leftButtonText: {
      type: String,
      required: true,
    },

    disableButtons: {
      type: Boolean,
      required: false,
    },

    disableLeftButton: {
      type: Boolean,
      required: false,
    },

    disableNextButton: {
      type: Boolean,
      required: false,
    },

    isLeaveSiteWarning: {
      type: Boolean,
      required: false,
    },

    hasSecondPage: {
      type: Boolean,
      required: false,
    },

    isFirstPage: {
      type: Boolean,
      required: false,
    },

    backButtonText: {
      type: String,
      required: false,
    },
  },

  created(){
    document.body.style.overflow = 'hidden';
  },

  beforeDestroy(){
    document.body.style.overflow = 'visible';
  },
};
</script>
<!--
the following components are modals & have overflow hidden on create, visible on beforeDestroy
do not use with v-show or add overflow hidden/visible respectively
AdminUserCreate
BaseModal
DiaryCopy
DiaryCreate
InterventionCopy
InterventionCreate
InterventionImport
LessonCopy
LessonCreate
LessonEditorElements (preview)
StudyCopy
StudyCreate
StudyMedia (if it is modal itself)
-->
