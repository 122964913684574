import Vue from 'vue';
import {mapGetters} from 'vuex';
import utils from '../mixins/utils';

export default {
  mixins: [utils],

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
    ]),
  },

  methods: {
    //save true -> for save method of lesson elements editor -> check all
    //save false -> for condition editor -> only check question elements
    checkIfElementsCompleteAll: function(elements, save, locales){
      let newErrorTextArr = [];
      const elementsComplete = [];
      for (const elem in elements) {
        const element = elements[elem];
        let errorText = "";
        switch(element.type){
          case "ElementsHeadline":
            if(save){
              errorText = this.checkIfHeadlineFieldsCompleteAll(element, locales);
            }
            break;
          case "ElementsQuestion":
            errorText = this.checkIfQuestionFieldsCompleteAll(element, locales);
            break;
          case "ElementsText":
            if(save){
              errorText = this.checkIfTextFieldsCompleteAll(element, locales);
            }
            break;
          case "ElementsMedia":
            if(save){
              errorText = this.checkIfMediaFieldsCompleteAll(element, locales);
            }
            break;
          case "ElementsSpace":
            if(save){
              errorText = this.checkIfSpaceFieldsCompleteAll(element);
            }
            break;
          case "ElementsBlock":
            if(save){
              errorText = this.checkIfBlockFieldsCompleteAll(element);
            }
            break;
          case "ElementsPage":
            if(save){
              errorText = this.checkIfPageFieldsCompleteAll(element);
            }
            break;
          default:
            errorText = "";
            break;
        }

        if (element.type === "ElementsBlock") {
          //check children of block
          const resultBlock = this.checkIfElementsCompleteAll(element.propsObj.elements, save, locales);
          elementsComplete.push(errorText === "" && resultBlock.elementsComplete);
          newErrorTextArr[element.propsObj.id] = errorText;
          newErrorTextArr = Object.assign(newErrorTextArr, resultBlock.errorTextsForElements);
        }else{
          elementsComplete.push(errorText === "");
          newErrorTextArr[element.propsObj.id] = errorText;
        }
      }

      // TODO: check if two page elements after one another

      return {
        errorTextsForElements: newErrorTextArr,
        elementsComplete: !elementsComplete.includes(false),
      };
    },

    checkIfHeadlineFieldsCompleteAll: function(element, locales){
      let errorText = "";

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation] === ""){
          if(errorText === ""){
            errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
          }else{
            errorText += ", ";
          }
          errorText += Vue.i18n.translate('elementsHeadlineTranslation.headline') + " (" + translation + ")";
        }
      }

      return errorText;
    },

    checkIfQuestionFieldsCompleteAll: function(element, locales){
      let errorText = "";
      const errorSingleMultipleArr = [];
      let errorSingleMultipleText = "";
      let errorSlider = false;
      let errorTable = false;
      let errorSliderText = "";
      const errorsArr = [];
      const questionType = element.propsObj.selectedQuestionProp;

      if(questionType === ""){
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionType'));
      }

      if(element.propsObj.labelProp === ""){
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionLabel'));
      }

      if(questionType === "SingleChoice" || questionType === "MultipleChoice"){
        if(element.propsObj.values.length < 2){
          errorSingleMultipleText = Vue.i18n.translate('elementsQuestionTranslation.singleMultipleChoiceError');
        }

        const valuesArr = [];
        for(const value in element.propsObj.values){
          if(element.propsObj.values[value] === ""){
            errorsArr.push(
              Vue.i18n.translate('elementsQuestionTranslation.errorAnswerValueEmpty') + " " +
              Number(Number(value) + 1)
            );
          }
          if(valuesArr.includes(element.propsObj.values[value])){
            errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.errorDuplicateAnswerLabel'));
          }
          valuesArr.push(element.propsObj.values[value]);
        }
      }else if(questionType === "Slider"){
        if(Number.isNaN(element.propsObj.values.min) || element.propsObj.values.min === ""){
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.valueMin'));
        }
        if(Number.isNaN(element.propsObj.values.max) || element.propsObj.values.max === ""){
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.valueMax'));
        }
        if(Number.isNaN(element.propsObj.values.step) || element.propsObj.values.step === ""){
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.step'));
        }
        if(Number.isNaN(element.propsObj.values.start) || element.propsObj.values.start === ""){
          errorSlider = true;
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.startValue'));
        }
        if(!errorSlider){
          const start = Number(element.propsObj.values.min);
          const end = Number(element.propsObj.values.max);
          const step = Number(element.propsObj.values.step);
          const startVal = Number(element.propsObj.values.start);
          if(((end - start)/step) > 101){
            errorSliderText = Vue.i18n.translate('elementsQuestionTranslation.errorSliderTooManyValues');
          }
          if(startVal < start || startVal > end){
            errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.startValueNotIncluded');
          }
          if(start > end){
            errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.minGreaterMax');
          }
        }
      }else if(element.propsObj.selectedQuestionProp === "QuestionTable"){
        if(element.propsObj.values.max - element.propsObj.values.min < 1){
          errorTable = true;
          errorSingleMultipleText = Vue.i18n.translate('elementsQuestionTranslation.tableAnswersError');
        }
        if(element.propsObj.values.questions < 2){
          errorTable = true;
          errorSingleMultipleText += (errorSingleMultipleText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.tableQuestionsError');
        }
      }

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.propsObj.selectedQuestionProp != "QuestionTable" && element.translations[translation].question === ""){
          errorsArr.push( Vue.i18n.translate('elementsQuestionTranslation.question') + " (" + translation + ")");
        }

        if(questionType === "SingleChoice" || questionType === "MultipleChoice"){
          if(element.propsObj.values.length != element.translations[translation].answers.length){
            errorSingleMultipleArr.push(translation);
          }

          for(const answer in element.translations[translation].answers){
            if(element.translations[translation].answers[answer] === ""){
              errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.answerOption') +  " " + Number(Number(answer) + 1) + " (" + translation + ")");
            }
          }
        }else if(questionType === "Slider"){
          if(!element.translations[translation].answers[0].label){
            errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.errorSliderMinTextValueEmpty') + " (" + translation + ")");
          }
          if(!element.translations[translation].answers[1].label){
            errorsArr.push(
              Vue.i18n.translate('elementsQuestionTranslation.errorSliderMaxTextValueEmpty') + " (" + translation + ")"
            );
          }
        }else if(element.propsObj.selectedQuestionProp === "QuestionTable" && !errorTable){
          for(let answer = element.propsObj.values.min; answer <= element.propsObj.values.max; answer++){
            if(!element.translations[translation].answers[answer]){
              errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.answerOption') + " " + Number(answer) + " (" + translation + ")");
            }
          }

          for(let question = 0; question < element.propsObj.values.questions; question++){
            if(!element.translations[translation].question[question]){
              errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.question') + " " + Number(question) + " (" + translation + ")");
            }
          }
        }
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if(errorSingleMultipleArr.length > 0){
        errorText += (errorText != "" ? ". " : "") + Vue.i18n.translate('elementsQuestionTranslation.errorSingleMultiple') + " (";
        for(const err2 in errorSingleMultipleArr){
          errorText += (err2 > 0 ? ", " : "") + errorSingleMultipleArr[err2];
        }
        errorText += ")";
      }else if(errorSliderText != ""){
        errorText += (errorText != "" ? ". " : "") + errorSliderText;
      }

      if(errorSingleMultipleText != ""){
        errorText += (errorText != "" ? ". " : "") + errorSingleMultipleText;
      }

      return errorText
    },

    checkIfTextFieldsCompleteAll: function(element, locales){
      let errorText = "";
      let includesOtherPictures = false;
      let languageOfOtherPictures = "";

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation] === ""){
          errorText += (errorText === "" ? Vue.i18n.translate('elementsTextTranslation.errorFillIn') : ", ") +
            Vue.i18n.translate('elementsTextTranslation.text') + " (" + translation + ")";
        }else{
          const regEx = new RegExp(/<img src='https:\/\/[A-Za-z0-9/.-_]*'/, "g");
          const urls = element.translations[translation].match(regEx);
          for(const url in urls){
            if(!urls[url].startsWith(this.$urlUploads)){
              includesOtherPictures = true;
              languageOfOtherPictures += (languageOfOtherPictures != "" ? ", " : "") + translation;
              break;
            }
          }
        }
      }
      if(includesOtherPictures){
        errorText += (errorText != "" ? ". " : "") + Vue.i18n.translate('elementsTextTranslation.errorOtherPictures') +
          " (" + languageOfOtherPictures + ")";
      }
      return errorText;
    },

    checkIfMediaFieldsCompleteAll: function(element, locales){
      let errorText = "";

      if(element.propsObj.width > 100 || element.propsObj.width < 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn') +
          Vue.i18n.translate('elementsMediaTranslation.sizeValidValue');
      }

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation].uri === ""){
          errorText += (errorText === "" ? Vue.i18n.translate('elementsTextTranslation.errorFillIn') : ", ") +
            Vue.i18n.translate('elementsMediaTranslation.file') + " (" + translation + ")";
        }
      }

      return errorText
    },

    checkIfSpaceFieldsCompleteAll: function(element){
      return !element.propsObj.size ? Vue.i18n.translate('elementsSpaceTranslation.errorSize') : "";
    },

    checkIfBlockFieldsCompleteAll: function(element){
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];

      if(element.propsObj.type === ""){
        errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorTypeEmpty'));
      }

      if(element.propsObj.type != 'details' && element.propsObj.type != 'none'){
        const result = this.checkIfConditionCompleteAll(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if(!element.propsObj.thenBlock.repeat.question){
        if(element.propsObj.thenBlock.repeat.data < 1){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorRepetitionMin'));
        }
      }else if(element.propsObj.thenBlock.repeat.data === "" || this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data] === 0 ||
          this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type === "text"){
        errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorRepetitionLabel'));
      }else if(this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type != "slider"){
        errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorRepetitionLabel'));
      }

      if(element.propsObj.type != 'conditional' && element.propsObj.type != 'none'){
        for(const translation in element.translations){
          if(element.translations[translation] === ""){
            errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errortextDetailsEmpty') +
              " (" + translation + ")");
          }
        }
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        //check if questionLabelList has questions that can be used in conditions
        if(this.getQuestionLabelList.length > 0 && (element.propsObj.type === 'conditional' || element.propsObj.type === 'both' || element.propsObj.thenBlock.repeat.question)){
          let questionExists = false;
          for(const i in this.getQuestionLabelList){
            const question = this.getQuestionLabelList[i];
            if(question != 0 && question.type != "text"){
              questionExists = true;
              break;
            }
          }
          if(!questionExists){
            errorText += ". " + Vue.i18n.translate('elementsBlockTranslation.noQuestions');
          }
        }
      }

      if(errorText != "" && !errorText.endsWith(".")){
        errorText += ".";
      }

      if(errorValueSecondValue){
        errorText += " " + Vue.i18n.translate('elementsBlockTranslation.errorValueSecondValue');
      }else if(errorTextComplex != ""){
        errorText += " " + errorTextComplex;
      }

      return errorText
    },

    checkIfPageFieldsCompleteAll: function(element){
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];
      const isConditionalPage = element.propsObj.condition != "" && element.propsObj.condition != null;

      if(isConditionalPage){
        const result = this.checkIfConditionCompleteAll(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        if(isConditionalPage){
          //check if questionLabelList has questions that can be used in conditions
          let questionExists = false;
          if(this.getQuestionLabelList.length > 0){
            for(const i in this.getQuestionLabelList){
              const question = this.getQuestionLabelList[i];
              if(question != 0 && question.type != "text"){
                questionExists = true;
              }
            }
            if(!questionExists){
              errorText += ". " + Vue.i18n.translate('elementsBlockTranslation.noQuestions');
            }
          }
        }
      }

      if(errorText != "" && !errorText.endsWith(".")){
        errorText += ".";
      }

      if(errorValueSecondValue){
        errorText += " " + Vue.i18n.translate('elementsBlockTranslation.errorValueSecondValue');
      }else if(errorTextComplex != ""){
        errorText += " " + errorTextComplex;
      }

      return errorText
    },

    checkIfConditionCompleteAll: function(element, errorsArr, returnErrorDetails){
      let errorValueSecondValue = false;
      let errorTextComplex = "";
      const condition = element.propsObj.condition;
      //todo check if condition is ever fulfillable (for example slider > slider.max)
      if(element.propsObj.isComplexCondition){
        if(condition.nodeType === "leaf"){
          //user clicked on complex condition but did not define a condition
          element.propsObj.isComplexCondition = false;
          condition.questionLabel = -1;
        }else if(!this.checkIfComplexConditionCompleteAll(condition)){
          errorTextComplex = Vue.i18n.translate('elementsBlockTranslation.errorComplexCondition');
        }
      }
      if(!element.propsObj.isComplexCondition){
        if(condition.questionLabel === -1 || (!condition.questionLabel && condition.questionLabel !== 0)){
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionLabel'));
        }
        if(condition.leafType != "question" && condition.operation === ""){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorOperationEmpty'));
        }
        if(condition.operation != "answered" && condition.value === ""){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorValueEmpty'));
        }
        if(condition.leafType != "question" && condition.operation === "between"){
          if(condition.secondValue === ""){
            errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorSecondValueEmpty'));
          }else if(!(condition.value < condition.secondValue)){
            errorValueSecondValue = true;
          }
        }
      }
      if(returnErrorDetails){
        return {errorTextComplex: errorTextComplex, errorsArr: errorsArr, errorValueSecondValue: errorValueSecondValue}
      }else{
        return errorsArr.length === 0 && errorTextComplex === "" && !errorValueSecondValue
      }
    },

    checkIfComplexConditionCompleteAll: function(condition){
      if(condition.nodeType === "leaf"){
        if(condition.questionLabel === "" || (condition.operation != "answered" && condition.value === "")){
          return false
        }
        if(condition.leafType === 'date' || condition.leafType === 'slider'){
          if(condition.operation === ""){
            return false
          }else if(condition.operation === "between"){
            if(condition.secondValue === ""){
              return false
            }else if(!(condition.value < condition.secondValue)){
              return false
            }else{
              return true
            }
          }
        }
        return true
      }else if(condition.nodeType === "and" || condition.nodeType === "or"){
        if(!(condition.children.length > 1)){
          return false
        }else{
          var boolList = [];
          for(var child in condition.children){
            boolList.push(this.checkIfComplexConditionCompleteAll(condition.children[child]));
          }
          if(boolList.includes(false)){
            return false
          }else{
            return true
          }
        }
      }else if(condition.nodeType === "not"){
        if(condition.children.length != 1){
          return false
        }else{
          return this.checkIfComplexConditionCompleteAll(condition.children[0])
        }
      }
      return false
    },
  }
};
