<template>
  <div class="elementsBlockPreview">
    <div :is="hasTypeDetails ? 'details' : 'div'" v-if="showAllConditions || showBlock" :id="'elementsBlockPreview' + propsObj.id" class="previewDetails">
      <summary v-if="hasTypeDetails">
        {{translation}}
      </summary>

      <div v-for="n in getNumberOfRepetitions" :key="n">
        <LessonPreviewElement
          v-for="element in propsObj.elements"
          :key="element.propsObj.id"
          :element="element"
          :repetitionIndex="repetitionIndex * getNumberOfRepetitions + n - 1"
          :isDiary="isDiary"
          :showRequiredQuestions="showRequiredQuestions"
          :showAllConditions="showAllConditions"
        ></LessonPreviewElement>
        <!-- repetitionIndex of outer block(s) & elements -> repeated questions are saved as one json for nested repetitions
          block
            block     0
              question    0
              question    1
              question    2
            block     1
              question    3
              question    4
              question    5
            block     2
              question    6
              question    7
              question    8
        -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import '@aas2/aas2-evaluation-service';

export default {
  name: 'ElementsBlockPreview',

  components: {
    LessonPreviewElement: () => import('./LessonPreviewElement.vue')
  },

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: String,
    },

    repetitionIndex: {
      required: true,
      type: Number,
    },

    isDiary: {
      required: true,
      type: Boolean,
    },

    showRequiredQuestions: {
      required: true,
      type: Boolean,
    },

    showAllConditions: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      evaluationService: '',
    }
  },

  computed: {
    ...mapGetters([
      'getPreviewAnswers',
    ]),

    hasTypeDetails: function(){
      return this.propsObj.type === "details" || this.propsObj.type === "both"
    },

    showBlock: function(){
      if(this.evaluationService && this.propsObj.condition){
        return this.evaluationService.evaluateCondition(this.propsObj.condition, this.getPreviewAnswers)
      }else{
        return true
      }
    },

    getNumberOfRepetitions: function(){
      if(!this.propsObj.thenBlock.repeat.question){ //constant repetition
        return this.propsObj.thenBlock.repeat.data
      }else{ //slider repetition
        //get answer of question, if question answered use the answer, otherwise 1
        var answer = this.getPreviewAnswers[this.propsObj.thenBlock.repeat.data];
        return answer != null && answer[0] && Number(answer[0]) >= 0 ? Number(answer[0]) : 1
      }
    }
  },

  created(){
    this.evaluationService = require('@aas2/aas2-evaluation-service');
  },
}
</script>
