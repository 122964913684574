/*
 * The class should not have extra symbols for the name of the font (e.g. "ql-font-sans-serif" should be "ql-font-sansserif").
 * This needs to happen due to issues with the Vue2-editor removing the class from the span containing the text.
 *
 * New fonts have to be added in the src/styles/fonts.css-file.
 * This includes the toolbar-container and the content of the Vue2-editor.
 */
function initFonts() {
  const fonts = [
    { class: "ql-font-roboto", label: "Roboto" },
    { class: "ql-font-serif", label: "Serif" },
    { class: "ql-font-sansserif", label: "Sans-Serif" },
    { class: "ql-font-monospace", label: "Monospace" },
    { class: "ql-font-manrope", label: "Manrope" },
    { class: "ql-font-abrilfatface", label: "Abril Fatface" },
  ];
  const valueStart = "ql-font-".length;
  // The value-property is needed for font-selection in the Vue2-editor and has to match the font-name in the class
  return fonts.map(font => ({ ...font, value: font.class.slice(valueStart) }));
}

export const FONTS = initFonts();
