<template>
  <BaseModal
    id="createLessonModal"
    class="lessonCreate"
    :headerText="$t('lessonTranslation.createLessonHeader', { name: targetData.details.name })"
    :leftButtonText="$t('generalTranslation.create')"
    :disableButtons="isCreated"
    :disableLeftButton="name === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <section class="wrapper">
        <form @submit.prevent="closeModal(true)">
          <label>
            {{ 'generalTranslation.name' | translate }} *
          </label>
          <input type="text" v-model="name" id="inputNewName" :placeholder="$t('generalTranslation.name')" required>
        </form>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'LessonCreate',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    targetData: { //intervention in which lesson should be created (lessonCounter, details)
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      name: '',
      isCreated: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
      'getSelectedLanguage',
    ]),

    targetInterventionId: function(){ //lesson created for this intervention
      return Number(this.$route.params.interventionId)
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_LESSONELEMENTSARR',
    ]),

    closeModal(done) {
      if(done){
        if(this.name === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorNameEmpty')});
        }else{
          this.isCreated = true;
          this.$emit('set-changed', { name: 'all', value: false });
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('lessonTranslation.createLessonLoad')});

          var newPos = 1;
          if(this.targetData.lessonCounter > -1){
            newPos = this.targetData.lessonCounter + 1;
          }

          var json =
          {
            "data" : {
              "type" : "questionnaires",
              "attributes" : {
                "name" : this.name,
                "position": newPos,
                "locales": []
              }
            }
          };

          if(this.getSelectedLanguage === "de"){
            json.data.attributes.locales = ["de"];
          }else{
            json.data.attributes.locales = ["en"];
          }

          this.SET_LESSONELEMENTSARR([]);

          var self = this;
          this.createLessonForInterventionRequest(this.targetInterventionId, json)
          .then(function (newLessonId){
            var config = self.getNewConfig(newLessonId);
            self.updateIntDetails(config, newLessonId);
          })
          .catch(function (error){
            self.handleErrors(error, function(){ self.closeModal(done) }, "");
          });
        }
      }else if(!this.isCreated){
        this.$emit('close-modal');
      }
    },

    updateIntDetails: function(json, newLessonId){
      var self = this;
      this.updateInterventionDetailsRequest(json, this.targetInterventionId)
      .then( function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('lessonTranslation.createLessonSuccess')});
        self.$emit('route-to', { name: 'lessonEditor', params: { lessonId: newLessonId, view: 0 }});
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.updateIntDetails(json, newLessonId) }, "");
      });
    },

    getNewConfig: function(newLessonId){
      var json = {
        data: {
          type: "interventions",
          id: this.targetInterventionId,
          attributes: {
            default_configuration: {
              questionnaire_configuration: (this.targetData.details.default_configuration) ? JSON.parse(JSON.stringify(this.targetData.details.default_configuration.questionnaire_configuration)) : [],
              custom_order: (this.targetData.details.default_configuration) ? JSON.parse(JSON.stringify(this.targetData.details.default_configuration.custom_order)) : []
            }
          }
        }
      };

      json.data.attributes.default_configuration.questionnaire_configuration.push(
        {
          id: newLessonId,
          unlock_type: (json.data.attributes.default_configuration.questionnaire_configuration.length === 0) ? "always" : "after_previous",
          unlock_days_after_start: null,
          feedback_required: false
        }
      );

      json.data.attributes.default_configuration.custom_order.push(newLessonId);
      return json
    },
  }
}
</script>
