<template>
  <div class="baseSearch">
    <section class="wrapper">
      <section class="filterField">
        <button type="button" :id="'buttonFilter-' + type" @click="toggleFilter">
          <i class="fa fa-filter"></i>
          {{ 'myStudiesTranslation.filter' | translate }}
          <i class="fa" :class="{'fa-chevron-down': !showFilter, 'fa-chevron-up': showFilter}"></i>
        </button>
      </section>
      <form class="searchField" @submit.prevent="searchList">
        <input type="text" v-model="searchValue" :id="'searchField-' + type" :placeholder="$t('myStudiesTranslation.search')">
        <button type="submit" :id="'searchButton-' + type">
          <i class="fa fa-search"></i>
        </button>
      </form>
    </section>

    <section v-if="showFilter" class="filterBox">
      <section class="wrapper">
        <section class="col sec33">
          <select v-if="!hasTypeStudyMedia" v-model="selectedSortValue" :id="'inputSearchSort-' + type" @change="searchList">
            <option value="" :id="'inputSearchSort-' + type + '-None'" disabled>
              {{ 'myStudiesTranslation.chooseSortOption' | translate }}
            </option>
            <option v-for="data in sortOptions" :value="data" :id="'inputSearchSort-' + type + '-' + data" :key="data">
              {{getText(data)}}
            </option>
          </select>
        </section>

        <section class="col sec33">
          <select v-if="type === 'admin-users'" v-model="filterVerified" id="inputSearchVerified" key="verifiedSelect" @change="searchList">
            <option value="-1" id="inputSearchVerifiedNone" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option value="" id="inputSearchVerifiedBoth" key="fav1">
              {{ 'adminUsersTranslation.verifiedNotVerified' | translate }}
            </option>
            <option value="verified" id="inputSearchVerifiedTrue" key="fav2">
              {{ 'adminUsersTranslation.verified' | translate }}
            </option>
            <option value="notVerified" id="inputSearchVerifiedFalse" key="fav3">
              {{ 'adminUsersTranslation.notVerified' | translate }}
            </option>
          </select>

          <select v-if="type === 'admin-users'" v-model="filterRole" id="inputSearchRole" key="roleSelect" class="roleFilter" @change="searchList">
            <option value="-1" id="inputSearchRoleNone" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option value="" id="inputSearchRoleAll" selected>
              {{ 'adminUsersTranslation.allRoles' | translate }}
            </option>
            <option value="admin" id="inputSearchRoleAdmin" key="fav1">
              {{ 'adminUsersTranslation.admin' | translate }}
            </option>
            <option value="ecoach" id="inputSearchRoleEcoach" key="fav2">
              {{ 'adminUsersTranslation.ecoach' | translate }}
            </option>
            <option value="editor" id="inputSearchRoleEditor" key="fav3">
              {{ 'adminUsersTranslation.editor' | translate }}
            </option>
          </select>

          <select v-if="type === 'announcements'" v-model="filterType" id="inputSearchAnnouncementType" key="announcementSelectType" @change="searchList">
            <option value="-1" id="inputSearchAnnouncementTypeNone" disabled>
              {{ 'adminAnnouncementsTranslation.selectType' | translate }}
            </option>
            <option value="" id="inputSearchAnnouncementTypeAll" key="all" selected>
              {{ 'adminAnnouncementsTranslation.allTypes' | translate }}
            </option>
            <option value="success" id="inputSearchAnnouncementSuccess" key="success">
              {{ 'adminAnnouncementsTranslation.success' | translate }}
            </option>
            <option value="info" id="inputSearchAnnouncementTypeInfo" key="info">
              {{ 'adminAnnouncementsTranslation.info' | translate }}
            </option>
            <option value="warning" id="inputSearchAnnouncementWarning" key="warning">
              {{ 'adminAnnouncementsTranslation.warning' | translate }}
            </option>
            <option value="error" id="inputSearchAnnouncementTypeError" key="error">
              {{ 'adminAnnouncementsTranslation.error' | translate }}
            </option>
          </select>

          <select v-if="type === 'announcements'" v-model="filterClient" id="inputSearchAnnouncementClient" key="announcementClient" @change="searchList">
            <option value="-1" id="inputSearchAnnouncementClientNone" disabled>
              {{ 'adminAnnouncementsTranslation.selectClient' | translate }}
            </option>
            <option value="" id="inputSearchAnnouncementClientAll" key="all" selected>
              {{ 'adminAnnouncementsTranslation.allPlatforms' | translate }}
            </option>
            <option value="cms" id="inputSearchAnnouncementClientCMS" key="cms">
              {{ 'adminAnnouncementsTranslation.cms' | translate }}
            </option>
            <option value="ecoach" id="inputSearchAnnouncementClientEcoach" key="ecoach">
              {{ 'adminAnnouncementsTranslation.ecoach' | translate }}
            </option>
            <option value="app" id="inputSearchAnnouncementClientApp" key="app">
              {{ 'adminAnnouncementsTranslation.app' | translate }}
            </option>
          </select>

          <section v-if="type === 'announcements'" class="checkboxSection">
            <label class="container">
              {{ 'adminAnnouncementsTranslation.shown' | translate }}
              <input type="checkbox" id="inputSearchAnnouncementShown" :value="filterValid" :checked="filterValid" @input="searchListValidAnn">
              <span class="checkmark"></span>
            </label>
          </section>

          <select v-if="type.startsWith('study-media-all')" v-model="filterSelection" :id="'inputSearchMediaType-' + type" key="typeSelect" @change="searchList">
            <option value="" :id="'inputSearchMediaType-' + type + '-None'" disabled>
              {{ 'elementsMediaTranslation.selectType' | translate }}
            </option>
            <option v-for="(filterAtt, index) in filterList" :value="filterAtt" :id="'inputSearchMediaType-' + type + '-' + filterAtt" :key="filterAtt" :selected="index === 0">
              {{ 'elementsMediaTranslation.' + filterAtt | translate }}
            </option>
          </select>

          <select v-if="hasTypeStudyMedia" v-model="filterFavorite" :id="'inputSearchMediaFavorite-' + type" key="favoriteSelect" @change="searchList">
            <option value="-1" :id="'inputSearchMediaFavorite-' + type + '-None'" disabled>
              {{ 'generalTranslation.select' | translate }}
            </option>
            <option value="" :id="'inputSearchMediaFavorite-' + type + '-Both'" key="fav1">
              {{ 'elementsMediaTranslation.favoriteNoFavorite' | translate }}
            </option>
            <option value="favorite" :id="'inputSearchMediaFavorite-' + type + '-true'" key="fav2">
              {{ 'elementsMediaTranslation.favorite' | translate }}
            </option>
            <option value="notFavorite" :id="'inputSearchMediaFavorite-' + type + '-false'" key="fav3">
              {{ 'elementsMediaTranslation.notFavorite' | translate }}
            </option>
          </select>
        </section>

        <section class="col sec33">
          <button type="button" :id="'buttonResetFilter-' + type" class="createButton" @click="resetFilter">
            {{ 'myStudiesTranslation.reset' | translate }}
          </button>
        </section>
      </section>
      <hr>
    </section>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
  name: 'BaseSearch',

  props: {
    //int-list is used for InterventionList
    //diary-list is used for diaryList
    //study-list is used for StudyList
    //study-users is used for StudyPermissions
    //admin-users is used for AdminUsers
    //admin-users-deletion is used for AdminUsers deletion requests
    //announcements is used for AdminAnnouncements
    //study-media is used for StudyMedia showing only pictures
    //study-media-all is used for StudyMedia showing all file types on manage media page
    //study-media-all-select is used for StudyMedia showing all file types as modal
    type: {
      required: true,
      type: String,
    },
  },

  data: function(){
    return {
      showFilter: false,
      searchValue: "",
      selectedSortValue: "",
      filterActive: [],
      filterVerified: "",
      filterRole: "",
      filterFavorite: "",
      filterSelection: "all",
      filterType: "",
      filterClient: "",
      filterValid: false,
      filterList: ["all", "image", "video", "audio", "application"],
    }
  },

  computed: {
    hasTypeStudyMedia: function(){
      return (this.type === 'study-media-all' || this.type === 'study-media' || this.type === 'study-media-all-select')
    },

    sortOptions: function(){
      if(this.type === "study-list"){
        return ["id", "-id", "name", "-name", "description", "-description"]
      } else if(this.type === "int-list" || this.type === "diary-list"){
        return ["id", "-id", "name", "-name", "title", "-title", "description", "-description"]
      }else if(this.type === "announcements"){
        return ["id", "-id", "title", "-title", "message", "-message", "user_id", "-user_id", "type", "-type", "start", "-start", "end", "-end"]
      }else if(this.type === "study-users" || this.type === "admin-users" || this.type === "admin-users-deletion"){
        return ["name", "-name", "firstname", "-firstname", "lastname", "-lastname"]
      }else{
        return []
      }
    },

    filterTerm: function(){
      var filter = "";
      if(this.filterActive.length === 1){
        if(this.filterActive[0] === "active"){
          filter = "&is_active=1";
        }else if(this.filterActive[0] === "notActive"){
          filter = "&is_active=0";
        }
      }
      if(this.searchValue != ""){
        if(!this.hasTypeStudyMedia){
          filter += "&searchterm=" + this.searchValue;
        }else{
          filter += "&originalName=" + this.searchValue;
        }
      }
      if(this.selectedSortValue != ""){
        filter += "&sort=" + this.selectedSortValue;
      }
      if(this.filterVerified === "verified"){
        filter += "&is_verified=1";
      }else if(this.filterVerified === "notVerified"){
        filter += "&is_verified=0";
      }
      if(this.filterRole != ""){
        filter += "&role=" + this.filterRole;
      }
      if(this.filterFavorite === "favorite"){
        filter += "&favorite=1";
      }else if(this.filterFavorite === "notFavorite"){
        filter += "&favorite=0";
      }
      if(this.filterSelection != "all"){
        filter += "&mimetype=" + this.filterSelection + "/*";
      }
      if(this.filterType != -1 && this.filterType != ""){
        filter += "&type=" + this.filterType;
      }
      if(this.filterClient != -1 && this.filterClient != ""){
        filter += "&client=" + this.filterClient;
      }
      if(this.filterValid){
        var currentDate = Math.round((new Date().getTime()) / 1000);
        filter += "&start<" + currentDate + "&end>" + currentDate;
      }
      return filter
    },
  },

  created(){
    if(this.type === "study-media-all-select" || this.type === "study-media"){
      this.showFilter = true;
      //this.filterFavorite = 'favorite';
    }
  },

  methods:{
    toggleFilter: function(){
      this.showFilter = !this.showFilter;
    },

    searchList: function(){
      this.$emit('get-new-list', this.filterTerm);
    },

    searchListValidAnn: function(evt){
      this.filterValid = evt.target.checked;
      this.searchList();
    },

    resetFilter: function(){
      this.$emit('reset-page');
      this.searchValue = "";
      this.filterActive = [];
      this.selectedSortValue = "";
      this.filterVerified = "";
      this.filterRole = "";
      this.filterFavorite = "";
      this.filterType = "";
      this.filterClient = "";
      this.filterSelection = "all";
      this.filterValid = false;
      this.$emit('get-new-list', this.filterTerm);
    },

    getText: function(value){
      var text;
      switch (value) {
        case "id":
        case "-id":
          text = Vue.i18n.translate('generalTranslation.creationDate')
          break;

        case "name":
        case "-name":
          if(this.type != "study-users" && this.type != "admin-users"){
            text = Vue.i18n.translate('generalTranslation.name')
          }else{
            text = Vue.i18n.translate('adminUsersTranslation.username')
          }
          break;

        case "title":
        case "-title":
          text = Vue.i18n.translate('interventionTranslation.title')
          break;

        case "description":
        case "-description":
        case "message":
        case "-message":
          text = Vue.i18n.translate('interventionTranslation.description')
          break;

        case "user_id":
        case "-user_id":
          text = Vue.i18n.translate('adminAnnouncementsTranslation.creator')
          break;

        case "type":
        case "-type":
          text = Vue.i18n.translate('adminAnnouncementsTranslation.type')
          break;

        case "start":
        case "-start":
          text = Vue.i18n.translate('adminAnnouncementsTranslation.startDate')
          break;

        case "end":
        case "-end":
          text = Vue.i18n.translate('adminAnnouncementsTranslation.endDate')
          break;

        case "firstname":
        case "-firstname":
          text = Vue.i18n.translate('adminUsersTranslation.firstname')
          break;

        case "lastname":
        case "-lastname":
          text = Vue.i18n.translate('adminUsersTranslation.lastname')
          break;

        default:
          text = ""
          break;
      }

      return text + " " + (value.startsWith("-") ? Vue.i18n.translate('generalTranslation.descending') :
        Vue.i18n.translate('generalTranslation.ascending'))
    }
  }
}
</script>
