<template>
  <div class="elementsMedia">
    <section v-if="getIsEditable && errorTextsForElements[propsObj.id] != ''" :id="'errorTextElement' + propsObj.id" :style="{visibility: showIfNotTranslation}" class="wrapper errorMessage">
      {{errorTextsForElements[propsObj.id]}}
    </section>

    <p>
      <label>
        {{titleLabelInLanguage}}
      </label>

      <ElementsReference
        :id="propsObj.id + 'Title' + isTranslation"
        :text="translation.subtitle"
        :placeholderData="titleLabelInLanguage"
        :isSkill="isSkill"
        @change-text="changeTitle"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </p>

    <p>
      <label>
        {{ 'elementsMediaTranslation.file' | translate }} *
      </label>
    </p>

    <section v-if="isTranslation" class="wrapper" :class="{spaceDescription: sameFile}">
      <section class="checkboxSection">
        <label class="container">
          {{ 'elementsMediaTranslation.sameFile' | translate }}
          <input type="checkbox" :value="sameFile" :id="'inputSameFile' + propsObj.id" checked @input="changeSameFile">
          <span class="checkmark"></span>
        </label>
      </section>
      <br>
      <br>
    </section>

    <p v-if="(getIsEditable && (!isTranslation || (isTranslation && !sameFile))) || !getIsEditable" class="media">
      <img v-if="propsObj.linkType.startsWith('image')" :src="translation.uri" loading="lazy" :id="'media' + propsObj.id + 'Image'">
      <video v-else-if="propsObj.linkType.startsWith('video') && !propsObj.isAudio" width="450" controls :src="translation.uri" :id="'media' + propsObj.id + 'Video'"></video>
      <audio v-else-if="(propsObj.linkType.startsWith('video') && propsObj.isAudio) || propsObj.linkType.startsWith('audio')"
        width="450" controls :src="translation.uri" :id="'media' + propsObj.id + 'Audio'"></audio>
      <span v-else-if="hasTypeFile(propsObj.linkType)">
        <i class="fas fa-4x" :class="fileClass" :id="'media' + propsObj.id + 'File' + fileClass"></i>
        <br>
        <a :href="translation.uri" target="_blank" rel="noopener noreferrer" :download="translation.filename" :id="'media' + propsObj.id + 'FileLink'">
            {{translation.filename}}
        </a>
      </span>
    </p>

    <section v-if="getIsEditable" class="wrapper media">
      <button type="button" v-if="!isTranslation || (isTranslation && !sameFile)" :id="'buttonSelectMedia' + propsObj.id" @click="showStudyMediaModal(true)">
        {{ 'elementsMediaTranslation.selectMedia' | translate }}
      </button>
    </section>

    <section class="sec50" v-if="!isTranslation && (propsObj.linkType.startsWith('image') || propsObj.linkType.startsWith('video'))">
      <section v-if="(propsObj.linkType.startsWith('image') && !propsObj.banner) || (propsObj.linkType.startsWith('video') && !propsObj.isAudio)" class="col sec33">
        <label>
          {{ 'elementsMediaTranslation.size' | translate }} *
        </label>
        <input type="number" min="0" max="100" :id="'inputMediaSize' + propsObj.id" :value="propsObj.width" :disabled="!getIsEditable"
          @input="$emit('change-element',{'type': 'attribute', 'name': 'width', 'value': $event.target.value})">
      </section>

      <section v-if="propsObj.linkType.startsWith('image')" class="col sec33">
        <label>
          {{ 'elementsMediaTranslation.banner' | translate }} *
        </label>
        <select :value="propsObj.banner" :disabled="!getIsEditable" :id="'inputMediaBanner' + propsObj.id" @input="$emit('change-element', {'type': 'attribute',
            'name': 'banner', 'value': Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0]})">
          <option value="" disabled :id="'inputMediaBanner' + propsObj.id + 'None'">
            {{ 'generalTranslation.select' | translate }}
          </option>
          <option :value="true" :id="'inputMediaBanner' + propsObj.id + 'True'" :key="'isBannerTrue' + propsObj.id">
            {{ 'generalTranslation.yes' | translate }}
          </option>
          <option :value="false" :id="'inputMediaBanner' + propsObj.id + 'False'" :key="'isBannerFalse' + propsObj.id">
            {{ 'generalTranslation.no' | translate }}
          </option>
        </select>
      </section>
      <section v-else class="col sec33">
        <label>
          {{ 'elementsMediaTranslation.isAudio' | translate }} *
        </label>
        <select :value="propsObj.isAudio" :disabled="!getIsEditable" :id="'inputMediaIsAudio' + propsObj.id" @input="$emit('change-element', {'type': 'attribute',
            'name': 'isAudio', 'value': Array.from($event.target.options).filter(o => o.selected).map(x => '_value' in x ? x._value : x.value)[0]})">
          <option value="" disabled :id="'inputMediaIsAudio' + propsObj.id + 'None'">
            {{ 'generalTranslation.select' | translate }}
          </option>
          <option :value="true" :id="'inputMediaIsAudio' + propsObj.id + 'True'" :key="'isAudioTrue' + propsObj.id">
            {{ 'generalTranslation.yes' | translate }}
          </option>
          <option :value="false" selected :id="'inputMediaIsAudio' + propsObj.id + 'False'" :key="'isAudioFalse' + propsObj.id">
            {{ 'generalTranslation.no' | translate }}
          </option>
        </select>
      </section>
    </section>

    <p>
      <label>
        {{descriptionLabelInLanguage}}
      </label>

      <ElementsReference
        :id="propsObj.id + 'Description' + isTranslation"
        :text="translation.description"
        :placeholderData="descriptionLabelInLanguage"
        :isSkill="isSkill"
        @change-text="changeDesc"
        @change-element="$emit('change-element', $event)"
      ></ElementsReference>
    </p>

    <StudyMedia
      v-if="studyMediaVisible"
      :id="'mediaSelection' + propsObj.id"
      :allFiles="true"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="showStudyMediaModal(false)"
      @item-selected="selectMedia"
    ></StudyMedia>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import ElementsReference from './ElementsReference.vue';
import StudyMedia from './StudyMedia.vue';
import elementsHelper from '../mixins/elementsHelper';

export default {
  name: 'ElementsMedia',

  components: {
    ElementsReference,
    StudyMedia
  },

  mixins: [elementsHelper],

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    linkForMedia: {
      required: true,
      type: String
    },

    errorTextsForElements: {
      required: true,
      type: Array,
    },

    studyId: {
      required: true,
      type: Number,
    },

    isSkill: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      oldLink: '',
      sameFile: true,
      studyMediaVisible: false,
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    titleLabelInLanguage: function(){
      return Vue.i18n.translate('elementsMediaTranslation.title' + (this.isTranslation ? 'Translation' : ''))
    },

    descriptionLabelInLanguage: function(){
      return Vue.i18n.translate('elementsMediaTranslation.description' + (this.isTranslation ? 'Translation' : ''))
    },
  },

  watch:{
    'translation.uri': function(newVal){
      if(this.isTranslation){
        if(newVal === this.linkForMedia){
          this.sameFile = true;
        }else{
          this.sameFile = false;
        }
      }
    },
  },

  created(){
    if(this.translation.uri === null){
      this.translation.uri = "";
    }
  },

  methods:{
    fileClass: function(){
      if(this.hasTypeWord(this.propsObj.linkType)){
        return 'fa-file-word'
      }else if(this.hasTypePPT(this.propsObj.linkType)){
        return 'fa-file-powerpoint'
      }else{
        return 'fa-file-pdf'
      }
    },

    changeSameFile: function(evt){
      var newLink;
      if(evt.target.checked){
        this.oldLink = this.translation.uri;
        newLink = this.linkForMedia;
      }else{
        newLink = this.oldLink;
      }
      this.sameFile = evt.target.checked;
      this.$emit('change-element', {'type': 'translation-attribute', 'name': 'uri', 'isTranslation': this.isTranslation, 'value': newLink });
    },

    showStudyMediaModal: function(bool){
      this.studyMediaVisible = bool;
      this.$emit('change-element', {'type': 'show-media-reference-modal', 'value': bool});
    },

    selectMedia: function(obj){
      var newObj = obj;
      newObj.type = "media-attributes";
      newObj.isTranslation = this.isTranslation;
      newObj.sameFile = this.sameFile;
      this.$emit('change-element', newObj);
      this.showStudyMediaModal(false);
    },

    changeTitle: function(text){
      this.$emit('change-element', {'type': 'translation-attribute', 'name': 'subtitle', 'isTranslation': this.isTranslation, 'value': text });
    },

    changeDesc: function(text){
      this.$emit('change-element', {'type': 'translation-attribute', 'name': 'description', 'isTranslation': this.isTranslation, 'value': text });
    },
  }
}
</script>
