<template>
  <BaseModal
    id="publishInterventionModal"
    class="interventionPublish pageModal"
    :class="{pageModalActivate: isFirstPage}"
    :headerText="$t('interventionTranslation.activateIntervention', { name: sourceData.selectedIntervention.attributes.name })"
    :leftButtonText="$t('generalTranslation.publish')"
    :disableButtons="isCopied"
    :disableLeftButton="selectedStudyData === ''"
    :disableNextButton="!allAccepted"
    :hasSecondPage="true"
    :isFirstPage="isFirstPage"
    :backButtonText="$t('generalTranslation.back')"
    @modal-page-changed="showNewPage"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <div v-if="isFirstPage">
        <label>
          {{ 'myStudiesTranslation.activateMode' | translate }}
        </label>
        <select v-model="activationTypeIsTest" id="inputPublishInterventionType">
          <option :value="-1" id="inputPublishInterventionTypeNone" disabled selected>
            {{ 'generalTranslation.select' | translate }}
          </option>
          <option :value="true" id="inputPublishInterventionTypeTest" key="actTest">
            {{ 'myStudiesTranslation.activateTest' | translate }}
          </option>
          <option :value="false" id="inputPublishInterventionTypeReal" key="actReal">
            {{ 'myStudiesTranslation.activateReal' | translate }}
          </option>
        </select>
        <br>
        <br>
        <section v-if="activationTypeIsTest && activationTypeIsTest != -1">
          <section class="checkboxSection notBold">
            <label class="container">
              {{ 'myStudiesTranslation.activateTestConfirm' | translate }}
              <input type="checkbox" v-model="confirmTest" id="inputPublishTestConfirm">
              <span class="checkmark"></span>
            </label>
          </section>
        </section>

        <section v-if="!activationTypeIsTest && activationTypeIsTest != -1">
          <label>
            {{ 'myStudiesTranslation.activateHint' | translate }}
          </label>
          <br>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ 'myStudiesTranslation.activateChecked' | translate }}
              <input type="checkbox" v-model="confirmChecked" id="inputPublishChecked">
              <span class="checkmark"></span>
            </label>
          </section>
          <br>
          <br>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ 'myStudiesTranslation.activateCritical' | translate }}
              <input type="checkbox" v-model="confirmCritical" id="inputPublishCritical">
              <span class="checkmark"></span>
            </label>
          </section>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ 'myStudiesTranslation.activateEmergencyText' | translate }}
              <input type="checkbox" v-model="confirmEmergencyText" id="inputPublishEmergency">
              <span class="checkmark"></span>
            </label>
          </section>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ 'myStudiesTranslation.activateIntro' | translate }}
              <input type="checkbox" v-model="confirmIntro" id="inputPublishIntro">
              <span class="checkmark"></span>
            </label>
          </section>
          <section class="checkboxSection notBold">
            <label class="container">
              {{ 'myStudiesTranslation.activateContact' | translate }}
              <input type="checkbox" v-model="confirmContact" id="inputPublishContact">
              <span class="checkmark"></span>
            </label>
          </section>
        </section>

        <section class="alignBottom">
          <section v-if="activationTypeIsTest != -1" :class="{textOfficial: !activationTypeIsTest, textTest: activationTypeIsTest}">
            {{ 'myStudiesTranslation.activateOtherPerson' | translate }}
          </section>
          {{ 'myStudiesTranslation.activateText' | translate }}
        </section>
      </div>

      <div v-else>
        <h3 class="hCopy">
          {{ 'interventionTranslation.activateInstruction' | translate }}
        </h3>
        <p>
          {{ 'interventionTranslation.shownStudies' | translate }}
        </p>
        <StudyList
          :studyListData="studyListData"
          :copyBool="true"
          :activateBool="true"
          :disableWhileCopy="isCopied"
          @get-new-list="getNewStudies"
          @item-selected="setSelectedStudy"
          @close-notification="closeNotification"
          @reset-page="resetPage"
        ></StudyList>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import StudyList from './StudyList.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: 'InterventionPublish',

  components: {
    StudyList,
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    sourceData: { //data of the intervention which should be copied or activated (selectedIntervention (id, name, config, interventionType))
      required: true,
      type: Object,
    },
  },

  data: function(){
    return{
      isFirstPage: true,
      activationTypeIsTest: -1,
      confirmTest: false,
      confirmChecked: false,
      confirmCritical: false,
      confirmEmergencyText: false,
      confirmIntro: false,
      confirmContact: false,
      isCopied: false,
      studyListData: "",
      selectedStudyData: "",
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),

    allAccepted: function(){
      return (this.activationTypeIsTest != -1 && ((this.activationTypeIsTest && this.confirmTest) ||
        (!this.activationTypeIsTest && this.confirmChecked && this.confirmCritical && this.confirmEmergencyText && this.confirmIntro && this.confirmContact)))
    },
  },

  created(){
    document.body.style.overflow = 'hidden';
  },

  beforeDestroy(){
    document.body.style.overflow = 'visible';
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCopied = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    showNewPage: function(next){
      if(next){
        if(!this.allAccepted){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorAccept')});
        }else{
          this.isFirstPage = false;
          this.getNewStudies({"page": 1, "filterTerm": ""});
        }
      }else{
        this.isFirstPage = true;
      }
    },

    setSelectedStudy: function(obj){
      this.selectedStudyData = obj;
    },

    closeModal: function(done) {
      if(done){
        if(this.selectedStudyData === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorSelectStudy')});
        }else{
          this.isCopied = true;
          this.$emit('set-changed', { name: 'all', value: false });
          var targetStudyId = this.selectedStudyData.studyId;
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.publishInterventionLoad')});
          this.getLessons(targetStudyId);
        }
      }else if(!this.isCopied){
        this.$emit('close-modal', { done: false });
      }
    },

    getLessons: function(targetStudyId){
      var self = this;
      var userCanEditTarget = (this.selectedStudyData.role === "Owner" || this.selectedStudyData.role === "Collaborator");
      this.requestLessonsOfIntervention(this.sourceData.selectedIntervention.id, 0, userCanEditTarget, false, -1, -1, false)
      .then( function(response){
        if(response.allLessons.length === 0){
          self.$emit('close-modal', { done: false });
          self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('myStudiesTranslation.lessonWarning')});
        }else{
          var allLessons = response.allLessons;

          //customorder is not changed when positions of lessons change
          //-> is only collection of lesson ids which are included in the intervention per default
          //-> set in correct order
          var customOrder = self.sourceData.selectedIntervention.attributes.default_configuration.custom_order;
          var newCustomOrder = [];
          var questionnaireConfig = self.sourceData.selectedIntervention.attributes.default_configuration.questionnaire_configuration;
          var allLessonIds = [];

          for(var lesson in allLessons){
            if(customOrder.includes(Number(allLessons[lesson].id))){
              newCustomOrder.push(Number(allLessons[lesson].id));
            }
            allLessonIds.push(Number(allLessons[lesson].id));

            //add entries for lessons not included in config
            var inConfig = questionnaireConfig.findIndex(elem => Number(elem.id) === Number(allLessons[lesson].id));
            if(inConfig === -1){
              questionnaireConfig.push(
                {
                    "id": allLessons[lesson].id,
                    "unlock_type": "after_previous",
                    "unlock_days_after_start": null,
                    "feedback_required": false
                }
              )
            }
          }

          //remove config of deleted questionnaires and duplicate entries from questionnaire_configuration
          var newQuestionnaireConfig = [];
          for(var ind in questionnaireConfig){
            if(allLessonIds.includes(Number(questionnaireConfig[ind].id))){
              newQuestionnaireConfig.push(questionnaireConfig[ind]);
            }
          }

          //change unlocktype of first lesson if after_previous; unaccompannied intervention can not have manually; remove condition labels
          var firstLesson = newCustomOrder[0];
          for(var index in newQuestionnaireConfig){
            if(newQuestionnaireConfig[index].unlock_type === "manually" && self.sourceData.selectedIntervention.attributes.intervention_type === "unaccompanied"){
              newQuestionnaireConfig[index].unlock_type = "after_previous";
            }
            if(newQuestionnaireConfig[index].id === firstLesson && newQuestionnaireConfig[index].unlock_type === "after_previous"){
              newQuestionnaireConfig[index].unlock_type = "always";
            }
            if(self.sourceData.selectedIntervention.attributes.intervention_type === "unaccompanied"){
              newQuestionnaireConfig[index].feedback_required = false;
            }

            if(self.$enabledFeatures.includes('jitai')){
              if(newQuestionnaireConfig[index].unlock_type === "conditional"){
                for(var condition in newQuestionnaireConfig[index].condition){
                  delete newQuestionnaireConfig[index].condition[condition]['labels'];
                }
                if(newQuestionnaireConfig[index].bonus_lesson === undefined){
                  newQuestionnaireConfig[index].bonus_lesson = true;
                }
              }else{
                newQuestionnaireConfig[index].condition = null;
              }
            }else if(newQuestionnaireConfig[index].unlock_type === "conditional"){
              newQuestionnaireConfig[index].unlock_type = "always";
              delete newQuestionnaireConfig[index]["condition"];
            }
          }
          var defaultConfig = {
            "questionnaire_configuration": newQuestionnaireConfig,
            "custom_order": newCustomOrder
          }
          self.updateConfigAndCopyInStudy(defaultConfig, targetStudyId);
        }
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getLessons(targetStudyId) }, "");
      });
    },

    updateConfigAndCopyInStudy: function(defaultConfig, targetStudyId){
      var self = this;
      this.copyInterventionInStudyRequest(this.sourceData.selectedIntervention.id, targetStudyId, defaultConfig, this.activationTypeIsTest)
      .then(function (){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.publishInterventionSuccess')});
        self.$emit('close-modal', { done: false }); //done false because no further actions after close (only success notification)
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.updateConfigAndCopyInStudy(defaultConfig, targetStudyId) }, "");
      });
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    getNewStudies: function(obj){
      //activate needs owner or ecoachmanager in target study
      var filterTerm = obj.filterTerm + "&role=study.owner,study.ecoachmanager";
      var self = this;
      this.requestCollaboratingOrNonCollaboratingStudies(obj.page, filterTerm, "study", true, false, -1)
      .then( function(response){
        self.studyListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewStudies(obj) }, "");
      });
    },

    resetPage: function(){
      this.studyListData.currentPage = 1;
    },
  }
}
</script>
