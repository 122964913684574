<template>
  <div class="theLogout">
    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import router from '../router';
import BaseNotification from './BaseNotification.vue';

export default {
  name: 'TheLogout',

  components: {
    BaseNotification
  },

  mixins: [httpHelper],

  computed: {
    ...mapGetters([
      'getToken',
      'getRoles',
    ]),
  },

  watch:{
    getToken(newVal){
      if(newVal === "" && this.$route.name != 'login'){
        this.SET_NOTIFICATIONTEXT("");
        router.push({ name: 'login'});
      }
    },
  },

  created(){
    this.logoutFunction();
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_LESSONELEMENTSARR',
      'SET_ROLES',
      'SET_SELECTEDLANGUAGE',
      'SET_AVAILABLELOCALES',
      'SET_USERID',
    ]),

    logoutFunction: function(){
      if(!this.getRoles.includes("admin") && !this.getRoles.includes("editor")){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.errorCode2')});
      }else{
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('logoutTranslation.logoutLoad')});
      }

      this.logoutRequest();
      this.SET_LESSONELEMENTSARR("");
      this.SET_ROLES("");
      this.SET_SELECTEDLANGUAGE("");
      this.SET_AVAILABLELOCALES("");
      this.SET_USERID("");
      localStorage.clear();
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },
  }
}
</script>
