import Vue from 'vue';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getSelectedLanguage'
    ]),

    summaryList: function(){
      return {
        "ElementsBlock": Vue.i18n.translate('lessonTranslation.addBlock'),
        "ElementsHeadline": Vue.i18n.translate('elementsHeadlineTranslation.headline'),
        "ElementsMedia": Vue.i18n.translate('elementsMediaTranslation.media'),
        "ElementsPage": Vue.i18n.translate('elementsPageTranslation.pageBreak'),
        "ElementsLine": Vue.i18n.translate('elementsLineTranslation.line'),
        "ElementsSpace": Vue.i18n.translate('elementsSpaceTranslation.space'),
        "ElementsQuestion": Vue.i18n.translate('elementsQuestionTranslation.question'),
        "ElementsTable": Vue.i18n.translate('elementsTableTranslation.table'),
        "ElementsText": Vue.i18n.translate('elementsTextTranslation.text'),
      }
    },

    questionTypeList: function(){
      return [
        {
          value: "YesNoSwitch",
          text: Vue.i18n.translate('elementsQuestionTranslation.yesNoSwitch')
        },
        {
          value: "SingleChoice",
          text: Vue.i18n.translate('elementsQuestionTranslation.singleChoice')
        },
        {
          value: "MultipleChoice",
          text: Vue.i18n.translate('elementsQuestionTranslation.multipleChoice')
        },
        {
          value: "Slider",
          text: Vue.i18n.translate('elementsQuestionTranslation.slider')
        },
        {
          value: "TextDate",
          text: Vue.i18n.translate('elementsQuestionTranslation.textDate')
        },
        {
          value: "TextTime",
          text: Vue.i18n.translate('elementsQuestionTranslation.textTime')
        },
        {
          value: "TextDateTime",
          text: Vue.i18n.translate('elementsQuestionTranslation.textDateTime')
        },
        {
          value: "TextString",
          text: Vue.i18n.translate('elementsQuestionTranslation.textString')
        },
        {
          value: "TextArea",
          text: Vue.i18n.translate('elementsQuestionTranslation.textArea')
        },
        {
          value: "QuestionTable",
          text: Vue.i18n.translate('elementsQuestionTranslation.questionTable')
        }
      ]
    },

    optionsObj: function(){
      return {
        "less": "<",
        "lessEqual": "<=",
        "greater": ">",
        "greaterEqual": ">=",
        "equals": "=",
        "notEquals": "!=",
        "between": Vue.i18n.translate('conditionEditorTranslation.between')
      }
    },
  },

  methods: {
    addElementsButtons: function(isInBlock, isSkill){
      var list = [
        {
          "elementType": "ElementsHeadline",
          "text": Vue.i18n.translate('lessonTranslation.addHeadline')
        },
        {
          "elementType": "ElementsText",
          "text": Vue.i18n.translate('lessonTranslation.addText')
        },
        {
          "elementType": "ElementsMedia",
          "text": Vue.i18n.translate('lessonTranslation.addMedia')
        },
        {
          "elementType": "ElementsQuestion",
          "text": Vue.i18n.translate('lessonTranslation.addQuestion')
        },
        {
          "elementType": "ElementsLine",
          "text": Vue.i18n.translate('lessonTranslation.addLine')
        },
        {
          "elementType": "ElementsSpace",
          "text": Vue.i18n.translate('lessonTranslation.addSpace')
        },
        {
          "elementType": "ElementsTable",
          "text": Vue.i18n.translate('lessonTranslation.addTable')
        },
        {
          "elementType": "ElementsBlock",
          "text": Vue.i18n.translate('lessonTranslation.addBlock')
        },
        {
          "elementType": "ElementsPage",
          "text": Vue.i18n.translate('lessonTranslation.addPage')
        }
      ];
      if(isInBlock || isSkill){
        list.splice(8,1);
      }

      if(isSkill){
        list.splice(3,1);
      }

      return list
    },

    getElementSummary: function(questionElement, detailsOpen){
      var summaryText = "";
      var self = this;
      var textWithReferences = "";
      if(detailsOpen || questionElement.type === "ElementsPage" || questionElement.type === "ElementsTable" ||
          questionElement.type === "ElementsLine" || questionElement.type === "ElementsSpace"){
        summaryText = this.summaryList[questionElement.type];

        if(questionElement.type === "ElementsHeadline" || questionElement.type === "ElementsText"){
          summaryText += " *";
        }

      }else if(questionElement.type === "ElementsHeadline"){
        if(questionElement.translations[this.getSelectedLanguage]){
          textWithReferences = questionElement.translations[this.getSelectedLanguage];
        }
        summaryText = this.summaryList[questionElement.type];

      }else if(questionElement.type === "ElementsMedia"){
        if(questionElement.translations[this.getSelectedLanguage] && questionElement.translations[this.getSelectedLanguage].subtitle){
          textWithReferences = questionElement.translations[this.getSelectedLanguage].subtitle;
        }
        summaryText = this.summaryList[questionElement.type];

      }else if(questionElement.type === "ElementsText"){
        if(questionElement.translations[this.getSelectedLanguage]){
          textWithReferences = questionElement.translations[this.getSelectedLanguage].replace(/<[^>]+>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        }
        summaryText = this.summaryList[questionElement.type];

      }else if(questionElement.type === "ElementsQuestion"){
        if(questionElement.propsObj.selectedQuestionProp === ""){
          summaryText = this.summaryList[questionElement.type];
        }else{
          var index = this.questionTypeList.findIndex(elem => elem.value === questionElement.propsObj.selectedQuestionProp);
          summaryText = (index != -1) ? this.questionTypeList[index].text : "";

          if(questionElement.propsObj.labelProp != ""){
            summaryText += " " + "(" + questionElement.propsObj.labelProp + ")";
          }
          if(questionElement.propsObj.selectedQuestionProp != "QuestionTable" && questionElement.translations[this.getSelectedLanguage] &&
              questionElement.translations[this.getSelectedLanguage].question){
            textWithReferences = questionElement.translations[this.getSelectedLanguage].question;
          }
        }
      }else if(questionElement.type === "ElementsBlock"){
        var detailsText = "";
        if(questionElement.translations[this.getSelectedLanguage]){
          detailsText = questionElement.translations[this.getSelectedLanguage];
        }

        if(questionElement.propsObj.type === "conditional" || questionElement.propsObj.type === "both"){
          if(questionElement.propsObj.type === "conditional"){
            summaryText = Vue.i18n.translate('elementsBlockTranslation.blockFor');
          }else{
            summaryText = Vue.i18n.translate('elementsBlockTranslation.blockForWithDetails');
          }
          if(!questionElement.propsObj.isComplexCondition){
            summaryText += " " + this.simpleConditionAsString(questionElement.propsObj, true);
          }else{
            summaryText += " " + Vue.i18n.translate('elementsBlockTranslation.complexCondition');
          }
          if(questionElement.propsObj.type === "both"){
            summaryText += ": " + detailsText;
          }
        }else if(questionElement.propsObj.type === "details"){
          summaryText = Vue.i18n.translate('elementsBlockTranslation.typeDetails') + ": " + detailsText;
        }else if(questionElement.propsObj.type === "none"){
          summaryText = Vue.i18n.translate('elementsBlockTranslation.noneBlock');
        }

        if(questionElement.propsObj.thenBlock){
          if(!questionElement.propsObj.thenBlock.repeat.question && questionElement.propsObj.thenBlock.repeat.data > 1){
            summaryText += ", " + Vue.i18n.translate('elementsBlockTranslation.repetitionContent') + " " +
              questionElement.propsObj.thenBlock.repeat.data + " " + Vue.i18n.translate('elementsBlockTranslation.repetitionDisplayed');
          }else if(questionElement.propsObj.thenBlock.repeat.question){
            summaryText += ", " + Vue.i18n.translate('elementsBlockTranslation.repetitionQuestionContent') + " ";
            if(questionElement.propsObj.thenBlock.repeat.data != "" && this.getQuestionLabelList[questionElement.propsObj.thenBlock.repeat.data] &&
                this.getQuestionLabelList[questionElement.propsObj.thenBlock.repeat.data] != 0){
              summaryText += this.getQuestionLabelList[questionElement.propsObj.thenBlock.repeat.data].label;
            }
          }
        }
      }

      if(textWithReferences != ""){
        textWithReferences = textWithReferences.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        //first replace gets {{number}} as x -> brackets are replaced in second replace -> only number -> get label
        textWithReferences = textWithReferences.replace(/\{{2}([^}{]+)}{2}/g, function(x){
          var number = x.replace(/{/g, "").replace(/}/g, "");
          if(self.getQuestionLabelList[number] && self.getQuestionLabelList[number] != 0){
            return "{{" + self.getQuestionLabelList[number].label + "}}"
          }else{
            return ""
          }
        });
        summaryText += ": " + textWithReferences;
      }
      return summaryText
    },

    simpleConditionAsString: function(propsObj, summary){
      var string = "";
      //label
      if(propsObj.condition.questionLabel != undefined && propsObj.condition.questionLabel != "" && this.getQuestionLabelList[propsObj.condition.questionLabel] &&
          this.getQuestionLabelList[propsObj.condition.questionLabel] != 0){
        string = this.getQuestionLabelList[propsObj.condition.questionLabel].label;
      }

      //operation
      if(propsObj.condition.operation === "answered"){
        string += " " + Vue.i18n.translate('elementsBlockTranslation.answeredAny');
      }else if(propsObj.condition.leafType != "date" && propsObj.condition.leafType != "slider"){
        if(summary){
          string += " " + "=";
        }else{
          string += " " + Vue.i18n.translate('elementsBlockTranslation.answeredWith');
        }
      }else{
        string += " " + this.optionsObj[propsObj.condition.operation];
      }

      //value question/slider/date
      if(propsObj.condition.operation != "answered"){
        if(typeof propsObj.condition.value === "boolean"){
          if(propsObj.condition.value){
            string += " " + Vue.i18n.translate('generalTranslation.yes');
          }else{
            string += " " + Vue.i18n.translate('generalTranslation.no');
          }
        }else{
          string += " " + propsObj.condition.value;
        }
      }

      //range for slider and date
      if(propsObj.condition.operation === "between"){
        string += " " + Vue.i18n.translate('conditionEditorTranslation.betweenAnd') + " " + propsObj.condition.secondValue;
      }

      return string
    },

    setMissingTranslations: function(elements, isTranslation, translationLanguage){
      var language;
      if(!isTranslation){
        language = this.getSelectedLanguage;
      }else{
        language = translationLanguage;
      }
      if(language != ""){
        for(var element in elements){
          //if there is not yet a translation for the selected language, add it
          if(!(elements[element].type === "ElementsLine" || elements[element].type === "ElementsSpace") && !elements[element].translations[language]){

            switch(elements[element].type){
              case "ElementsHeadline":
              case "ElementsText":
              case "ElementsTable":
              case "ElementsBlock":
                elements[element].translations[language] = "";
              break;

              case "ElementsMedia":
                elements[element].translations[language] = {
                  uri: isTranslation ? elements[element].translations[this.getSelectedLanguage].uri : "",
                  filename: isTranslation ? elements[element].translations[this.getSelectedLanguage].filename : "",
                  subtitle: "",
                  description: ""
                };

                //if no file, use file of another language if there are any
                if(!elements[element].translations[language].uri){
                  for(var translation in elements[element].translations){
                    if(translation != language && elements[element].translations[translation].uri){
                      elements[element].translations[language].uri = elements[element].translations[translation].uri;
                      elements[element].translations[language].filename = elements[element].translations[translation].filename;
                    }
                  }
                }
              break;

              case "ElementsQuestion":
                var answersArr = [];
                var questionText = "";
                switch(elements[element].propsObj.selectedQuestionProp){
                  case "YesNoSwitch":
                    answersArr = (language === "de" ? ["JA","NEIN"] : ["YES","NO"]);
                  break;

                  case "SingleChoice":
                  case "MultipleChoice":
                    answersArr = new Array(elements[element].propsObj.values.length).fill("");
                  break;

                  case "Slider":
                    answersArr = [
                      { value: Number(elements[element].propsObj.values.min), label: "" },
                      { value: Number(elements[element].propsObj.values.max), label: "" }
                    ];
                  break;

                  case "QuestionTable":
                    answersArr = new Array(Number(elements[element].propsObj.values.max) -
                      Number(elements[element].propsObj.values.min)).fill("");
                    questionText = new Array(elements[element].propsObj.values.questions).fill("");
                  break;

                  default:
                  break;
                }

                elements[element].translations[language] = {
                  question: questionText,
                  answers: answersArr
                };
              break;

              case "ElementsPage":
                if(this.$enabledFeatures.includes('progress')){
                  //page element did not have translations before
                  if(!elements[element].translations){
                    elements[element].translations = [];
                  }
                  elements[element].translations[language] = "";
                }
              break;

              default:
              break;
            }
          }

          if(elements[element].type === "ElementsBlock"){
            elements[element].propsObj.elements = this.setMissingTranslations(JSON.parse(JSON.stringify(elements[element].propsObj.elements)), isTranslation, translationLanguage);
          }
        }
      }
      return JSON.parse(JSON.stringify(elements))
    },

    hasTypeFile: function(mimetype){
      return (mimetype == 'application/pdf' ||
        mimetype == 'application/msword' ||
        mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        mimetype == 'application/vnd.ms-powerpoint' ||
        mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
        mimetype == 'application/vnd.oasis.opendocument.text')
    },

    hasTypeWord: function(mimetype){
      return (mimetype == 'application/msword' ||
        mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        mimetype == 'application/vnd.oasis.opendocument.text')
    },

    hasTypePPT: function(mimetype){
      return (mimetype == 'application/vnd.ms-powerpoint' || mimetype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
    },

    hasTypePDF: function(mimetype){
      return mimetype == 'application/pdf'
    },
  }
};
