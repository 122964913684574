<template>
  <div class="baseSelectLanguage wrapper">
    <section class="col sec50language">
      <select :value="getSelectedLanguage" id="inputSelectedLanguage" class="buttonForm" @change="changeLanguage1($event.target.value)">
        <option value="" id="inputSelectedLanguageNone" disabled>
          {{ 'generalTranslation.chooseLanguage' | translate }}
        </option>
        <option v-for="language in localesArray.filter(language => (language != selectedLanguage2 && isTranslationMode) || !(isTranslationMode))"
            :id="'inputSelectedLanguage' + language" :key="language">
          {{language}}
        </option>
      </select>
      <section v-if="getIsEditable" class="languageButton">
        <button type="button" :id="buttonShowTranslationId" @click="showTranslation">
          {{ 'interventionTranslation.showTranslation' | translate }}
        </button>
      </section>
    </section>

    <section class="col sec50language">
      <select v-if="isTranslationMode" :value="selectedLanguage2" id="inputSelectedLanguageLanguageTranslation" @change="changeLanguage2($event.target.value)">
        <option value="" id="inputSelectedLanguageTranslationNone" disabled>
          {{ 'generalTranslation.chooseLanguage' | translate }}
        </option>
        <option v-for="language in localesArray.filter(language => language != getSelectedLanguage)" :id="'inputSelectedLanguageLanguageTranslation' + language" :key="language">
          {{language}}
        </option>
      </select>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'BaseSelectLanguage',

  props: {
    localesArray: {
      required: true,
      type: Array,
    },

    selectedLanguage2: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    }
  },

  data: function(){
    return {
      buttonShowTranslationId: "buttonShowTranslation"
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getIsEditable'
    ]),
  },

  watch:{
    isTranslationMode(newVal){
      if(newVal){
        document.getElementById(this.buttonShowTranslationId).innerHTML = Vue.i18n.translate('interventionTranslation.hideTranslation');
        if(this.selectedLanguage2 === ""){
          var language;

          if(this.getSelectedLanguage === "de" && this.localesArray.includes("en")){
            language = "en";
          }else if(this.getSelectedLanguage != "de" && this.localesArray.includes("de")){
            language = "de";
          }else{
            language = this.localesArray[0];
            if(language === this.getSelectedLanguage){
              language = this.localesArray[1];
            }
          }

          this.$emit('change-language2', language);
        }
      }else{
        this.$emit('change-language2', "");
        document.getElementById(this.buttonShowTranslationId).innerHTML = Vue.i18n.translate('interventionTranslation.showTranslation');
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_SELECTEDLANGUAGE',
      'SET_NOTIFICATIONTEXT'
    ]),

    changeLanguage1: function(language){
      this.$emit('close-notification');
      this.SET_SELECTEDLANGUAGE(language);
      if(this.getSelectedLanguage === this.selectedLanguage2){
        this.$emit('change-language2', "");
      }
    },

    changeLanguage2: function(language){
      this.$emit('close-notification');
      this.$emit('change-language2', language);
    },

    showTranslation: function(){
      this.$emit('close-notification');
      if(this.isTranslationMode){
        this.$emit('change-translationmode', false);
      }else if(this.localesArray.length > 1){
        this.$emit('change-translationmode', true);
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.showTranslationWarning')});
      }
    },
  },
}
</script>
