<template>
  <div class="baseSelectBackground">
    <select v-model="colorSelected" id="inputBackground" class="buttonForm" :disabled="!getIsEditable">
      <option value="" id="inputBackgroundNone" disabled>
        {{ 'lessonTranslation.selectDefaultPageColor' | translate }}
      </option>
      <option :value="true" id="inputBackgroundColor" selected>
        {{ 'lessonTranslation.selectColor' | translate }}
      </option>
      <option :value="false" id="inputBackgroundImage">
        {{ 'lessonTranslation.selectBackground' | translate }}
      </option>
    </select>

    <input type="color" v-if="colorSelected" :value="colorValue" list="pageColorValues" id="inputPageColor" :disabled="!getIsEditable"
      @input="$emit('change-attribute', {'name': 'page_color', 'value': $event.target.value})">

    <datalist id="pageColorValues">
      <option v-for="data in colorsList" :value="data" :id="'pageColor' + data" :key="data">
      </option>
    </datalist>

    <section v-if="!colorSelected" class="wrapper media">
      <section class="col sec50">
        <img v-if="pagePictureLink != '' && !pageColor.startsWith('#')" :src="pagePictureLink">
        <i class="far fa-image fa-2x" v-else></i>
        <br>
        <button type="button" v-if="getIsEditable" id="buttonSelectBackground" @click="getStudyMedia">
          {{ 'elementsMediaTranslation.selectPicture' | translate }}
        </button>
      </section>
      <section class="col sec50">
      </section>
    </section>

    <StudyMedia
      v-if="studyMediaVisible"
      id="mediaSelectionBackground"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @close-modal="closeModal"
      @item-selected="selectMedia"
    ></StudyMedia>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import StudyMedia from './StudyMedia.vue';

export default {
  name: 'BaseSelectBackground',

  components: {
    StudyMedia
  },

  props: {
    pageColor: {
      required: true,
      type: String,
    },

    studyId: {
      required: true,
      type: Number,
    }
  },

  data: function(){
    return {
      colorSelected: true,
      colorsList: [
        "#fefcf0", "#ffffff", "#bbbbbb", "#888888", "#444444",
        "#facccc", "#f06666", "#e60000", "#a10000", "#5c0000",
        "#ffebcc", "#ffc266", "#ff9900", "#b26b00", "#663d00",
        "#ffffcc", "#ffff66", "#ffff00", "#b2b200", "#666600",
        "#cce8cc", "#66b966", "#008a00", "#006100", "#003700",
        "#cce0f5", "#66a3e0", "#0066cc", "#0047b2", "#002966",
        "#ebd6ff", "#c285ff", "#9933ff", "#6b24b2", "#3d1466"
      ],
      studyMediaVisible: false,
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable'
    ]),

    pagePictureLink: function(){
      return this.pageColor ? this.$urlUploads + "/studies/" + this.studyId + "/" + this.pageColor : ""
    },

    colorValue: function(){
      return this.pageColor ? this.pageColor : this.$defaultLessonPageColor
    },
  },

  watch:{
    pageColor: function(newVal){
      this.colorSelected = (!newVal || newVal.startsWith("#"));
    },
  },

  created(){
    this.colorSelected = (!this.pageColor || this.pageColor.startsWith("#"));
  },

  methods: {
    getStudyMedia: function(){
      this.studyMediaVisible = true;
      this.$emit('show-modal', { type: 'StudyMedia', value: true });
    },

    selectMedia: function(obj){
      var filename = obj.link.split("").reverse().join("");
      filename = filename.substr(0, filename.indexOf('/'));
      filename = filename.split("").reverse().join("");
      this.$emit('change-attribute', {'name': 'page_color', 'value': filename });
      this.closeModal();
    },

    closeModal: function(){
      this.studyMediaVisible = false;
      this.$emit('show-modal', { type: 'StudyMedia', value: false });
    },
  },
}
</script>
