<template>
  <div class="theLogin">
    <img src="eSano_cms.png">
    <form class="loginForm" @submit.prevent="loginFunction(email, password)">
      <input type="email" v-model="email" id="inputMailLogin" :placeholder="$t('generalTranslation.email') + ' *'" formControlName="email" autocomplete="username" required>
      <div v-if="email === ''" id="errorMailEmpty" class="loginErrorMessage">
        {{ 'loginTranslation.errorEnterEmail' | translate }}
      </div>
      <div v-if="email != '' && !emailRegEx" id="errorMailInvalid" class="loginErrorMessage">
        {{ 'loginTranslation.errorEnterValidEmail' | translate }}
      </div>
      <br>

      <input :type="showPassword ? 'text' : 'password'" v-model="password" id="inputPWLogin" :placeholder="$t('loginTranslation.password') + ' *'" formControlName="password" autocomplete="password" required>
      <a class="suffix-icon" @click="showPassword = !showPassword">
        <i class="fas" :class="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i>
      </a>
      <div v-if="password === ''" id="errorPWEmpty" class="loginErrorMessage">
        {{ 'loginTranslation.errorEnterPassword' | translate }}
      </div>
      <br>

      <router-link to="/resetpassword" id="resetPWLink" class="resetPWLink">
        {{ 'loginTranslation.forgotPassword' | translate }}
      </router-link>
      <br>

      <button type="submit" id="loginButton" :disabled="email === '' || !emailRegEx || password === ''">
        {{ 'loginTranslation.login' | translate }}
      </button>
    </form>

    <footer>
      <router-link to="/imprint" id="imprintLink">
        {{ 'generalTranslation.imprint' | translate }}
      </router-link>
      |
      <router-link to="/privacy" id="privacyLink">
        {{ 'generalTranslation.privacy' | translate }}
      </router-link>
    </footer>

    <BaseNotification></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import router from '../router';
import BaseNotification from './BaseNotification.vue';

export default {
  name: 'TheLogin',

  components: {
    BaseNotification,
  },

  mixins: [httpHelper],

  data: function(){
    return{
      email: '',
      password: '',
      showPassword: false,
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
      'getRoles',
      'getToken',
    ]),

    emailRegEx: function(){
      var regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regExp.test(this.email)
    }
  },

  watch:{
    getRoles(newVal){
      if(newVal != "" && newVal != -1){
        if(newVal.includes("admin") || newVal.includes("editor")){
          router.push({ name: 'home'});
        }else{
          router.push({ name: 'logout'});
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.errorCode2')});
        }
      }
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        localStorage.clear();
      }
    },

    getToken(newVal){
      if(newVal === ""){
        localStorage.clear();
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'CHANGE_TOKEN',
    ]),

    loginFunction: function(email, password){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('loginTranslation.loginLoad')});

      var self = this;
      this.loginRequest(email, password)
      .then( function(response){
        self.CHANGE_TOKEN(response.data.data.attributes.token);
        self.SET_NOTIFICATIONTEXT("");
        self.requestMyRoles();
      })
      .catch( function(error){
        self.handleErrorsNotLoggedIn(error, "");
      });
    },
  }
}
</script>
