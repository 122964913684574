<template>
  <BaseModal
    id="copyLessonModal"
    class="lessonCopy pageModal"
    :headerText="$t('lessonTranslation.copyLesson', { name: sourceData.name })"
    :leftButtonText="$t('generalTranslation.copy')"
    :disableButtons="isCopied"
    :disableLeftButton="selectedInterventionData === ''"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <h3 class="hCopy">
        {{ 'lessonTranslation.copyInstruction' | translate }}
      </h3>
      <p>
        {{ 'interventionTranslation.shownGroups' | translate }}
      </p>
      <InterventionList
        :interventionListData="interventionListData"
        usedInRoute="copyLesson"
        :disableWhileCopy="isCopied"
        @get-new-list="getNewInterventions"
        @item-selected="setSelectedIntervention"
        @close-notification="closeNotification"
        @reset-page="resetPage"
      ></InterventionList>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import InterventionList from './InterventionList.vue';
import BaseModal from './BaseModal.vue';

export default {
  name: 'LessonCopy',

  components: {
    InterventionList,
    BaseModal,
  },

  mixins: [httpHelper],

  props: {
    sourceData: { //data of lesson which should be copied (name, id, studyId)
      required: true,
      type: Object
    },
  },

  data: function(){
    return{
      isCopied: false,
      interventionListData: "",
      selectedInterventionData: "",
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  created(){
    document.body.style.overflow = 'hidden';
    this.getNewInterventions({"page": 1, "filterTerm": ""});
  },

  beforeDestroy(){
    document.body.style.overflow = 'visible';
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCopied = false;
      }
    }
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_MYROLEFORSTUDY',
    ]),

    getNewInterventions: function(obj){
      var filterTerm = obj.filterTerm + "&role=study.owner,study.collaborator";
      var self = this;
      this.requestCollaboratingOrNonCollaboratingInterventions(obj.page, filterTerm, true, false, -1)
      .then( function(response){
        self.interventionListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewInterventions(obj) }, "");
      });
    },

    setSelectedIntervention: function(obj){
      this.selectedInterventionData = obj;
    },

    updateInterventionConfigOfTargetIntervention: function(targetLessonId){
      var targetInterventionConfig = this.selectedInterventionData.config;
      var json = {
        data: {
          type: "interventions",
          id: this.selectedInterventionData.interventionId,
          attributes: {
            default_configuration: {
              questionnaire_configuration: targetInterventionConfig.questionnaire_configuration ? targetInterventionConfig.questionnaire_configuration : [],
              custom_order: targetInterventionConfig.custom_order ? targetInterventionConfig.custom_order : []
            }
          }
        }
      };

      json.data.attributes.default_configuration.questionnaire_configuration.push(
        {
          id: targetLessonId,
          unlock_type: (json.data.attributes.default_configuration.questionnaire_configuration.length === 0) ? "always" : "after_previous",
          unlock_days_after_start: null,
          feedback_required: false
        }
      );

      json.data.attributes.default_configuration.custom_order.push(targetLessonId);

      this.updateIntDetails(json, targetLessonId);
    },

    updateIntDetails: function(json, targetLessonId){
      var self = this;
      this.updateInterventionDetailsRequest(json, this.selectedInterventionData.interventionId)
      .then( function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('lessonTranslation.copyLessonSuccess')});
        self.isCopied = false;
        self.$emit('close-modal', { done: true, interventionId: self.selectedInterventionData.interventionId, lessonId: targetLessonId });
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.updateIntDetails(json, targetLessonId) }, "");
      });
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    closeModal(done) {
      if(done){
        if(this.selectedInterventionData === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorSelectIntervention')});
        }else{
          this.isCopied = true;
          this.$emit('set-changed', { name: 'all', value: false });
          this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('lessonTranslation.copyLessonLoad')});
          var userCanEditTarget = (this.selectedInterventionData.role === "Owner" || this.selectedInterventionData.role === "Access");

          //the lesson will be copied in this intervention -> get lessons in order to get lesson counter -> determine position of new lesson
          var self = this;
          this.requestLessonsOfIntervention(this.selectedInterventionData.interventionId, 0, userCanEditTarget, false, -1, -1, false)
          .then( function(response){
            var json =
            {
              "data":{
                "type":"questionnaires",
                "attributes":{
                  "position": response.allLessons.length + 1,
                  "intervention_id": self.selectedInterventionData.interventionId
                }
              }
            }
            return self.copyLessonRequest(self.sourceData.id, json, (self.selectedInterventionData.studyId === self.sourceData.studyId))
          })
          .then(function (targetLessonId){
            //set study id and role for study to which the intervention, in which the lesson was copied, belongs
            self.SET_MYROLEFORSTUDY(self.selectedInterventionData.role);
            self.updateInterventionConfigOfTargetIntervention(targetLessonId);
          })
          .catch( function(error){
            self.handleErrors(error, function(){ self.closeModal(done) }, "");
          });
        }
      }else if(!this.isCopied){
        this.$emit('close-modal', { done: false });
      }
    },

    resetPage: function(){
      this.interventionListData.currentPage = 1;
    },
  }
}
</script>
