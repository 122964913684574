<template>
  <BaseModal
    id="createUserModal"
    class="adminUserCreate"
    :headerText="$t('adminUsersTranslation.createAccount')"
    :leftButtonText="$t('generalTranslation.create')"
    :disableButtons="isCreated"
    :disableLeftButton="leftDisabled || !emailRegEx"
    @close-modal="closeModal"
  >
    <template v-slot:body>
      <section class="wrapper">
        <section class="wrapper">
          <label>
            {{ 'adminUsersTranslation.role' | translate }} *
          </label>
          <section class="notBold notNameTd">
            <section class="checkboxSection">
              <label>
                <i class="fas fa-check"></i>
                {{ 'adminUsersTranslation.patient' | translate }}
              </label>
            </section>
            <section v-for="role in rolesArray" :key="role" class="checkboxSection">
              <label class="container">
                {{ 'adminUsersTranslation.' + role | translate }}
                <input type="checkbox" v-model="rolesForUser" :value="role" :id="'inputUserRole' + role">
                <span class="checkmark"></span>
              </label>
            </section>
          </section>
        </section>
        <form @submit.prevent="closeModal(true)">
          <input type="submit" style="display:none"/>
          <label>
            {{ 'generalTranslation.email' | translate }} *
          </label>
          <input type="email" v-model="email" id="inputUserMail" :placeholder="$t('generalTranslation.email')">
          <div v-if="email != '' && !emailRegEx" id="errorUserMail" class="loginErrorMessage">
            {{ 'loginTranslation.errorEnterValidEmail' | translate }}
          </div>

          <label>
            {{ 'adminUsersTranslation.username' | translate }} *
          </label>
          <input type="text" v-model="name" id="inputUserName" :placeholder="$t('adminUsersTranslation.username')">

          <section v-if="rolesForUser.length > 0">
            <label>
              {{ 'adminUsersTranslation.firstname' | translate }} *
            </label>
            <input type="text" v-model="firstname" id="inputUserFirstname" :placeholder="$t('adminUsersTranslation.firstname')">

            <label>
              {{ 'adminUsersTranslation.lastname' | translate }} *
            </label>
            <input type="text" v-model="lastname" id="inputUserLastname" :placeholder="$t('adminUsersTranslation.lastname')">
          </section>
        </form>
      </section>
    </template>
  </BaseModal>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';

export default {
  name: 'AdminUserCreate',

  components: {
    BaseModal,
  },

  mixins: [httpHelper],

  data: function(){
    return{
      name: '',
      email: '',
      firstname: '',
      lastname: '',
      rolesForUser: ["editor"],
      isCreated: false,
      rolesArray: ["editor", "ecoach", "admin"]
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),

    leftDisabled: function(){
      return (this.email === "" || this.name === "" || (this.firstname === "" && this.rolesForUser.length > 0) ||
        (this.lastname === "" && this.rolesForUser.length > 0))
    },

    emailRegEx: function(){
      var regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regExp.test(this.email)
    }
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.isCreated = false;
      }
    },
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    closeModal(done) {
      if(done){
        this.createNewUser();
      }else if(!this.isCreated){
        this.$emit('close-modal', false);
      }
    },

    createNewUser: function() {
      var errorText = "";
      if(this.email === ""){
        errorText += Vue.i18n.translate('generalTranslation.email');
      }

      if(this.name === ""){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminUsersTranslation.username');
      }

      if(this.firstname === "" && this.rolesForUser.length > 0){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminUsersTranslation.firstname');
      }

      if(this.lastname === "" && this.rolesForUser.length > 0){
        errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('adminUsersTranslation.lastname');
      }

      if(errorText != ""){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminUsersTranslation.errorFillIn') + errorText});
      }else{
        this.isCreated = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('adminUsersTranslation.createAccountLoad')});

        var json = {
          "data" : {
            "type" : "users",
            "attributes" : {
              "name": this.name,
              "firstname": this.firstname,
              "lastname": this.lastname,
              "email": this.email,
              "role_slugs": this.rolesForUser
            }
          }
        };

        var self = this;
        this.createAccountForUserRequest(json)
        .then(function (){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.createAccountSuccess')});
          self.isCreated = false;
          self.$emit('close-modal', true);
        })
        .catch(function (error){
          self.handleErrors(error, function(){ self.createNewUser() }, "createAccount");
        });
      }
    },
  }
}
</script>
