<template>
  <div class="lessonEditorElementsCopy">
    <div class="pageModal">
      <BaseModal
        id="copyLessonElementModal"
        v-if="!isDiary"
        :headerText="$t('lessonTranslation.copyElements')"
        :leftButtonText="$t('generalTranslation.copy')"
        :disableButtons="disableCopyBool"
        :disableLeftButton="!selectedTargetData.lessonId"
        :disableNextButton="!selectedTargetData.interventionId"
        :hasSecondPage="true"
        :isFirstPage="isFirstPage"
        :backButtonText="$t('lessonTranslation.backToInterventionSelection')"
        @modal-page-changed="showNewPage"
        @close-modal="closeModal"
      >
        <template v-slot:body>
          <h3 v-if="isFirstPage" class="hCopy">
            {{ 'lessonTranslation.selectIntervention' | translate }}
          </h3>
          <p v-if="isFirstPage" key="firstPage">
            {{ 'interventionTranslation.shownInterventions' | translate }}
          </p>
          <h3 v-else class="hCopy2" key="secondPage">
            {{ 'lessonTranslation.selectLesson' | translate }}
          </h3>
          <InterventionList
            v-if="isFirstPage"
            :interventionListData="interventionListData"
            usedInRoute="copyLesson"
            :disableWhileCopy="disableCopyBool"
            @get-new-list="getNewInterventions"
            @item-selected="setSelectedTarget"
            @close-notification="$emit('close-notification')"
            @reset-page="resetPage"
          ></InterventionList>

          <LessonList
            v-else
            :allLessonsArray="[]"
            :lessonListData="lessonListData"
            :copyBool="true"
            :disableWhileCopy="disableCopyBool"
            :interventionId="selectedTargetData.interventionId"
            :interventionDetails="[]"
            :skillsList="[]"
            @get-new-list="getNewLessons"
            @item-selected="setSelectedTarget"
            @close-notification="$emit('close-notification')"
            class="hCopy3"
          ></LessonList>
        </template>
      </BaseModal>
    </div>

    <div class="pageModal">
      <BaseModal
        v-if="isDiary"
        id="copyDiaryElementModal"
        :headerText="$t('lessonTranslation.copyElements')"
        :leftButtonText="$t('generalTranslation.copy')"
        :disableButtons="disableCopyBool"
        :disableLeftButton="!selectedTargetData.diaryId"
        :hasSecondPage="false"
        @close-modal="closeModal"
      >
        <template v-slot:body>
          <h3 class="hCopy">
            {{ 'lessonTranslation.selectDiary' | translate }}
          </h3>
          <DiaryList
            :diaryListData="diaryListData"
            :studyId="-1"
            :copyBool="true"
            :disableWhileCopy="disableCopyBool"
            @get-new-list="getNewDiaries"
            @item-selected="setSelectedTarget"
            @close-notification="$emit('close-notification')"
            @reset-page="resetPage"
          ></DiaryList>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import httpHelper from '../mixins/httpHelper';
import BaseModal from './BaseModal.vue';
import InterventionList from './InterventionList.vue';
import LessonList from './LessonList.vue';
import DiaryList from './DiaryList.vue';

export default {
  name: 'LessonEditorElementsCopy',

  components: {
    BaseModal,
    InterventionList,
    LessonList,
    DiaryList
  },

  mixins: [httpHelper],

  props: {
    isDiary: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    },

    elementsToCopy: {
      required: true,
      type: Array,
    },
  },

  data: function(){
    return{
      disableCopyBool: false,
      isFirstPage: true,
      interventionListData: "",
      lessonListData: {},
      diaryListData: "",
      selectedTargetData: "",
    }
  },

  computed: {
    ...mapGetters([
      'getNotificationText',
    ]),
  },

  watch:{
    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.disableCopyBool = false;
        if(!this.isDiary && newVal.text != Vue.i18n.translate('generalTranslation.errorSelectIntervention') &&
            newVal.text != Vue.i18n.translate('generalTranslation.errorSelectLesson') ||
            (this.isDiary && newVal.text != Vue.i18n.translate('generalTranslation.errorSelectDiary'))){
          this.closeModal(false);
        }
      }else if(newVal != "" && newVal.type === "success"){
        this.disableCopyBool = false;
      }
    },
  },

  created(){
    if(!this.isDiary){
      this.getNewInterventions({"page": 1, "filterTerm": ""});
    }else{
      this.getNewDiaries({"page": 1, "filterTerm": ""});
    }
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT'
    ]),

    showNewPage: function(next){
      if(next){
        if(!this.selectedTargetData.interventionId){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.errorSelectIntervention')});
        }else{
          this.isFirstPage = false;
          this.getNewLessons({"page": 1});
        }
      }else{
        this.selectedTargetData = "";
        this.isFirstPage = true;
        this.getNewInterventions({"page": 1, "filterTerm": ""});
      }
    },

    getNewInterventions: function(obj){
      var self = this;
      this.requestCollaboratingOrNonCollaboratingInterventions(obj.page, obj.filterTerm + "&role=study.owner,study.collaborator", true, false, -1)
      .then( function(response){
        self.interventionListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewInterventions(obj) }, "");
      });
    },

    getNewLessons: function(obj){
      var self = this;
      this.requestLessonsOfIntervention(this.selectedTargetData.interventionId, obj.page, true, false, -1, -1, false)
      .then( function(response){
        self.lessonListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewLessons(obj) }, "");
      });
    },

    getNewDiaries: function(obj){
      var self = this;
      this.requestCollaboratingDiaries(obj.page, obj.filterTerm + "&role=study.owner,study.collaborator", false)
      .then( function(response){
        self.diaryListData = response;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewDiaries(obj) }, "");
      });
    },

    resetPage: function(){
      if(!this.isDiary){
        this.interventionListData.currentPage = 1;
      }else{
        this.diaryListData.currentPage = 1;
      }
    },

    setSelectedTarget: function(obj){
      if(!this.isDiary && !this.isFirstPage){
        this.selectedTargetData = JSON.parse(JSON.stringify(Object.assign(this.selectedTargetData, obj)));
      }else{
        this.selectedTargetData = obj;
      }
    },

    closeModal: function(done){
      if(done){
        if(this.selectedTargetData.lessonId === ""){
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('generalTranslation.' + (!this.isDiary ? 'errorSelectLesson' : 'errorSelectDiary'))});
        }else{
          this.copyElementsInLesson();
        }
      }else if(!this.disableCopyBool){
        this.$emit('close-modal', { done: false });
      }
    },

    copyElementsInLesson: async function(){
      this.disableCopyBool = true;
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('lessonTranslation.copyLessonElementsLoad')});

      //copy media of elements if elements are copied to different workgroup
      if(this.studyId != this.selectedTargetData.studyId){
        var copyString = JSON.stringify(this.elementsToCopy);
        var part1 = new RegExp(this.$urlUploads);
        var part2 = /\/studies\/[0-9]*\/[a-zA-Z0-9]*\.[a-zA-Z0-9]*/;
        var regEx = new RegExp(part1.source + part2.source, "g");
        var urls = copyString.match(regEx);

        var filesToCopy = [];

        try{
          var media = await this.requestStudyMedia(this.studyId);

          for(var file in urls){
            var filename = urls[file];
            filename = filename.split("").reverse().join("");
            filename = filename.substr(0, filename.indexOf('/'));
            filename = filename.split("").reverse().join("");

            var index = media.findIndex(elem => elem.attributes.url === filename);
            if(index != -1){
              filesToCopy.push(
                {
                  "url": urls[file],
                  "original_name": media[index].attributes.original_name
                }
              )
            }
          }

          if(filesToCopy.length > 0){
            await this.copyMediaInDifferentWorkgroupRequest(this.studyId, this.selectedTargetData.studyId, filesToCopy);
          }

          part1 = new RegExp(this.$urlUploads);
          part2 = /\/studies\/[0-9]*\/([0-9a-z]*)/;
          regEx = new RegExp(part1.source + part2.source, "g");
          copyString = copyString.replace(regEx, this.$urlUploads + "/studies/" + this.selectedTargetData.studyId + "/" + "$1");
          this.elementsToCopy = JSON.parse(copyString);
        }catch(error){
          this.handleErrors(error, function(){ this.copyElementsInLesson() }, "");
        }
      }

      var self = this;
      this.requestLessonElements(this.selectedTargetData.lessonId, true, false)
      .then(function (response){
        self.addElementsToElementsArrOfLesson(self.selectedTargetData.lessonId, response);
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.copyElementsInLesson() }, "");
      });
    },

    addElementsToElementsArrOfLesson: function(newLessonId, elementsOfOtherLesson){
      var allElements = [];
      var pagesInTargetLesson = 0;
      var pagesInCopyElements = 0;

      //if elementscounter is -1 then selected lesson has no elements
      //-> just push selected elements with positions beginning with 1

      //if has elements -> insert selected elements at the end
      //elementsToCopy already changed from ids to pos 1 2 3 ...
      //-> positions beginning with pos + (#elements)
      //also change references, conditions, repetitions
      var numberOfElementsInOther = 0;
      if(elementsOfOtherLesson.length != -1){
        numberOfElementsInOther = elementsOfOtherLesson.length;
        allElements = JSON.parse(JSON.stringify(elementsOfOtherLesson));
        for(var i in allElements){
          if(allElements[i].elementtype === "elements/pages"){
            pagesInTargetLesson++;
          }else if(i === 0){
            pagesInTargetLesson++;
          }
        }
      }

      //change positions, references, conditions and repetitions according to new positions
      for(var el in this.elementsToCopy){
        var element = JSON.parse(JSON.stringify(this.elementsToCopy[el]));

        //position
        element.position = Number(element.position) + numberOfElementsInOther;

        if(element.elementtype === "elements/blockopens"){
          //condition
          if(element.type === "conditional" || element.type === "both"){
            this.changePositionsInCondition(element.condition.ifBlock, numberOfElementsInOther);
          }

          //repetition
          if(element.condition.thenBlock.repeat.question){
            element.condition.thenBlock.repeat.data = Number(element.condition.thenBlock.repeat.data) + numberOfElementsInOther;
          }
        }else if(element.elementtype === "elements/pages"){
          if(element.condition.ifBlock){
            this.changePositionsInCondition(element.condition.ifBlock, numberOfElementsInOther);
          }
          pagesInCopyElements++;
        }else if(element.elementtype === "elements/headlines" || element.elementtype === "elements/texts" ||
            element.elementtype === "elements/media" || element.elementtype === "elements/questions"){
          //references
          for(var translation in element.translations){
            switch(element.elementtype){
              case "elements/headlines":
                element.translations[translation].headline = this.replacePositionsWithNewPositions(
                  element.translations[translation].headline, numberOfElementsInOther);
              break;
              case "elements/texts":
                element.translations[translation].text = this.replacePositionsWithNewPositions(
                  element.translations[translation].text, numberOfElementsInOther);
              break;
              case "elements/media":
                element.translations[translation].subtitle = this.replacePositionsWithNewPositions(
                  element.translations[translation].subtitle, numberOfElementsInOther);
                element.translations[translation].description = this.replacePositionsWithNewPositions(
                  element.translations[translation].description, numberOfElementsInOther);
              break;
              case "elements/questions":
                if(element.questiontype != "QuestionTable"){
                  element.translations[translation].question = this.replacePositionsWithNewPositions(
                    element.translations[translation].question, numberOfElementsInOther);
                }else{
                  for(var q in element.translations[translation].question){
                    element.translations[translation].question[q] = this.replacePositionsWithNewPositions(
                      element.translations[translation].question[q], numberOfElementsInOther);
                  }
                }

                if(element.questiontype === "SingleChoice" || element.questiontype === "MultipleChoice" || element.questiontype === "QuestionTable"){
                  for(var answer in element.translations[translation].answers){
                    element.translations[translation].answers[answer] = this.replacePositionsWithNewPositions(
                      element.translations[translation].answers[answer], numberOfElementsInOther);
                  }
                }
              break;
              default:
              break;
            }
          }
        }

        allElements.push(element);
      }

      this.$emit('close-modal', { done: true, pages: pagesInTargetLesson + pagesInCopyElements,
        selectedTargetData: this.selectedTargetData, newLessonId: newLessonId, allElements: allElements });
    },

    replacePositionsWithNewPositions: function(text, numberOfElementsInOther){
      //first replace gets {{number}} as x -> brackets are replaced in second replace -> only number -> change pos
      text = text.replace(/\{{2}([^}{]+)}{2}/g, function(x){
        var pos = x.replace(/{/g, "").replace(/}/g, "");
        return "{{" + Number(Number(pos) + numberOfElementsInOther) + "}}"
      });
      return text
    },

    changePositionsInCondition: function(condition, numberOfElementsInOther){
      if(condition.nodeType != "leaf"){
        for(var child in condition.children){
          this.changePositionsInCondition(condition.children[child], numberOfElementsInOther);
        }
      }else{
        condition.questionLabel = Number(condition.questionLabel) + numberOfElementsInOther;
      }
    },
  }
}
</script>
