import Vue from 'vue';
import axios from 'axios';
import {mapGetters, mapMutations} from 'vuex';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import permissionHelper from '../mixins/permissionHelper';
import router from '../router';

/*
NOTE! axios delete request can not have a body

post/put/patch requests with body are build like:

axios.post(url,
  {
    "data" : { //data field for API
      ...
    }
  },
  {
    headers: {
      Authorization: "Bearer " + this.getToken
    }
  }
)

-> body in data parameter, headers in config parameter
-> body and headers as separate parameters without data key


delete with body has to be build like:

axios.delete(url,
  {
    data: {
      "data" : { //data field for API
        ...
      }
    },
    headers: {
      Authorization: "Bearer " + this.getToken
    }
  }
)

-> data & headers both in config parameter
-> together in 1 {} !
-> extra data key

*/

export default {
  mixins: [elementJSONToPropsHelper, permissionHelper],

  data () {
    return {
      elementsData: [],
      errorCodes: {
        2: Vue.i18n.translate('httpHelperTranslation.errorCode2'),
        3: Vue.i18n.translate('httpHelperTranslation.errorCode3'),
        4: Vue.i18n.translate('httpHelperTranslation.errorCode4'),
        5: Vue.i18n.translate('httpHelperTranslation.errorCode5'),
        6: Vue.i18n.translate('httpHelperTranslation.errorCode6'),
        7: Vue.i18n.translate('httpHelperTranslation.errorCode7'),
        10: Vue.i18n.translate('httpHelperTranslation.errorCode10'),
        11: Vue.i18n.translate('httpHelperTranslation.errorCode11'),
        12: Vue.i18n.translate('httpHelperTranslation.errorCode12'),
        13: Vue.i18n.translate('httpHelperTranslation.errorCode13'),
        14: Vue.i18n.translate('httpHelperTranslation.errorCode14'),
        20: Vue.i18n.translate('httpHelperTranslation.errorCode20'),
        21: Vue.i18n.translate('httpHelperTranslation.errorCode21'),
        22: Vue.i18n.translate('httpHelperTranslation.errorCode22'),
        23: Vue.i18n.translate('httpHelperTranslation.errorCode23'),
        24: Vue.i18n.translate('httpHelperTranslation.errorCode24'),
        25: Vue.i18n.translate('httpHelperTranslation.errorCode25'),
        30: Vue.i18n.translate('httpHelperTranslation.errorCode30'),
        31: Vue.i18n.translate('httpHelperTranslation.errorCode31'),
        32: Vue.i18n.translate('httpHelperTranslation.errorCode32'),
        40: Vue.i18n.translate('httpHelperTranslation.errorCode40'),
        41: Vue.i18n.translate('httpHelperTranslation.errorCode41'),
        42: Vue.i18n.translate('httpHelperTranslation.errorCode42'),
        82: Vue.i18n.translate('httpHelperTranslation.errorCode82'),
        83: Vue.i18n.translate('httpHelperTranslation.errorCode83'),
        84: Vue.i18n.translate('httpHelperTranslation.errorCode84'),
        85: Vue.i18n.translate('httpHelperTranslation.errorCode85'),
      },
      httpErrorCodes: {
        500: Vue.i18n.translate('httpHelperTranslation.httpErrorCode500'),
        503: Vue.i18n.translate('httpHelperTranslation.httpErrorCode503'),
        504: Vue.i18n.translate('httpHelperTranslation.httpErrorCode504'),
      },
    }
  },

  computed: {
    ...mapGetters([
      'getToken',
      'getUserId',
      'getAppLanguage',
      'getIsEditable',
    ]),
  },

  methods: {
    ...mapMutations([
      'CHANGE_TOKEN',
      'SET_LESSONELEMENTSARR',
      'SET_NOTIFICATIONTEXT',
      'SET_ROLES',
      'SET_AVAILABLELOCALES',
      'SET_USERID',
      'SET_MYROLEFORSTUDY',
      'SET_ROLEIDS',
    ]),

    handleErrors: function(error, func, name){
      var errorText = "";
      if(typeof error.response === "undefined"){
        errorText = error.message;
      }else if(error.response.status.toString().startsWith("4")){
        if(error.response.status.toString() == '401' && error.response.data.errors[0].code != "28"){
          var self = this;
          this.refreshTokenRequest()
          .then(function (){
            func();
          })
          .catch(function (error2){
            if(name === "logout"){
              self.CHANGE_TOKEN("");
            }

            if(typeof error2.response === "undefined"){
              errorText = error2.message;
            }else{
              errorText = self.errorCodes[error2.response.data.errors[0].code];
            }
          });
        }else if(error.response.status.toString() == '401' && error.response.data.errors[0].code === "28"){
          errorText = Vue.i18n.translate('httpHelperTranslation.wrongPassword');
        }else if(error.response.status.toString() == '404' && name === "diaryDetails"){
          errorText = Vue.i18n.translate('diaryTranslation.diaryNotComplete');
        }else if((error.response.status.toString() == '403' || error.response.status.toString() == '404' || error.response.data.errors[0].code === "2" ||
            error.response.data.errors[0].code === "10") && (name === "studyDetails" || name === "diaryDetails")){
          errorText = Vue.i18n.translate('httpHelperTranslation.errorCode2');
          this.SET_MYROLEFORSTUDY("None");
          router.replace('/errorPage');
        }else if(name === "logout"){
          this.CHANGE_TOKEN("");
        }else if(name === "createAccount"){
          if(error.response.status.toString() == '422'){
            errorText = Vue.i18n.translate('httpHelperTranslation.registerErrorEmailInvalid');
          }else if(error.response.status.toString() == '403' || error.response.status.toString() == '409'){
            if(JSON.stringify(error.response).includes("username")){
              errorText = Vue.i18n.translate('httpHelperTranslation.usernameAlreadyExists');
            }else{
              errorText = Vue.i18n.translate('httpHelperTranslation.registerErrorEmailDuplicate');
            }
          }else{
            errorText = this.errorCodes[error.response.data.errors[0].code];
          }
        }else if(name === "submitFile"){
          if(error.response.status.toString() === '422'){
            errorText = Vue.i18n.translate('elementsMediaTranslation.fileNotSupported');
          }else{
            errorText = this.errorCodes[error.response.data.errors[0].code];
          }
        }else if(name === "profile" && error.response.status.toString() === '422'){
          errorText = Vue.i18n.translate('httpHelperTranslation.usernameAlreadyExists');
        }else if(name === "password" && error.response.status.toString() === '422' && JSON.stringify(error.response.data.errors[0].meta).includes("data leak")){
          errorText = Vue.i18n.translate('httpHelperTranslation.passwordDataLeak');
        }else if(name === "config" && error.response.status.toString() == '403' && error.response.data.errors[0].code === "1510"){
          errorText = Vue.i18n.translate('httpHelperTranslation.configDiaryNotIncluded');
        }else if(name != "createAccount"){
          errorText = this.errorCodes[error.response.data.errors[0].code];
        }
      }else{
        if(name === "logout"){
          this.CHANGE_TOKEN("");
        }
        if(this.httpErrorCodes[error.response.status]){
          errorText = this.httpErrorCodes[error.response.status];
        }else{
          errorText = error.response.detail;
        }
      }

      if(errorText === undefined){
        if(this.errorCodes[error.response.data.errors[0].code]){
          errorText = this.errorCodes[error.response.data.errors[0].code];
        }else{
          errorText = error.response.data.errors[0].detail;
        }
      }

      if(errorText != ""){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.error') + errorText});
      }else{
        this.SET_NOTIFICATIONTEXT("");
      }
    },

    handleErrorsNotLoggedIn: function(error, name){
      var errorText = "";
      if(typeof error.response === "undefined"){
        errorText = error.message;
      }else if(error.response.status.toString() == '422' && name === "resendVerification"){
        errorText = Vue.i18n.translate('httpHelperTranslation.registerErrorEmailInvalid');
      }else if(error.response.status.toString() == '422' && name === "resetPW"){
        errorText = Vue.i18n.translate('httpHelperTranslation.errorInvalidEmail');
      }else if(error.response.status.toString() == '429' && name != "resetPW"){
        errorText = Vue.i18n.translate('httpHelperTranslation.tooManyLogins');
      }else if(error.response.status.toString().startsWith("4")){
        if(this.errorCodes[error.response.data.errors[0].code]){
          errorText = this.errorCodes[error.response.data.errors[0].code];
        }else{
          errorText = error.response.data.errors[0].detail;
        }
      }else if(this.httpErrorCodes[error.response.status]){
        errorText = this.httpErrorCodes[error.response.status];
      }else{
        errorText = error.response.detail;
      }
      if(errorText != ""){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.error') + errorText});
      }else{
        this.SET_NOTIFICATIONTEXT("");
      }
    },

    //returns promise
    loginRequest: function(email, password){
      return axios.post(this.$url + '/auth/login',
        {
          "data" : {
            "type" : "users",
            "attributes" : {
              "email": email,
              "password": password
            }
          }
        },
        {
          withCredentials: true
        }
      )
      .then(function (response){
        return response
      });
    },

    refreshTokenRequest: function () {
      return new Promise((resolve, reject) => {

        var self = this;
        axios.post(this.$url + '/auth/refresh',
          {
            "data" : {
            }
          },
          {
            headers: {
              Authorization: "Bearer " + this.getToken
            },
            withCredentials: true
          }
        )
        .then(function (response){
          self.CHANGE_TOKEN(response.data.data.attributes.token);
          resolve();
        })
        .catch(function (error){
          if(error.response && error.response.status.toString() == '400' || error.response.status.toString() == '401' || error.response.status.toString() == '409'){
            self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.error') + Vue.i18n.translate('httpHelperTranslation.errorLoginAgain')});
            self.CHANGE_TOKEN("");
            if(self.$route.name != "login"){
              router.push({ name: 'login'});
            }
          }else{
            self.handleErrorsNotLoggedIn(error, "");
          }
          reject(error);
        });
      })
    },

    //returns promise
    createAccountForUserRequest: function(json){
      return axios.post(this.$url + '/admin/auth/assign/register', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    resendVerificationForUserRequest: function(email){
      var json = {
        "data" : {
          "type" : "users",
          "attributes" : {
            "email": email
          }
        }
      };

      var self = this;
      axios.post(this.$url + '/auth/verify/resend', json)
      .then(function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('adminUsersTranslation.resendVerificationSuccess')});
      })
      .catch(function (error){
        self.handleErrorsNotLoggedIn(error, "resendVerification");
      });
    },

    updatePasswordRequest: function(json){
      var self = this;
      axios.patch(this.$url + '/my/profile/password', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('preferencesTranslation.changePWSuccess')});
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.updatePasswordRequest(json) }, "password");
      });
    },

    //returns promise
    //copyElementsBool true for copyElements
    requestLessonElements: function(lessonId, copyElementsBool, returnErrors){
      var self = this;
      return axios.get(this.$url + '/questionnaires/' + lessonId + '/elements',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var elements = response.data.data.attributes.elements;
        //filter duplicates
        var counter = 1;
        var newElements = [];

        /*
        var filesToCopy = [];
        var translation, filename;
        */
        for(var elem in elements){
          //for moving media including name
          /*
          if(elements[elem].elementtype === "elements/media"){
            for(translation in elements[elem].translations){
              if(elements[elem].translations[translation].uri.startsWith("https://api.esano-trainings.de/uploads/media/")){
                filesToCopy.push(
                  {
                    "url": elements[elem].translations[translation].uri,
                    "original_name": elements[elem].translations[translation].filename
                  }
                );
                filename = elements[elem].translations[translation].uri.split("").reverse().join("");
                filename = filename.substr(0, filename.indexOf('/'));
                filename = filename.split("").reverse().join("");
                console.log("https://api.esano-trainings.de/uploads/studies/312/" + filename.split(".")[0])
                elements[elem].translations[translation].uri = "https://api.esano-trainings.de/uploads/studies/" + studyId + "/" + filename.split(".")[0]
              }
            }
          }else if(elements[elem].elementtype === "elements/texts"){
            for(translation in elements[elem].translations){
              var string = elements[elem].translations[translation].text;
              var regEx = new RegExp(/https:\/\/api\.esano-trainings\.de\/uploads\/media\/\d{10}\.[a-zA-Z0-9]*/      /*,"g");
              var urls = string.match(regEx);
              for(var url in urls){
                filename = urls[url].split("").reverse().join("");
                filename = filename.substr(0, filename.indexOf('/'));
                filename = filename.split("").reverse().join("");
                filename = filename.split(".")[0];
                filesToCopy.push(
                  {
                    "url": urls[url],
                    "original_name": filename
                  }
                );
              }
              if(urls != null){
                var part1 = new RegExp(self.$urlUploads);
                var part2 = /\/media\/([0-9]*)\.[a-zA-Z0-9]*/       /*;
                regEx = new RegExp(part1.source + part2.source, "g");
                string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");
                console.log(string);
                elements[elem].translations[translation].text = string;
              }
            }
          }*/

          if(elements[elem].position === counter){
            counter++;
            newElements.push(elements[elem])
          }
        }

        //for moving media
        //console.log(JSON.stringify(filesToCopy));

        if(!copyElementsBool){
          var result = self.jsonToProperties(newElements, returnErrors);

          if(returnErrors){
            if(self.getIsEditable && result.removedOldConditionalPage > 0){
              self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.removedOldConditionalPage', { counter: result.removedOldConditionalPage, pages: result.pages })});
            }
            return result.elements
          }else{
            return result
          }
        }else{
          return newElements
        }
      });
    },

    //returns promise
    copyMediaInDifferentWorkgroupRequest: function(sourceStudyId, targetStudyId, files){
      var jsonObject =
      {
        "data" : {
          "type" : "studies",
          "attributes" : {
              "study_id": targetStudyId,
              "files": files
          }
        }
      };
      return axios.post(this.$url + '/editor/studies/' + sourceStudyId + "/media/copy", jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    //returns promise
    createLessonElementsRequest: function(lessonId, jsonObject){
      return axios.post(this.$url + '/editor/questionnaires/' + lessonId + '/elements', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then(function (){
        return
      });
    },

    //returns promise
    //studyType workgroup/study/organisationgroup
    //collaborating studies: workgroup is usually used; for copy/activate into study -> study used
    //non collaborating studies always requested with type workgroup
    requestCollaboratingOrNonCollaboratingStudies: function(page, filterTerm, studyType, collaborating, getUnknownPageWithId, oldStudyId){
      var self = this;
      var queryString;
      if(getUnknownPageWithId){
        queryString = "&limit=0";
      }else if(typeof page === "undefined" || page === "" || page <= 0){
        queryString = "&page=" + 1;
      }else{
        queryString = "&page=" + page;
      }

      var url = this.$url + '/editor/studies';
      if(!collaborating){
        url += '/noncollaborating?include=owners&type=workgroup';
      }else{
        url += '?include=roles,owners&type='+ studyType;
      }
      url += filterTerm + queryString;

      return axios.get(url,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage,
          }
        }
      )
      .then(function (response){
        if(response.data.meta && response.data.meta.current_page > response.data.meta.last_page){
          return self.requestCollaboratingOrNonCollaboratingStudies(response.data.meta.last_page, filterTerm, studyType, collaborating, getUnknownPageWithId, oldStudyId);
        }else{
          if(collaborating){
            var rolesList = self.getRolesList(response.data.included);
            self.SET_ROLEIDS(rolesList);
          }

          var studyListData = {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page :  0,
            total: response.data.meta ? response.data.meta.total : 0
          };

          if(getUnknownPageWithId){
            var index = response.data.data.findIndex(elem => Number(elem.id) === oldStudyId);
            var currentPage = (index != -1) ? Math.ceil((index + 1)/self.$perPage) : 1;
            studyListData.currentPage = currentPage;
            studyListData.allPages = Math.ceil(studyListData.total/self.$perPage);

            var begin = self.$perPage * (currentPage - 1);
            var number = self.$perPage * currentPage;
            studyListData.list = response.data.data.slice(begin, number);
          }

          return studyListData
        }
      });
    },

    //returns promise
    //(non)collaborating interventions always requested with type workgroup
    requestCollaboratingOrNonCollaboratingInterventions: function(page, filterTerm, collaborating, getUnknownPageWithId, oldInterventionId){
      var self = this;
      var queryString;
      if(getUnknownPageWithId){
        queryString = "&limit=0";
      }else if(typeof page === "undefined" || page === "" || page <= 0){
        queryString = "&page=" + 1;
      }else{
        queryString = "&page=" + page;
      }
      var url = this.$url + '/editor/interventions';
      if(!collaborating){
        url += '/noncollaborating';
      }
      url += '?include=roles,owners&typeOfParentStudy=workgroup' + filterTerm + queryString

      return axios.get(url,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage,
          }
        }
      )
      .then(function (response){
        if(response.data.meta && response.data.meta.current_page > response.data.meta.last_page){
          return self.requestCollaboratingOrNonCollaboratingInterventions(response.data.meta.last_page, filterTerm, collaborating, getUnknownPageWithId, oldInterventionId);
        }else{
          var rolesList = self.getRolesList(response.data.included);
          self.SET_ROLEIDS(rolesList);

          var interventionListData = {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            total: response.data.meta ? response.data.meta.total : 0,
          };

          if(getUnknownPageWithId){
            var index = response.data.data.findIndex(elem => Number(elem.id) === oldInterventionId);
            var currentPage = (index != -1) ? Math.ceil((index + 1)/self.$perPage) : 1;
            interventionListData.currentPage = currentPage;
            interventionListData.allPages = Math.ceil(interventionListData.total/self.$perPage);

            var begin = self.$perPage * (currentPage - 1);
            var number = self.$perPage * currentPage;
            interventionListData.list = response.data.data.slice(begin, number);
          }

          return interventionListData
        }
      });
    },

    //returns promise
    requestCollaboratingDiaries: function(page, filterTerm, getAll){
      var self = this;
      var queryString;
      if(getAll){
        queryString = "&limit=0";
      }else if(typeof page === "undefined" || page === "" || page <= 0){
        queryString = "&page=" + 1;
      }else{
        queryString = "&page=" + page;
      }

      return axios.get(this.$url + '/editor/diaries?include=roles&typeOfParentStudy=workgroup' + filterTerm + queryString,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage,
          }
        }
      )
      .then(function (response){
        if(response.data.meta && response.data.meta.current_page > response.data.meta.last_page){
          return self.requestCollaboratingDiaries(response.data.meta.last_page, filterTerm, getAll)
        }else{
          var includedRoles = [];
          for(var entry in response.data.data){
            if(response.data.data[entry].relationships && response.data.data[entry].relationships.roles){
              for(var role in response.data.data[entry].relationships.roles.data){
                includedRoles.push(response.data.data[entry].relationships.roles.data[role]);
              }
            }
          }
          var rolesList = self.getRolesList(includedRoles);
          self.SET_ROLEIDS(rolesList);

          return {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0
          }
        }
      });
    },

    //returns promise
    requestInterventionsOfStudy: function(studyId, page, filterTerm, getAll, oldIntId, showLastPage){
      var queryString;
      if(getAll){
        queryString = "&limit=0";
      }else if(typeof page === "undefined" || page === "" || page <= 0){
        queryString = "&page=" + 1;
      }else{
        queryString = "&page=" + page;
      }
      var url = this.$url + '/studies/' + studyId + '/interventions?' + queryString + filterTerm;
      var self = this;
      return axios.get(url,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage,
          }
        }
      )
      .then(function (response){
        if(response.data.meta && (response.data.meta.current_page > response.data.meta.last_page ||
            showLastPage && response.data.meta.current_page != response.data.meta.last_page)){
          return self.requestInterventionsOfStudy(studyId, response.data.meta.last_page, filterTerm, getAll, oldIntId, showLastPage);
        }else{
          var interventionListData = {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            total: response.data.meta ? response.data.meta.total : 0,
          };

          if(getAll){
            var index = interventionListData.list.findIndex(elem => Number(elem.id) === oldIntId);
            if(index != -1){
              interventionListData.currentPage = Math.ceil((index + 1)/self.$perPage);
            }
            interventionListData.allPages = Math.ceil(interventionListData.total/self.$perPage);
            var begin = self.$perPage * (interventionListData.currentPage - 1);
            var number = self.$perPage * interventionListData.currentPage;
            interventionListData.list = interventionListData.list.slice(begin, number);
          }

          return interventionListData
        }
      });
    },

    //returns promise
    requestDiariesOfStudy: function(studyId, page, filterTerm, oldDiaryId, showLastPage){
      //page=0 represents limit=0 and no page set
      var queryString;
      if(page === 0){
        queryString = "limit=0";
      }else if(typeof page === "undefined" || page === "" || page < 0){
        queryString = "page=" + 1;
      }else{
        queryString = "page=" + page;
      }
      var url = this.$url + '/studies/' + studyId + '/diaries?' + queryString + filterTerm;

      var self = this;
      return axios.get(url,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage,
          }
        }
      )
      .then(function (response){
        if(response.data.meta && (response.data.meta.current_page > response.data.meta.last_page ||
          showLastPage && response.data.meta.current_page != response.data.meta.last_page)){
          return self.requestDiariesOfStudy(studyId, response.data.meta.last_page, filterTerm, oldDiaryId, showLastPage)
        }else{
          var diaryListData = {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            total: response.data.meta ? response.data.meta.total : 0,
          };

          if(page === 0 && oldDiaryId != -1){ //limit=0 & specific page should be shown, page number is not known, only diaryid
            var index = diaryListData.list.findIndex(elem => Number(elem.id) === oldDiaryId);
            if(index != -1){
              diaryListData.currentPage = Math.ceil((index + 1)/self.$perPage);
            }
            diaryListData.allPages = Math.ceil(diaryListData.total/self.$perPage);
            var begin = self.$perPage * (diaryListData.currentPage - 1);
            var number = self.$perPage * diaryListData.currentPage;
            diaryListData.list = diaryListData.list.slice(begin, number);
          }

          return diaryListData
        }
      });
    },

    requestResetPasswordInstructions: function(email){
      var self = this;
      axios.post(this.$url + '/auth/password/reset/instructions',
        {
          "data" : {
            "type" : "users",
            "attributes" : {
              "email" : email
            }
          }
        }
      )
      .then(function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('resetPasswordTranslation.resetPasswordSuccess') + email + ". " +
          Vue.i18n.translate('resetPasswordTranslation.resetPasswordSuccessHint')
        });
      })
      .catch(function (error){
        //should not return 400 if no account for this email
        if(error.response && error.response.status.toString() == '400' && error.response.data.errors[0].code == '4'){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('resetPasswordTranslation.resetPasswordSuccess') + email + ". " +
            Vue.i18n.translate('resetPasswordTranslation.resetPasswordSuccessHint')
          });
        }else{
          self.handleErrorsNotLoggedIn(error, "resetPW");
        }
      });
    },

    //returns promise
    //study details including all translations
    requestStudyDetails: function(studyId){
      return axios.get(this.$url + '/editor/studies/' + studyId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data.attributes
      });
    },

    //returns promise
    //intervention details including all translations
    requestInterventionDetails: function(interventionId){
      return axios.get(this.$url + '/editor/interventions/' + interventionId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data.attributes
      });
    },

    //returns promise
    requestInterventionTranslationStatus: function(interventionId){
      return axios.get(this.$url + '/interventions/' + interventionId + '/translation/status',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data.attributes.translation_status
      });
    },

    //returns promise
    requestLessonsOfIntervention: async function(interventionId, page, userCanEdit, getSpecificPage, oldLessonId, copyLessonId, includeTranslationStatus){
      var self = this;
      //page=0 represents limit=0 and no page set
      var queryString;
      if(page === 0){
        queryString = "&limit=0";
      }else if(typeof page === "undefined" || page === "" || page < 0){
        queryString = "&page=" + 1;
      }else{
        //queryString = "&page=" + page + "&limit=3";
        queryString = "&page=" + page;
      }
      queryString += includeTranslationStatus ? "&include=translation_status" : "";
      return axios.get(this.$url + '/interventions/' + interventionId + '/questionnaires?sort=position' + queryString,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage
          }
        }
      )
      .then(async function (response){
        if(response.data.meta && response.data.data.length > 0 && response.data.meta.current_page > response.data.meta.last_page){
          return self.requestLessonsOfIntervention(interventionId, response.data.meta.last_page, userCanEdit, getSpecificPage, oldLessonId, copyLessonId, includeTranslationStatus);
        }else{
          if(userCanEdit){
            let lessonsWithNewPos = await self.checkAndUpdateLessonPositions(response.data.data, page);
            if(lessonsWithNewPos.updated){
              response.data.data = lessonsWithNewPos.lessons;
            }
          }

          var lessonListData = { //specific page with 15 elements
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            allLessons: JSON.parse(JSON.stringify(response.data.data)),
            total: response.data.meta ? response.data.meta.total : 0
          };

          if(page === 0){ //limit=0
            if(getSpecificPage || copyLessonId != ""){
              var index = lessonListData.list.findIndex(elem => Number(elem.id) === oldLessonId || Number(elem.id) === copyLessonId);
              if(index != -1){
                lessonListData.currentPage = Math.ceil((index + 1)/self.$perPage);
              }
            }

            lessonListData.allPages = Math.ceil(lessonListData.total /self.$perPage);
            var begin = self.$perPage * (lessonListData.currentPage - 1);
            var number = self.$perPage * lessonListData.currentPage;
            lessonListData.list = lessonListData.list.slice(begin, number);
          }

          return lessonListData
        }
      });
    },

    checkAndUpdateLessonPositions: async function(lessons, page){
      var updatedPositions = [];
      var addPreviousPage = 0;
      if(page > 1){
        addPreviousPage = 15 * (page - 1);
      }
      for(var lesson in lessons){
        if(lessons[lesson].attributes.position != Number(lesson) + 1 + addPreviousPage){
          lessons[lesson].attributes.position = Number(lesson) + 1 + addPreviousPage;
          updatedPositions.push(
            {
              questionnaire_id: lessons[lesson].id,
              position: Number(lesson) + 1 + addPreviousPage
            }
          );
        }
      }
      if(updatedPositions.length > 0){
        await this.updateLessonPositionsInInterventionRequest(updatedPositions);
      }
      return {
        updated: updatedPositions.length > 0,
        lessons: lessons
      }
    },

    //returns promise
    requestDiaryUsedInLessonsOfStudy: function(diaryId, studyId){
      return axios.get(this.$url + '/studies/' + studyId + '/questionnaires?limit=0&unlockDiaries=' + diaryId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data;
      });
    },

    //returns promise
    requestDiaryUsedInInterventionsOfStudy: function(diaryId, studyId){
      return axios.get(this.$url + '/studies/' + studyId + '/interventions?limit=0',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data.filter(intervention => intervention.attributes.unlock_diaries != null && intervention.attributes.unlock_diaries.includes(diaryId));
      });
    },

    //returns promise
    createLessonForInterventionRequest: function(interventionId, jsonObject){
      return axios.post(this.$url + '/editor/interventions/' + interventionId + '/questionnaires', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newLessonId = response.headers.location.split("").reverse().join("");
        newLessonId = newLessonId.substr(0, newLessonId.indexOf('/'));
        newLessonId = newLessonId.split("").reverse().join("");
        return Number(newLessonId)
      });
    },

    //returns promise
    copyLessonRequest: function(lessonId, jsonObject, sameWorkgroup){
      var url = sameWorkgroup ? '/in/workgroup/copy' : '/to/workgroup/copy';

      return axios.post(this.$url + '/editor/questionnaires/' + lessonId + url, jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newLessonId = response.headers.location.split("").reverse().join("");
        newLessonId = newLessonId.substr(0, newLessonId.indexOf('/'));
        newLessonId = newLessonId.split("").reverse().join("");
        return Number(newLessonId)
      });
    },

    //returns promise
    deleteLessonRequest: function(lessonId){
      return axios.delete(this.$url + '/editor/questionnaires/' + lessonId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    updateInterventionConfigAfterDeleteLessonRequest: function(interventionId, jsonObject){
      return axios.patch(this.$url + '/editor/interventions/' + interventionId, jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //retruns promise
    //lesson details including all translations
    requestLessonDetails: function(lessonId){
      return axios.get(this.$url + '/editor/questionnaires/' + lessonId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data.attributes
      })
    },

    //returns promise - not used
    activateInterventionRequest: function(interventionId){
      return axios.patch(this.$url + '/editor/interventions/' + interventionId + '/activate',
      {
        "data" : {
        }
      },
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    requestSkillsOfIntervention: function(interventionId, page, getAll){
      var self = this;
      var queryString;
      if(getAll){
        queryString = "?limit=0";
      }else if(typeof page === "undefined" || page === "" || page <= 0){
        queryString = "?page=" + 1;
      }else{
        queryString = "?page=" + page;
      }
      return axios.get(this.$url + '/editor/interventions/' + interventionId + '/skills' + queryString,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage
          }
        }
      )
      .then(function (response){
        if(response.data.meta && response.data.meta.current_page > response.data.meta.last_page){
          return self.requestSkillsOfIntervention(interventionId, response.data.meta.last_page, getAll);
        }else{
          return {
            list: response.data.data,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0,
            total: response.data.meta ? response.data.meta.total : 0,
          };
        }
      })
    },

    //returns promise
    createSkillForInterventionRequest: function(interventionId, jsonObject){
      return axios.post(this.$url + '/editor/interventions/' + interventionId + '/skills', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newSkillId = response.headers.location.split("").reverse().join("");
        newSkillId = newSkillId.substr(0, newSkillId.indexOf('/'));
        newSkillId = newSkillId.split("").reverse().join("");
        return Number(newSkillId)
      });
    },

    //returns promise
    deleteSkillRequest: function(skillId){
      return axios.delete(this.$url + '/editor/skills/' + skillId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //retruns promise
    //lesson details including all translations
    requestSkillDetails: function(skillId){
      return axios.get(this.$url + '/editor/skills/' + skillId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        return response.data.data.attributes
      })
    },

    //returns promise
    updateSkillDetailsRequest: function(details, skillId){
      var json = {
        "data" : {
          "type" : "skills",
          "attributes" : details
        }
      };
      return axios.patch(this.$url + '/editor/skills/' + skillId, json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    //returns promise
    //copyElementsBool true for copyElements
    requestSkillElements: function(skillId, copyElementsBool){
      var self = this;
      return axios.get(this.$url + '/editor/skills/' + skillId + '/elements',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var elements = response.data.data.attributes.elements;
        //filter duplicates
        var counter = 1;
        var newElements = [];

        for(var elem in elements){
          if(elements[elem].position === counter){
            counter++;
            newElements.push(elements[elem])
          }
        }

        if(!copyElementsBool){
          newElements = self.jsonToProperties(newElements, false);
          return newElements
        }else{
          response.data.data.attributes.elements = newElements;
          return response
        }
      });
    },

    //returns promise
    createSkillElementsRequest: function(skillId, jsonObject){
      return axios.post(this.$url + '/editor/skills/' + skillId + '/elements', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then(function (){
        return
      });
    },

    //not used
    activateDiaryRequest: function(diaryId){
      var self = this;
      axios.patch(this.$url + '/editor/diaries/' + diaryId + '/activate',
      {
        "data" : {
        }
      },
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.publishDiarySuccess')});
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.activateDiaryRequest(diaryId) }, "");
      });
    },

    //returns promise
    createStudyRequest: function(name){
      var jsonObject =
      {
        "data" : {
          "type" : "studies",
          "attributes" : {
            "name" : name,
            "type" : 'workgroup'
          }
        }
      };

      return axios.post(this.$url + '/studies', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newStudyId = response.headers.location.split("").reverse().join("");
        newStudyId = newStudyId.substr(0, newStudyId.indexOf('/'));
        return newStudyId.split("").reverse().join("");
      });
    },

    //returns promise
    createInterventionRequest: function(studyId, name){
      var jsonObject =
      {
        "data" : {
          "type" : "interventions",
          "attributes" : {
            "name" : name,
            "type" : "intervention",
            "study_id": studyId,
            "locales": ["de", "en"],
            "intervention_type": 'unaccompanied',
          }
        }
      };

      if(this.$enabledFeatures.includes('buddy')){
        jsonObject.data.attributes.buddy_support = false;
      }

      return axios.post(this.$url + '/editor/interventions', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newInterventionId = response.headers.location.split("").reverse().join("");
        newInterventionId = newInterventionId.substr(0, newInterventionId.indexOf('/'));
        return newInterventionId.split("").reverse().join("")
      });
    },

    //returns promise
    createDiaryRequest: async function(studyId, name){
      var jsonObject =
      {
        "data" : {
          "type" : "diary",
          "attributes" : {
            "name" : name,
            "study_id": studyId,
            "locales": ["de", "en"],
          }
        }
      };

      var self = this;
      return axios.post(this.$url + '/editor/diaries', jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        var newDiaryId = response.headers.location.split("").reverse().join("");
        newDiaryId = newDiaryId.substr(0, newDiaryId.indexOf('/'));
        newDiaryId = newDiaryId.split("").reverse().join("");
        await self.createLessonForDiaryRequest(newDiaryId);
        return newDiaryId
      });
    },

      //returns promise
    createLessonForDiaryRequest: async function(newDiaryId){
      var jsonObject =
      {
        "data" : {
          "type" : "questionnaires",
          "attributes" : {
            "name" : "diary",
            "position": 1,
            "locales": []
          }
        }
      };
      return axios.post(this.$url + '/editor/diaries/' + newDiaryId + "/questionnaires", jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newLessonId = response.headers.location.split("").reverse().join("");
        newLessonId = newLessonId.substr(0, newLessonId.indexOf('/'));
        newLessonId = newLessonId.split("").reverse().join("");
        return Number(newLessonId)
      });
    },

    //returns promise
    requestDiaryAndLesson: async function(diaryId, getElements){
      var self = this;
      return axios.get(this.$url + '/editor/diaries/' + diaryId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        if(getElements){
          let elements = await self.requestLessonElementsOfDiary(response.data.data.attributes.questionnaire_id);
          var result = self.jsonToProperties(elements, true);
          self.SET_LESSONELEMENTSARR(result.elements);

          if(self.getIsEditable && result.removedOldConditionalPage > 0){
            self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('lessonTranslation.removedOldConditionalPage', { counter: result.removedOldConditionalPage, pages: result.pages })});
          }
        }
        return response.data.data.attributes
      });
    },

    //returns promise
    requestLessonElementsOfDiary: async function(lessonId){
      return axios.get(this.$url + '/questionnaires/' + lessonId + '/elements',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        var elements = response.data.data.attributes.elements;
        //filter duplicates
        var counter = 1;
        var newElements = [];
        for(var elem in elements){
          if(elements[elem].position === counter){
            counter++;
            newElements.push(elements[elem])
          }
        }
        return newElements
      });
    },

    //returns promise
    //default_configuration: original & copy get updated
    copyInterventionInStudyRequest: function(interventionId, studyId, defaultConfig, isTest){
      var json =
      {
        "data" : {
          "type" : "interventions",
          "attributes" : {
            "study_id": studyId,
            "default_configuration": defaultConfig,
            "is_test": isTest,
          }
        }
      };
      return axios.post(this.$url + '/editor/interventions/' + interventionId + "/from/workgroup/copy", json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    //returns promise
    copyInterventionInWorkgroupRequest: function(interventionId, studyId, sameWorkgroup){
      var url = sameWorkgroup ? "/in/workgroup/copy" : "/to/workgroup/copy";

      var json =
      {
        "data" : {
          "type" : "interventions",
          "attributes" : {
            "study_id": studyId
          }
        }
      };
      return axios.post(this.$url + '/editor/interventions/' + interventionId + url, json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    //returns promise
    copyDiaryRequest: function(diaryId, studyId, sameWorkgroup){
      var url = sameWorkgroup ? "/in/workgroup/copy" : "/to/workgroup/copy";

      var json =
      {
        "data" : {
          "type" : "diary",
          "attributes" : {
            "study_id": studyId
          }
        }
      };
      return axios.post(this.$url + '/editor/diaries/' + diaryId + url, json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    //returns promise
    copyStudyRequest: function(studyId, name){
      var jsonObject =
      {
        "data" : {
          "type" : "studies",
          "attributes" : {
            "name" : name
          }
        }
      };

      return axios.post(this.$url + '/editor/studies/' + studyId + "/copy", jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    //returns promise
    deleteInterventionRequest: function(interventionId){
      return axios.delete(this.$url + '/editor/interventions/' + interventionId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    deleteDiaryRequest: function(diaryId){
      return axios.delete(this.$url + '/editor/diaries/' + diaryId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    deleteStudyRequest: function(studyId){
      return axios.delete(this.$url + '/editor/studies/' + studyId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    exportInterventionRequest: async function(interventionId){
      var self = this;
      return axios.get(this.$url + '/editor/interventions/' + interventionId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        let lessons = await self.exportLessonsRequest(interventionId);
        var exportedData = {
          "interventionDetails": response.data.data,
          "lessons": lessons
        };

        return exportedData;
      });
    },

    //returns promise
    exportLessonsRequest: async function(interventionId){
      var self = this;
      return axios.get(this.$url + '/editor/interventions/' + interventionId + '/questionnaires?limit=0',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        var arrLessons = [];
        for(var lesson in response.data.data){
          // console.log(response.data.data[lesson].attributes.name)
          let elements = await self.exportLessonElementsRequest(response.data.data[lesson].id);

          var checkElementsString = response.data.data[lesson].attributes.position + ": " + elements.length + " ";
          if(elements.length > 0){
            checkElementsString += elements[elements.length - 1].position + " -> " + (elements.length === elements[elements.length - 1].position);
          }else{
            checkElementsString += "0 -> true";
          }
          console.log(checkElementsString);

          //filter duplicates
          var counter = 1;
          var newElements = [];
          for(var elem in elements){
            if(elements[elem].position === counter){
              counter++;
              newElements.push(elements[elem])
            }
          }

          var blockCounter = 0;
          for(var element in newElements){
            if(newElements[element].elementtype === "elements/blockopens"){
              blockCounter++;
            }else if(newElements[element].elementtype === "elements/blockcloses"){
              blockCounter--;
            }else if(newElements[element].elementtype === "elements/pages" && blockCounter > 0){
              console.log("conditional page in lesson " + response.data.data[lesson].id)
            }
          }

          arrLessons.push(
            {
              "id": response.data.data[lesson].id,
              "details": response.data.data[lesson].attributes,
              "elements": newElements
            }
          );

        }

        return arrLessons
      });
    },

    //returns promise
    exportLessonElementsRequest: async function(id){
      return axios.get(this.$url + '/questionnaires/' + id + '/elements',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var elements = response.data.data.attributes.elements;
        //filter duplicates
        var counter = 1;
        var newElements = [];
        //var filesToCopy = [];
        //var translation, filename;
        //var blockCounter = 0;
        // var lessonHasSliderError = 0;

        for(var elem in elements){
          /*
          var studyId = 316;

          if(elements[elem].elementtype === "elements/media"){
            for(translation in elements[elem].translations){
              if(elements[elem].translations[translation].uri.startsWith("https://api.esano-trainings.de/uploads/media/")){
                filesToCopy.push(
                  {
                    "url": elements[elem].translations[translation].uri,
                    "original_name": elements[elem].translations[translation].filename
                  }
                );
                filename = elements[elem].translations[translation].uri.split("").reverse().join("");
                filename = filename.substr(0, filename.indexOf('/'));
                filename = filename.split("").reverse().join("");
                //console.log("lessonid: " + id);
                //console.log("https://api.esano-trainings.de/uploads/studies/" + studyId + "/" + filename.split(".")[0])
                elements[elem].translations[translation].uri = "https://api.esano-trainings.de/uploads/studies/" + studyId + "/" + filename.split(".")[0]
              }
            }
          }
          */

          // if(elements[elem].elementtype === "elements/blockopens"){
          //   blockCounter++;
          // }else if(elements[elem].elementtype === "elements/blockcloses"){
          //   blockCounter--;
          // }else if(elements[elem].elementtype === "elements/pages" && blockCounter > 0){
          //   console.log("lessonId: " + id + " pos:" + elements[elem].position);
          // }

          // if(elements[elem].elementtype === "elements/questions" && elements[elem].questiontype === "Slider"){
          //   for(var t in elements[elem].translations){
          //     if(elements[elem].translations[t].answers.length === 0){
          //       lessonHasSliderError++;
          //     }else{
          //       console.log(elements[elem].translations[t].answers[0].label)
          //       console.log(elements[elem].translations[t].answers[1].label)
          //     }
          //   }
          // }

          if(elements[elem].position === counter){
            counter++;
            newElements.push(elements[elem])
          }
        }

        // if(lessonHasSliderError > 0){
        //   console.log("lessonId: " + id + " : " + lessonHasSliderError)
        // }

        //console.log(JSON.stringify(filesToCopy))

        return newElements
      });
    },

    //returns promise
    exportDiaryRequest: async function(diaryId){
      var self = this;
      return axios.get(this.$url + '/editor/diaries/' + diaryId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        let elements = await self.exportLessonElementsRequest(response.data.data.attributes.questionnaire_id);

        //filter duplicates
        var counter = 1;
        var newElements = [];
        for(var elem in elements){
          if(elements[elem].position === counter){
            counter++;
            newElements.push(elements[elem])
          }
        }

        var exportedData = {
          "diaryDetails": response.data.data,
          "elements": newElements
        };

        return exportedData;
      });
    },

    importInterventionRequest: function(jsonObject, studyId){
      var json = {
        "data" : {
          "type" : "interventions",
          "attributes" : jsonObject.interventionDetails.attributes
        }
      };
      json.data.attributes.study_id = studyId;
      json.data.attributes.is_active = false;
      json.data.attributes.unlock_diaries = [];

      if(!json.data.attributes.picture || json.data.attributes.picture.startsWith("https://") || json.data.attributes.picture.startsWith("/var")){
        json.data.attributes.picture = "";
      }

      //export from old cms has no translations array -> only exported in de
      if(!json.data.attributes.translations || json.data.attributes.translations.length === 0){
        json.data.attributes.translations = [
          {
            "locale": "de",
            "title": json.data.attributes.title,
            "description": json.data.attributes.description,
          }
        ];
      }

      if(!json.data.attributes.intervention_type || json.data.attributes.intervention_type === "accompanied_and_unaccompanied"){
        json.data.attributes.intervention_type = "unaccompanied";
      }

      if(!this.$enabledFeatures.includes('buddy') && (json.data.attributes.buddy_support || json.data.attributes.buddy_support === 0)){
        delete json.data.attributes['buddy_support'];
      }else if(this.$enabledFeatures.includes('buddy')){
        json.data.attributes.buddy_support = false;
      }

      var self = this;
      axios.post(this.$url + '/editor/interventions', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newInterventionId = response.headers.location.split("").reverse().join("");
        newInterventionId = newInterventionId.substr(0, newInterventionId.indexOf('/'));
        newInterventionId = newInterventionId.split("").reverse().join("");

        if(jsonObject.lessons.length > 0){
          var string = JSON.stringify(jsonObject);
          var regEx;

          //replace all old links
          for(var oldUrl in self.$urlUploadsOld){
            if(self.$urlUploadsOld[oldUrl] != self.$urlUploads){
              regEx = new RegExp(self.$urlUploadsOld[oldUrl], "g");
              string = string.replace(regEx, self.$urlUploads);
            }
          }

          //replace all links $urlUploads/studies/any-study-id/name.extension with current study id -> $urlUploads/studies/studyid/name.extension
          var part1 = new RegExp(self.$urlUploads);
          var part2 = /\/studies\/[0-9]*\/([0-9a-z]*\.[a-zA-Z0-9]*)/;
          regEx = new RegExp(part1.source + part2.source, "g");
          string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");

          //replace all links $urlUploads/studies/any-study-id/name with current study id -> $urlUploads/studies/studyid/name
          part1 = new RegExp(self.$urlUploads);
          part2 = /\/studies\/[0-9]*\/([0-9a-z]*)/;
          regEx = new RegExp(part1.source + part2.source, "g");
          string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");

          //replace all old links: $urlUploads/media/name.extension with $urlUploads/studies/studyid/name.extension
          part1 = new RegExp(self.$urlUploads);
          part2 = /\/media\/([0-9]*\.[a-zA-Z0-9]*)/;
          regEx = new RegExp(part1.source + part2.source, "g");
          string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");

          //replace all old links: $urlUploads/studies/name.extension with $urlUploads/studies/studyid/name.extension
          part1 = new RegExp(self.$urlUploads);
          part2 = /\/studies\/([0-9]*\.[a-zA-Z0-9]*)/;
          regEx = new RegExp(part1.source + part2.source, "g");
          string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");

          jsonObject = JSON.parse(string);
          var config = jsonObject.interventionDetails.attributes.default_configuration.questionnaire_configuration;

          self.importLessonsRequest(studyId, newInterventionId, jsonObject.lessons, [], {}, config);
        }else{
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.importInterventionSuccess')});
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.importInterventionRequest(jsonObject, studyId) }, "");
      });
    },

    importLessonsRequest: function(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config){
      var details = {
        "data" : {
          "type" : "questionnaires",
          "attributes" : lessons[0].details
        }
      };

      //export from old cms has no locales array
      if(!details.data.attributes.locales){
        details.data.attributes.locales = ["de", "en"];
      }

      if(!details.data.attributes.page_color){
        details.data.attributes.page_color = "";
      }

      if(details.data.attributes.page_color.startsWith("https://")){
        var color = details.data.attributes.page_color;
        color = color.split("").reverse().join("");
        color = color.substr(0, color.indexOf('/'));
        color = color.split("").reverse().join("");
        color = color.split(".")[0];
        details.data.attributes.page_color = color;
      }

      details.data.attributes.unlock_diaries = [];

      //export from old cms has no translations array -> only exported in de
      if((!details.data.attributes.translations || details.data.attributes.translations.length === 0) &&
          typeof details.data.attributes.title != "undefined" && typeof details.data.attributes.description != "undefined"){
        details.data.attributes.translations = [
          {
            "locale": "de",
            "title": details.data.attributes.title,
            "description": details.data.attributes.description
          }
        ];
      }

      var newTranslations = [];
      //check if title or description null
      for(var translation in details.data.attributes.translations){
        if(details.data.attributes.translations[translation].title){
          newTranslations.push(JSON.parse(JSON.stringify(details.data.attributes.translations[translation])));
        }
      }
      details.data.attributes.translations = newTranslations;

      var self = this;
      axios.post(this.$url + '/editor/interventions/' + interventionId + '/questionnaires', details,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var newLessonId = response.headers.location.split("").reverse().join("");
        newLessonId = newLessonId.substr(0, newLessonId.indexOf('/'));
        newLessonId = newLessonId.split("").reverse().join("");

        lessonIds.push(Number(newLessonId));
        oldNewLessonIds[lessons[0].id] = Number(newLessonId);
        self.importLessonElementsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config);
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.importLessonsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config) }, "");
      });
    },

    importLessonElementsRequest: function(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config){
      for(var elem in lessons[0].elements){
        if(lessons[0].elements[elem].elementtype === "elements/headlines" && typeof lessons[0].elements[elem].color === "undefined"){
          lessons[0].elements[elem].color = "#000000";
        }else if(lessons[0].elements[elem].elementtype === "elements/pages" && !lessons[0].elements[elem].color){
          lessons[0].elements[elem].color = "";
        }else if(lessons[0].elements[elem].elementtype === "elements/questions" && lessons[0].elements[elem].questiontype === "Slider" &&
            typeof lessons[0].elements[elem].values.start === "undefined"){
          lessons[0].elements[elem].values.start = lessons[0].elements[elem].values.min;
        }
      }

      var elements = {
        "data" : {
          "type" : "elements/elements",
          "attributes" :  {
            "elements" : lessons[0].elements
          }
        }
      };

      var self = this;
      axios.post(this.$url + '/editor/questionnaires/' + lessonIds[lessonIds.length - 1] + '/elements', elements,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then(function (){
        lessons.shift();
        if(lessons.length > 0){
          self.importLessonsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config);
        }else{
          // custom order is in order of imported lessons after import (so in order same order as lessons were created),
          // lessons are shown in order of position in lesson overview & configuration
          // only when publishing intervention the custom order is sorted in the order of the lesson positions
          var updateIntDetailsData = {
            "data" : {
              "type" : "interventions",
              "attributes" : {
                "default_configuration": {
                  "questionnaire_configuration": [],
                  "custom_order": lessonIds
                }
              }
            }
          }

          var lesson;
          if(oldNewLessonIds[undefined] != undefined){ //old version, ids of lessons were not exported, config can not be updated accordingly, set after_previous & always
            for(lesson in lessonIds){
              var unlockType = "after_previous";
              if(updateIntDetailsData.data.attributes.default_configuration.questionnaire_configuration.length === 0){
                unlockType = "always";
              }

              updateIntDetailsData.data.attributes.default_configuration.questionnaire_configuration.push(
                {
                  id: Number(lessonIds[lesson]),
                  unlock_type: unlockType,
                  unlock_days_after_start: null,
                  feedback_required: false
                }
              );
            }
          }else{ //update old config
            for(lesson in config){
              //replace old id with new one
              config[lesson].id = oldNewLessonIds[config[lesson].id];
              //for conditions replace questionnaire_id (only for lessons not diaries)
              if(config[lesson].unlock_type === "conditional"){
                var newConditions = [];
                for(var condition in config[lesson].condition){
                  if(config[lesson].condition[condition].diary_id === null){ //update lesson condition
                    config[lesson].condition[condition].questionnaire_id = oldNewLessonIds[config[lesson].condition[condition].questionnaire_id];
                    newConditions.push(config[lesson].condition[condition]);
                  }
                  //else: remove diary condition (diary is not imported with intervention)
                }

                //all conditions were for diaries -> all removed -> set unlock type to manually
                if(newConditions.length === 0){
                  config[lesson].unlock_type = "manually";
                  config[lesson].condition = null;
                  //also set intervention type to accompanied in this case
                  updateIntDetailsData.data.attributes.intervention_type = "accompanied";
                }else{ //conditions for diaries removed, some are for lessons, unlock type can stay conditional
                  config[lesson].condition = newConditions;
                }
              }
            }
          }
          updateIntDetailsData.data.attributes.default_configuration.questionnaire_configuration = config;

          self.updateInterventionDetailsAfterImportInterventionRequest(interventionId, updateIntDetailsData);
        }
      }).catch(function (error){
        self.handleErrors(error, function(){ self.importLessonElementsRequest(studyId, interventionId, lessons, lessonIds, oldNewLessonIds, config) }, "");
      });
    },

    updateInterventionDetailsAfterImportInterventionRequest: function(interventionId, updateIntDetailsData){
      var self = this;
      axios.patch(this.$url + '/editor/interventions/' + interventionId, updateIntDetailsData,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.importInterventionSuccess')});
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.updateInterventionDetailsAfterImportInterventionRequest(interventionId, updateIntDetailsData) }, "");
      });
    },

    importDiaryRequest: async function(jsonObject, studyId){
      var json = {
        "data" : {
          "type" : "diary",
          "attributes" : jsonObject.diaryDetails.attributes
        }
      };
      json.data.attributes.study_id = studyId;
      json.data.attributes.is_active = false;

      if(!json.data.attributes.picture || json.data.attributes.picture.startsWith("https://") || json.data.attributes.picture.startsWith("/var")){
        json.data.attributes.picture = "";
      }

      var self = this;
      axios.post(this.$url + '/editor/diaries', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (response){
        var newDiaryId = response.headers.location.split("").reverse().join("");
        newDiaryId = newDiaryId.substr(0, newDiaryId.indexOf('/'));
        newDiaryId = newDiaryId.split("").reverse().join("");

        //create lesson for diary
        let newDiaryLessonId = await self.createLessonForDiaryRequest(newDiaryId);

        //create lesson elements for diary
        var string = JSON.stringify(jsonObject.elements);
        var regEx;

        //replace all old links
        for(var oldUrl in self.$urlUploadsOld){
          if(self.$urlUploadsOld[oldUrl] != self.$urlUploads){
            regEx = new RegExp(self.$urlUploadsOld[oldUrl], "g");
            string = string.replace(regEx, self.$urlUploads);
          }
        }

        //replace all links $urlUploads/studies/any-study-id/name.extension with current study id -> $urlUploads/studies/studyid/name.extension
        var part1 = new RegExp(self.$urlUploads);
        var part2 = /\/studies\/[0-9]*\/([0-9a-z]*\.[a-zA-Z0-9]*)/;
        regEx = new RegExp(part1.source + part2.source, "g");
        string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");

        //replace all links $urlUploads/studies/any-study-id/name with current study id -> $urlUploads/studies/studyid/name
        part1 = new RegExp(self.$urlUploads);
        part2 = /\/studies\/[0-9]*\/([0-9a-z]*)/;
        regEx = new RegExp(part1.source + part2.source, "g");
        string = string.replace(regEx, self.$urlUploads + "/studies/" + studyId + "/" + "$1");

        var diaryElements = JSON.parse(string);

        for(var elem in diaryElements){
          if(diaryElements[elem].elementtype === "elements/headlines" && !diaryElements[elem].color){
            diaryElements[elem].color = "#000000";
          }else if(diaryElements[elem].elementtype === "elements/pages" && !diaryElements[elem].color){
            diaryElements[elem].color = "";
          }else if(diaryElements[elem].elementtype === "elements/questions" && diaryElements[elem].questiontype === "Slider" &&
              typeof diaryElements[elem].values.start === "undefined"){
            diaryElements[elem].values.start = diaryElements[elem].values.min;
          }
        }

        var elementsJSON = {
          "data" : {
            "type" : "elements/elements",
            "attributes" :  {
              "elements" : diaryElements
            }
          }
        };

        self.createLessonElementsRequest(newDiaryLessonId, elementsJSON);
      })
      .then( function(){
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('diaryTranslation.importDiarySuccess')});
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.importDiaryRequest(jsonObject, studyId) }, "");
      });
    },

    //returns promise
    updateStudyDetailsRequest: function(jsonObject, studyId){
      return axios.patch(this.$url + '/studies/' + studyId, jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    updateDiaryDetailsRequest: function(jsonObject, diaryId){
      return axios.patch(this.$url + '/editor/diaries/' + diaryId, jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    updateInterventionDetailsRequest: function(jsonObject, interventionId){
      return axios.patch(this.$url + '/editor/interventions/' + interventionId, jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    updateInterventionDetailsUnlockDiariesRequest: async function(interventionId, jsonObject){
      return axios.patch(this.$url + '/editor/interventions/' + interventionId, jsonObject,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(async function (){
        return
      });
    },

    updateUnlockDiariesForLessonsRequest: async function(data){
      const url = this.$url + "/editor/questionnaires/diaries";
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    updateLessonDetailsRequest: async function(details, lessonId){
      const url = `${this.$url}/editor/questionnaires/${lessonId}`;
      const data = {
        data: {
          type: "questionnaires",
          attributes: details,
        },
      };
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    updateLessonPositionsInInterventionRequest: async function(jsonObject){
      const url = this.$url + "/editor/questionnaires/positions";
      const data = {
        data: {
          type: "questionnaires",
          attributes: {
            new_positions: jsonObject,
          },
        },
      }
      const config = { headers: { Authorization: "Bearer " + this.getToken } };
      return axios.patch(url, data, config);
    },

    updateLessonSkillsInInterventionRequest: async function(jsonObject){
      var json = {
        "data" : {
          "type" : "questionnaires",
          "attributes" : {
            "new_skills": jsonObject
          }
        }
      }

      return axios.patch(this.$url + '/editor/questionnaires/skills', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (){
        return
      });
    },

    requestMyRoles: function(){
      var self = this;
      axios.get(this.$url + '/my/roles',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(response){
        var myRoles = [];
        if(response.data.data.length != 0){
          for(var role in response.data.data){
            switch(response.data.data[role].attributes.slug) {
              case "admin":
                myRoles.push("admin")
              break;

              case "editor":
                myRoles.push("editor")
              break;

              case "ecoach":
                myRoles.push("ecoach")
              break;

              default:
              break;
            }
          }
        }

        if(myRoles.includes("admin") || myRoles.includes("editor")){
          self.SET_ROLES(myRoles);
          self.requestMyUserId();
        }else{
          self.SET_ROLES(-1);
          self.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.error') + Vue.i18n.translate('httpHelperTranslation.errorCode2')});
          self.logoutRequest();
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.requestMyRoles() }, "");
      });
    },

    requestMyUserId: function(){
      var self = this;
      axios.get(this.$url + '/my/profile',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(response){
        self.SET_USERID(response.data.data.id);
        self.requestAllAvailableLocales();
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.requestMyUserId() }, "");
      });
    },

    //returns promise
    requestMyProfile: function(){
      return axios.get(this.$url + '/my/profile',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(response){
        return response.data.data
      });
    },

    //returns promise
    updateMyProfileRequest: function(json){
      var self = this;
      return axios.patch(this.$url + '/my/profile', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then(function(){
        return self.requestMyProfile();
      })
      .then(function(response){
        return response
      });
    },

    /*
    roleIds:
    Study Owner (study.owner): 3
    Study Collaborator (study.collaborator): 4
    Study Access (study.access): 8
    Study eCoach Manager (study.ecoachmanager): 5
    Study eCoach (study.ecoach): 6
    */
    //returns promise
    requestCollaboratorsIncludingPermissions: function(queryString, studyId, isCollabView, idsOfChangedRoles, changedRoles){
      var self = this;
      return axios.get(this.$url + '/studies/' + studyId + '/collaborators?include=roles&' + queryString,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var collaborators = response.data.data;
        var collaboratorIds = [];
        var collaboratorData = [];
        var roles = [];
        for(var user in collaborators){
          var roleIds = [];
          var collaboratorRole = "";
          collaboratorIds.push(collaborators[user].id);
          roles = collaborators[user].relationships.roles.data;
          for(var r in roles){
            roleIds.push(roles[r].attributes.slug);
          }

          //get permission role
          if(roleIds.includes("study.owner")){
            collaboratorRole = "Study Owner";
            if(Number(collaborators[user].id) === Number(self.getUserId)){
              self.SET_MYROLEFORSTUDY("Owner");
            }

          }else if(roleIds.includes("study.collaborator")){
            collaboratorRole = "Study Collaborator";
            if(Number(collaborators[user].id) === Number(self.getUserId)){
              self.SET_MYROLEFORSTUDY("Collaborator");
            }

          }else if(roleIds.includes("study.access")){
            collaboratorRole = "Study Access";
            if(Number(collaborators[user].id) === Number(self.getUserId)){
              self.SET_MYROLEFORSTUDY("Access");
            }

          }else{
            collaboratorRole = "none";
            if(Number(collaborators[user].id) === Number(self.getUserId)){
              self.SET_MYROLEFORSTUDY("None");
            }
          }

          if(collaboratorRole != "none"){
            var collabData = {
              "firstname": collaborators[user].attributes.firstname,
              "lastname": collaborators[user].attributes.lastname,
              "name": collaborators[user].attributes.name,
              "email": collaborators[user].attributes.email,
              "id": collaborators[user].id,
              "role": collaboratorRole,
              "oldRole": collaboratorRole
            };

            if(isCollabView){
              //include permissions that changed on earlier visit of this view
              if(idsOfChangedRoles.includes(collaborators[user].id)){
                for(var id in idsOfChangedRoles){
                  if(collaborators[user].id === idsOfChangedRoles[id]){
                     collabData = changedRoles[id]
                  }
                }
              }
            }

            collaboratorData.push(collabData);
          }
        }

        return {
          list: collaboratorData,
          currentPage: response.data.meta ? response.data.meta.current_page : 0,
          allPages: response.data.meta ? response.data.meta.last_page : 0,
          idList: collaboratorIds
        }
      })
      .catch(function (error){
        if(error.response && error.response.status && error.response.status.toString() == '403'){
          self.SET_MYROLEFORSTUDY("None");
          return ""
        }else{
          return error
        }
      });
    },

    //returns promise
    requestAllEditorsIncludingPermissions: function(collaboratorListData, page, filterTerm, idsOfChangedRoles, changedRoles){
      return axios.get(this.$url + '/editor/users?page=' + page + filterTerm,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var editors = response.data.data;
        var editorList = [];
        var collaboratorRole;
        for(var editor in editors){
          if(collaboratorListData.idList && collaboratorListData.idList.includes(editors[editor].id)){
            for(var collaborator in collaboratorListData.list){
              if(collaboratorListData.list[collaborator].id === editors[editor].id){
                collaboratorRole = collaboratorListData.list[collaborator].role;
              }
            }
          }else{
            collaboratorRole = "none";
          }
          editorList.push(
            {
              "firstname": editors[editor].attributes.firstname,
              "lastname": editors[editor].attributes.lastname,
              "name": editors[editor].attributes.name,
              "email": editors[editor].attributes.email,
              "id": editors[editor].id,
              "role": collaboratorRole,
              "oldRole": collaboratorRole
            }
          );
        }

        //include permissions that changed on earlier visit of the permission page
        if(idsOfChangedRoles.length > 0){
          for(var ed in editorList){
            if(idsOfChangedRoles.includes(editorList[ed].id)){
              for(var id in idsOfChangedRoles){
                if(editorList[ed].id === idsOfChangedRoles[id]){
                   editorList[ed] = changedRoles[id]
                }
              }
            }
          }
        }

        return {
          list: editorList,
          currentPage: response.data.meta ? response.data.meta.current_page : 0,
          allPages: response.data.meta ? response.data.meta.last_page : 0
        }
      });
    },

    //returns promise
    addCollaboratorsToStudyRequest: async function(usersList, studyId){
      return axios.post(this.$url + '/studies/' + studyId + '/relationships/collaborators',
        {
          "data" : {
            "type" : "users",
            "attributes" : {
              "users" : usersList
            }
          }
        },
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    removeCollaboratorsFromStudyRequest: async function(usersList, studyId){
      return axios.delete(this.$url + '/studies/' + studyId + '/relationships/collaborators',
        {
          data: {
            "data" : {
              "type" : "users",
              "attributes" : {
                "users" : usersList
              }
            }
          },
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    requestAllUsersIncludingPermissions: function(page, filterTerm){
      return axios.get(this.$url + '/admin/users?page=' + page + filterTerm,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function (response){
        var users = response.data.data;
        var userList = [];
        for(var user in users){
          var currentUser = {
            "firstname": users[user].attributes.firstname,
            "lastname": users[user].attributes.lastname,
            "name": users[user].attributes.name,
            "email": users[user].attributes.email,
            "id": users[user].id,
            "verified": users[user].attributes.is_verified,
            "role": [],
          };

          for(var role in users[user].attributes.roles){
            if(users[user].attributes.roles[role].slug === "admin"){
              currentUser.role.push("admin");
            }else if(users[user].attributes.roles[role].slug === "ecoach"){
              currentUser.role.push("ecoach");
            }else if(users[user].attributes.roles[role].slug === "editor"){
              currentUser.role.push("editor");
            }
          }

          userList.push(currentUser);
        }

        return {
          list: userList,
          allPages: response.data.meta ? response.data.meta.last_page : 0,
          currentPage: response.data.meta ? response.data.meta.current_page : 0,
          total: response.data.meta ? response.data.meta.total : 0,
        }
      });
    },

    //returns promise
    addRolesToUserRequest: async function(userId, rolesToAdd){
      return axios.post(this.$url + '/admin/roles',
        {
          "data" : {
            "type" : "roles",
            "attributes" : {
              "user_id" : userId,
              "role_slugs" : rolesToAdd
            }
          }
        },
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    removeRolesFromUserRequest: async function(userId, rolesToRemove){
      return axios.delete(this.$url + '/admin/roles',
        {
          data: {
            "data" : {
              "type" : "roles",
              "attributes" : {
                "user_id" : userId,
                "role_slugs" : rolesToRemove
              }
            }
          },
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    deleteUserRequest: function(userId){
      return axios.delete(this.$url + '/admin/users/' + userId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    requestAccountDeletionRequests: function(page, filterTerm){
      return axios.get(this.$url + '/admin/users/delete/requests?include=users,studies&page=' + page + filterTerm,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then( function(response){
        return {
          list: response.data.data,
          currentPage: response.data.meta ? response.data.meta.current_page : 0,
          allPages: response.data.meta ? response.data.meta.last_page : 0
        }
      });
    },

    //returns promise
    pseudonymizeUserRequest: function(userId){
      return axios.delete(this.$url + '/admin/users/' + userId + '/pseudonymise',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    deleteUserCompletelyRequest: function(userId){
      return axios.delete(this.$url + '/admin/users/' + userId + '/delete',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    declineAccountDeletionRequest: function(userId){
      return axios.patch(this.$url + '/admin/users/delete/requests/' + userId, {},
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    requestStudyUploads: function(studyId, page, filterTerm, pagesMedia, showLastPage, newAttributes){
      var self = this;
      return axios.get(this.$url + '/studies/' + studyId + '/media?page=' + page + filterTerm,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then( function(response){
        if(response.data.meta && pagesMedia < response.data.meta.last_page && showLastPage){
          return self.requestStudyUploads(studyId, response.data.meta.last_page, filterTerm, response.data.meta.last_page, showLastPage, newAttributes);
        }else{
          var newStudyMediaFiles = response.data.data;
          //var arr = [];
          for(var file in newStudyMediaFiles){
            /*
            arr.push(
              {
                "url": "https://api.esano-trainings.de/" + newStudyMediaFiles[file].attributes.url,
                "original_name": newStudyMediaFiles[file].attributes.original_name
              }
            )
            */
            if(newAttributes[newStudyMediaFiles[file].id] != undefined){
              newStudyMediaFiles[file].attributes.original_name = newAttributes[newStudyMediaFiles[file].id].original_name;
              newStudyMediaFiles[file].attributes.favorite = newAttributes[newStudyMediaFiles[file].id].favorite;
            }
          }
          //console.log(JSON.stringify(arr));

          return {
            list: newStudyMediaFiles,
            currentPage: response.data.meta ? response.data.meta.current_page : 0,
            allPages: response.data.meta ? response.data.meta.last_page : 0
          }
        }
      });
    },

    //returns promise
    requestStudyMedia: async function(studyId){
      return axios.get(this.$url + '/studies/' + studyId + '/media?limit=0',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then( function(response){
        return response.data.data
      });
    },

    //returns promise
    submitStudyMediaRequest: function(formData, studyId){
      return axios.post(this.$url + '/studies/' + studyId + '/media', formData,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then( function(){
        return
      });
    },

    //returns promise
    deleteStudyMediaRequest: function(json, studyId){
      return axios.delete(this.$url + '/studies/' + studyId + '/media',
        {
          data: json,
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then( function(){
        return
      });
    },

    //returns promise
    updateStudyMediaAttributesRequest: function(json){
      return axios.patch(this.$url + '/editor/studies/media/attributes', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then( function(){
        return
      });
    },

    requestAllAvailableLocales: function(){
      var self = this;
      axios.get(this.$url + '/misc/locales')
      .then(function (response){
        var json = response.data.data;
        var availableLocalesArray = [];
        for(var locale in json){
          availableLocalesArray.push(json[locale].attributes.locale);
        }
        self.SET_AVAILABLELOCALES(availableLocalesArray);
      })
      .catch(function (error){
        self.handleErrorsNotLoggedIn(error, "");
      });
    },

    //returns promise
    requestAnnouncements: async function(page){
      var currentTime = Math.round(new Date().getTime() / 1000);
      var queryString = "&client=cms&start<" + currentTime + "&end>" + currentTime + "&sort=-start";

      return axios.get(this.$url + '/announcements?include=user&page=' + page + queryString,
        {
          headers: {
            Authorization: "Bearer " + this.getToken,
            'Accept-Language': this.getAppLanguage
          }
        }
      )
      .then( function(response){
        return response.data.data
      });
    },

     //returns promise
     requestAdminAnnouncements: async function(page, filterTerm){
      var queryString = (filterTerm != "") ? "&" + filterTerm : "";

      return axios.get(this.$url + '/admin/announcements?include=user&page=' + page + queryString,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then( function(response){
        return {
          list: response.data.data,
          currentPage: response.data.meta ? response.data.meta.current_page : 0,
          allPages: response.data.meta ? response.data.meta.last_page : 0,
          total: response.data.meta ? response.data.meta.total : 0,
        }
      });
    },

    //returns promise
    createAnnouncementRequest: async function(json){
      return axios.post(this.$url + '/admin/announcements', json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    updateAnnouncementRequest: async function(id, json){
      return axios.patch(this.$url + '/admin/announcements/' + id, json,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        return
      });
    },

    //returns promise
    deleteAnnouncementRequest: function(announcementId){
      return axios.delete(this.$url + '/admin/announcements/' + announcementId,
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      ).then( function(){
        return
      });
    },

    logoutRequest: function(){
      var self = this;
      axios.delete(this.$url + '/auth/logout',
        {
          headers: {
            Authorization: "Bearer " + this.getToken
          }
        }
      )
      .then(function(){
        self.CHANGE_TOKEN("");
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.logoutRequest() }, "logout");
      });
    }
  }
};
