<template>
  <div class="myMoreInterventions">
    <InterventionList
      :key="$route.fullPath"
      :interventionListData="interventionListData"
      :usedInRoute="$route.name"
      :disableWhileCopy="copyInterventionVisible"
      @get-new-list="getNewInterventions"
      @route-to="routeTo($event, false)"
      @show-modal="showModal"
      @close-notification="closeNotification"
      @reset-page="resetPage"
    ></InterventionList>

    <InterventionCreate
      v-if="createInterventionVisible"
      :targetData="{isInStudy: false}"
      @close-modal="closeModalCreate"
      @route-to="routeTo($event, true)"
      @close-notification="closeNotification"
    ></InterventionCreate>

    <!-- InterventionCopy InterventionPublish -->
    <div
      v-if="copyInterventionVisible"
      :is="sourceData.type"
      :sourceData="sourceData"
      @close-modal="closeModalInt"
      @close-notification="closeNotification"
    ></div>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import httpHelper from '../mixins/httpHelper';
import router from '../router';
import BaseNotification from './BaseNotification.vue';
import InterventionList from './InterventionList.vue';
import InterventionCopy from './InterventionCopy.vue';
import InterventionPublish from './InterventionPublish.vue';
import InterventionCreate from './InterventionCreate.vue';

export default {
  name: 'MyMoreInterventions',

  components: {
    BaseNotification,
    InterventionList,
    InterventionCopy,
    InterventionPublish,
    InterventionCreate,
  },

  mixins: [httpHelper],

  props: ['oldInterventionId'],

  data: function(){
    return{
      copyInterventionVisible: false,
      createInterventionVisible: false,
      sourceData: "",
      interventionListData: "",
      deleteInterventionVisible: false,
      getSpecificPage: false,
    }
  },

  watch:{
    '$route': function(){
      this.getNewInterventions({"page": 1, "filterTerm": ""});
    },
  },

  mounted(){
    this.closeNotification();
    if(this.oldInterventionId != undefined){
      this.getSpecificPage = true;
    }
    this.getNewInterventions({"page": this.interventionListData.currentPage, "filterTerm": ""});
  },

  beforeRouteLeave(to, from, next){
    if(this.copyInterventionVisible || this.createInterventionVisible || this.deleteInterventionVisible){
      next(false);
    }else{
      next();
    }
  },

  methods: {
    getNewInterventions: function(obj){
      var isCollab = (this.$route.name === "myInterventions");
      var self = this;
      this.requestCollaboratingOrNonCollaboratingInterventions(obj.page, obj.filterTerm, isCollab, this.getSpecificPage, this.oldInterventionId)
      .then( function(response){
        self.interventionListData = response;
        self.getSpecificPage = false;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewInterventions(obj) }, "");
      });
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    routeTo: function(obj, closeModal){
      if(closeModal){
        this.closeModalCreate();
      }
      router.push(obj);
    },

    showModal: function(obj){
      switch (obj.type) {
        case "InterventionCopy":
        case "InterventionPublish":
          this.closeNotification();
          this.sourceData = obj;
          this.copyInterventionVisible = true;
        break;

        case "InterventionCreate":
          this.closeNotification();
          this.createInterventionVisible = true;
        break;

        case "InterventionDelete":
          this.deleteInterventionVisible = obj.value;
        break;

        default:
        break;
      }
    },

    closeModalInt: function(obj){
      this.copyInterventionVisible = false;

      if(obj.done){
        var page = this.interventionListData.allPages;
        if(this.interventionListData.total > 0 && this.interventionListData.total % this.$perPage === 0){
          //last page has 15 -> copy is on next
          page++;
        }

        this.getNewInterventions({ "page": page, "filterTerm": "" });
      }
    },

    resetPage: function(){
      this.interventionListData.currentPage = 1;
    },

    closeModalCreate: function(){
      this.createInterventionVisible = false;
    },
  }
}
</script>
