import Vue from 'vue';
import {mapGetters} from 'vuex';
import utils from '../mixins/utils';

export default {
  mixins: [utils],

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
    ]),
  },

  // it is now enough to fill in only one language, all translation fields of the language have to be filled in though (backend does
  // not accept translations with empty fields) (media subtitle & description are optional)
  // there can not be incomplete translation entries
  // for the old version where all languages have to be filled in see file elementsCompleteHelperAll
  // if translations are not filled in, they are removed in elementPropsToJSONHelper because backend does
  // not accept translations with empty fields
  methods: {
    //save true -> for save method of lesson elements editor -> check all
    //save false -> for condition editor -> only check question elements
    checkIfElementsComplete: function(elements, save, locales){
      let newErrorTextArr = [];
      const elementsComplete = [];

      for (const elem in elements) {
        const element = elements[elem];
        let errorText = "";
        switch(element.type){
          case "ElementsHeadline":
            if(save){
              errorText = this.checkIfHeadlineFieldsComplete(element, locales);
            }
            break;
          case "ElementsQuestion":
            errorText = this.checkIfQuestionFieldsComplete(element, locales);
            break;
          case "ElementsText":
            if(save){
              errorText = this.checkIfTextFieldsComplete(element, locales);
            }
            break;
          case "ElementsMedia":
            if(save){
              errorText = this.checkIfMediaFieldsComplete(element, locales);
            }
            break;
          case "ElementsSpace":
            if(save){
              errorText = this.checkIfSpaceFieldsComplete(element);
            }
            break;
          case "ElementsBlock":
            if(save){
              errorText = this.checkIfBlockFieldsComplete(element, locales);
            }
            break;
          case "ElementsPage":
            if(save){
              errorText = this.checkIfPageFieldsComplete(element);
            }
            break;
          default:
            errorText = "";
            break;
        }

        if(element.type === "ElementsBlock"){
          //check children of block
          const resultBlock = this.checkIfElementsComplete(element.propsObj.elements, save, locales);
          elementsComplete.push(errorText === "" && resultBlock.elementsComplete);
          newErrorTextArr[element.propsObj.id] = errorText;
          newErrorTextArr = Object.assign(newErrorTextArr, resultBlock.errorTextsForElements);
        }else{
          elementsComplete.push(errorText === "");
          newErrorTextArr[element.propsObj.id] = errorText;
        }
      }

      //todo check if two page elements after one another

      return {
        errorTextsForElements: newErrorTextArr,
        elementsComplete: !elementsComplete.includes(false)
      };
    },

    // errortext with languages
    //   var emptyTranslations = [];
    //   for(var translation in element.translations){
    //     if(element.translations[translation] === ""){
    //       emptyTranslations.push(translation);
    //     }else{
    //       allTranslationsEmpty = false;
    //     }
    //   }
    //   if(allTranslationsEmpty){
    //     errorText = Vue.i18n.translate('elementsTextTranslation.errorFillInOne') + Vue.i18n.translate('elementsHeadlineTranslation.headline') +
    //       " (" + emptyTranslations + ")";
    //   }

    checkIfHeadlineFieldsComplete: function(element, locales){
      let errorText = "";
      let allTranslationsEmpty = true;

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation] != ""){
          allTranslationsEmpty = false;
        }
      }

      if(allTranslationsEmpty){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn') + Vue.i18n.translate('elementsHeadlineTranslation.headline') +
          " " + Vue.i18n.translate('elementsTextTranslation.errorOneTranslation');
      }
      return errorText
    },

    checkIfQuestionFieldsComplete: function(element, locales){
      if(element.propsObj.selectedQuestionProp === ""){
        return Vue.i18n.translate('elementsTextTranslation.errorFillIn') + Vue.i18n.translate('elementsQuestionTranslation.questionType');
      }

      switch (element.propsObj.selectedQuestionProp) {
        case "SingleChoice":
        case "MultipleChoice":
          return this.checkIfQuestionSingleMultipleComplete(element, locales)
        case "Slider":
          return this.checkIfQuestionSliderComplete(element, locales)
        case "QuestionTable":
          return this.checkIfQuestionTableComplete(element, locales)
        default:
          return this.checkIfQuestionSimpleComplete(element, locales)
      }
    },

    checkIfQuestionSimpleComplete: function(element, locales){
      let errorText = "";
      let allTranslationsEmpty = true;

      if(element.propsObj.labelProp === ""){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn') + Vue.i18n.translate('elementsQuestionTranslation.questionLabel');
      }

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation].question != ""){
          allTranslationsEmpty = false;
        }
      }

      if(allTranslationsEmpty){
        errorText += (errorText != "" ? ", " : Vue.i18n.translate('elementsTextTranslation.errorFillIn')) + Vue.i18n.translate('elementsQuestionTranslation.question') + " " +
          Vue.i18n.translate('elementsTextTranslation.errorOneTranslation');
      }

      return errorText
    },

    checkIfQuestionSingleMultipleComplete: function(element, locales){
      let errorText = "";
      const errorSingleMultipleArr = [];
      let errorSingleMultipleText = "";
      const errorsArr = [];
      let allTranslationsEmpty = true;

      if(element.propsObj.labelProp === ""){
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionLabel'));
      }
      if(element.propsObj.values.length < 2){
        errorSingleMultipleText = Vue.i18n.translate('elementsQuestionTranslation.singleMultipleChoiceError');
      }

      const valuesArr = [];
      for(const value in element.propsObj.values){
        if(element.propsObj.values[value] === ""){
          errorsArr.push(
            Vue.i18n.translate('elementsQuestionTranslation.errorAnswerValueEmpty') + " " +
            Number(Number(value) + 1)
          );
        }
        if(valuesArr.includes(element.propsObj.values[value])){
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.errorDuplicateAnswerLabel'));
        }
        valuesArr.push(element.propsObj.values[value]);
      }

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation].question != ""){
          allTranslationsEmpty = false;
        }

        if(element.propsObj.values.length != element.translations[translation].answers.length){
          errorSingleMultipleArr.push(translation);
        }
        // answers only checked on length, can be empty string though
        // todo maybe complete translation for one language
        // for(answer in element.translations[translation].answers){
        //   if(element.translations[translation].answers[answer] === ""){
        //     errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.answerOption') +  " " + Number(Number(answer) + 1) + " (" + translation + ")");
        //   }
        // }
      }

      if(allTranslationsEmpty){
        errorsArr.push( Vue.i18n.translate('elementsQuestionTranslation.question') + " " + Vue.i18n.translate('elementsTextTranslation.errorOneTranslation'));
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(var err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if(errorSingleMultipleArr.length > 0){
        errorText += (errorText != "" ? ". " : "") + Vue.i18n.translate('elementsQuestionTranslation.errorSingleMultiple') + " (";
        for(var err2 in errorSingleMultipleArr){
          errorText += (err2 > 0 ? ", " : "") + errorSingleMultipleArr[err2];
        }
        errorText += ")";
      }

      if(errorSingleMultipleText != ""){
        errorText += (errorText != "" ? ". " : "") + errorSingleMultipleText;
      }

      return errorText
    },

    checkIfQuestionSliderComplete: function(element, locales){
      let errorText = "";
      let errorSlider = false;
      let errorSliderText = "";
      const errorsArr = [];

      if(element.propsObj.labelProp === ""){
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionLabel'));
      }

      if(Number.isNaN(element.propsObj.values.min) || element.propsObj.values.min === ""){
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.valueMin'));
      }
      if(Number.isNaN(element.propsObj.values.max) || element.propsObj.values.max === ""){
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.valueMax'));
      }
      if(Number.isNaN(element.propsObj.values.step) || element.propsObj.values.step === ""){
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.step'));
      }
      if(Number.isNaN(element.propsObj.values.start) || element.propsObj.values.start === ""){
        errorSlider = true;
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.startValue'));
      }
      if(!errorSlider){
        const start = Number(element.propsObj.values.min);
        const end = Number(element.propsObj.values.max);
        const step = Number(element.propsObj.values.step);
        const startVal = Number(element.propsObj.values.start);
        if(((end - start)/step) > 101){
          errorSliderText = Vue.i18n.translate('elementsQuestionTranslation.errorSliderTooManyValues');
        }
        if(startVal < start || startVal > end){
          errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.startValueNotIncluded');
        }
        if(start > end){
          errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.minGreaterMax');
        }else if(start === end){
          errorSliderText += (errorSliderText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.minIsMax');
        }
      }

      let allTranslationsEmpty = true;

      for (const translation in element.translations) {
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation].question != ""){
          allTranslationsEmpty = false;
        }
        // todo maybe complete translation for one language
        // if(element.translations[translation].answers[0].label === ""){
        //   errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.errorSliderMinTextValueEmpty') + " (" + translation + ")");
        // }
        // if(element.translations[translation].answers[1].label === ""){
        //   errorsArr.push(
        //     Vue.i18n.translate('elementsQuestionTranslation.errorSliderMaxTextValueEmpty') + " (" + translation + ")"
        //   );
        // }
      }

      if(allTranslationsEmpty){
        errorsArr.push( Vue.i18n.translate('elementsQuestionTranslation.question') + " " + Vue.i18n.translate('elementsTextTranslation.errorOneTranslation'));
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(var err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if(errorSliderText != ""){
        errorText += (errorText != "" ? ". " : "") + errorSliderText;
      }

      return errorText
    },

    //todo allTranslationsEmpty
    checkIfQuestionTableComplete: function(element, locales){
      let errorSingleMultipleText = "";
      let checkTable = true;
      const values = element.propsObj.values;

      if(values.max - values.min < 1){
        checkTable = false;
        errorSingleMultipleText = Vue.i18n.translate('elementsQuestionTranslation.tableAnswersError');
      }
      if(values.questions < 2){
        checkTable = false;
        errorSingleMultipleText += (errorSingleMultipleText != "" ? " " : "") + Vue.i18n.translate('elementsQuestionTranslation.tableQuestionsError');
      }

      const errorsArr = [];
      if (checkTable) {
        let invalidTable = true;
        translations_loop:
        for(const language in element.translations){
          if (this.unusedLocale(locales, language)) {
            continue translations_loop;
          }
          const translation = element.translations[language];
          for(let answer = values.min; answer <= values.max; answer++){
            if(!translation.answers[answer]){
              continue translations_loop;
            }
          }
          for(let question = 0; question < values.questions; question++){
            if(!translation.question[question]){
              continue translations_loop;
            }
          }
          invalidTable = false;
          break translations_loop; // 1 valid translation is enough
        }
        if (invalidTable) {
          const invalidInput = Vue.i18n.translate('elementsQuestionTranslation.invalidQuestionTable');
          const lang = Vue.i18n.translate('elementsTextTranslation.errorOneTranslation');
          errorsArr.push(`${invalidInput} ${lang}`);
        }
      }

      if (element.propsObj.labelProp === "") {
        errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionLabel'));
      }

      let errorText = "";
      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }
      }

      if(errorSingleMultipleText != ""){
        errorText += (errorText != "" ? ". " : "") + errorSingleMultipleText;
      }

      return errorText;
    },

    checkIfTextFieldsComplete: function(element, locales){
      let errorText = "";
      let allTranslationsEmpty = true;
      let includesOtherPictures = false;
      let languageOfOtherPictures = "";

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation] != ""){
          allTranslationsEmpty = false;
          const regEx = new RegExp(/<img src='https:\/\/[A-Za-z0-9/.-_]*'/,"g");
          const urls = element.translations[translation].match(regEx);
          for(const url in urls){
            if(!urls[url].startsWith(this.$urlUploads)){
              includesOtherPictures = true;
              languageOfOtherPictures += (languageOfOtherPictures != "" ? ", " : "") + translation;
              break;
            }
          }
        }
      }
      if(allTranslationsEmpty){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn') + Vue.i18n.translate('elementsTextTranslation.text') +
          " " + Vue.i18n.translate('elementsTextTranslation.errorOneTranslation');
      }else if(includesOtherPictures){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorOtherPictures') + " (" + languageOfOtherPictures + ")";
      }

      return errorText
    },

    checkIfMediaFieldsComplete: function(element, locales){
      let errorText = "";
      let allTranslationsEmpty = true;

      if(element.propsObj.width > 100 || element.propsObj.width < 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn') +
          Vue.i18n.translate('elementsMediaTranslation.sizeValidValue');
      }

      for(const translation in element.translations){
        if (this.unusedLocale(locales, translation)) {
          continue;
        }
        if(element.translations[translation].uri != ""){
          allTranslationsEmpty = false;
        }else if(element.translations[translation].subtitle != "" || element.translations[translation].description != ""){
          //title/description not empty, uri empty
          errorText += (errorText === "" ? Vue.i18n.translate('elementsTextTranslation.errorFillIn') : ", ") +
            Vue.i18n.translate('elementsMediaTranslation.file') + " (" + translation + ")";
        }
      }

      if(allTranslationsEmpty){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn') + Vue.i18n.translate('elementsMediaTranslation.file') +
          " " + Vue.i18n.translate('elementsTextTranslation.errorOneTranslation');
      }

      return errorText
    },

    checkIfSpaceFieldsComplete: function(element){
      return !element.propsObj.size ? Vue.i18n.translate('elementsSpaceTranslation.errorSize') : "";
    },

    checkIfBlockFieldsComplete: function(element, locales){
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];
      let allTranslationsEmpty = true;

      if(element.propsObj.type === ""){
        errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorTypeEmpty'));
      }

      if(element.propsObj.type != 'details' && element.propsObj.type != 'none'){
        const result = this.checkIfConditionComplete(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if(!element.propsObj.thenBlock.repeat.question){
        if(element.propsObj.thenBlock.repeat.data < 1){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorRepetitionMin'));
        }
      }else if(element.propsObj.thenBlock.repeat.data === "" || this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data] === 0 ||
          this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type === "text"){
        errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorRepetitionLabel'));
      }else if(this.getQuestionLabelList[element.propsObj.thenBlock.repeat.data].type != "slider"){
        errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorRepetitionLabel'));
      }

      if(element.propsObj.type != 'conditional' && element.propsObj.type != 'none'){
        for(const translation in element.translations){
          if (this.unusedLocale(locales, translation)) {
            continue;
          }
          if(element.translations[translation] != ""){
            allTranslationsEmpty = false;
          }
        }

        if(allTranslationsEmpty){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errortextDetailsEmpty') +
            " " + Vue.i18n.translate('elementsTextTranslation.errorOneTranslation'));
        }
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        if(element.propsObj.type === 'conditional' || element.propsObj.type === 'both' || element.propsObj.thenBlock.repeat.question){
          //check if questionLabelList has questions that can be used in conditions
          let questionExists = false;
          if(this.getQuestionLabelList.length > 0){
            for(const question in this.getQuestionLabelList){
              if(this.getQuestionLabelList[question] != 0 && this.getQuestionLabelList[question].type != "text"){
                questionExists = true;
              }
            }
            if(!questionExists){
              errorText += ". " + Vue.i18n.translate('elementsBlockTranslation.noQuestions');
            }
          }
        }
      }

      if(errorText != "" && !errorText.endsWith(".")){
        errorText += ".";
      }

      if(errorValueSecondValue){
        errorText += " " + Vue.i18n.translate('elementsBlockTranslation.errorValueSecondValue');
      }else if(errorTextComplex != ""){
        errorText += " " + errorTextComplex;
      }

      return errorText;
    },

    checkIfPageFieldsComplete: function(element){
      let errorText = "";
      let errorTextComplex = "";
      let errorValueSecondValue = false;
      let errorsArr = [];
      const isConditionalPage = element.propsObj.condition != "" && element.propsObj.condition != null;

      if(isConditionalPage){
        const result = this.checkIfConditionComplete(element, errorsArr, true);
        errorTextComplex = result.errorTextComplex;
        errorsArr = result.errorsArr;
        errorValueSecondValue = result.errorValueSecondValue;
      }

      if(errorsArr.length > 0){
        errorText = Vue.i18n.translate('elementsTextTranslation.errorFillIn');
        for(const err in errorsArr){
          errorText += (err > 0 ? ", " : "") + errorsArr[err];
        }

        if(isConditionalPage){
          //check if questionLabelList has questions that can be used in conditions
          let questionExists = false;
          if(this.getQuestionLabelList.length > 0){
            for(const i in this.getQuestionLabelList){
              const question = this.getQuestionLabelList[i];
              if(question != 0 && question.type != "text"){
                questionExists = true;
              }
            }
            if(!questionExists){
              errorText += ". " + Vue.i18n.translate('elementsBlockTranslation.noQuestions');
            }
          }
        }
      }

      if(errorText != "" && !errorText.endsWith(".")){
        errorText += ".";
      }

      if(errorValueSecondValue){
        errorText += " " + Vue.i18n.translate('elementsBlockTranslation.errorValueSecondValue');
      }else if(errorTextComplex != ""){
        errorText += " " + errorTextComplex;
      }

      return errorText
    },

    checkIfConditionComplete: function(element, errorsArr, returnErrorDetails){
      let errorValueSecondValue = false;
      let errorTextComplex = "";
      const condition = element.propsObj.condition;
      //todo check if condition is ever fulfillable (for example slider > slider.max)
      if(element.propsObj.isComplexCondition){
        if(condition.nodeType === "leaf"){
          //user clicked on complex condition but did not define a condition
          element.propsObj.isComplexCondition = false;
          condition.questionLabel = -1;
        }else if(!this.checkIfComplexConditionComplete(condition)){
          errorTextComplex = Vue.i18n.translate('elementsBlockTranslation.errorComplexCondition');
        }
      }
      if(!element.propsObj.isComplexCondition){
        if(condition.questionLabel === -1 || (!condition.questionLabel && condition.questionLabel !== 0)){
          errorsArr.push(Vue.i18n.translate('elementsQuestionTranslation.questionLabel'));
        }
        if(condition.leafType != "question" && condition.operation === ""){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorOperationEmpty'));
        }
        if(condition.operation != "answered" && condition.value === ""){
          errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorValueEmpty'));
        }
        if(condition.leafType != "question" && condition.operation === "between"){
          if(condition.secondValue === ""){
            errorsArr.push(Vue.i18n.translate('elementsBlockTranslation.errorSecondValueEmpty'));
          }else if(!(Number(condition.value) < Number(condition.secondValue))){
            errorValueSecondValue = true;
          }
        }
      }
      if(returnErrorDetails){
        return {errorTextComplex: errorTextComplex, errorsArr: errorsArr, errorValueSecondValue: errorValueSecondValue}
      }else{
        return errorsArr.length === 0 && errorTextComplex === "" && !errorValueSecondValue
      }
    },

    checkIfComplexConditionComplete: function(condition){
      if(condition.nodeType === "leaf"){
        if(condition.questionLabel === "" || (condition.operation != "answered" && condition.value === "")){
          return false
        }
        if(condition.leafType === 'date' || condition.leafType === 'slider'){
          if(condition.operation === ""){
            return false
          }else if(condition.operation === "between"){
            if(condition.secondValue === ""){
              return false
            }else if(!(condition.value < condition.secondValue)){
              return false
            }else{
              return true
            }
          }
        }
        return true
      }else if(condition.nodeType === "and" || condition.nodeType === "or"){
        if(!(condition.children.length > 1)){
          return false
        }else{
          var boolList = [];
          for(var child in condition.children){
            boolList.push(this.checkIfComplexConditionComplete(condition.children[child]));
          }
          if(boolList.includes(false)){
            return false
          }else{
            return true
          }
        }
      }else if(condition.nodeType === "not"){
        if(condition.children.length != 1){
          return false
        }else{
          return this.checkIfComplexConditionComplete(condition.children[0])
        }
      }
      return false
    },

    //function for non-nested json
    getRepetitionsAndSlider: function(jsonElementsList){
      var repetitionsArr = [];
      var sliderArr = [];
      var repetitionBegin = [];
      for(var element in jsonElementsList){
        if(jsonElementsList[element].elementtype === "elements/blockopens"){
            repetitionBegin.push(
              {
                "start": Number(Number(element) + 1),
                "question": jsonElementsList[element].condition.thenBlock ? JSON.parse(JSON.stringify(jsonElementsList[element].condition.thenBlock.repeat.question)) : "",
                "data": jsonElementsList[element].condition.thenBlock ? JSON.parse(JSON.stringify(jsonElementsList[element].condition.thenBlock.repeat.data)) : ""
              }
            );
        }else if(jsonElementsList[element].elementtype === "elements/blockcloses"){
          var rep = repetitionBegin.pop();
          //add all repetitions whose repetition number is depending on a slider (question === true)
          if(typeof rep != "undefined" && rep.question != "" && rep.question){
            rep.end = Number(Number(element) + 1);
            repetitionsArr.push(rep);
          }
        }else if(jsonElementsList[element].elementtype === "elements/questions" && jsonElementsList[element].questiontype === "Slider"){
          //check if slider inside repetition block (repetition > 1) -> add slider to list
          for(var repetition in repetitionBegin){
            if((!repetitionBegin[repetition].question && Number(repetitionBegin[repetition].data) > 1) ||
                repetitionBegin[repetition].question){
              sliderArr.push(Number(Number(element) + 1));
              break;
            }
          }
        }
      }
      var obj = {
        "repetitions": repetitionsArr,
        "sliderPos": sliderArr
      };
      return obj
    },

    //repeated slider must not be used for repetition number of other repetition
    //or: slider used for repetition number must not be in repetition
    //error shown at repeated slider element
    checkIfRepeatedSliderUsedInRepetition: function(obj, jsonElementsList){
      var repetitions = obj.repetitions;
      //sliderArr contains repeated slider questions -> these must not be used for repetition number
      var sliderArr = obj.sliderPos;
      var errorLabels = [];
      for(var slider in sliderArr){
        var index = repetitions.findIndex(elem => elem.data === sliderArr[slider]);
        if(index != -1){
          var pos = sliderArr[slider];
          errorLabels.push(jsonElementsList[pos - 1].label);
        }
      }
      return errorLabels
    },

    //check if questions used in conditions, references, repetitions, which shall be copied, are also copied
    checkIfCopyElementsCompleteFunction: function(selectedElements, elements, addElementsIdCounter){
      var questions = this.checkIfCopyElementsComplete(selectedElements, []);
      var missingQuestions = [];

      var flattedSelectedElements = this.flattenJSON(JSON.parse(JSON.stringify(selectedElements)), addElementsIdCounter + 1, 1).elements;

      while(questions.length > 0){
        var qu = questions.pop();
        if(flattedSelectedElements.findIndex(element => element && element.propsObj.id === qu) === -1){
          missingQuestions.push(qu);
        }
        questions = questions.filter(quest => quest != qu);
      }

      var errText = "";

      //questions used in conditions, references, repetitions are missing
      if(missingQuestions.length != 0){
        errText += Vue.i18n.translate('lessonTranslation.copyElementsQuestionsMissing') + " ";
        for(var missingQuest in missingQuestions){
          errText += (missingQuest > 0) ? ", " : "";
          var elIndex = elements.findIndex(element => element.propsObj.id === missingQuestions[missingQuest]);
          errText += (elIndex != -1) ? elements[elIndex].propsObj.labelProp : "";
        }
      }
      return errText
    },

    checkIfCopyElementsComplete: function(selectedElements, questions){
      for(var element in selectedElements){
        switch (selectedElements[element].type){
          case "ElementsBlock":
            //condition
            if(selectedElements[element].propsObj.type === "conditional" || selectedElements[element].propsObj.type === "both"){
              questions = questions.concat(this.getIncludedQuestionsInCondition(selectedElements[element].propsObj.condition));
            }

            //repetition
            if(selectedElements[element].propsObj.thenBlock && selectedElements[element].propsObj.thenBlock.repeat.question){
              questions.push(selectedElements[element].propsObj.thenBlock.repeat.data);
            }

            //children
            questions = questions.concat(this.checkIfCopyElementsComplete(selectedElements[element].propsObj.elements, questions));
          break;

          case "ElementsPage":
            //condition
            if(selectedElements[element].propsObj.condition){
              questions = questions.concat(this.getIncludedQuestionsInCondition(selectedElements[element].propsObj.condition));
            }
          break;

          case "ElementsHeadline":
          case "ElementsText":
          case "ElementsMedia":
          case "ElementsQuestion":
            //references
            for(var translation in selectedElements[element].translations){
              var referenceString;
              switch(selectedElements[element].type){
                case "ElementsHeadline":
                  referenceString = selectedElements[element].translations[translation];
                break;

                case "ElementsText":
                  referenceString = selectedElements[element].translations[translation];
                break;

                case "ElementsMedia":
                  referenceString = selectedElements[element].translations[translation].subtitle + selectedElements[element].translations[translation].description;
                break;

                case "ElementsQuestion":
                  if(selectedElements[element].propsObj.selectedQuestionProp != "QuestionTable"){
                    referenceString = selectedElements[element].translations[translation].question;
                  }else{
                    referenceString = JSON.parse(JSON.stringify(selectedElements[element].translations[translation].question)).join('');
                  }

                  if(selectedElements[element].propsObj.selectedQuestionProp === "SingleChoice" || selectedElements[element].propsObj.selectedQuestionProp === "MultipleChoice" ||
                      selectedElements[element].propsObj.selectedQuestionProp === "QuestionTable"){
                    referenceString += JSON.parse(JSON.stringify(selectedElements[element].translations[translation].answers)).join('');
                  }
                break;

                default:
                  referenceString = "";
                break;
              }

              var references = referenceString.match(/\{{2}([^}{]+)}{2}/g);
              if(references != null){
                var q = references.map(x => Number(x.replace(/{/g, "").replace(/}/g, "")));
                questions = questions.concat(q);
              }
            }
          break;

          default:
          break;
        }
      }

      return questions
    },

    getIncludedQuestionsInCondition: function(condition){
      if(condition && condition.nodeType != "leaf"){
        var includedQuestions = [];
        for(var child in condition.children){
          includedQuestions = includedQuestions.concat(this.getIncludedQuestionsInCondition(condition.children[child]));
        }
        return includedQuestions
      }else{
        return [Number(condition.questionLabel)]
      }
    },
  }
};
