<template>
  <div class="interventionEditor">
    <section v-show="!loadingPage">
      <BaseMenuBar
        :nameList="[studyDetails.name, interventionDetails.name]"
        :idList="[studyId]"
      ></BaseMenuBar>

      <BaseTabs
        :tabDetails="tabDetails"
        :selectedView="selectedView"
        @show-view="showView"
      ></BaseTabs>

      <section v-if="!hasNoPermissions" class="buttonFormRight interventionPreviewButton">
        <i
          class="fas fa-search fa-lg buttonMiddle previewButton"
          id="buttonShowInterventionPreview"
          v-tooltip="textPreviewButton"
          @click="showPreview"
        >
          <div class="pageText">
            {{ 'interventionTranslation.intervention' | translate }}
          </div>
        </i>
      </section>

      <section v-show="selectedView === 0">
        <button type="button" id="buttonExportIntervention" @click="exportInterv">
          {{ 'interventionTranslation.export' | translate }}
        </button>

        <BaseSelectLanguage
          v-if="interventionDetails.locales"
          :localesArray="interventionDetails.locales"
          :selectedLanguage2="selectedLanguage2"
          :isTranslationMode="isTranslationMode"
          @close-notification="closeNotification"
          @change-language2="changeLanguage2"
          @change-translationmode="changeTranslationMode"
        ></BaseSelectLanguage>

        <section :id="interventionDetailsFormId" class="wrapper">
          <button type="submit" disabled style="display: none"></button>
          <section v-show="!loadingLanguage">
            <!-- normal language (left) -->
            <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
              <InterventionEditorDetails
                :interventionDetails="interventionDetails"
                :translation="currentTranslation"
                :isTranslation="false"
                :isTranslationMode="isTranslationMode"
                :selectedLanguageForTranslation="selectedLanguage2"
                :isDiary="false"
                :studyId="studyId"
                @close-notification="closeNotification"
                @change-attribute="changeAttributeForDetails"
                @show-modal="showModal"
              ></InterventionEditorDetails>
            </section>

            <!-- translation language (right) -->
            <section :class="{col: isTranslationMode, sec50:isTranslationMode}">
              <InterventionEditorDetails
                v-if="isTranslationMode && getIsEditable"
                :interventionDetails="interventionDetails"
                :translation="currentTranslation2"
                :isTranslation="true"
                :isTranslationMode="isTranslationMode"
                :selectedLanguageForTranslation="selectedLanguage2"
                :isDiary="false"
                :studyId="studyId"
                @close-notification="closeNotification"
                @change-attribute="changeAttributeForDetails"
                @show-modal="showModal"
              ></InterventionEditorDetails>
            </section>

            <section class="wrapper">
              <button type="submit" id="buttonSaveInterventionDetails" :disabled="isSaved" @click="saveInterventionDetails">
                {{ 'generalTranslation.save' | translate }}
              </button>
            </section>
          </section>

          <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingLanguage"></i>
        </section>
      </section>

      <section v-if="translationStatusVisible" id="translationStatus">
        <section v-show="selectedView === 3" class="wrapper">
          <div v-if="translationStatus != null" key="progressDiv">
            <select v-model="selectedProgress" id="inputProgress" class="buttonForm" @change="changeProgressView">
              <option value="" id="inputProgressNone" disabled>
                {{ 'interventionTranslation.chooseProgress' | translate }}
              </option>
              <option value="total" id="inputProgressTotal">
                {{ 'interventionTranslation.total' | translate }}
              </option>
              <option v-for="lesson in allLessonsArray.length" :value="lesson" :id="'inputProgress' + lesson" :key="lesson">
                {{ 'generalTranslation.lesson' | translate }} {{lesson}}
              </option>
            </select>
            <section v-for="language in Object.keys(translationStatus)" :key="language">
              <section class="wrapper">
                <section class="col sec3">
                  {{language}}
                </section>
                <section class="col sec25">
                  <progress :value="translationStatus[language]" max="1"></progress>
                  {{ Math.round(translationStatus[language] * 100) + "%"}}
                </section>
              </section>
            </section>
          </div>
          <div v-else key="noProgressDiv">
            {{ 'interventionTranslation.noProgress' | translate }}
          </div>
        </section>
      </section>

      <section v-show="selectedView === 1" id="lessonsIntervention">
        <section v-if="!loadingLessons" class="wrapper">
          <button type="button" v-if="allLessonsArray.length > 1 && getIsEditable" id="buttonUpdateLessonPositions" class="buttonForm" @click="showUpdatePositions">
            {{ 'interventionTranslation.updateLessonPositions' | translate }}
          </button>
          <button type="button" v-if="$enabledFeatures.includes('skills') && allLessonsArray.length > 0 && skillListData.list && skillListData.list.length > 0 && getIsEditable"
              id="buttonUpdateLessonSkills" class="buttonForm" @click="showUpdateSkills">
            {{ 'interventionTranslation.updateSkills' | translate }}
          </button>
          <button type="button" v-if="allLessonsArray.length > 0 && !hasNoPermissions" id="buttonUpdateConfig" class="buttonForm" @click="updateConfig">
            {{configButtonText}}
          </button>
          <button type="button" v-if="getIsEditable" id="buttonCreateLesson" class="createButton" @click="createLesson" >
            {{ 'lessonTranslation.createLesson' | translate }}
          </button>
        </section>

        <LessonList
          :key="$route.fullPath"
          :allLessonsArray="allLessonsArray"
          :lessonListData="lessonListData"
          :copyBool="false"
          :disableWhileCopy="anyModalVisible"
          :interventionId="interventionId"
          :interventionDetails="interventionDetails"
          :skillsList="skillListData.list ? skillListData.list : []"
          @get-new-list="getNewLessonList"
          @route-to="routeTo"
          @close-notification="closeNotification"
          @show-modal="showModal"
        ></LessonList>
      </section>

      <section v-show="selectedView === 2" id="skillsIntervention">
        <section v-if="!loadingSkills" class="wrapper">
          <button type="button" v-if="getIsEditable" id="buttonCreateSkill" class="createButton" @click="createSkill">
            {{ 'interventionTranslation.createSkill' | translate }}
          </button>
        </section>

        <SkillList
          :key="$route.fullPath"
          :skillListData="skillListData"
          :copyBool="false"
          :disableWhileCopy="anyModalVisible"
          :interventionId="interventionId"
          :interventionDetails="interventionDetails"
          :studyId="studyId"
          @get-new-list="getNewSkillList"
          @route-to="routeTo"
          @close-notification="closeNotification"
          @show-modal="showModal"
        ></SkillList>
      </section>
    </section>
    <i class="fa fa-spinner fa-spin fa-2x loadData" v-if="loadingPage"></i>

    <LessonPositions
      v-if="modalVisible === 'updatePositions'"
      :allLessonsArray="allLessonsArray"
      @close-notification="closeNotification"
      @close-modal="closeModalSkillsPositions"
    ></LessonPositions>

    <SkillsForLesson
      v-if="modalVisible === 'updateSkills'"
      :allLessonsArray="allLessonsArray"
      :skillListData="skillListData"
      @close-modal="closeModalSkillsPositions"
    ></SkillsForLesson>

    <BaseModal
      v-if="modalVisible === 'warningChanges'"
      id="changesModal"
      :bodyText="$t('generalTranslation.unsavedChanges') + ': ' + $t('interventionTranslation.interventionDetails') + '.'"
      :leftButtonText="$t('interventionTranslation.leave')"
      :isLeaveSiteWarning="true"
      @close-modal="resolvePromise"
    ></BaseModal>

    <InterventionEditorConfig
      v-if="modalVisible === 'updateConfig'"
      :allLessonsArray="allLessonsArray"
      :interventionDetails="interventionDetails"
      @close-modal="closeModalConfig"
      @open-condition-editor="openConditionEditor"
    ></InterventionEditorConfig>

    <div
      v-if="modalVisible === 'LessonCreate' || modalVisible === 'SkillCreate'"
      :is="modalVisible"
      :targetData="targetData"
      @set-changed="setChanged"
      @close-modal="closeModal"
      @route-to="routeTo"
    ></div>

    <LessonCopy
      v-if="modalVisible === 'LessonCopy'"
      :sourceData="sourceData"
      @set-changed="setChanged"
      @close-modal="closeModalCopy"
      @close-notification="closeNotification"
    ></LessonCopy>

    <BaseNotification ref="notificationRef"></BaseNotification>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters,mapMutations} from 'vuex';
import router from '../router';
import httpHelper from '../mixins/httpHelper';
import elementJSONToPropsHelper from '../mixins/elementJSONToPropsHelper';
import BaseMenuBar from './BaseMenuBar.vue';
import BaseTabs from './BaseTabs.vue';
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import BaseSelectLanguage from './BaseSelectLanguage.vue';
import LessonList from './LessonList.vue';
import LessonCreate from './LessonCreate.vue';
import LessonCopy from './LessonCopy.vue';
import LessonPositions from './LessonPositions.vue';
import SkillCreate from './SkillCreate.vue';
import SkillList from './SkillList.vue';
import SkillsForLesson from './SkillsForLesson.vue';
import InterventionEditorDetails from './InterventionEditorDetails.vue';
import InterventionEditorConfig from './InterventionEditorConfig.vue';

export default {
  name: 'InterventionEditor',

  components: {
    BaseMenuBar,
    BaseTabs,
    BaseNotification,
    BaseModal,
    BaseSelectLanguage,
    LessonCreate,
    LessonList,
    LessonCopy,
    SkillCreate,
    SkillList,
    InterventionEditorDetails,
    InterventionEditorConfig,
    SkillsForLesson,
    LessonPositions
  },

  mixins: [httpHelper, elementJSONToPropsHelper],

  props: ['view', 'oldLessonId', 'oldSkillId'],

  data: function(){
    return{
      interventionDetails: [],
      selectedLanguage2: '',
      allLessonsArray: [],
      modalVisible: "",
      isSaved: false,
      translationStatus: [],
      translationStatusIntervention: [],
      selectedProgress: "total",
      selectedView: 0,
      loadingLanguage: true,
      formChanged: false, //true if intervention details changed by user & not yet saved
      isTranslationMode: false,
      currentTranslation: "",
      currentTranslation2: "",
      initBool: true,
      sourceData: "",
      lessonListData: {},
      studyDetails: [],
      loadingPage: true,
      translationStatusVisible: false,
      copyLessonId: "",
      getSpecificPage: false,
      resolvePromise: null,
      rejectPromise: null,
      loadingLessons: false,
      loadingSkills: false,
      skillListData: {},
      targetData: "",
      interventionDetailsFormId: "interventionDetailsForm",
    }
  },

  computed: {
    ...mapGetters([
      'getSelectedLanguage',
      'getNotificationText',
      'getPageRefreshBool',
      'getPageRefreshDetails',
      'getMyRoleForStudy',
      'getUserId',
      'getConditionEditor',
      'getConditionEditorData',
      'getIsEditable'
    ]),

    studyId: function(){
      return Number(this.interventionDetails.study_id)
    },

    interventionId: function(){
      return Number(this.$route.params.interventionId)
    },

    isOwner: function(){
      return (this.getMyRoleForStudy === "Owner")
    },

    hasNoPermissions: function(){
      return (this.getMyRoleForStudy === "None")
    },

    configButtonText: function(){
      if(this.isOwner){
        return Vue.i18n.translate('interventionTranslation.changeConfig')
      }else{
        return Vue.i18n.translate('interventionTranslation.showConfig')
      }
    },

    textPreviewButton: function(){
      return (Vue.i18n.translate('interventionTranslation.preview') + " " +
        Vue.i18n.translate('interventionTranslation.intervention'))
    },

    anyModalVisible: function(){
      return this.modalVisible === 'LessonCopy' || this.modalVisible === "updateConfig" || this.modalVisible === 'updatePositions' || this.modalVisible === 'updateSkills'
    },

    tabDetails: function(){
      return [
        {text: Vue.i18n.translate('interventionTranslation.interventionDetails'), show: true, id: "details"},
        {text: Vue.i18n.translate('interventionTranslation.lessonsOfIntervention'), show: true, id: "lessons"},
        {text: Vue.i18n.translate('interventionTranslation.skills'), show: this.$enabledFeatures.includes('skills'), id: "skills"},
        {text: Vue.i18n.translate('interventionTranslation.translationProgress'), show: true, id: "progress"},
      ]
    }
  },

  watch:{
    //get new data after copy lesson in different intervention
    '$route.params.interventionId': function(){
      this.loadingPage = true;
      this.loadingLanguage = true;
      this.lessonListData.currentPage = 1;
      this.selectedView = 1;
      this.getInitialData(1);
    },

    getNotificationText(newVal){
      if(newVal != "" && newVal.type === "error"){
        this.loadingLanguage = false;
        if(newVal != Vue.i18n.translate('interventionTranslation.unsavedChangesConfigLeave') &&
          newVal != Vue.i18n.translate('interventionTranslation.unsavedChangesConfigLeaveInterventionEditor') &&
          newVal != Vue.i18n.translate('interventionTranslation.unsavedChangesPositionsLeave') &&
          newVal != Vue.i18n.translate('interventionTranslation.unsavedChangesPositionsLeaveInterventionEditor')
        ){
          this.isSaved = false;
        }
      }else if(newVal != "" && newVal.type === "success"){
        this.isSaved = false;
      }
    },

    getSelectedLanguage(){
      this.setCurrentTranslations(false);
    },

    selectedLanguage2(newVal){
      if(newVal != ""){
        this.setCurrentTranslations(true);
      }else if(this.currentTranslation2 != ""){
        this.interventionDetails.translations.push(this.currentTranslation2);
        this.currentTranslation2 = "";
      }
    },
  },

  mounted(){
    if(this.getNotificationText.text != Vue.i18n.translate('interventionTranslation.createInterventionSuccess')){
      this.closeNotification();
    }
    if(this.getPageRefreshBool && this.getPageRefreshDetails.id === this.interventionId){
      this.interventionDetails = this.getPageRefreshDetails.details;
      this.selectedView = this.getPageRefreshDetails.view;
      this.studyDetails = this.getPageRefreshDetails.studyDetails;
      this.skillListData = this.getPageRefreshDetails.skillListData;
      this.formChanged = this.getPageRefreshDetails.formChanged;
      this.init();
      this.getNewLessons(0);
      this.loadingPage = false;
    }else if(this.getConditionEditor && this.getConditionEditorData != "" && this.$enabledFeatures.includes('jitai')){
      this.studyDetails = this.getConditionEditorData.studyDetails;
      this.interventionDetails = this.getConditionEditorData.interventionDetails;
      this.formChanged = this.getConditionEditorData.formChanged;
      this.allLessonsArray = this.getConditionEditorData.allLessonsArray;
      this.lessonListData = this.getConditionEditorData.lessonListData;
      this.selectedView = 1;
      this.init();
      this.loadingPage = false;
      this.modalVisible = "updateConfig";
    }else{
      if(this.view != undefined){
        this.selectedView = this.view;
      }
      if(this.oldLessonId != undefined){
        this.selectedView = 1;
        this.getSpecificPage = true;
      }
      if(this.oldSkillId != undefined && this.$enabledFeatures.includes('skills')){
        this.selectedView = 2;
      }
      this.getInitialData(1);
    }
    if(!this.getConditionEditor){
      this.SET_CONDITIONEDITORDATA("");
    }
    this.SET_CONDITIONEDITOR(false);
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    if(this.getIsEditable && document.getElementById(this.interventionDetailsFormId) != null){
      document.getElementById(this.interventionDetailsFormId).addEventListener("input", this.changeFormHasChanged);
    }

    window.addEventListener('beforeunload', this.beforeunloadFunction);
    window.addEventListener('unload', this.unloadFunction);
  },

  beforeRouteLeave(to, from, next){
    this.SET_PAGEREFRESHBOOL(false);
    this.SET_PAGEREFRESHDETAILS([]);

    if((this.modalVisible === 'updateConfig' && !this.getConditionEditor) || this.modalVisible === 'updatePositions' || this.modalVisible === 'LessonCreate' || this.modalVisible === 'LessonCopy' ||
        this.modalVisible === 'lessonDelete' || this.modalVisible === 'SkillCreate' || this.modalVisible === 'skillDelete' || this.modalVisible === 'updateSkills'){
      next(false);
    }else if(this.modalVisible != "updateConfig" && this.formChanged){
      var self = this;
      this.showWarningModal()
      .then(ok => {
        self.closeModal();
        if(ok){
          if(to.params.oldInterventionId === undefined && (to.name === "studyEditor" || to.name === "myInterventions")){
            to.params.oldInterventionId = self.interventionId;
          }
          next();
        }else{
          next(false);
        }
      });
    }else{
      if(to.params.oldInterventionId === undefined && (to.name === "studyEditor" || to.name === "myInterventions")){
        to.params.oldInterventionId = this.interventionId;
      }
      next();
    }
  },

  beforeDestroy(){
    if(this.getIsEditable && document.getElementById(this.interventionDetailsFormId) != null){
      document.getElementById(this.interventionDetailsFormId).removeEventListener("input", this.changeFormHasChanged);
    }
    window.removeEventListener('beforeunload', this.beforeunloadFunction);
    window.removeEventListener('unload', this.unloadFunction);
  },

  methods: {
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
      'SET_LESSONELEMENTSARR',
      'SET_SELECTEDLANGUAGE',
      'SET_PAGEREFRESHBOOL',
      'SET_PAGEREFRESHDETAILS',
      'SET_CODEVIEWDATA',
      'SET_CONDITIONEDITOR',
      'SET_CONDITIONEDITORDATA',
    ]),

    showWarningModal: function(){
      return new Promise((resolve, reject) => {
        this.modalVisible = 'warningChanges';
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      })
    },

    getInitialData: function(skillPage){
      var self = this;
      this.requestInterventionDetails(this.interventionId)
      .then(function (response){
        self.interventionDetails = response;
        self.init();
        return self.requestCollaboratorsIncludingPermissions("page=0&id=" + self.getUserId, self.studyId, false, -1, -1)
      })
      .then(function (){
        return self.requestStudyDetails(self.studyId)
      })
      .then(function(response){
        self.studyDetails = response;
        if(self.$enabledFeatures.includes('skills')){
          return self.requestSkillsOfIntervention(self.interventionId, skillPage, false)
        }else{
          return {list: [], currentPage: 0, allPages: 0, total: 0}
        }
      })
      .then(function(response){
        self.skillListData = response;
        self.loadingPage = false;
        self.getNewLessons(0);
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getInitialData(skillPage) }, "studyDetails");
      });
    },

    getDetailsOfIntervention: function(initBool, isSkill){
      var self = this;
      this.requestInterventionDetails(this.interventionId)
      .then(function (response){
        self.interventionDetails = response;
        self.init();

        if(initBool && !isSkill){
          self.getNewLessons(0);
        }else if(initBool && isSkill){
          self.getNewSkills(self.skillListData.currentPage);
        }
      })
      .catch(function (error){
        self.handleErrors(error, function(){ self.getDetailsOfIntervention(initBool, isSkill) }, "interventionDetails");
      });
    },

    init: function(){
      this.setCurrentTranslations(false);

      if(this.initBool === true){
        this.initBool = false;
        if(this.interventionDetails.locales == null || this.interventionDetails.locales.length === 0){
          this.interventionDetails.locales = ["de", "en"];
        }
        if(!this.interventionDetails.locales.includes(this.getSelectedLanguage)){
          var language = (this.interventionDetails.locales.includes("de")) ? "de" : this.interventionDetails.locales[0];
          this.SET_SELECTEDLANGUAGE(language);
        }
        if(this.$enabledFeatures.includes('progress')){
          if(!this.interventionDetails.gamification){
            this.interventionDetails.gamification = {
              progressbars_enabled: 0
            };
          }else if(!this.interventionDetails.gamification.progressbars_enabled){
            this.interventionDetails.gamification.progressbars_enabled = 0;
          }
        }

        if(this.$enabledFeatures.includes('skills')){
          if(!this.interventionDetails.gamification){
            this.interventionDetails.gamification = {
              skills_order: []
            };
          }else if(!this.interventionDetails.gamification.skills_order){
            this.interventionDetails.gamification.skills_order = [];
          }
        }
      }
    },

    showModal: function(obj){
      switch (obj.type) {
        case "LessonDelete":
          this.modalVisible = obj.value ? 'lessonDelete' : "";
        break;

        case "LessonCopy":
          this.closeNotification();
          this.sourceData = obj;
          this.sourceData.studyId = this.studyId;
          this.modalVisible = 'LessonCopy';
          this.showWarningUnsavedChanges();
        break;

        case "SkillDelete":
          this.modalVisible = obj.value ? 'skillDelete' : "";
        break;

        case "SkillCopy":
          console.log("todo copy skill");
        break;

        case "LanguageError":
          this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.deleteSelectedLanguageWarning')});
        break;

        default:
        break;
      }
    },

    changeFormHasChanged: function(){
      this.formChanged = true;
    },

    createLesson: function(){
      this.closeNotification();
      this.SET_LESSONELEMENTSARR([]);
      this.targetData = { lessonCounter: this.allLessonsArray.length, details: this.interventionDetails };
      this.modalVisible = "LessonCreate";
      this.showWarningUnsavedChanges();
    },

    createSkill: function(){
      this.closeNotification();
      this.SET_LESSONELEMENTSARR([]);
      this.targetData = this.interventionDetails;
      this.modalVisible = 'SkillCreate';
      this.showWarningUnsavedChanges();
    },

    closeModal: function(){
      this.modalVisible = "";
    },

    closeModalCopy: function(obj){
      this.closeModal();

      if(obj.done){
        var newInterventionId = obj.interventionId;
        var newLessonId = obj.lessonId;
        //route stays the same -> only get new lessons array because contains 1 more
        if(this.interventionId === newInterventionId){
          var totalPages = Math.ceil(this.allLessonsArray.length/this.$perPage);
          if(totalPages > 0 && this.allLessonsArray.length % this.$perPage === 0){
            //last page has 15 -> copy is on next
            totalPages++;
          }
          //get new lessons & int details
          this.modalVisible = "";
          this.lessonListData.currentPage = totalPages;
          this.copyLessonId = newLessonId;
          this.getDetailsOfIntervention(true, false);
        }else{
          //route changes -> get new intervention Details, permissions, lessons (last page) in route.params.interventionId watch function
          this.copyLessonId = newLessonId;
          router.push({ name: 'interventionEditor', params: { interventionId: newInterventionId }});
        }
      }
    },

    saveInterventionDetails: function(){
      if(this.interventionDetails.name === "" || this.currentTranslation.title === "" || this.currentTranslation.description === "" ||
          this.currentTranslation2 != "" && (this.currentTranslation2.title === "" || this.currentTranslation2.description === "")){

        var errorText = "";
        if(this.interventionDetails.name === ""){
          errorText += Vue.i18n.translate('generalTranslation.name');
        }

        if(this.currentTranslation.title === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.title');
        }

        if(this.currentTranslation.description === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.description');
        }

        if(this.currentTranslation2 != "" && this.currentTranslation2.title === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.titleTranslation');
        }

        if(this.currentTranslation2 != "" && this.currentTranslation2.description === ""){
          errorText += ((errorText != "") ? ", " : "") + Vue.i18n.translate('interventionTranslation.descriptionTranslation');
        }

        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('adminUsersTranslation.errorFillIn') + errorText});
      }else{
        this.isSaved = true;
        this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.updateInterventionDetailsLoad')});

        var details = JSON.parse(JSON.stringify(this.interventionDetails));

        if(!details.page_color){
          details.page_color = this.$defaultLessonPageColor;
        }

        if(details.intervention_type === "accompanied"){
          details.is_private = 1; //addedByEcoach
        }else{
          details.is_private = 0; //addedOrSubscribing
        }

        details.password = "";

        //all translations in translations array
        details.translations.push(this.currentTranslation);
        if(this.currentTranslation2 != ""){
          details.translations.push(this.currentTranslation2);
        }

        var json = {
          "data" : {
            "type" : "interventions",
            "attributes" : details
          }
        };

        this.updateIntDetails(json, false);
      }
    },

    closeModalSkillsPositions: function(obj){
      this.modalVisible = "";
      if(obj.done){
        this.getNewLessons(0);
      }else{
        this.closeNotification();
      }
    },

    closeModalConfig: function(obj){
      if(obj.done){
        this.updateIntDetails(obj.json, true);
      }else{
        this.modalVisible = "";
        this.closeNotification();
      }
    },

    updateIntDetails: function(json, updateConfigBool){
      if(json.data.attributes.unlock_diaries === null){
        json.data.attributes.unlock_diaries = [];
      }

      var self = this;
      this.updateInterventionDetailsRequest(json, this.interventionId)
      .then( function(){
        if(!updateConfigBool){
          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updateInterventionDetailsSuccess')});
          self.formChanged = false;
          self.getDetailsOfIntervention(false, false);
        }else{
          //set updated values (is patch so maybe not all are included)
          for(var attr in json.data.attributes){
            self.interventionDetails[attr] = json.data.attributes[attr];
          }

          self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.updateInterventionConfigSuccess')});
          self.getNewLessons(0);
          self.modalVisible = "";
        }
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.updateIntDetails(json, updateConfigBool) }, "config");
      });
    },

    changeLanguage2: function(language){
      this.selectedLanguage2 = language;
    },

    changeTranslationMode: function(isTranslationMode){
      this.isTranslationMode = isTranslationMode;
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    unloadFunction: function(){
      this.SET_PAGEREFRESHBOOL(true);
      this.closeNotification();
      this.isTranslationMode = false;

      //all translations in translations array
      var interventionDetailsArr = JSON.parse(JSON.stringify(this.interventionDetails));
      if(this.currentTranslation){
        interventionDetailsArr.translations.push(this.currentTranslation);
        this.currentTranslation = "";
      }

      if(this.currentTranslation2){
        interventionDetailsArr.translations.push(this.currentTranslation2);
        this.currentTranslation2 = "";
      }

      var data = {
        id: this.interventionId,
        details: interventionDetailsArr,
        view: this.selectedView,
        studyDetails: this.studyDetails,
        skillListData: this.skillListData,
        formChanged: this.formChanged,
      };

      this.SET_PAGEREFRESHDETAILS(data);
    },

    showPreview: function(){
      this.closeNotification();
      router.push({ name: 'interventionPreview', params: { interventionId: this.interventionId}});
    },

    changeProgressView: function(){
      this.closeNotification();
      if(this.selectedProgress != ""){
        if(this.selectedProgress === "total"){
          this.translationStatus = this.translationStatusIntervention;
        }else{
          var self = this;
          this.requestLessonDetails(this.allLessonsArray[this.selectedProgress - 1].id)
          .then(function (response){
            self.translationStatus = response.translation_status;
          })
          .catch(function (error){
            self.handleErrors(error, function(){ self.changeProgressView() }, "");
          });
        }
      }
    },

    showView: function(view){
      this.selectedView = view;
      if(view === 3){
        this.getTranslationStatusIntervention();
      }
    },

    getTranslationStatusIntervention: function(){
      var self = this;
      this.requestInterventionTranslationStatus(this.interventionId)
      .then( function(response){
        self.translationStatusIntervention = response;
        self.translationStatus = response;
        self.translationStatusVisible = true;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getTranslationStatusIntervention() }, "");
      });
    },

    routeTo: function(obj){
      this.closeModal();
      router.push(obj);
    },

    updateConfig: function(){
      this.closeNotification();
      this.modalVisible = "updateConfig";
    },

    exportInterv: function(){
      this.SET_NOTIFICATIONTEXT({type: "load", text: Vue.i18n.translate('interventionTranslation.exportInterventionLoad')});
      var self = this;
      this.exportInterventionRequest(this.interventionId)
      .then( function(response){
        let dataStr = JSON.stringify(response, null, "\t");
        let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        let exportFileDefaultName = 'data.json';
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
        self.SET_NOTIFICATIONTEXT({type: "success", text: Vue.i18n.translate('interventionTranslation.exportInterventionSuccess')});
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.exportInterv() }, "");
      });
    },

    beforeunloadFunction: function(e){
      if(this.formChanged || (this.modalVisible === "updateConfig" && this.isOwner)){ //not checked if actual changes in config
        e.preventDefault();
        e.returnValue = '';
        return;
      }
      delete e['returnValue'];
    },

    showWarningUnsavedChanges: function(){
      if(this.formChanged){
        var text = Vue.i18n.translate('interventionTranslation.unsavedChangesInterventionDetails') + " ";
        if(this.modalVisible === 'LessonCopy'){
          text += Vue.i18n.translate('generalTranslation.warningCopy');
        }else{
          text += Vue.i18n.translate('generalTranslation.warningCreate');
        }
        this.SET_NOTIFICATIONTEXT({type: "error", text: text});
      }
    },

    setChanged: function(){
      this.formChanged = false;
    },

    changeAttributeForDetails: function(obj){
      this.closeNotification();
      if(obj.name === "locales"){
        obj.value.sort();
      }else if(obj.name === "is_private"){
        this.interventionDetails.accesstype = (obj.value === 1) ? "invite" : "";
      }

      if(obj.name === "progressbars_enabled"){
        this.interventionDetails.gamification.progressbars_enabled = obj.value;
      }else if(obj.name != "title" && obj.name != "description"){
        this.interventionDetails[obj.name] = obj.value;
      }else if(!obj.isTranslation){
        this.currentTranslation[obj.name] = obj.value;
      }else{
        this.currentTranslation2[obj.name] = obj.value;
      }
      this.formChanged = true;
    },

    setCurrentTranslations: function(isTranslation){
      this.loadingLanguage = true;
      var language = !isTranslation ? this.getSelectedLanguage : this.selectedLanguage2;

      if(this.selectedLanguage2 === "" && this.currentTranslation2 != ''){
        this.interventionDetails.translations.push(this.currentTranslation2);
        this.currentTranslation2 = '';
      }

      var translation = '';
      var index = this.interventionDetails.translations.findIndex(elem => elem.locale === language);
      if(index != -1){
        translation = this.interventionDetails.translations[index];
        this.interventionDetails.translations.splice(index, 1);
      }else if(language != ""){
        translation = {
          "locale": language,
          "title": "",
          "description": "",
        };
      }

      if(!isTranslation){
        if(this.currentTranslation != ""){
          this.interventionDetails.translations.push(this.currentTranslation);
        }
        if(translation != ""){
          this.currentTranslation = translation;
        }
      }else{
        if(this.currentTranslation2 != ''){
          this.interventionDetails.translations.push(this.currentTranslation2);
        }
        if(translation != "" && this.selectedLanguage2 != ""){
          this.currentTranslation2 = translation;
        }
      }
      this.loadingLanguage = false;
    },

    getNewLessonList: function(obj){
      if(obj.getDetails){
        //get new lessons & int details
        this.modalVisible = "";
        this.lessonListData.currentPage = obj.page;
        this.getDetailsOfIntervention(true, false);
      }else{
        this.getNewLessons(obj.page);
      }
    },

    getNewLessons: function(page){
      this.loadingLessons = true;
      var self = this;
      //todo change last to true after translation status include is faster
      this.requestLessonsOfIntervention(this.interventionId, page, this.getIsEditable, this.getSpecificPage, this.oldLessonId, this.copyLessonId, false)
      .then( function(response){
        if(response.allLessons.length > response.list.length || response.allPages === 1){ //limit=0 or only one page
          self.allLessonsArray = response.allLessons;
        }

        if(response.allLessons.length > response.list.length && self.getSpecificPage || self.copyLessonId != ""){ //limit=0
          self.copyLessonId = "";
          self.getSpecificPage = false;
        }

        self.lessonListData = response;
        self.loadingLessons = false;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewLessons(page) }, "");
      });
    },

    getNewSkillList: function(obj){
      if(obj.getDetails){
        //get new skills & int details (to get newest version of skills_order)
        this.modalVisible = "";
        this.loadingPage = true;
        //get new int details, skills list, lesson list
        this.getInitialData(this.skillListData.currentPage)
      }else{
        this.getNewSkills(obj.page);
      }
    },

    getNewSkills: function(page){
      this.loadingSkills = true;
      var self = this;
      this.requestSkillsOfIntervention(this.interventionId, page, false)
      .then( function(response){
        self.skillListData = response;
        self.loadingSkills = false;
      })
      .catch( function(error){
        self.handleErrors(error, function(){ self.getNewSkills(page) }, "");
      });
    },

    showUpdatePositions: function(){
      this.closeNotification();
      this.modalVisible = 'updatePositions';
    },

    showUpdateSkills: function(){
      this.closeNotification();
      this.modalVisible = 'updateSkills';
    },

    openConditionEditor: function(data){
      //save data of current page to re-init after condition set
      data.studyDetails = this.studyDetails;
      data.interventionDetails = this.interventionDetails;
      data.formChanged = this.formChanged;
      data.allLessonsArray = this.allLessonsArray;
      data.lessonListData = this.lessonListData;

      this.SET_CODEVIEWDATA("");
      this.SET_CONDITIONEDITOR(true);
      this.SET_CONDITIONEDITORDATA(data);
      router.push({ name: 'conditionEditor', params:{ lessonId: 0 }});
    }
  }
}
</script>
