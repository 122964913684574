<template>
  <div class="skillEditorDetails">
    <section :style="{visibility: showIfNotTranslation}">
      <label>
        {{ 'interventionTranslation.name' | translate }} *
      </label>
      <input type="text" :value="skillDetails.name" id="inputName" :placeholder="$t('interventionTranslation.name')"
        :disabled="!getIsEditable" @input="$emit('change-attribute', {'name': 'name', 'value': $event.target.value})">
    </section>

    <label>
      {{titleTextInLanguage}} *
    </label>
    <input type="text" :value="translation.title" :id="'inputTitle' + (isTranslation ? 'Translation' : '')" :placeholder="titleTextInLanguage" :disabled="!getIsEditable"
      @input="$emit('change-attribute', {'name': 'title', 'isTranslation': isTranslation, 'value': $event.target.value})">

    <section v-if="!isTranslation">
      <section class="wrapper">
        <label>
          {{ 'interventionTranslation.selectSkillColor' | translate }} *
        </label>
        <input type="color" id="inputSkillColor" list="skillColorValues" :value="colorValue" :disabled="!getIsEditable"
          @input="$emit('change-attribute', {'name': 'color', 'value': $event.target.value})">
        <datalist id="skillColorValues">
          <option v-for="data in colorsList" :id="'skillColor' + data" :key="data" :value="data">
          </option>
        </datalist>
      </section>

      <section class="wrapper">
        <br>
        <label>
          {{ 'interventionTranslation.skillIcon' | translate }}
        </label>
        {{ 'interventionTranslation.skillIconType' | translate }}
        <section class="wrapper media">
          <section class="col sec50">
            <img v-if="pagePictureLink != ''" :src="pagePictureLink">
            <i class="far fa-image fa-2x" v-else></i>
            <br><br>
            <button type="button" v-if="getIsEditable" id="buttonSelectSkillIcon" @click="getStudyMedia">
              {{ 'interventionTranslation.selectSkillIcon' | translate }}
            </button>
            <button type="button" v-if="getIsEditable && pagePictureLink != ''" id="buttonRemoveSkillIcon" class="skillIconButton" @click="$emit('change-attribute', {'name': 'icon', 'value': ''})">
              {{ 'interventionTranslation.removeSkillIcon' | translate }}
            </button>
          </section>
          <section class="col sec50">
          </section>
        </section>
      </section>
    </section>

    <StudyMedia
      v-if="studyMediaVisible"
      id="mediaSelection"
      :allFiles="false"
      :isSelection="true"
      :studyId="studyId"
      :multiple="false"
      @item-selected="selectMedia"
      @close-modal="closeModal"
    ></StudyMedia>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import StudyMedia from './StudyMedia.vue';

export default {
  name: 'SkillEditorDetails',

  components: {
    StudyMedia
  },

  props: {
    skillDetails: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },

    selectedLanguageForTranslation: {
      required: true,
      type: String,
    },

    isTranslationMode: {
      required: true,
      type: Boolean,
    },

    studyId: {
      required: true,
      type: Number,
    }
  },

  data: function(){
    return {
      selectedLanguageToAdd: '',
      colorsList: [
        "#fefcf0", "#ffffff", "#bbbbbb", "#888888", "#444444",
        "#facccc", "#f06666", "#e60000", "#a10000", "#5c0000",
        "#ffebcc", "#ffc266", "#ff9900", "#b26b00", "#663d00",
        "#ffffcc", "#ffff66", "#ffff00", "#b2b200", "#666600",
        "#cce8cc", "#66b966", "#008a00", "#006100", "#003700",
        "#cce0f5", "#66a3e0", "#0066cc", "#0047b2", "#002966",
        "#ebd6ff", "#c285ff", "#9933ff", "#6b24b2", "#3d1466"
      ],
      studyMediaVisible: false,
    }
  },

  computed: {
    ...mapGetters([
      'getAvailableLocales',
      'getSelectedLanguage',
      'getIsEditable'
    ]),

    showIfNotTranslation: function(){
      return !this.isTranslation ? 'visible' : 'hidden'
    },

    pagePictureLink: function(){
      return this.skillDetails.icon ? this.$urlUploads + "/studies/" + this.studyId + "/" + this.skillDetails.icon : ""
    },

    colorValue: function(){
      return this.skillDetails.color ? this.skillDetails.color : "#15417e"
    },

    titleTextInLanguage: function(){
      if(!this.isTranslation){
        return Vue.i18n.translate('interventionTranslation.title')
      }else{
        return Vue.i18n.translate('interventionTranslation.titleTranslation')
      }
    },
  },

  methods:{
    ...mapMutations([
      'SET_NOTIFICATIONTEXT',
    ]),

    updateLanguages: function(){
      if(this.selectedLanguageToAdd != ""){
        this.closeNotification();
        var arr = JSON.parse(JSON.stringify(this.skillDetails.locales));
        arr.push(this.selectedLanguageToAdd);
        this.selectedLanguageToAdd = "";
        this.$emit('change-attribute', {'name': 'locales', 'value': arr});
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('interventionTranslation.selectLanguageWarning')});
      }
    },

    deleteLanguage: function(index){
      var arr = JSON.parse(JSON.stringify(this.skillDetails.locales));

      if((arr[index] === this.getSelectedLanguage) || (arr[index] === this.selectedLanguageForTranslation) && this.isTranslationMode){
        this.$emit('show-modal', { type: 'LanguageError' });
      }else{
        arr.splice(index, 1);
        this.$emit('change-attribute', {'name': 'locales', 'value': arr});
      }
    },

    closeNotification: function(){
      this.$emit('close-notification');
    },

    getStudyMedia: function(){
      this.studyMediaVisible = true;
      this.$emit('show-modal', { type: 'StudyMedia', value: true });
    },

    selectMedia: function(obj){
      var filename = obj.link.split("").reverse().join("");
      filename = filename.substr(0, filename.indexOf('/'));
      filename = filename.split("").reverse().join("");
      this.$emit('change-attribute', {'name': 'icon', 'value': filename });
      this.closeModal();
    },

    closeModal: function(){
      this.studyMediaVisible = false;
      this.$emit('show-modal', { type: 'StudyMedia', value: false });
    },
  }
}
</script>
