<template>
  <div class="elementsQuestionAnswer" :id="id">
    <li>
      <section :class="[{radioSection: type === 'SingleChoice' && !isTranslation, checkboxSection: type === 'MultipleChoice' && !isTranslation}]">
        <section class="col">
          <section :class="{wrapper: !isTranslation, secAnswerTranslation: isTranslation && type != 'QuestionTableAnswer' && type != 'QuestionTableQuestion'}">
            <section v-if="!isTranslation && type != 'QuestionTableAnswer' && type != 'QuestionTableQuestion'" class="col secAnswerLeft">
              <label class="container">
                <input :type="type === 'SingleChoice' ? 'radio': 'checkbox'" disabled>
                <span class="checkmark"></span>
              </label>
            </section>

            <section class="col" :class="{secAnswer: !isTranslation && type != 'QuestionTableQuestion' && type != 'QuestionTableAnswer'}">
              <label>
                {{answerTextLabelInLanguage}} *
              </label>
              <ElementsReference
                :text="answerProp"
                :id="id + 'answerOption'"
                :placeholder="answerTextLabelInLanguage"
                :isSkill="false"
                classToSet="answerReference"
                @change-text="changeText"
                @change-element="$emit('change-element', $event)"
              ></ElementsReference>
            </section>

            <section v-if="!isTranslation" class="col secAnswerL">
              <section v-if="type != 'QuestionTableAnswer' && type != 'QuestionTableQuestion'">
                <section class="wrapper">
                  <label>
                    {{ 'answerTranslation.label' | translate }} *
                  </label>
                </section>

                <textarea
                  v-if="getIsEditable"
                  :id="id + 'answerValue'"
                  :value="valueProp"
                  @input="$emit('change-element', {'type': 'attribute', 'name': 'values', 'pos': pos, 'isTranslation': isTranslation, 'value': $event.target.value})"
                />
                <p v-else>
                  {{valueProp}}
                </p>

                <button type="button" v-if="getIsEditable" :id="'buttonGenerateLabel' + id" @click="generateAnswerLabel">
                  {{ 'elementsQuestionTranslation.generateQuestionLabel' | translate }}
                </button>
              </section>
            </section>
          </section>
        </section>
      </section>
    </li>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import elementPropsToJSONHelper from '../mixins/elementPropsToJSONHelper';
import ElementsReference from './ElementsReference.vue';

export default {
  name: 'ElementsQuestionAnswer',

  components: {
    ElementsReference
  },

  mixins: [elementPropsToJSONHelper],

  props: {
    id: {
      required: true,
      type: String,
    },

    answerProp: {
      required: true,
      type: String,
    },

    valueProp: {
      required: true,
      type: String,
    },

    type: {
      required: true,
      type: String,
    },

    pos: {
      required: true,
      type: Number,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),

    answerTextLabelInLanguage: function(){
      var text;
      if(this.type === 'QuestionTableAnswer'){
        text = Vue.i18n.translate('answerTranslation.answerText' + (this.isTranslation ? 'Translation' : '')) + " " + this.valueProp;
      }else if(this.type === 'QuestionTableQuestion'){
        text = Vue.i18n.translate('elementsQuestionTranslation.question' + (this.isTranslation ? 'Translation' : '')) + " " + this.valueProp;
      }else{
        text = Vue.i18n.translate('answerTranslation.answer' + (this.isTranslation ? 'Translation' : ''));
      }
      return text
    },
  },

  methods:{
    generateAnswerLabel: function(){
      var generatedLabel = this.answerProp.toLowerCase().replace(/ä/g,"ae").replace(/ö/g,"oe").replace(/ü/g,"ue").replace(/ß/g,"ss").replace(/[\W_]+/g,"").substring(0,20);
      this.$emit('change-element', {'type': 'attribute', 'name': 'values', 'pos': this.pos, 'isTranslation': this.isTranslation, 'value': generatedLabel});
    },

    changeText: function(text){
      this.$emit('change-element', {'type': 'translation-attribute',  'name': (this.type != 'QuestionTableQuestion') ? 'answers' : 'tableQuestions', 'pos': this.pos, 'isTranslation': this.isTranslation, 'value': text });
    },
  }
}
</script>
