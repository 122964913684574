<template>
  <div class="elementsQuestionTable">
    <section class="wrapper">
      <section v-if="!isTranslation">
        <h3 class="answers">
          {{ 'elementsQuestionTranslation.answerOptions' | translate }} *
        </h3>
        <input type="number" :value="propsObj.values.min" min="0" :id="'inputAnswerValueMin' + propsObj.id" class="questionTableInput" :disabled="!getIsEditable"
          @input="changeValue({'value': $event.target.value, 'pos': 'min'})">
        {{ 'generalTranslation.to' | translate }}
        <input type="number" :value="propsObj.values.max" :min="propsObj.values.min + 2" :id="'inputAnswerValueMax' + propsObj.id" class="questionTableInput"
          :disabled="!getIsEditable" @input="changeValue({'value': $event.target.value, 'pos': 'max'})">
      </section>

      <ul :class="{ulAnswers: getIsEditable, wrapper: !getIsEditable, answerSpaceTable: isTranslation}">
        <li v-for="answer in (propsObj.values.max - propsObj.values.min + 1)" :key="answer">
          <ElementsQuestionAnswer
            :key="propsObj.values.min + answer - 1"
            :id="'element' + propsObj.id + 'answer' + (propsObj.values.min + answer - 1) + (isTranslation ? 'Translation' : '')"
            :pos="propsObj.values.min + answer - 1"
            :answerProp="translation.answers[propsObj.values.min + answer - 1]"
            :valueProp="propsObj.values.min + answer - 1"
            :type="propsObj.selectedQuestionProp + 'Answer'"
            :isTranslation="isTranslation"
            @change-element="$emit('change-element', $event)"
          ></ElementsQuestionAnswer>
        </li>
      </ul>

      <h3 v-if="!isTranslation" class="answers">
        {{ 'elementsQuestionTranslation.questions' | translate }}
      </h3>
      <div v-else class="spaceQuestionText">
      </div>

      <ul :class="{ulAnswers: getIsEditable, wrapper: !getIsEditable}">
        <li v-for="question in propsObj.values.questions" :key="question">
          <section :class="{wrapper: !isTranslation}">
            <section :class="{col: getIsEditable, secAnswerTable: getIsEditable}">
              <ElementsQuestionAnswer
                :key="questionCounter + question"
                :id="'element' + propsObj.id + 'question' + (questionCounter + question) + (isTranslation ? 'Translation' : '')"
                :pos="question - 1"
                :answerProp="translation.question[question - 1]"
                :valueProp="question"
                :type="propsObj.selectedQuestionProp + 'Question'"
                :isTranslation="isTranslation"
                @change-element="$emit('change-element', $event)"
              ></ElementsQuestionAnswer>
            </section>

            <section v-if="getIsEditable && !isTranslation" class="col secAnswerLeft">
              <i
                class="fa fa-trash"
                :id="'buttonRemoveQuestionTableQuestion' + propsObj.id + 'Index' + (question - 1)"
                v-tooltip="$t('elementsQuestionTranslation.removeQuestion')"
                @click="addRemoveQuestion('removeQuestion', question - 1)"
              ></i>
            </section>
          </section>
        </li>
      </ul>

      <section v-if="getIsEditable && !isTranslation" class="wrapper addAnswerButton">
        <button type="button" :id="'buttonAddQuestionTableQuestion' + propsObj.id" @click="addRemoveQuestion('addQuestion', 0)">
          {{ 'elementsQuestionTranslation.addQuestion' | translate }}
        </button>
      </section>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ElementsQuestionAnswer from './ElementsQuestionAnswer.vue'

export default {
  name: 'ElementsQuestionTable',

  components: {
    ElementsQuestionAnswer,
  },

  props: {
    propsObj: {
      required: true,
      type: Object,
    },

    translation: {
      required: true,
      type: Object,
    },

    isTranslation: {
      required: true,
      type: Boolean,
    },
  },

  data: function(){
    return {
      questionCounter: 0,
    }
  },

  computed: {
    ...mapGetters([
      'getIsEditable',
    ]),
  },

  methods:{
    changeValue: function(obj){
      this.$emit('change-element', {'type': 'attribute', 'name': 'values', 'pos': obj.pos, 'isTranslation': this.isTranslation, 'value': obj.value});
    },

    addRemoveQuestion: function(name, questionIndex){
      console.log(this.questionCounter)
      console.log(this.propsObj.values.questions)
      this.questionCounter += this.propsObj.values.questions;
      console.log(this.questionCounter)
      this.$emit('change-element', {'type': 'attribute', 'name': name, 'isTranslation': this.isTranslation, 'pos': questionIndex });
    },
  }
}
</script>
