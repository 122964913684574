<template>
<div class="LessonEditorConditionEditor">
    <div class="wrapperCondition">
      <div class="col pageDragAndDropSelection" id="sectionAddElements">
        <details open>
          <summary>
            {{ 'conditionEditorTranslation.addNewItems' | translate }}
          </summary>
          <draggable
            tag="ul"
            id="listAddElements"
            :list="newElements"
            :group="{ name: 'g1', pull: 'clone', put: false}"
            :sort="false"
            :clone="cloneFunction"
            class="selectionList list-group"
          >
            <li v-for="element in newElements" :id="'entryDragDropElement' + element.nodeType" :key="element.nodeType" class="list-group-item">

              <section class="wrapper">
                <section class="col secLeftRight">
                  <i class="fas fa-info-circle" v-tooltip="{ content: element.description, theme: 'info-tooltip' }"></i>
                </section>

                <section v-if="element.nodeType != 'leaf'" key="secNode" class="col secMiddle">
                  <section class="nodeSection">
                    <section class="secSelection">
                      <section v-if="element.nodeType != 'not'" class="secSelectionChild">
                      </section>
                    </section>
                    <section class="secSelection">
                      {{getNodeTypeText(element.nodeType)}}
                      <!--{{element.nodeType.toUpperCase()}}-->
                    </section>
                    <section class="secSelection">
                      <section class="secSelectionChild">
                      </section>
                    </section>
                  </section>
                </section>

                <section v-else key="secLeaf" class="col secMiddle">
                  <section class="nodeSection secLeaf">
                    <section class="secSelection secQuestion">
                      {{ 'conditionEditorTranslation.questionAnswered' | translate }} <br>
                      {{ 'conditionEditorTranslation.with' | translate }} ...
                    </section>
                  </section>
                </section>

                <section class="col secLeftRight">
                </section>
              </section>
            </li>
          </draggable>
        </details>

        <details open class="secTestCondition">
          <summary>
            {{ 'conditionEditorTranslation.testCondition' | translate }}
          </summary>
          <br>
          {{ 'conditionEditorTranslation.testConditionDescription' | translate }}
          <br>

          <form class="buttonForm" @submit.prevent="testConditionFunction">
            <section v-for="(question, index) in questionsUsedInTree" :key="index"> <!-- todo key -->
              {{questionLabelList[question].label}}
              <!--question value-->
              <select v-if="questionLabelList[question].type === 'question'" v-model="questionLabelList[question].testValue" :id="'inputTestValue' + question" @change="updateTest">
                <option value="" :id="'inputTestValue' + question + 'None'" disabled selected>
                  {{ 'elementsBlockTranslation.selectAnswer' | translate }}
                </option>
                <option v-for="(value, index) in questionLabelList[question].value" :value="value" :id="'inputTestValue' + question + '-' + value" :key="index"> <!-- todo key -->
                  {{getValueText(value)}}
                </option>
              </select>

              <!--slider value-->
              <input type="number" v-else-if="questionLabelList[question].type === 'slider'" v-model="questionLabelList[question].testValue" :min="questionLabelList[question].value.min"
                :max="questionLabelList[question].value.max" :step="questionLabelList[question].value.step" :id="'inputTestValue' + question" key="slider" @change="updateTest">

              <!--date/time value-->
              <flatPickr v-if="questionLabelList[question].type === 'date' && questionLabelList[question].value[0] === 'TextDateTime'" :id="'inputTestValue' + question + questionLabelList[question].value[0]"
                key="dateTimePicker" v-model="questionLabelList[question].testValue" :config="configDateTime" :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
                @on-change="updateTest"></flatPickr>

              <flatPickr v-else-if="questionLabelList[question].type === 'date' && questionLabelList[question].value[0] === 'TextDate'" :id="'inputTestValue' + question + questionLabelList[question].value[0]"
                key="datePicker" v-model="questionLabelList[question].testValue" :config="configDate" :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
                @on-change="updateTest"></flatPickr>

              <flatPickr v-else-if="questionLabelList[question].type === 'date' && questionLabelList[question].value[0] === 'TextTime'" :id="'inputTestValue' + question + questionLabelList[question].value[0]"
                key="timePicker" v-model="questionLabelList[question].testValue" :config="configTime" :placeholder="$tlang(getSelectedLanguage, 'elementsQuestionTranslation.selectDate')"
                @on-change="updateTest"></flatPickr>

              <!--text value-->
              <input type="text" v-if="questionLabelList[question].type === 'text'" v-model="questionLabelList[question].testValue" :id="'inputTestValue' + question" @input="updateTest">
            </section>
            <br>
            <button type="submit" id="buttonTestCondition">
              {{testButtonText}}
            </button>
          </form>
        </details>
      </div>

      <div class="col pageDragAndDrop" id="sectionCondition">
        <section class="wrapper">
          <section class="col sec17">
            <button type="button" id="buttonOpenCodeEditor" class="buttonFormLeft saveConditionButton" @click="openCodeView">
              {{ 'conditionEditorTranslation.codeView' | translate }}
            </button>
          </section>
          <section class="col sec66">
            <h4>
              {{ 'conditionEditorTranslation.condition' | translate }}
            </h4>
          </section>
          <section class="col sec17">
            <button type="button" id="buttonSaveCondition" class="buttonFormRight saveConditionButton" @click="saveCondition">
              {{ 'conditionEditorTranslation.apply' | translate }}
            </button>
          </section>
        </section>

        <draggable
          tag="ul"
          id="listOfElements"
          :list="tree"
          :group="{ name: 'g1', put: (tree.length === 0)}"
          :sort="false"
          class="rootDragArea list-group"
          :class="{dragItems: (tree.length === 0)}"
        >
          <p v-if="tree.length === 0">
            {{ 'conditionEditorTranslation.dragAndDropArea' | translate }}
          </p>
          <LessonEditorConditionTreeItem
            :item="tree"
            parentType="root"
            :parentTestBool="testResult"
            :conditionTestMode="conditionTestMode"
            @change-condition="changeConditionAttribute"
          ></LessonEditorConditionTreeItem>
        </draggable>
      </div>

      <div class="col pageClipboard" id="sectionClipboard">
        <h4>
          {{ 'conditionEditorTranslation.clipboard' | translate }}
        </h4>
        <draggable
          tag="ul"
          id="listClipboardAdd"
          :list="clipboardListAdd"
          :group="{ name: 'g1', put: (clipboardList.length >= 0)}"
          :sort="false"
          class="rootDragArea rootDragArea2 list-group"
          :class="{dragItems: (clipboardList.length >= 0)}"
        >
          <div v-if="clipboardList.length >= 0">
            {{ 'conditionEditorTranslation.clipboardArea' | translate }}
          </div>
        </draggable>

        <draggable
          tag="ul"
          id="listClipboard"
          :list="clipboardListAdd"
          :group="{ name: 'g1', put: false}"
          :sort="false"
          class="rootDragArea rootDragArea2 list-group"
        >
          <li :class="['list-group-item']">
            <LessonEditorConditionTreeItem
              :item="clipboardList"
              parentType="root"
              :parentTestBool="false"
              :conditionTestMode="conditionTestMode"
              class="firstTreeItem"
              @change-condition="changeConditionAttribute"
            ></LessonEditorConditionTreeItem>
          </li>
        </draggable>
      </div>
    </div>

<!--
    <section class="wrapper completeButton">
      <button type="button" @click="checkIfComplete()">
        check if complete tree
      </button>
    </section>
-->

    <!--{
      {tree}}
      <br>
      {{clipboardList}}
    -->

    <BaseModal
      v-if="warningChangesVisible"
      id="changesModal"
      :bodyText="modalText"
      :isLeaveSiteWarning="true"
      :leftButtonText="$t('interventionTranslation.leave')"
      @close-modal="closeModal"
    ></BaseModal>

    <BaseNotification ref="notificationRef"></BaseNotification>

  </div>
</template>
<script>
import Vue from 'vue';
import draggable from "vuedraggable";
import {mapGetters, mapMutations} from 'vuex';
import router from '../router';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/de.js';
import LessonEditorConditionTreeItem from './LessonEditorConditionTreeItem.vue'
import BaseNotification from './BaseNotification.vue';
import BaseModal from './BaseModal.vue';
import questionConditionHelper from '../mixins/questionConditionHelper';

export default {
  name: 'LessonEditorConditionEditor',

  components: {
    draggable,
    BaseNotification,
    BaseModal,
    LessonEditorConditionTreeItem,
    flatPickr
  },

  mixins: [questionConditionHelper],

  data: function(){
    return {
      tree: [],
      complete: false,
      clipboardList: [],
      clipboardListAdd: [],
      questionsUsedInTree: [],
      testResult: false,
      warningChangesVisible: false,
      saveBool: false,
      modalText: "",
      oldTree: "",
      routerPathTo: '',
      leaveSiteBool: false,
      hoursList: [],
      minutesList: [],
      conditionTestMode: false,
    }
  },

  computed: {
    ...mapGetters([
      'getQuestionLabelList',
      'getFormElementsChanged',
      'getConditionEditor',
      'getConditionEditorData',
      'getCodeViewData',
      'getIsEditable',
      'getSelectedLanguage',
    ]),

    lessonId: function(){
      return Number(this.$route.params.lessonId)
    },

    newElements: function(){
      return [
        {
          nodeType: 'leaf',
          description: Vue.i18n.translate('conditionEditorTranslation.tooltipQuestion')
        },
        {
          nodeType: 'and',
          description: Vue.i18n.translate('conditionEditorTranslation.tooltipAnd')
        },
        {
          nodeType: 'or',
          description: Vue.i18n.translate('conditionEditorTranslation.tooltipOr')
        },
        {
          nodeType: 'not',
          description: Vue.i18n.translate('conditionEditorTranslation.tooltipNot')
        },
      ]
    },

    testButtonText: function(){
      if(!this.conditionTestMode){
        return Vue.i18n.translate('conditionEditorTranslation.test')
      }else{
        return Vue.i18n.translate('conditionEditorTranslation.hideTest')
      }
    },

    displayFormat: function(){
      return {
        "TextDate": Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.dateFormat'),
        "TextDateTime": Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.dateFormat') + " " +
          Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.timeFormat'),
        "TextTime": Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.timeFormat')
      }
    },

    configDateTime: function(){
      return {
        altFormat: this.displayFormat["TextDateTime"],
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: true,
        time_24hr: this.show24hrs,
        noCalendar: false,
        defaultHour: 0,
        defaultMinute: 0,
        locale: this.getSelectedLanguage,
        position: "auto center",
      }
    },

    configDate: function(){
      return {
        altFormat: this.displayFormat["TextDate"],
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: false,
        noCalendar: false,
        locale: this.getSelectedLanguage,
        position: "auto center",
      }
    },

    configTime: function(){
      return {
        altFormat: this.displayFormat["TextTime"],
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: true,
        time_24hr: this.show24hrs,
        noCalendar: true,
        defaultHour: 0,
        defaultMinute: 0,
        locale: this.getSelectedLanguage,
        position: "auto center",
      }
    },

    show24hrs: function(){
      return Vue.i18n.translateIn(this.getSelectedLanguage, 'elementsQuestionTranslation.ampm') === "false"
    },
  },

  watch: {
    clipboardListAdd: function(newVal){
      if(newVal.length > 0){
        var reverseList = this.clipboardList.reverse();
        reverseList.push(newVal[0]);
        this.clipboardList = reverseList.reverse();
        this.clipboardListAdd = [];
      }
    },

    tree: function(newVal){
      if(newVal.length < 1 && this.conditionTestMode){
        this.conditionTestMode = false;
      }
    },

    //change used questions list if item dragged to/from clipboard
    clipboardList: function(newVal, oldVal){
      if(newVal.length === oldVal.length){
        if(this.tree.length > 0){
          var list = this.getUsedQuestionsTree(this.tree[0]);
          this.questionsUsedInTree = this.deleteDuplicates(list);
        }else{
          this.questionsUsedInTree = [];
        }
      }
    },
  },

  mounted(){
    if(!this.getConditionEditor || !this.getIsEditable){
      this.SET_FORMELEMENTSCHANGED(false);
      this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('httpHelperTranslation.errorCode10')});
      router.go(-1);
    }else{
      this.closeNotification();
      this.questionLabelList = JSON.parse(JSON.stringify(this.getQuestionLabelList));
      for(var question in this.questionLabelList){
        if(this.questionLabelList[question] != undefined && this.questionLabelList[question] != null &&
            this.questionLabelList[question] != 0 && this.questionLabelList[question].type != "text"){
          this.questionLabelList[question].testValue = "";
        }
      }
      this.conditionTestMode = false;

      var newTree;
      //if getCodeViewData set -> switched from code to editor view -> take new & old from there
      if(this.getCodeViewData != ""){
        if(this.getCodeViewData.new != ""){
          newTree = JSON.parse(JSON.stringify(this.getCodeViewData.new));
          if(newTree.nodeType != ""){
            this.tree = [this.initTree(newTree)];
          }
        }//else this.tree is []
        this.oldTree = this.getCodeViewData.old;
        if(this.getCodeViewData.clipboard != ""){
          this.clipboardList = this.getCodeViewData.clipboard;
        }//else this.clipboardList is []
      }
      //else switched from lessonEditor -> old & new are the condition of the block
      else if(this.getConditionEditorData.condition != ""){
        newTree = JSON.parse(JSON.stringify(this.getConditionEditorData.condition));
        if(newTree.nodeType != ""){
          this.tree = [this.initTree(newTree)];
        }

        //oldTree as string as it was in lessonEditor (not initalized) -> for code view
        this.oldTree = JSON.stringify(this.getConditionEditorData.condition);
      }

      if(this.tree.length > 0){
        var list = this.getUsedQuestionsTree(this.tree[0]);
        this.questionsUsedInTree = this.deleteDuplicates(list);
      }
    }

    window.addEventListener('beforeunload', this.beforeunloadFunction);
  },

  beforeRouteLeave(to, from, next){
    this.routerPathTo = to;
    var treeChanged = false;

    //if leaveSiteBool -> leave without actions
    if(!this.leaveSiteBool && this.tree.length > 0){
      var oldTreeInited;
      if(this.oldTree.length > 0){
        //oldTree is only string as condition was in lessonEditor -> not initialized -> init now
        oldTreeInited = JSON.stringify([this.initTree(JSON.parse(this.oldTree))]);
      }else{
        oldTreeInited = '';
      }

      if(oldTreeInited != JSON.stringify(this.tree)){
        treeChanged = true;
      }
    }

    if(this.getConditionEditor && to.name != "conditionCodeEditor" && !((to.name === "lessonEditor" || to.name === "diaryEditor") && this.lessonId != 0) &&
        !(to.name === "interventionEditor" && this.lessonId === 0)){
      var text = "";

      //if condition for lesson or diary editor: elements & condition may be different
      if(this.lessonId != 0){
        //check lesson elements
        if(this.getFormElementsChanged){
          text = Vue.i18n.translate('generalTranslation.unsavedChanges') + ": " +  Vue.i18n.translate('lessonTranslation.lessonElements');
        }
      }else{ //if condition for config: intervention details, config & condition may have changed
        //check intervention details
        if(this.getConditionEditorData.formChanged){
          text = Vue.i18n.translate('generalTranslation.unsavedChanges') + ": " + Vue.i18n.translate('interventionTranslation.interventionDetails');
        }

        if(this.getConditionEditorData.configChanged){
          if(text === ""){
            text = Vue.i18n.translate('generalTranslation.unsavedChanges') + ": ";
          }else{
            text += ", "
          }
          text += Vue.i18n.translate('interventionTranslation.unsavedChangesConfig');
        }
      }

      //check condition
      if(treeChanged && !this.saveBool){
        if(text === ""){
          text = Vue.i18n.translate('generalTranslation.unsavedChanges') + ": ";
        }else{
          text += ", "
        }
        text += Vue.i18n.translate('conditionEditorTranslation.condition');
      }

      if(text != ""){
        this.modalText = text;
        this.warningChangesVisible = true;
        next(false);
      }else{
        //if not saved set conditioneditordata empty in lesson; not for config since it contains all data of interventioneditor, condition stays as it was
        if(!this.saveBool && to.name != "conditionCodeEditor" && !(to.name === "interventionEditor" && this.lessonId === 0)){
          this.SET_CONDITIONEDITORDATA("");
        }
        next({ replace: true });
      }
    }else{
      //if not saved set conditioneditordata empty in lesson; not for config since it contains all data of interventioneditor, condition stays as it was
      if(!this.saveBool && to.name != "conditionCodeEditor" && !(to.name === "interventionEditor" && this.lessonId === 0)){
        this.SET_CONDITIONEDITORDATA("");
      }
      next({ replace: true });
    }
  },

  beforeDestroy(){
    window.removeEventListener('beforeunload', this.beforeunloadFunction);
  },

  methods: {
    ...mapMutations([
      'SET_CONDITIONEDITOR',
      'SET_NOTIFICATIONTEXT',
      'SET_FORMELEMENTSCHANGED',
      'SET_CONDITIONEDITORDATA',
      'SET_CODEVIEWDATA',
    ]),

    // addItem: function(obj){
    //   var leafType = '';
    //   if(obj.nodeType === "leaf"){
    //     leafType = "question";
    //   }
    //   obj.item.children.push({
    //     name: 'node',
    //     nodeType: obj.nodeType,
    //     leafType: leafType,
    //     questionLabel: '',
    //     operation: '',
    //     value: '',
    //     valuesForLabel: [],
    //     children: [],
    //     testBool: false,
    //     secondValue: "",
    //   })
    // },

    deleteItem: function(obj){
      var children = obj.item;
      children.splice(obj.index,1);
      obj.item = children;

      //get new list for testing & eliminate duplicates
      if(this.tree.length > 0){
        var list = this.getUsedQuestionsTree(this.tree[0]);
        this.questionsUsedInTree = this.deleteDuplicates(list);
        if(this.checkIfComplete(this.tree)){
          this.updateTest();
        }
      }else{
        this.questionsUsedInTree = [];
        this.conditionTestMode = false;
      }
    },

    changeConditionAttribute: function(obj){
      switch(obj.name){
        case "label":
          this.changeQuestionlabelLeafType(obj);
        break;

        case "operation":
          this.changeOperation(obj);
        break;

        case "value":
          this.changeValue(obj);
        break;

        case "second-value":
        this.changeSecondValue(obj);
        break;

        case "delete-item":
        this.deleteItem(obj);
        break;

        case "change-order":
        this.changeOrder(obj);
        break;

        default:
        break;
      }
    },

    changeQuestionlabelLeafType: function(obj){
      var oldType = JSON.stringify(obj.item.leafType);
      var newType = this.questionLabelList[obj.value].type;
      obj.item.leafType = newType;
      if(newType === "question"){
        obj.item.operation = "";
        obj.item.secondValue = "";
      }else if(newType === "text" || (newType === "date" && this.questionLabelList[obj.value].value[0] === "TextTime")){
        obj.item.operation = "answered";
      }
      if(newType === "text" && oldType != "text"){
        this.questionLabelList[obj.value].testValue = "";
      }
      if(newType === "date" && oldType === JSON.stringify("date") && this.questionLabelList[obj.item.questionLabel].value[0] != this.questionLabelList[obj.value].value[0]){
        this.questionLabelList[obj.value].testValue = "";
        this.questionLabelList[obj.item.questionLabel].testValue = "";
      }

      obj.item.questionLabel = obj.value;
      obj.item.valuesForLabel = this.questionLabelList[obj.value].value;
      if(!obj.item.valuesForLabel.includes(obj.item.value)){
        obj.item.value = "";
      }

      if(obj.item.operation != "answered"){
        console.log("value leer")
        this.conditionTestMode = false;
      }

      //get new list for testing & eliminate duplicates
      if(this.tree.length > 0){
        var list = this.getUsedQuestionsTree(this.tree[0]);
        this.questionsUsedInTree = this.deleteDuplicates(list);
        if(this.checkIfComplete(this.tree)){
          this.updateTest();
        }
      }else{
        this.questionsUsedInTree = [];
      }
    },

    changeOperation: function(obj){
      obj.item.operation = obj.value;
      if(obj.value === "answered"){
        obj.item.value = "";
      }
      if(obj.value != 'between'){
        obj.item.secondValue = "";
      }
      if(this.checkIfComplete(this.tree)){
        this.updateTest();
      }
    },

    changeValue: function(obj){
      if(obj.item.leafType === "slider" || obj.item.leafType === "table"){
        obj.item.value = Number(obj.value);
      }else if(obj.item.leafType === "question" && obj.value === "true"){
        obj.item.value = true;
      }else if(obj.item.leafType === "question" && obj.value === "false"){
        obj.item.value = false;
      }else{
        obj.item.value = obj.value;
      }
      if(this.checkIfComplete(this.tree)){
        this.updateTest();
      }
    },

    changeSecondValue: function(obj){
      if(obj.item.leafType === "slider" || obj.item.leafType === "table"){
        obj.item.secondValue = Number(obj.value);
      }else{
        obj.item.secondValue = obj.value;
      }
      if(this.checkIfComplete(this.tree)){
        this.updateTest();
      }
    },

    changeOrder: function(obj){
      var itemList = obj.item;
      var start = 0;
      var end = itemList.length - 1;
      itemList.splice(end, 0, itemList.splice(start, 1)[0]);
      obj.item = itemList;
    },

    checkIfComplete: function(){
      this.complete = "";
      if(this.tree.length > 0){
        this.complete = this.checkIfCompleteTree(this.tree[0]);
      }else{
        this.complete = false;
      }
      return this.complete
    },

    checkIfCompleteTree: function(tree){
      if(tree.nodeType === "leaf"){
        if(tree.questionLabel === "" || (tree.value === "" && tree.operation != "answered")){
          return false
        }
        if((tree.leafType === 'date' || tree.leafType === 'slider') && tree.operation === ""){
          return false
        }
        return true
      }else if(tree.nodeType === "and" || tree.nodeType === "or"){
        if(!(tree.children.length > 1)){
          return false
        }else{
          var boolList = [];
          for(var child in tree.children){
            boolList.push(this.checkIfCompleteTree(tree.children[child]));
          }
          return !boolList.includes(false)
        }
      }else if(tree.nodeType === "not"){
        if(tree.children.length != 1){
          return false
        }else{
          return this.checkIfCompleteTree(tree.children[0])
        }
      }
      return false
    },

    getUsedQuestionsTree: function(tree){
      if(tree.nodeType === "leaf"){
        if(tree.questionLabel != "" || tree.questionLabel === "0" || tree.questionLabel === 0){
          return [Number(tree.questionLabel)]
        }else{
          return []
        }
      }else if(tree.nodeType === "and" || tree.nodeType === "or"){
        var childQuestions = [];
        for(var child in tree.children){
          childQuestions = childQuestions.concat(this.getUsedQuestionsTree(tree.children[child]));
        }
        return childQuestions
      }else if(tree.nodeType === "not"){
        return (tree.children.length > 0) ? this.getUsedQuestionsTree(tree.children[0]) : []
      }
    },

    deleteDuplicates: function(list){
      var newList = [];
      for(var label in list){
        if((!newList.includes(Number(list[label]))) && ((list[label] != "" && list[label] > 0) || list[label] === 0)){
          newList.push(Number(list[label]));
        }
      }
      return newList
    },

    testConditionFunction: function(){
      if(!this.conditionTestMode){
        this.testCondition();
      }else{
        this.hideTestCondition();
      }
    },

    testCondition: function(){
      if(this.tree.length === 0 || !this.checkIfCompleteTree(this.tree[0])){
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('conditionEditorTranslation.errorCondition')});
      }else{
        this.conditionTestMode = true;
        this.testResult = this.evaluateCondition(this.tree[0]);
      }
    },

    hideTestCondition: function(){
      this.conditionTestMode = false;
    },

    updateTest: function(){
      if(this.conditionTestMode){
        this.testCondition();
      }
    },

    setTime: function(questionIndex){
      if(this.hoursList[questionIndex] != null && (this.hoursList[questionIndex] === 0 || this.hoursList[questionIndex] != "") &&
          this.minutesList[questionIndex] != null && (this.minutesList[questionIndex] === 0 || this.minutesList[questionIndex] != "")){
        this.questionLabelList[questionIndex].testValue = "a";
        this.updateTest();
      }
    },

    evaluateCondition: function(tree){ //todo use evaluationService
      var bool;
      var boolList = [];
      if(tree.nodeType === "leaf"){
        var selectedTestValue = this.questionLabelList[Number(tree.questionLabel)].testValue;
        if(tree.operation === "answered"){
          bool = (selectedTestValue != "" || selectedTestValue === false)
        }else{
          if(tree.leafType === 'question'){
            bool = (selectedTestValue === tree.value);
          }else if(selectedTestValue === "" || selectedTestValue === false){
            bool = false;
          }else{
            switch(tree.operation) {
              case "less":
                bool = (selectedTestValue < tree.value)
              break;

              case "lessEqual":
                bool = (selectedTestValue <= tree.value)
              break;

              case "greater":
                bool = (selectedTestValue > tree.value)
              break;

              case "greaterEqual":
                bool = (selectedTestValue >= tree.value)
              break;

              case "equals":
                bool = (selectedTestValue === tree.value)
              break;

              case "notEquals":
                bool = (selectedTestValue != tree.value)
              break;

              case "between":
                bool = (tree.value <= selectedTestValue && selectedTestValue <= tree.secondValue)
              break;

              default:
              break;
            }
          }
        }
      }else{
        for(var child in tree.children){
          boolList.push(this.evaluateCondition(tree.children[child]));
        }
        if(tree.nodeType === "and"){
          bool = !boolList.includes(false);
        }else if(tree.nodeType === "or"){
          bool = boolList.includes(true);
        }else if(tree.nodeType === "not"){
          bool = !boolList[0];
        }
      }
      tree.testBool = bool;
      return bool
    },

    saveCondition: function(){
      if(this.checkIfComplete()){
        var saved = this.saveTree(this.tree[0]);
        var data = this.getConditionEditorData;
        data.condition = saved;
        this.SET_CONDITIONEDITORDATA(data);
        this.SET_FORMELEMENTSCHANGED(true);
        this.saveBool = true;
        router.go(-1);
      }else{
        this.SET_NOTIFICATIONTEXT({type: "error", text: Vue.i18n.translate('conditionEditorTranslation.errorConditionNotComplete')});
      }
    },

    saveTree: function(tree){
      var saveTree = {
        "nodeType": tree.nodeType
      }

      if(tree.nodeType === "leaf"){
        saveTree.leafType = tree.leafType;
        //tree.questionLabel can only be "" when switching to code view (when saveCondition: checkIfComplete ensures that != "")
        saveTree.questionLabel = (tree.questionLabel != "") ? Number(tree.questionLabel) : "";

        if(tree.operation != "answered"){
          saveTree.value = tree.value;
        }

        if(tree.operation != ""){
          saveTree.operation = tree.operation;
        }

        if(tree.leafType != "question" && tree.operation === "between"){
          saveTree.secondValue = tree.secondValue;
        }
      }else if(tree.nodeType === "and" || tree.nodeType === "or" || tree.nodeType === "not"){
        //checkIfComplete ensures that not has exactly one child, and & or have at least two children
        saveTree.children = [];
        for(var child in tree.children){
          saveTree.children.push(this.saveTree(tree.children[child]));
        }
      }
      return saveTree
    },

    initTree: function(tree){
      if(tree.nodeType === "leaf"){
        if(tree.leafType === "question"){
          if(typeof tree.operation === "undefined"){
            tree.operation = "";
          }
          tree.secondValue = "";
        }else if(tree.operation != "between"){
          tree.secondValue = "";
        }
        tree.children = [];
        tree.questionLabel = (tree.questionLabel > 0) ? String(Number(tree.questionLabel)) : "";
        tree.valuesForLabel = (tree.questionLabel > 0) ? this.questionLabelList[tree.questionLabel].value : [];
      }else{
        tree.leafType = "";
        tree.questionLabel = "";
        tree.operation = "";
        tree.value = "";
        tree.secondValue = "";
        tree.valuesForLabel = [];
        for(var child in tree.children){
          this.initTree(tree.children[child]);
        }
      }
      tree.testBool = false;
      return tree
    },

    cloneFunction: function(el){
      var newEl = {
        name: 'node',
        nodeType: el.nodeType,
        leafType: '',
        questionLabel: '',
        operation: '',
        value: '',
        valuesForLabel: [],
        children: [],
        testBool: false,
        secondValue: "",
      };
      if(newEl.nodeType === 'leaf'){
        newEl.leafType = 'question';
      }

      return newEl
    },

    closeNotification: function(){
      this.$refs["notificationRef"].closeNotification();
    },

    closeModal: function(done){
      this.warningChangesVisible = false;

      if(done){
        this.leaveSiteBool = true;
        if(((this.routerPathTo.name === "lessonEditor" || this.routerPathTo.name === "diaryEditor") && this.lessonId != 0) ||
            (this.routerPathTo.name === "interventionEditor" && this.lessonId === 0)){
          //formElementsChanged stays as it was before opening conditionEditor
          router.go(-1);
        }else{
          this.SET_CONDITIONEDITOR(false);
          this.SET_FORMELEMENTSCHANGED(false);
          router.replace(this.routerPathTo);
        }
      }
    },

    openCodeView: function(){
      var data = { old: this.oldTree, new: "", clipboard: "" };

      if(this.tree.length > 0){
        data.new = this.saveTree(this.tree[0]);
      }

      if(this.clipboardList.length > 0){
        data.clipboard = this.clipboardList;
      }

      this.SET_CODEVIEWDATA(data);
      router.replace({ name: 'conditionCodeEditor', params: { lessonId: this.lessonId }});
    },

    getNodeTypeText: function(nodeType){
      if(nodeType === "and"){
        return Vue.i18n.translate('conditionEditorTranslation.and')
      }else if(nodeType === "or"){
        return Vue.i18n.translate('conditionEditorTranslation.or')
      }else{
        return Vue.i18n.translate('conditionEditorTranslation.not')
      }
    },

    beforeunloadFunction: function(e){
      e.preventDefault();
      e.returnValue = '';
      return;
    },
  },
}
</script>
